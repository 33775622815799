import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import { RoundedOrangeButton } from "../../../_UtilityComponent/Button/RoundedButton";
import style from "./ShipmentCourier.module.css";
import { withRouter } from "react-router-dom";
import { StyledButtonTheming } from "../../../_UtilityComponent/Button/StyledButton";
import { CourierSelectorBtn } from "./ShipmentCourier.styles";
class ShipmentCourier extends Component {
  render() {
    return (
      <div>
        <>
          {/* is it not fail? (don't have any error API or no route found) if not proceed, if yes print error*/}
          {this.props.cartItem.isRouteAvailable ? (
            <>
              {/* if it's first time and not yet checked just show 1 button, else show selected courier and rates price of the selected courier   */}
              {!this.props.cartItem.isServiceSelected ? (
                <MDBCol className="text-center">
                  <StyledButtonTheming
                    size="sm"
                    onClick={() =>
                      this.props.popCourierSelector("COURIER", this.props.cartItem)
                    }
                  >
                    Pilih Kurir
                  </StyledButtonTheming>
                </MDBCol>
              ) : (
                <>
                  <CourierSelectorBtn
                    className={`mt-3 mb-3`}
                    onClick={() =>
                      this.props.popCourierSelector("COURIER", this.props.cartItem)
                    }
                  >
                    <MDBRow>
                      <MDBCol>
                        <MDBCol className="font-weight-bold" size="12">
                          {this.props.cartItem.selectedService.courier_name}{" "}
                          {this.props.cartItem.selectedService.service}
                        </MDBCol>
                        <MDBCol size="12">
                          {this.props.cartItem.selectedService.estimate != "0-0" ||
                          this.props.cartItem.selectedService.estimate != "1-1" ||
                          this.props.cartItem.selectedService.estimate != ""
                            ? this.props.cartItem.selectedService.estimate + " hari"
                            : ""}
                        </MDBCol>
                      </MDBCol>
                      <MDBCol size="4" className={style.courierSelectorArrow}>
                        <MDBIcon icon="angle-right" />
                      </MDBCol>
                    </MDBRow>
                  </CourierSelectorBtn>
                </>
              )}
            </>
          ) : (
            <MDBCol className="mt-3 mb-3">
              <MDBCol className="red-text text-center">
                Maaf, barang ini tidak dapat dikirimkan ke alamat anda. Silahkan
                untuk mengganti jenis barang atau alamat anda.
              </MDBCol>
              <RoundedOrangeButton
                size="sm"
                outline
                onClick={() => this.props.history.push("/cart")}
              >
                Kembali ke keranjang
              </RoundedOrangeButton>
            </MDBCol>
          )}
        </>
        {/* is service already selected ? show the price of calculated courier and add to subtotal, else just subtotal of items */}
        {this.props.cartItem.isServiceSelected ? (
          <>
            <MDBRow>
              <MDBCol>Harga Pengiriman :</MDBCol>
              <MDBCol>
                {new Intl.NumberFormat("id-ID", {
                  style: "currency",
                  currency: "IDR",
                }).format(this.props.cartItem.selectedService.courier_price)}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>Subtotal :</MDBCol>
              <MDBCol>
                {new Intl.NumberFormat("id-ID", {
                  style: "currency",
                  currency: "IDR",
                }).format(this.props.cartItem.selectedService.cart_price)}
              </MDBCol>
            </MDBRow>
          </>
        ) : (
          <MDBRow>
            <MDBCol>Subtotal :</MDBCol>
            <MDBCol>
              {new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
              }).format(this.props.cartItem.cartPrice)}
            </MDBCol>
          </MDBRow>
        )}
      </div>
    );
  }
}

export default withRouter(ShipmentCourier);
