import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCard, MDBCol, MDBBtn } from "mdbreact";
import ImageComponent from "../../../_UtilityComponent/Image/ImageComponent";
import ShipmentCourier from "../ShipmentCourier/ShipmentCourier";
class ShipmentItem extends Component {
  render() {
    return (
      <>
        {this.props.cart.map((cartItem, cartIndex) => {
          return (
            <div key={cartItem.id}>
              <hr />
              <MDBContainer>
                <div className="mt-1 mb-1">
                  Dikirim dari : {cartItem.sellerLocation.zip.sub_district}
                </div>
                {cartItem.cartDetail.map((cartDetailItem, cartDetailIndex) => {
                  return (
                    <div key={cartDetailItem.id}>
                      <MDBRow>
                        <MDBCol size="4">
                          <ImageComponent
                            isSquare={true}
                            img={cartDetailItem.linkPicture}
                          />
                        </MDBCol>
                        <MDBCol className="shipment--detail--main" size="8">
                          <MDBRow className="prod-title">
                            <MDBCol className="font-weight-bolder">
                              {cartDetailItem.productName}
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol>
                              {cartDetailItem.qty} item
                              {cartDetailItem.qty > 1 ? "s" : ""} (
                              {cartDetailItem.weight * cartDetailItem.qty} gr)
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol className="shipment--detail--main">
                              <div className="prod-price">
                                {new Intl.NumberFormat("id-ID", {
                                  style: "currency",
                                  currency: "IDR",
                                }).format(cartDetailItem.price)}
                              </div>
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </MDBRow>
                    </div>
                  );
                })}
                <ShipmentCourier
                  cartItem={cartItem}
                  popCourierSelector={this.props.popCourierSelector}
                />
              </MDBContainer>
            </div>
          );
        })}
        <hr />
      </>
    );
  }
}

export default ShipmentItem;
