import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { PrivateRoute } from "../utils/PrivateRoute";
//specifik component
import Home1 from "../template1/pages/Home";
import Home2 from "../template2/pages/Home";
import Home3 from "../template3/pages/Home";
import Home4 from "../template4/pages/Home";
import Home5 from "../template5/pages/Home";
//general component
import Cart from "../pages/Cart";
import SellerLocation from "../pages/SellerLocation";
import SellerCategory from "../pages/SellerCategory";
import SellerProducts from "../pages/SellerProducts";
import SellerProductsForm from "../pages/SellerProductsForm";
import ProfileUser from "../pages/ProfileUser";
import ProfileSeller from "../pages/ProfileSeller";
import ProductDetail from "../pages/ProductDetail";
import Login from "./pages/Login";
import RegisterUser from "../pages/RegisterUser";
import Shipment from "../pages/Shipment";
import AboutSetting from "../pages/AboutSetting";
import About from "../pages/About";
import ChangePasswordToken from "../pages/ChangePasswordToken";
import Transaction from "../pages/Transaction";
import PrintTemplateMain from "../components/PrintTemplate/PrintTemplateMain";
import Activation from "../pages/Activation";
import BankAccount from "../pages/BankAccount";

const home_list = {
  home1: Home1,
  home2: Home2,
  home3: Home3,
  home4: Home4,
  home5: Home5,
};

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route
          path="/"
          exact
          component={home_list[`home${this.props.template_id}`]}
        />
        <Route path="/login" exact component={Login} />
        <Route path="/register" exact component={RegisterUser} />
        <Route exact path="/activation/:token" component={Activation} />
        <Route
          path="/category/:page?"
          exact
          component={home_list[`home${this.props.template_id}`]}
        />
        <Route path="/product_detail/:id" exact component={ProductDetail} />
        <PrivateRoute path="/cart" exact component={Cart} />
        <PrivateRoute path="/cart/shipment" exact component={Shipment} />
        <PrivateRoute
          path="/admin/sellerlocation"
          exact
          component={SellerLocation}
        />
        <Route path="/admin/sellercategory" exact component={SellerCategory} />
        <Route path="/admin/sellerproducts" exact component={SellerProducts} />
        <PrivateRoute
          path="/admin/sellerproducts/add-product"
          exact
          component={SellerProductsForm}
        />
        <PrivateRoute
          path="/admin/sellerproducts/edit-product/:id"
          exact
          component={SellerProductsForm}
        />
        <PrivateRoute
          path="/admin/sellerprofile"
          exact
          component={ProfileSeller}
        />
        <PrivateRoute
          path="/admin/storeprofile"
          exact
          component={AboutSetting}
        />
        <PrivateRoute
          path="/admin/bank_account"
          exact
          component={BankAccount}
        />
        <PrivateRoute path="/profile" exact component={ProfileUser} />
        <PrivateRoute path="/profile/location" exact component={ProfileUser} />
        <Route path="/about" exact component={About} />
        <Route
          exact
          path="/change_password_token/:token?"
          component={ChangePasswordToken}
        />
        <PrivateRoute
          exact
          path="/transaction/:page?"
          component={Transaction}
        />
        <PrivateRoute
          exact
          path="/print/:type?/:id?"
          component={PrintTemplateMain}
        />
      </Switch>
    );
  }
}

export default Routes;
