import React, { Component } from "react";
import HomeProductContext from "../context/HomeProductContext";
import SellerPrmContext  from "../../SellerPrmContext";
import ProductPageMain from "../components/ProductPage/ProductPageMain";

export default class ProductPage extends Component {
  render() {
    return (
      <div>
        <SellerPrmContext.Consumer>
          {(sellerPrm) => (
            <HomeProductContext.Consumer>
              {(homeProduct) => (
                <ProductPageMain
                  sellerPrm={sellerPrm}
                  homeProduct={homeProduct}
                />
              )}
            </HomeProductContext.Consumer>
          )}
        </SellerPrmContext.Consumer>
      </div>
    );
  }
}
