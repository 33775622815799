import React from "react";
import { MDBRow, MDBCol, MDBInput } from "mdbreact";

export const CartTitlePage = (props) => {
  return (
    <>
      <MDBRow>
        <MDBCol className="text-center mt-3">
          <h4>Keranjang Belanja</h4>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol className="text-center mt-3">
          <p>{props.cartTotalItem} barang</p>
        </MDBCol>
      </MDBRow>
      {props.cartTotalItem > 0 ? (
        <MDBInput
          label="Pilih Semua"
          onChange={() => props.changeHandlerCheckAll(props.sellerId)}
          type="checkbox"
          id="checkAll"
          checked={props.checkAll}
          filled
        />
      ) : (
        ""
      )}
    </>
  );
};
