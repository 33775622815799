import React, { Component } from "react";
import {
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBView,
} from "mdbreact";

class CarouselProduct extends Component {
  // componentDidUpdate(prevProps, prevState) {
  //   if (this.props.activeItem !== prevProps.activeItem) {
  //     console.log(this.props.activeItem);
  //   }
  // }

  render() {
    return (
      <MDBCarousel
        // style={{paddingLeft: "0px", paddingRight: "0px"}}
        activeItem={this.props.activeItem}
        length={this.props.photo.length}
        showControls={true}
        showIndicators={true}
        interval={false}
      >
        <MDBCarouselInner>
          {this.props.photo.map((image) => {
            return (
              <MDBCarouselItem key={image.id} itemId={image.itemId}>
                <MDBView>
                  <img className="d-block w-100" src={image.link} />
                </MDBView>
              </MDBCarouselItem>
            );
          })}
        </MDBCarouselInner>
      </MDBCarousel>
    );
  }
}

export default CarouselProduct;
