import React from "react";
import { MDBCol, MDBRow, MDBBtn, MDBContainer } from "mdbreact";
import { Text } from "../../../context/Language";
import { StyledButtonTheming } from "../../_UtilityComponent/Button/StyledButton";

export const CartTotalWithCheckout = (props) => {
  const tempData = props.cart.find((a) =>
    a.cartDetail.find((b) => b.isCheck === true)
  );
  return (
    <>
      <MDBContainer>
        <hr style={{ borderTop: "1px solid black" }} />
      </MDBContainer>
      <MDBRow>
        <MDBCol className="text-center mt-4">
          <MDBCol size="12" style={{ fontWeight: "500" }}>
            TOTAL
          </MDBCol>
          <MDBCol size="12">
            {new Intl.NumberFormat("id-ID", {
              style: "currency",
              currency: "IDR",
            }).format(props.total)}
          </MDBCol>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol className="text-center mt-4 mb-4">
          {tempData ? (
            <StyledButtonTheming
              $width100
              onClick={() => props.checkUserLocationData()}
            >
              <Text pid="cartPage" tid="checkout" />
            </StyledButtonTheming>
          ) : (
            <StyledButtonTheming $width100 disabled>
              <Text pid="cartPage" tid="checkout" />
            </StyledButtonTheming>
          )}
        </MDBCol>
      </MDBRow>
    </>
  );
};
