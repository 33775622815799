import React, { Component } from "react";
import { MDBContainer } from "mdbreact";
import { WebSearchForm } from "./WebSearchForm/WebSearchForm";
import { WebSearchResult } from "./WebSearchResult/WebSearchResult";

export default class WebSearchView extends Component {
  render() {
    return (
      <>
        <MDBContainer className="pt-4 mb-4">
          <WebSearchForm
            changeHandler={this.props.changeHandler}
            submitHandler={this.props.submitHandler}
          />
        </MDBContainer>
        <WebSearchResult data={this.props.data} />
      </>
    );
  }
}
