import { MDBCol, MDBRow } from "mdbreact";
import React from "react";
import * as S from "../_Styled/CardPayment.styles.js";

export const UserPaymentStatusItem = (props) => {
  return (
    <div>
      <S.CardContainerPayment className="mt-3 mb-3">
        <S.CardHeaderPayment>
          Transaction Time : {props.item.transaction_time}
        </S.CardHeaderPayment>
        <S.CardBodyPayment>
          <MDBRow>
            <MDBCol size="12">
              No.Order : {props.item.order_id}
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="12">
              Status : {props.item.status}
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="12">
              Payment Type : {props.item.payment_type}
            </MDBCol>
          </MDBRow>
        </S.CardBodyPayment>
        <S.CardBodyPayment>
          <MDBRow>
            <MDBCol size="3"></MDBCol>
            <MDBCol size="9">
              <MDBRow>
                <MDBCol size="12">Total Pembayaran</MDBCol>
                <MDBCol size="12">
                  {new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                  }).format(props.item.gross_amount)}
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </S.CardBodyPayment>
      </S.CardContainerPayment>
    </div>
  );
};
