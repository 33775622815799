import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBRow,
} from "mdbreact";
import React, { Component } from "react";
import { CMDBBtn } from "../StyledComponent";

export default class StoreView extends Component {
  render() {
    const {
      logo,
      name_web,
      instagram,
      twitter,
      facebook,
      subdomain,
    } = this.props;
    return (
      <MDBCard style={{ minWidth: "450px" }}>
        <MDBCardBody>
          <MDBRow style={{ height: "140px" }}>
            <MDBCol size="4" middle>
              <img
                className="img-fluid img-thumbnail z-depth-2"
                src={logo}
                style={{ maxWidth: "125.65px" }}
              />
            </MDBCol>
            <MDBCol size="8" middle>
              <MDBRow>Store Name :{name_web}</MDBRow>
              <MDBRow>Facebook :{facebook}</MDBRow>
              <MDBRow>Twitter :{twitter}</MDBRow>
              <MDBRow>Instagram :{instagram}</MDBRow>
              <MDBRow end>
                <CMDBBtn
                  active
                  bg_color="#804199"
                  onClick={() => {
                    window.location = `https://${subdomain}`;
                  }}
                >
                  Kunjungi
                </CMDBBtn>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    );
  }
}
