import React, { Component } from "react";
import ProfileSellerMain from "../components/ProfileView/ProfileSellerMain";
import SellerPrmContext from "../../SellerPrmContext";

export default class ProfileUser extends Component {
  render() {
    return (
      <SellerPrmContext.Consumer>
        {(sellerprm) => <ProfileSellerMain sellerprm={sellerprm} />}
      </SellerPrmContext.Consumer>
    );
  }
}
