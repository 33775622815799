import { MDBBtn, MDBCol, MDBContainer, MDBInput, MDBRow } from "mdbreact";
import React from "react";
import AddressSelector from "./AddressSelector";
import { ManageSellerAddContainer } from "./ManageSellerLocationCategory.styles";

export const ManageSellerLocationAdd = (props) => {
  return (
    <ManageSellerAddContainer>
      <AddressSelector
        showModal={props.addressSelectorModal}
        toggleHandler={props.addressSelectorHandler}
        textLocation={props.textLocation}
        searchLocation={props.searchLocation}
        locationList={props.locationList}
        selectedLocation={props.selectedLocation}
      />
      <MDBContainer>
        <form
          className="needs-validation"
          onSubmit={(e) => {
            props.submitHandler(e, props.sellerId);
          }}
          noValidate
        >
          <MDBInput
            value={props.alias}
            name="alias"
            onChange={props.changeHandler}
            type="text"
            className="form-control"
            label="Label Toko/Gudang"
            required
          >
            <small className="form-text text-muted">
              Contoh: Toko Merah atau Gudang Bekasi
            </small>
            <div className="invalid-feedback">Masukan Nama Label</div>
          </MDBInput>
          <MDBInput
            value={props.address}
            name="address"
            onChange={props.changeHandler}
            type="textarea"
            className="form-control"
            label="Alamat Toko/Gudang"
            rows="2"
            required
          >
            <div className="invalid-feedback">Masukan Alamat Toko/Gudang</div>
          </MDBInput>
          <div onClick={props.addressSelectorHandler}>
            <MDBInput
              value={props.district}
              name="district"
              type="textarea"
              className="form-control"
              label="Kecamatan/Kota/Kode Pos"
              rows="2"
              required
            >
              <div className="invalid-feedback">
                Masukan Kecamatan/Kota/Kode Pos Anda
              </div>
            </MDBInput>
          </div>
          <MDBInput
            value={props.name}
            name="name"
            onChange={props.changeHandler}
            type="text"
            className="form-control"
            label="Atas Nama"
            required
          >
            <div className="invalid-feedback">
              Masukan Nama Pengirim/Nama Toko
            </div>
          </MDBInput>
          <MDBInput
            value={props.hp}
            name="hp"
            onChange={props.changeHandler}
            type="text"
            className="form-control"
            label="Nomor Handphone"
            required
          >
            <div className="invalid-feedback">Masukan nomor handphone</div>
          </MDBInput>
          <MDBBtn
            className="w-100 mr-0 ml-0 mt-2"
            color="deep-orange"
            type="submit"
          >
            Tambah Alamat
          </MDBBtn>
        </form>
      </MDBContainer>
    </ManageSellerAddContainer>
  );
};
