import React, { Component } from "react";
import SellerCategoryContext from "../context/SellerCategoryContext";
import SellerCategoryMain from "../components/SellerCategory/SellerCategoryMain";

export default class SellerCategory extends Component {
  render() {
    return (
      <SellerCategoryContext.Consumer>
        {(sellerCategory) => (
          <SellerCategoryMain sellerCategory={sellerCategory} />
        )}
      </SellerCategoryContext.Consumer>
    );
  }
}
