import React, { Component } from "react";
import SellerLocationView from "./SellerLocationView";
import SellerLocationFormModal from "./SellerLocationFormModal";

export default class SellerLocationMain extends Component {
  componentDidMount() {
    this.props.sellerLocation.listSellerLocation();
    this.props.sellerLocation.getZipLocation();
  }

  render() {
    // if (
    //   !this.props.sellerLocation.isLoading &&
    //   this.props.sellerLocation.dataTable
    // ) {
      
    return (
      <div>
        <SellerLocationFormModal
          sellerLocationId={this.props.sellerLocation.updateSellerLocationId}
          name={this.props.sellerLocation.name}
          hp={this.props.sellerLocation.hp}
          alias={this.props.sellerLocation.alias}
          zipCode={this.props.sellerLocation.zipCode}
          zipId={this.props.sellerLocation.zipId}
          address={this.props.sellerLocation.address}
          changeHandler={this.props.sellerLocation.changeHandler}
          isCheckGudang={this.props.sellerLocation.isCheckGudang}
          modalState={this.props.sellerLocation.modalState}
          toggleHandler={this.props.sellerLocation.modalHandler}
          actionType={this.props.sellerLocation.actionType}
          checkGudangHandler={this.props.sellerLocation.checkGudangHandler}
          storeSellerLocation={this.props.sellerLocation.storeSellerLocation}
          updateSellerLocation={this.props.sellerLocation.updateSellerLocation}
        />
        <SellerLocationView
          data={this.props.sellerLocation.dataTable}
          addModal={this.props.sellerLocation.modalHandler}
        />
      </div>
    );
    // } else {
    //   return <div>Sedang loading data alamat</div>;
    // }
  }
}
