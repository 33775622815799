import React, { createContext, Component } from "react";
//import for provider
import API from "../../utils/API";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { withRouter } from "react-router-dom";
import axios from "axios";

//Context
export const ProductHomeContext = createContext();

//Provider
class ProductHomeProvider extends Component {
  state = {
    category: undefined,
    product: undefined,
    product_detail: undefined,
    productDetailPhoto: undefined,
    totalItem: undefined,
    about_title: "",
    about: undefined,
    about_img: undefined,
  };

  //handler
  setSelectedProduct = (product) => {
    this.setState({ selected_product: product });
  };

  //get data
  createConfig = (token) => {
    let config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
    return config;
  };

  getProductAllCategory = (sellerId) => {
    const limit = `?limit=7`;
    API.get(
      `home/${sellerId}${limit}`,
      this.createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        // console.log(res.data.data.category)
        if (res) {
          let tempcat = res.data.data.category;
          tempcat = tempcat.map((data) => ({
            id: data.id,
            name: data.name,
            img: data.img,
            is_active: data.is_active,
            product: data.product,
          }));
          this.setState({ category: tempcat });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getProductCategory = async (
    sellerId,
    category_id,
    Prmpage = 1,
    Prmlimit = 20
  ) => {
    const page = `?page=${Prmpage}`;
    const limit = Prmlimit ? `&limit=${Prmlimit}` : ``;
    API.get(
      `home/${sellerId}/cat/${category_id}${page}${limit}`,
      this.createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        if (res.data.data) {
          let product = res.data.data.product.data.map((list) => ({
            id: list.id,
            name: list.name,
            categoryId: list.category_id,
            img: list.thumbnail.link,
            price: list.price_product_single,
          }));
          this.setState((prevState) => ({
            product:
              Prmpage !== 1 ? [...prevState.product, ...product] : product,
            totalItem: res.data.data.product.total,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getProductDetail = (sellerId, product_id) => {
    API.get(`home/${sellerId}/${product_id}`)
      .then((res) => {
        this.setState({ product_detail: res.data.data.product[0] });
        this.setProductDetailPhoto();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  setProductDetailPhoto = () => {
    const { product_detail } = this.state;
    let allPhotoTemp;
    let productPhotoTemp;
    let productDetailPhotoTemp;

    productPhotoTemp = product_detail.photo_product.map((item) => ({
      ...item,
      detail_photo: false,
    }));

    productDetailPhotoTemp = product_detail.dtl_product_active.map((item) => ({
      ...item.photo_product[0],
      detail_photo: true,
    }));

    allPhotoTemp = [...productPhotoTemp, ...productDetailPhotoTemp];

    allPhotoTemp = allPhotoTemp.map((item, index) => ({
      ...item,
      itemId: index + 1,
    }));

    this.setState({
      productDetailPhoto: allPhotoTemp,
    });
  };

  setAboutProduct = (web_link) => {
    // web_link = web_link.substring(0, web_link.indexOf("."));
    API.get(`about/${web_link}`, this.createConfig(Cookies.get("access_token")))
      .then((res) => {
        const data = res.data.data.about;
        if (data !== null) {
          let product_img =
            data.product_image === null ? undefined : data.product_image;
          this.setState({
            about_title: data.product_title === null ? "" : data.product_title,
            about: data.about_product === null ? undefined : data.about_product,
            about_img: product_img,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //other
  addToChartFromPage = (
    sellerId,
    product_id,
    quantity,
    note = "",
    seller_loc = "",
    stock
  ) => {
    const data = new FormData();
    data.append("product_id", product_id);
    data.append("qty", quantity);
    data.append("note", note);
    data.append("seller_location_id", seller_loc);
    data.append("process", "new");

    if (parseInt(quantity) <= parseInt(stock)) {
      if (Cookies.get("access_token")) {
        API.post(
          `cart/${sellerId}`,
          data,
          this.createConfig(Cookies.get("access_token"))
        )
          .then((res) => {
            Swal.fire({
              icon: "success",
              html: "Item successfully added to cart",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#3085d6",
              confirmButtonText: "Belanja lagi",
              cancelButtonText: "Lihat Keranjang",
            }).then((res) => {
              this.props.history.push(res.value ? "/" : "/cart");
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.props.history.push("/login");
      }
    } else {
      Swal.fire({
        icon: "error",
        html: "Stok tidak tersedia",
      });
    }
  };

  render() {
    return (
      <ProductHomeContext.Provider
        value={{
          ...this.state,
          getProductAllCategory: this.getProductAllCategory,
          getProductCategory: this.getProductCategory,
          getProductDetail: this.getProductDetail,
          setSelectedProduct: this.setSelectedProduct,
          addToChartFromPage: this.addToChartFromPage,
          setAboutProduct: this.setAboutProduct,
        }}
      >
        {this.props.children}
      </ProductHomeContext.Provider>
    );
  }
}

export default withRouter(ProductHomeProvider);
