import React, { Component } from "react";
import ProductContainer from "./ProductContainer/ProductContainer";
import HomeProductContext from "../../context/HomeProductContext";
import SellerPrmContext  from "../../../SellerPrmContext";

export default class ProductHomeConsumer extends Component {
  render() {
    return (
      <div>
        <SellerPrmContext.Consumer>
          {(sellerPrm) => (
            <HomeProductContext.Consumer>
              {(homeProduct) => (
                <ProductContainer
                  sellerPrm={sellerPrm}
                  homeProduct={homeProduct}
                />
              )}
            </HomeProductContext.Consumer>
          )}
        </SellerPrmContext.Consumer>
      </div>
    );
  }
}
