import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import StoreView from "../components/Search/StoreView";
import SectionContainer from "../components/sectionContainer";
import API from "../../utils/API";

class Search extends Component {
  state = {
    store_list: undefined,
    search: "",
  };

  componentDidMount() {
    this.setSearchData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setSearchData();
    }
  }

  setSearchData = () => {
    const search_text =
      this.props.match.params.text === undefined
        ? this.state.search
        : this.props.match.params.text;
    this.setState({ search: search_text }, () => {
      this.getStoreList();
    });
  };
  
  handelSearchChange = (event) => {
    this.setState({ search: event.target.value });
  };

  getStoreList = () => {
    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };
    API.post("/web-search", { query: this.state.search }, config)
      .then((res) => {
        const data = res.data.data.search;
        // console.log(data)
        this.setState({ store_list: data }, () => {
          this.props.history.push(`/search/${this.state.search}`);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <MDBContainer style={{paddingTop: "6rem" }}>
        <SectionContainer header={`SEARCH`}>
          <MDBRow className="d-flex justify-content-center align-items-center">
            <div className="input-group w-75">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon">
                  <i className="fa fa-search prefix"></i>
                </span>
              </div>
              <input
                type="text"
                value={this.state.search}
                onChange={this.handelSearchChange}
                className="form-control form-control-lg"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon"
              />
            </div>
            <div className="input-group-prepend">
              <button
                type="button"
                className="btn btn-light"
                style={{ marginLeft: "0" }}
                onClick={() => this.getStoreList()}
              >
                SEARCH
              </button>
            </div>
            {/* <div className="input-group-prepend" style={{paddingLeft:'10px'}}>
              <button
                type="button"
                className="btn btn-light"
                style={{ marginLeft: "0" }}
                onClick={() => this.getStoreList()}
              >
                SEARCH
              </button>
            </div> */}
          </MDBRow>
          <hr />
          <MDBRow>
            {this.state.store_list !== undefined ? (
              this.state.store_list.length === 0 ? (
                <MDBContainer>
                  <MDBRow>
                    <MDBCol>
                      <MDBRow className="d-flex justify-content-center align-items-center">
                        Tidak ada yang cocok dengan kata "{this.state.search}".
                      </MDBRow>
                      <MDBRow className="d-flex justify-content-center align-items-center">
                        Silahkan cari dengan kata kunci yang berupa nama webside
                        atau toko
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              ) : (
                this.state.store_list.map((data, index) => {
                  return (
                    <MDBCol lg='6' sm='12' key={index} style={{ paddingTop: "10px" }}>
                      <StoreView {...data} />
                    </MDBCol>
                  );
                })
              )
            ) : (
              ""
            )}
          </MDBRow>
        </SectionContainer>
      </MDBContainer>
    );
  }
}

export default withRouter(Search);
