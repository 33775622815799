import React, { Component } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBIcon,
  MDBInputGroup,
} from "mdbreact";
import { PageContainer } from "../LandingView/Landing.styles";
import {
  LoginLandingContainer,
  LoginBannerImage,
  LoginLandingContent,
  WelcomeText,
} from "./LoginLanding.styles";
import { Text } from "../../context/Language";
import { Link } from "react-router-dom";
import { RoundedPurpleButton } from "../_UtilityComponent/Button/RoundedButton";
import MainBgMobile from "../../../assets/landing_bg_mobile.png";

export class LoginView extends Component {
  state = {
    showPass: false,
  };
  render() {
    return (
      <LoginLandingContainer>
        <LoginBannerImage src={MainBgMobile} />
        <LoginLandingContent>
          <form
            className="needs-validation"
            onSubmit={this.props.submitHandler}
            noValidate
          >
            <MDBRow>
              <WelcomeText>Welcome Back</WelcomeText>
            </MDBRow>
            <div className="mt-3">
              <MDBInputGroup
                material
                value={this.props.email}
                onChange={this.props.changeHandler}
                type="email"
                containerClassName="mb-0 mt-0"
                name="email"
                hint="E-mail"
                prepend={
                  <MDBCol middle>
                    <MDBIcon icon="user" />
                  </MDBCol>
                }
                required
              />
            </div>
            <div className="mt-3">
              <MDBInputGroup
                material
                value={this.props.password}
                onChange={this.props.changeHandler}
                type={this.state.showPass ? "text" : "password"}
                containerClassName="mb-3 mt-0"
                name="password"
                prepend={
                  <MDBCol middle>
                    <MDBIcon icon="key" />
                  </MDBCol>
                }
                append={
                  <div
                    onClick={() => {
                      this.setState({ showPass: !this.state.showPass });
                    }}
                  >
                    {this.state.showPass ?
                    <MDBCol middle>
                      <MDBIcon icon="eye-slash" />
                    </MDBCol>
                    :
                    <MDBCol middle>
                      <MDBIcon icon="eye" />
                    </MDBCol>}
                  </div>
                }
                hint="Password"
                required
              />
            </div>

            {/* <div className="text-center">
            <MDBInput
              label={<Text pid="loginPage" tid="rememberMeCheck" />}
              onChange={this.props.checkRememberMeHandler}
              type="checkbox"
              id="rememberMe"
              filled
            />
          </div> */}
            <div className="text-center">
              <Link to="#" onClick={this.props.forgotPassword}>
                {<Text pid="loginPage" tid="forgotPass" />}
              </Link>
            </div>
            <MDBCol className="text-center">
              <RoundedPurpleButton size="sm" type="submit" className="w-100">
                {<Text pid="loginPage" tid="login" />}
              </RoundedPurpleButton>
            </MDBCol>
            <MDBCol className="text-center">
              <Link to="/register">
                {<Text pid="loginPage" tid="toRegister" />}
              </Link>
            </MDBCol>
          </form>
        </LoginLandingContent>
      </LoginLandingContainer>
    );
  }
}

export default LoginView;
