import { MDBContainer, MDBRow } from "mdbreact";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactToPrint from "react-to-print";
import API from "../../../utils/API";
import Cookies from "js-cookie";
import { CMDBBtn } from "../StyledComponent";
import InvoicePrintView from "./InvoicePrintView";

class PrintTemplateMain extends Component {
  state = {
    print_type: undefined,
    invoice_data: undefined,
  };

  componentDidMount() {
    this.initPage();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.initPage();
    }
  }

  createConfig = (token) => {
    let config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
    return config;
  };

  getInvoiceDetail = (invoice_id) => {
    API.get(
      `payment/invoice_detail/${invoice_id}`,
      this.createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        // console.log(res)
        this.setState({ invoice_data: res.data.invoice });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  initPage = () => {
    const { match } = this.props;
    const invoice_id = match.params.id ? match.params.id : undefined;
    const type = match.params.type ? match.params.type : undefined;
    if (invoice_id && type === "invoice") {
      this.getInvoiceDetail(invoice_id);
      this.setState({ print_type: type });
    }
  };

  render() {
    return (
      <div style={{ marginTop: "1rem" }}>
        <MDBContainer>
          <MDBRow>
            <ReactToPrint
              trigger={() => {
                return (
                  <CMDBBtn active bg_color="#804199">
                    Print
                  </CMDBBtn>
                );
              }}
              content={() => this.componentRef}
            />
          </MDBRow>
          <MDBRow>
            <InvoicePrintView
              ref={(el) => (this.componentRef = el)}
              data={this.state.invoice_data}
            />
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

export default withRouter(PrintTemplateMain);
