import React, { Component } from "react";
import MainRoutes from "../../MainRoutes";
import MainHeader from "../MainHeader/MainHeader";
import SellerPrmContext from "../../../SellerPrmContext";
import { withRouter } from "react-router-dom";
import API from "../../../utils/API";
import Cookies from "js-cookie";

//template component
import Routes from "../../template/Routes";
import Header1 from "../../template1/Header";
import Header2 from "../../template1/Header";
import Header3 from "../../template3/Header";
import Header4 from "../../template4/Header";
import Header5 from "../../template5/Header";

// import Footer5 from "../../template5/Footer";
import { CenterLoader } from "../../../mobile/components/_UtilityComponent/Loader/CenterLoader";

const header_list = {
  header1: Header1,
  header2: Header2,
  header3: Header3,
  header4: Header4,
  header5: Header5,
};

// const footer_list = {
//   footer5: Footer5,
// };

class TemplateSwitcher extends Component {
  static contextType = SellerPrmContext;

  constructor() {
    super();
    this.state = {
      headerHeight: 0,
    };
  }

  checkHeaderHeight = (headerHeight) => {
    this.setState({ headerHeight: headerHeight });
  };

  logout = () => {
    this._isMounted = true;
    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
    API.get("/auth/logout", config)
      .then((res) => {
        Cookies.remove("access_token", { domain: "1toko.com" });
        Cookies.remove("logged", { domain: "1toko.com" });
        Cookies.remove("access_token");
        Cookies.remove("logged");
        this.props.history.push("/");
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { seller_style } = this.context.subDomainData;
    if (!this.context.isLoading) {
      if (this.context.link === "merchant.1toko.com") {
        return (
          <>
            <MainHeader
              dataUser={this.context}
              // isSeller={this.context.isSeller}
              logout={this.logout}
              checkHeaderHeight={this.checkHeaderHeight}
            />
            <div style={{ marginTop: this.state.headerHeight }}>
              <MainRoutes />
            </div>
          </>
        );
      }

      if (this.context.subDomainData.template_id !== undefined) {
        const template_id = this.context.subDomainData.template_id;
        const header = header_list[`header${template_id}`];
        // const footer = footer_list[`footer${template_id}`];
        return (
          <>
            {React.createElement(header, {
              subDomainData: this.context.subDomainData,
              isSeller: this.context.isSeller,
              logout: this.logout,
              checkHeaderHeight: this.checkHeaderHeight,
              seller_style: seller_style,
            })}
            {/* <div style={{ paddingTop: this.state.headerHeight }}> */}
            <div>
              <Routes template_id={template_id} />
            </div>
            {/* {React.createElement(footer, {
              subDomainData: this.context.subDomainData,
            })} */}
          </>
        );
      } else {
        return (
          <>
            <MainHeader
              dataUser={this.context}
              logout={this.logout}
              checkHeaderHeight={this.checkHeaderHeight}
            />
            {/* <div style={{ marginTop: this.state.headerHeight }}> */}
            <div>
              <MainRoutes />
            </div>
          </>
        );
      }
    } else {
      return <CenterLoader />;
    }
  }
}

export default withRouter(TemplateSwitcher);
