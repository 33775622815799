import { MDBIcon } from "mdbreact";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Redirect, useLocation, useParams } from "react-router-dom";
import API from "../../utils/API";
import { DashboardSellerView } from "../components/DashboardSellerView";
import Cookies from "js-cookie";
import { DashboardItemStoreImg } from "../components/DashboardSellerView/DashboardSeller.styles";
import { ManageSellerLocation } from "../components/DashboardSellerView/ManageSellerLocation";
import { ManageSellerCategory } from "../components/DashboardSellerView/ManageSellerCategory";
import { ManageSellerProducts } from "../components/DashboardSellerView/ManageSellerProducts";
import { ManageSellerBankAccount } from "../components/DashboardSellerView/ManageSellerBankAccount";

export const DashboardSeller = () => {
  let location = useLocation();
  let params = useParams();
  let page = params.page;
  let s_id = params.s_id;
  let s_name = params.s_name;
  let process = params.process;

  const [currentPage, setCurrentPage] = useState("dashboard");
  const [itemDashboard, setItemDashboard] = useState([
    // {
    //   id: 1,
    //   link: "seller_dashboard/account",
    //   icon: <MDBIcon size="3x" className="purple-text" icon="user" />,
    //   text: "MY ACCOUNT",
    // },
    {
      id: 2,
      link: "seller_dashboard/store_list",
      icon: <MDBIcon size="3x" className="purple-text" icon="store-alt" />,
      text: "MY STORE",
    },
    {
      id: 3,
      link: "seller_dashboard/order_confirmation",
      icon: <MDBIcon size="3x" className="purple-text" icon="shopping-cart" />,
      text: "MY ORDER",
    },
    {
      id: 4,
      link: "seller_dashboard/manage_products",
      icon: <MDBIcon size="3x" className="purple-text" icon="boxes" />,
      text: "MANAGE PRODUCTS",
    },
    {
      id: 5,
      link: "seller_dashboard/manage_category",
      icon: <MDBIcon size="3x" className="purple-text" icon="list-alt" />,
      text: "MANAGE CATEGORY",
    },
    {
      id: 6,
      link: "seller_dashboard/manage_location",
      icon: <MDBIcon size="3x" className="purple-text" icon="map-marker-alt" />,
      text: "MANAGE LOCATION",
    },
    {
      id: 7,
      link: "seller_dashboard/manage_bank_account",
      icon: <MDBIcon size="3x" className="purple-text" icon="credit-card" />,
      text: "MANAGE BANK ACCOUNT",
    },
  ]);
  const [orderItemDashboard, setOrderItemDashboard] = useState([
    {
      id: 0,
      link: "",
      icon: "",
      text: "",
    },
  ]);
  const [storeList, setStoreList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const createConfig = (token) => {
    let config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
    return config;
  };

  /////PAGE MANAGEMENT
  useEffect(() => {
    page === undefined ? setCurrentPage("dashboard") : setCurrentPage(page);
    if (
      page === "order_confirmation" ||
      page === "store_list" ||
      page === "manage_location" ||
      page === "manage_products" ||
      page === "manage_category" ||
      page === "manage_bank_account"
    ) {
      getStoreList();
    }
    return () => {
      setStoreList([]);
      setOrderStorePage();
    };
  }, [page]);

  // useEffect(() => {
  //   console.log(location);
  //   console.log("page: " + currentPage);
  // }, [currentPage]);

  const getStoreList = async () => {
    try {
      const resp = await API.get(
        "auth/store",
        createConfig(Cookies.get("access_token"))
      );
      if (componentIsMounted.current) {
        const storeTemp = resp.data.data.store_list;
        const storeList = storeTemp.map((item) => {
          let temp_link;
          switch (page) {
            case "order_confirmation":
              temp_link = `order_confirmation/${item.id}/${item.name_web}`;
              setOrderStorePage(item.id, item.name_web);
              break;
            case "store_list":
              temp_link = item.subdomain;
              break;
            case "manage_location":
              temp_link = `manage_location/${item.id}`;
              break;
            case "manage_products":
              temp_link = `manage_products/${item.id}`;
              break;
            case "manage_category":
              temp_link = `manage_category/${item.id}`;
              break;
            case "manage_bank_account":
              temp_link = `manage_bank_account/${item.id}`;
              break;
          }
          return {
            id: item.id,
            text: item.name_web,
            icon: <DashboardItemStoreImg src={item.logo} />,
            domain: item.domain,
            subdomain: item.subdomain,
            link: temp_link,
          };
        });
        setStoreList(storeList);
      }
    } catch (err) {
      // Handle Error Here
      console.log(err);
    }
  };

  const setOrderStorePage = (id, name_web) => {
    if (id && name_web) {
      const link = `/seller_dashboard/order_confirmation/${id}/${name_web}`;
      setOrderItemDashboard([
        {
          id: 1,
          link: `${link}/1`,
          icon: (
            <MDBIcon size="3x" className="purple-text" icon="clipboard-list" />
          ),
          text: "KONFIRMASI PESANAN",
        },
        {
          id: 2,
          link: `${link}/2`,
          icon: (
            <MDBIcon size="3x" className="purple-text" icon="truck-loading" />
          ),
          text: "PENGAMBILAN PESANAN",
        },
        {
          id: 3,
          link: `${link}/3`,
          icon: (
            <MDBIcon size="3x" className="purple-text" icon="truck-moving" />
          ),
          text: "PESANAN DIPROSES",
        },
        {
          id: 4,
          link: `${link}/4`,
          icon: (
            <MDBIcon size="3x" className="purple-text" icon="clipboard-check" />
          ),
          text: "PESANAN SELESAI",
        },
        {
          id: 5,
          link: `${link}/5`,
          icon: (
            <MDBIcon size="3x" className="purple-text" icon="clipboard-check" />
          ),
          text: "PERMINTAAN PEMBATALAN",
        },
        {
          id: 6,
          link: `${link}/6`,
          icon: (
            <MDBIcon size="3x" className="purple-text" icon="window-close" />
          ),
          text: "PESANAN DIBATALKAN",
        },
      ]);
    } else {
      setOrderItemDashboard(undefined);
    }
  };
  /////////////////////

  /////ORDER CONFIRMATION
  useEffect(() => {
    if (process == "order_confirmation") {
      getAllStoreInvoice(process, s_id);
      return () => {
        setInvoiceList([]);
      };
    }
  }, [process]);

  const getAllStoreInvoice = async (status, storeId, courier = "") => {
    const data = new FormData();
    data.append("status", status);
    data.append("courier", courier);
    data.append("storeId", storeId);
    try {
      const resp = await API.post(
        `payment/all_store_invoice`,
        data,
        createConfig(Cookies.get("access_token"))
      );
      if (componentIsMounted.current) {
        setInvoiceList(resp.data.invoice_list);
      }
    } catch (err) {
      console.log(err);
    }
  };
  /////////////////////

  switch (page) {
    case "account":
      return <DashboardSellerView page={"account"} />;
    case "store_list":
      return (
        <DashboardSellerView page={"store_list"} itemDashboard={storeList} />
      );
    case "order_confirmation":
      if (s_id && s_name && process) {
        return (
          <DashboardSellerView page={"order_confirmation"} process={process} />
        );
      } else if (s_id && s_name) {
        return (
          <DashboardSellerView
            page={"order_confirmation"}
            itemDashboard={orderItemDashboard}
          />
        );
      } else {
        return (
          <DashboardSellerView
            page={"order_confirmation"}
            itemDashboard={storeList}
          />
        );
      }
    case "manage_products":
      if (s_id) {
        return <ManageSellerProducts />;
      } else {
        return (
          <DashboardSellerView
            page={"manage_products"}
            itemDashboard={storeList}
          />
        );
      }
    case "manage_category":
      if (s_id) {
        return <ManageSellerCategory />;
      } else {
        return (
          <DashboardSellerView
            page={"manage_category"}
            itemDashboard={storeList}
          />
        );
      }
    case "manage_location":
      if (s_id) {
        return <ManageSellerLocation />;
      } else {
        return (
          <DashboardSellerView
            page={"manage_location"}
            itemDashboard={storeList}
          />
        );
      }
    case "manage_bank_account":
      if (s_id) {
        return <ManageSellerBankAccount />;
      } else {
        return (
          <DashboardSellerView
            page={"manage_bank_account"}
            itemDashboard={storeList}
          />
        );
      }
    default:
      return <DashboardSellerView itemDashboard={itemDashboard} />;
  }
};
