import React, { Component } from "react";
import SellerPrmContext from "../../SellerPrmContext";
import API from "../../utils/API";
import Cookies from "js-cookie";
import TransactionMain from "../components/Transaction/TransactionMain";
import UserTransactionProvider, {
  UserTransactionContext,
} from "../context/UserTransactionProvider";

export default class Transaction extends Component {
  render() {
    return (
      <SellerPrmContext.Consumer>
        {(sellerprm) => (
          <UserTransactionProvider>
            <UserTransactionContext.Consumer>
              {(transprm) => (
                <TransactionMain sellerprm={sellerprm} transprm={transprm} />
              )}
            </UserTransactionContext.Consumer>
          </UserTransactionProvider>
        )}
      </SellerPrmContext.Consumer>
    );
  }
}
