import { MDBCard, MDBCol } from "mdbreact";
import { Link, NavLink } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";

export const StyledProductCategory_Container = styled(MDBCol).attrs({
  size: "5",
})`
  padding: 3px;
  min-width: fit-content;
`;

export const StyledProductCategory_ContainerCard = styled(MDBCard)`
  padding: 5px;
  border: 1px solid ${({ theme }) => theme.color.primary};
  /* font-family: ${(props) =>
    props.theme.font.primary
      ? props.theme.font.primary
      : "Lato, sans-serif"}; */
  /* min-width: min-content; */
  /* max-width: unset; */
  color: ${(props) => props.theme.font.dark};
  text-align: center;
  font-weight: bold;
  border-radius: 7px;

  ${(props) =>
    //transient-props
    props.$activeTab
      ? css`
          background-color: ${({ theme }) => theme.color.primary};
          color: ${(props) =>
            props.theme.color.type === "dark"
              ? props.theme.font.light
              : props.theme.font.dark};
        `
      : ""}

  /* Template 5 */

  ${(props) =>
    props.theme.template.id === "5" &&
    css`
      color: #000000;
    `}

    ${(props) =>
    props.theme.template.id === "5" && props.$activeTab
      ? css`
          background-color: ${({ theme }) => theme.color.primary};
          color: #ffffff;
        `
      : ""}
`;
