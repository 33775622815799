import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
} from "mdbreact";
import React, { Component } from "react";
import { CMDBBtn } from "../StyledComponent";

export default class TransactionPendingView extends Component {
  state = {
    show: true,
  };

  handleShowDetail = () => {
    this.setState({ show: !this.state.show });
  };

  formatDate = (string) => {
    var options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(string).toLocaleDateString("ID", options);
  };

  formatCurrency = (country, currency, text) => {
    return new Intl.NumberFormat(country, {
      style: "currency",
      currency: currency,
    }).format(text);
  };

  render() {
    const {
      cart_detail,
      gross_amount,
      order_id,
      payment_type,
      status,
      transaction_time,
      seller_details,
    } = this.props.data;
    const { data, seller_style } = this.props;
    // console.log(transaction_time);
    return (
      <MDBCard
        className="w-100"
        style={{ marginBottom: "1rem" }}
      >
        <MDBCardBody>
          <MDBRow>
            <MDBCol>
              <table className="w-100">
                <tbody>
                  <tr>
                    <td>No. Order</td>
                    <td>:</td>
                    <td>{order_id}</td>
                  </tr>
                  <tr>
                    <td>Tanggal Pemesanan</td>
                    <td>:</td>
                    <td>{this.formatDate(transaction_time)}</td>
                  </tr>
                  <tr>
                    <td>Nama Toko</td>
                    <td>:</td>
                    <td>{seller_details.seller_name}</td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <hr style={{ padding: "0", margin: "0" }} />
                    </td>
                  </tr>
                  <tr>
                    <td>Status Pesanan</td>
                    <td>:</td>
                    <td>{status}</td>
                  </tr>
                  <tr>
                    <td>Metode Bayar</td>
                    <td>:</td>
                    <td>{payment_type}</td>
                  </tr>
                  <tr>
                    <td>Total Bayar</td>
                    <td>:</td>
                    <td>{this.formatCurrency("ID", "IDR", gross_amount)}</td>
                  </tr>
                </tbody>
              </table>
            </MDBCol>
          </MDBRow>
          <MDBRow style={{ margin: "8px" }}>
            <MDBRow>
              <button
                style={{
                  fontSize: "15px",
                  backgroundColor: seller_style.color_btn,
                  color: "white",
                  border: `1px solid ${seller_style.color_btn}`,
                  borderRadius: "4px",
                }}
                onClick={() => this.handleShowDetail()}
              >
                <MDBIcon far icon="eye" />
                {` Lihat detail`}
              </button>
            </MDBRow>

            <div
              className="overflow-auto"
              hidden={this.state.show}
              style={{
                width: "100%",
                height: "180px",
                marginTop: "1rem",
              }}
            >
              {cart_detail.map((cart) => {
                return cart.item_details.map((item, index) => {
                  let isCourier =
                    item.item_id.toString().split("-")[0] === "COURIER";
                  let price = parseInt(item.price) * parseInt(item.quantity);
                  if (!isCourier) {
                    // console.log(item.img);
                    return (
                      <div
                        key={index}
                        style={{ marginBottom: "1rem", display: "flex" }}
                      >
                        <img
                          className="img-fluid img-thumbnail z-depth-1"
                          src={item.img}
                          style={{ maxHeight: "4rem" }}
                        />
                        <div style={{ marginLeft: "5px", fontSize: "15px" }}>
                          <span>{`${item.name} (${item.detail_name}) ${item.weight} gr`}</span>
                          <br />
                          <span>
                            {`${new Intl.NumberFormat("ID", {
                              style: "currency",
                              currency: "IDR",
                            }).format(price)} (${item.quantity} item)`}
                          </span>
                          <br />
                          <span>{`Note : ${item.note}`}</span>
                        </div>
                      </div>
                    );
                  } else {
                    return "";
                  }
                });
              })}
            </div>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    );
  }
}
