import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn } from "mdbreact";
import { Text } from "../../context/Language";
import { CMDBBtn } from "../StyledComponent";
import '../../../assets/css/fonts.css'

export default class ProfileViewUser extends Component {
  componentDidMount() {}

  render() {
    let { isEdit, isEditChange, updateUserProfile } = this.props;
    return (
      <MDBContainer style={{fontFamily:'Lato Regular'}}>
        <form
          className="needs-validation"
          onSubmit={updateUserProfile}
          noValidate
        >
          <MDBRow>
            <MDBCol>
              <MDBInput
                value={this.props.data.name}
                onChange={this.props.changeHandler}
                type="text"
                className="form-control"
                id="data"
                name="name"
                label={<Text pid="profileUser" tid="name" />}
                required
                disabled={isEdit}
              />
              <MDBInput
                value={this.props.data.no_tlp}
                onChange={this.props.changeHandler}
                type="text"
                className="form-control"
                id="data"
                name="no_tlp"
                label={<Text pid="profileUser" tid="no_tlp" />}
                disabled={isEdit}
              />
              <MDBInput
                value={this.props.data.email}
                onChange={this.props.changeHandler}
                type="email"
                className="form-control"
                id="data"
                name="email"
                label={<Text pid="profileUser" tid="email" />}
                required
                disabled={isEdit}
              />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol size="6" hidden={isEdit ? true : false}>
              <CMDBBtn
                active
                bg_color={this.props.color_btn}
                className="w-100"
                type="submit"
              >
                {<Text pid="profileUser" tid="submitBtn" />}
              </CMDBBtn>
            </MDBCol>
            <MDBCol size="6" hidden={isEdit ? false : true}>
              <CMDBBtn
                active
                bg_color={this.props.color_btn}
                className="w-100"
                onClick={() => isEditChange()}
              >
                {<Text pid="profileUser" tid="editBtn" />}
              </CMDBBtn>
            </MDBCol>
            <MDBCol size="6">
              <CMDBBtn
                active
                bg_color={this.props.color_btn}
                className="w-100"
                onClick={() => this.props.popupModal("1")}
              >
                {<Text pid="profileUser" tid="changePassBtn" />}
              </CMDBBtn>
            </MDBCol>
          </MDBRow>
        </form>
      </MDBContainer>
    );
  }
}
