import { MDBContainer } from "mdbreact";
import React from "react";
import { Redirect } from "react-router-dom";
import { RoundedBlueButton } from "../../_UtilityComponent/Button/RoundedButton";
import ImageComponent from "../../_UtilityComponent/Image/ImageComponent";
import { CenterLoader } from "../../_UtilityComponent/Loader/CenterLoader";

import style from "./WebSearchResult.module.css";

export const WebSearchResult = (props) => {
  const { data, isLoading } = props;
  if (!isLoading) {
    if (data.length > 0) {
      return (
        <div className="mt-4">
          {props.data.map((item, index) => {
            return (
              <div key={index} className={style.list}>
                <div className={style.list__container}>
                  <div className={style.list__picture}>
                    <ImageComponent
                      isSquare={true}
                      img={item.logo}
                      roundedFrame={true}
                    />
                  </div>
                  <div className={style.list__name}>{item.name_web}</div>
                </div>
                <div className={style.list__buttons}>
                  <RoundedBlueButton
                    size="sm"
                    onClick={() => {
                      window.location = `http://${item.subdomain}`;
                    }}
                  >
                    Enter Store
                  </RoundedBlueButton>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else if (data.length === 0) {
      return (
        <MDBContainer className="mt-5 text-center">Tidak Ada Data</MDBContainer>
      );
    }
  } else {
    return <CenterLoader />;
  }
};
