import React from "react";
import styles from "./ProductAllRows.module.css";
import { MDBContainer, MDBRow } from "mdbreact";
import { Product } from "../Product/Product";

import {
  StyledProductAllRows_ShowAllLink,
  StyledProductAllRows_Title,
  StyledProductAllRows_TitleContainer,
} from "./ProductAllRows.styles";
import { Link, useHistory } from "react-router-dom";

const ProductAllRows = (props) => {
  const history = useHistory();
  return (
    <>
      {props.statePage === "ALL" ? (
        <MDBContainer className={styles.container}>
          <StyledProductAllRows_TitleContainer>
            <StyledProductAllRows_Title>
              {props.item.name}
            </StyledProductAllRows_Title>
            <StyledProductAllRows_ShowAllLink
              onClick={() => history.push(`${/category/ + props.item.id}`)}
            >
              Lihat Semua
            </StyledProductAllRows_ShowAllLink>
          </StyledProductAllRows_TitleContainer>
          <MDBRow className={styles.rowProduct}>
            {props.item.product.map((item, index) =>
              index < 4 ? (
                <Product
                  key={item.id + index}
                  item={item}
                  showingType={"ALL_PRODUCT_ROW"}
                />
              ) : (
                ""
              )
            )}
            {/* {props.item.product.length > 0 ? (
              <Product
                // key={item.id}
                item={props.item}
                showingType={"TO_PRODUCT_CATEGORY"}
              />
            ) : (
              ""
            )} */}
          </MDBRow>
        </MDBContainer>
      ) : (
        ""
      )}
      {props.statePage === "CATEGORY" ? (
        <Product item={props.item} showingType={"CATEGORY_PRODUCT"} />
      ) : (
        ""
      )}
      {props.statePage === "CATEGORY" && props.lastItem === true ? (
        <>
          <Product showingType={"LOAD_MORE_PRODUCT"} />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default ProductAllRows;
