import styled from "styled-components";

export const ImageLogoContainer = styled.img`
  width: 100%;
  height: auto;
  max-width: 100px;

  /* @media (min-width: 275px) {
    max-width: 100px;
  } */

  @media (min-width: 300px) {
    max-width: 130px;
  }

  @media (min-width: 350px) {
    max-width: 150px;
  }
`;
