import React, { Component } from "react";
import API from "../../utils/API";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { MDBBtn, MDBIcon } from "mdbreact";
import SellerProductsContext from "./SellerProductsContext";
import { withRouter } from "react-router-dom";

class SellerProductsProvider extends Component {
  state = {
    //state-group-products
    name: "",
    categoryId: "",
    description: "",
    //state-photo-product
    photoProductState: [[], [], [], [], []],
    previewProductPhoto: "",
    photoDetailProductState: "",
    previewDetailProductPhoto: "",
    //state-detail-product
    detailProductId: "", //untuk update
    nameDetail: "",
    price: 0,
    hpp: 0, //hargapokok
    qty: 0, //stock
    weight: 0, //in gram
    sellerLocationId: "",
    groupProductId: "",
    photoDetailProductId: "",
    //list-dataTable-group-products
    dataTable: {},
    //list-dataTable-detail-product
    dataTableDetail: {},
    // for show update
    listCategoryActiveTemp: [],
    listCategoryActive: [],
    // for show detail update
    listSellerLocationsArr: [],
    // for reset product
    nameReset: "",
    categoryIdReset: "",
    descriptionReset: "",
    //
    isFromRegister:false,
    modalState: false,
    isLoading: false,
    isError: false,
  };

  modalHandler = (actionType, list) => {
    const { name, categoryId, description, photoProductState } = this.state;
    if (actionType === "ADD") {
      if (
        name &&
        categoryId &&
        description &&
        photoProductState[0] instanceof File
      ) {
        this.setState(
          {
            actionType,
            modalState: this.state.modalState ? false : true,
          },
          () => this.clearDetailForm()
        );
      } else {
        Swal.fire({
          icon: "error",
          text:
            "Nama, Kategori, Deskripsi, dan Gambar Utama tidak boleh kosong",
        });
      }
    } else if (actionType === "ADD_FROM_EDIT_PAGE") {
      if (name && categoryId && description) {
        this.setState(
          {
            actionType,
            modalState: this.state.modalState ? false : true,
          },
          () => this.clearDetailForm()
        );
      } else {
        Swal.fire({
          icon: "error",
          text: "Nama, Kategori, dan Deskripsi tidak boleh kosong",
        });
      }
    } else if (actionType === "UPDATE") {
      this.setState(
        {
          detailProductId: list.id,
          nameDetail: list.name,
          price: list.price,
          hpp: list.hpp, //hargapokok
          qty: list.stock, //stock
          weight: list.weight, //in gram
          sellerLocationId: list.seller_location_id,
          groupProductId: list.group_product_id,
          photoDetailProductId: list.photo_product ? list.photo_product.id : "",
          previewDetailProductPhoto: list.photo_product
            ? list.photo_product.link
            : "",
          actionType,
          modalState: this.state.modalState ? false : true,
        },
        () => {
          this.setSellerLocationActive(list.seller_location_id);
        }
      );
    } else if (actionType === "CLOSE") {
      this.setState(
        {
          actionType,
          modalState: this.state.modalState ? false : true,
        },
        () => this.clearDetailForm()
      );
    }
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  selectProductPhoto = (event, photoType) => {
    if (event) {
      // if (photoType === 0) {
      let photoProductState = [...this.state.photoProductState];
      let photo = { ...photoProductState[photoType] };
      photo = event;
      photoProductState[photoType] = photo;
      this.setState({ photoProductState });
      // }
    }
  };

  selectDetailProductPhoto = (event) => {
    if (event) {
      this.setState({ photoDetailProductState: event });
    }
  };

  selectProductCategory = (event) => {
    // let tempSelected;
    // tempSelected = this.state.template.find((item) => item.value === event[0]);
    if (event[0]) {
      this.setState({
        categoryId: event[0],
      });
    }
    // }
  };

  selectSellerLocation = (event) => {
    if (event[0]) {
      this.setState({
        sellerLocationId: event[0],
      });
    }
    // }
  };

  listSellerProducts = (sellerId) => {
    this.setState({ isLoading: true });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    // const sellerId = Cookies.get("qqq");
    // ${this.state.sellerId}

    API.get(`/group_product/${sellerId}`, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.data) {
          console.log(res)
          let listDataTemp = res.data.data.product.map((list) => ({
            id: list.id,
            seller_id: list.seller_id,
            name: list.name,
            category_name: list.category_name[0],
            description: list.description,
            photo_product_main: list.photo_product[0],
            is_active: list.is_active,
          }));
          let dataTableTemp = {
            columns: [
              {
                label: "No",
                field: "no",
                sort: "asc",
              },
              {
                label: "Nama",
                field: "name",
                sort: "asc",
              },
              {
                label: "Kategori",
                field: "category",
                sort: "asc",
              },
              {
                label: "Produk Aktif",
                field: "isActive",
                sort: "asc",
              },
              {
                label: "Foto Produk",
                field: "mainPhoto",
                sort: "asc",
              },
              {
                label: "Action",
                field: "action",
              },
            ],
            rows: listDataTemp.map((list, index) => ({
              no: index+1,
              name: list.name,
              category: list.category_name,
              mainPhoto: list.photo_product_main ? (
                <img
                  style={{ maxWidth: 128, maxHeight: 128 }}
                  src={list.photo_product_main.link}
                  alt={"mainPhoto" + list.id}
                />
              ) : (
                "Tidak ada gambar"
              ),
              isActive:
                parseInt(list.is_active) === 1 ? "Aktif" : "Tidak Aktif",
              action: (
                <>
                  <>
                    <MDBBtn
                      size="sm"
                      color={parseInt(list.is_active) !== 1 ? "green" : "red"}
                      onClick={() =>
                        this.beActiveProduct(list.id, list.is_active, sellerId)
                      }
                    >
                      {parseInt(list.is_active) !== 1 ? (
                        <>
                          {" "}
                          <MDBIcon
                            icon="toggle-on"
                            className="mr-1"
                          /> Aktifkan{" "}
                        </>
                      ) : (
                        <>
                          {" "}
                          <MDBIcon icon="toggle-off" className="mr-1" />{" "}
                          Nonaktifkan{" "}
                        </>
                      )}
                    </MDBBtn>
                    <br />
                  </>

                  <MDBBtn
                    size="sm"
                    color="primary"
                    onClick={() =>
                      this.props.history.push(
                        `/admin/sellerproducts/edit-product/${list.id}`
                      )
                    }
                  >
                    <MDBIcon icon="edit" className="mr-1" /> Ubah
                  </MDBBtn>
                  {/* <MDBBtn
                    size="sm"
                    onClick={() =>
                      this.deleteSellerCategory(list.seller_id, list.id)
                    }
                  >
                    <MDBIcon icon="trash-alt" className="mr-1" /> Hapus
                  </MDBBtn> */}
                </>
              ),
            })),
          };
          this.setState({
            // data: listDataForHeader,
            dataTable: dataTableTemp,
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  storeProductPhoto = (groupProductId, sellerId) => {
    this.setState({ isLoading: true });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    const data = new FormData();
    // console.log("State[0]");
    // console.log(this.state.photoProductState[0]);
    data.append("upload_img", this.state.photoProductState[0]);
    data.append("group_product_id", groupProductId);

    API.post(`/photo_product/${sellerId}`, data, config)
      .then((res) => {
        if (parseInt(res.data.code) !== 200) {
          console.log(res);
        } else {
          if (this.state.photoProductState[1] instanceof File) {
            // console.log(res);
            const data = new FormData();
            console.log("State[1]");
            console.log(this.state.photoProductState[1]);
            data.append("upload_img", this.state.photoProductState[1]);
            data.append("group_product_id", groupProductId);
            API.post(`/photo_product/${sellerId}`, data, config);
          }
          if (this.state.photoProductState[2] instanceof File) {
            // console.log(res);
            const data = new FormData();
            console.log("State[2]");
            console.log(this.state.photoProductState[2]);
            data.append("upload_img", this.state.photoProductState[2]);
            data.append("group_product_id", groupProductId);
            API.post(`/photo_product/${sellerId}`, data, config);
          }
          if (this.state.photoProductState[3] instanceof File) {
            // console.log(res);
            const data = new FormData();
            console.log("State[3]");
            console.log(this.state.photoProductState[3]);
            data.append("upload_img", this.state.photoProductState[3]);
            data.append("group_product_id", groupProductId);
            API.post(`/photo_product/${sellerId}`, data, config);
          }
          if (this.state.photoProductState[4] instanceof File) {
            // console.log(res);
            const data = new FormData();
            console.log("State[4]");
            console.log(this.state.photoProductState[4]);
            data.append("upload_img", this.state.photoProductState[4]);
            data.append("group_product_id", groupProductId);
            API.post(`/photo_product/${sellerId}`, data, config);
          }
          this.storeSellerDetailProduct(groupProductId, sellerId);
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  updateProductPhoto = (groupProductId, sellerId) => {
    this.setState({ isLoading: true });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    // const sellerId = Cookies.get("qqq");
    // console.log(this.state.sellerId);

    if (this.state.photoProductState[0] instanceof File) {
      const data = new FormData();
      // console.log("State[0]");
      // console.log(this.state.photoProductState[0]);
      data.append("id", this.state.previewProductPhoto[0].id);
      data.append("upload_img", this.state.photoProductState[0]);
      data.append("group_product_id", groupProductId);

      API.post(`/photo_product/${sellerId}`, data, config)
        .then((res) => {
          if (parseInt(res.data.code) !== 200) {
            console.log(res);
          }
        })
        .catch((error) => {
          Swal.disableLoading();
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            if (error.response) {
              let errmessage = "";
              let keys = Object.keys(error.response.data.errors);
              for (let i = 0; i < keys.length; i++) {
                errmessage +=
                  keys[i] +
                  " " +
                  error.response.data.errors[keys[i]][0] +
                  "<br/>";
              }
              Swal.fire({
                icon: "error",
                html: errmessage,
              });
            }
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    }

    if (this.state.photoProductState[1] instanceof File) {
      const data = new FormData();
      // console.log("State[1]");
      // console.log(this.state.photoProductState[1]);
      data.append("upload_img", this.state.photoProductState[1]);
      if (this.state.previewProductPhoto[1].hasOwnProperty("id")) {
        data.append("id", this.state.previewProductPhoto[1].id);
      }
      data.append("group_product_id", groupProductId);
      API.post(`/photo_product/${sellerId}`, data, config);
    }
    if (this.state.photoProductState[2] instanceof File) {
      const data = new FormData();
      // console.log("State[2]");
      // console.log(this.state.photoProductState[2]);
      data.append("upload_img", this.state.photoProductState[2]);
      if (this.state.previewProductPhoto[2].hasOwnProperty("id")) {
        data.append("id", this.state.previewProductPhoto[2].id);
      }
      data.append("group_product_id", groupProductId);
      API.post(`/photo_product/${sellerId}`, data, config);
    }
    if (this.state.photoProductState[3] instanceof File) {
      const data = new FormData();
      // console.log("State[3]");
      // console.log(this.state.photoProductState[3]);
      data.append("upload_img", this.state.photoProductState[3]);
      if (this.state.previewProductPhoto[3].hasOwnProperty("id")) {
        data.append("id", this.state.previewProductPhoto[3].id);
      }
      data.append("group_product_id", groupProductId);
      API.post(`/photo_product/${sellerId}`, data, config);
    }
    if (this.state.photoProductState[4] instanceof File) {
      const data = new FormData();
      // console.log("State[4]");
      // console.log(this.state.photoProductState[4]);
      data.append("upload_img", this.state.photoProductState[4]);
      if (this.state.previewProductPhoto[4].hasOwnProperty("id")) {
        data.append("id", this.state.previewProductPhoto[4].id);
      }
      data.append("group_product_id", groupProductId);
      API.post(`/photo_product/${sellerId}`, data, config);
    }
  };

  storeDetailProductPhoto = (
    groupProductId,
    productId,
    photoDetailProductId,
    sellerId
  ) => {
    this.setState({ isLoading: true });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    // const sellerId = Cookies.get("qqq");
    // console.log(this.state.sellerId);

    const data = new FormData();
    data.append("upload_img", this.state.photoDetailProductState);
    data.append("product_id", productId);
    data.append("group_product_id", groupProductId);
    if (photoDetailProductId) {
      data.append("id", this.state.photoDetailProductId);
    }

    API.post(`/photo_product/${sellerId}`, data, config)
      .then((res) => {
        if (parseInt(res.data.code) !== 200) {
          console.log(res);
        } else {
          // console.log(res);
          this.showSellerProducts(groupProductId, sellerId);
          this.modalHandler("CLOSE");
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  storeSellerProduct = (sellerId) => {
    // this.setState({ isLoading: true });

    // //Swal loading
    // Swal.fire({
    //   title: "Menambahkan...",
    //   allowOutsideClick: false,
    //   onBeforeOpen: () => {
    //     Swal.showLoading();
    //   },
    // });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    // const sellerId = Cookies.get("qqq");
    // console.log(this.state.sellerId);

    if (
      this.state.nameDetail !== "" &&
      this.state.price !== 0 &&
      this.state.hpp !== 0 && //hargapokok
      this.state.qty !== 0 && //stock
      this.state.weight !== 0 && //in gram
      this.state.sellerLocationId !== ""
    ) {
      if (this.state.nameDetail.length >= 3) {
        // console.log("true");
        const data = new FormData();
        data.append("name", this.state.name);
        data.append("category_id", this.state.categoryId);
        data.append("description", this.state.description);

        API.post(`/group_product/${sellerId}`, data, config)
          .then((res) => {
            this.setState({ isLoading: false });
            if (parseInt(res.data.code) !== 200) {
              console.log(res);
            } else {
              // if (this.state.selectedProductPhoto) {

              // for (
              //   let index = 0;
              //   index < this.state.photoProductState.length;
              //   index++
              // ) {
              //   console.log(this.state.photoProductState[index])
              //   if (this.state.photoProductState[index] instanceof File) {
              this.storeProductPhoto(res.data.data.group_product.id, sellerId);
              //   }
              // }

              // }
              // this.setState(
              //   { groupProductId: res.data.data.group_product.id },
              //   () => {
              //     this.storeSellerDetailProduct(this.state.groupProductId);
              // this.props.history.push(
              //   `/admin/sellerproducts/edit-product/${this.state.groupProductId}`
              // );
              //   }
              // );
              Swal.fire({
                icon: "success",
                text: "Berhasil menambahkan produk",
                onBeforeOpen: () => {
                  Swal.hideLoading();
                },
              });
            }
          })
          .catch((error) => {
            Swal.disableLoading();
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              if (error.response) {
                let errmessage = "";
                let keys = Object.keys(error.response.data.errors);
                for (let i = 0; i < keys.length; i++) {
                  errmessage +=
                    keys[i] +
                    " " +
                    error.response.data.errors[keys[i]][0] +
                    "<br/>";
                }
                Swal.fire({
                  icon: "error",
                  html: errmessage,
                });
              }
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      } else {
        Swal.fire({
          icon: "error",
          text: "Nama tidak boleh kurang dari 3 huruf!",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        text: "Isian pada detail tidak boleh ada yang kosong!",
      });
    }
  };

  updateSellerProduct = (groupProductId, sellerId) => {
    this.setState({ isLoading: true });

    //Swal loading
    Swal.fire({
      title: "Mengubah...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    // const sellerId = Cookies.get("qqq");
    // console.log(this.state.sellerId);

    const data = new FormData();
    data.append("id", groupProductId);
    data.append("name", this.state.name);
    data.append("category_id", this.state.categoryId);
    data.append("description", this.state.description);

    if (this.state.name && this.state.categoryId && this.state.description) {
      API.post(`/group_product/${sellerId}`, data, config)
        .then((res) => {
          this.setState({ isLoading: false });
          if (parseInt(res.data.code) !== 200) {
            console.log(res);
          } else {
            // if (this.state.selectedProductPhoto) {
            this.updateProductPhoto(res.data.data.group_product.id, sellerId);
            // }
            Swal.fire({
              icon: "success",
              text: "Berhasil mengubah produk",
              onBeforeOpen: () => {
                Swal.hideLoading();
              },
            });
          }
        })
        .catch((error) => {
          Swal.disableLoading();
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            if (error.response) {
              let errmessage = "";
              let keys = Object.keys(error.response.data.errors);
              for (let i = 0; i < keys.length; i++) {
                errmessage +=
                  keys[i] +
                  " " +
                  error.response.data.errors[keys[i]][0] +
                  "<br/>";
              }
              Swal.fire({
                icon: "error",
                html: errmessage,
              });
            }
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    } else {
      Swal.fire({
        icon: "error",
        text: "Nama, Kategori, dan Deskripsi tidak boleh kosong",
      });
    }
  };

  // deleteSellerCategory = (sellerId, categoryId) => {
  //   let token = Cookies.get("access_token");

  //   const config = {
  //     headers: {
  //       "X-Requested-With": "XMLHttpRequest",
  //       Authorization: "Bearer " + token,
  //     },
  //   };

  //   Swal.fire({
  //     title: "Anda yakin akan menghapus kategori ini?",
  //     icon: "warning",
  //     cancelButtonText: "Tidak",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Ya",
  //   }).then((result) => {
  //     if (result.value) {
  //       return API.delete(`/seller_location/${sellerId}/${categoryId}`, config)
  //         .then((res) => {
  //           if (parseInt(res.data.code) !== 200) {
  //             console.log(res.data);
  //           } else {
  //             Swal.fire({
  //               title: "Berhasil",
  //               text: "Alamat telah berhasil dihapus",
  //               type: "success",
  //               showConfirmButton: true,
  //               confirmButtonText: "Tutup",
  //             }).then(() => this.listSellerCategory());
  //           }
  //         })
  //         .catch((error) => {
  //           Swal.disableLoading();
  //           if (error.response) {
  //             /*
  //              * The request was made and the server responded with a
  //              * status code that falls out of the range of 2xx
  //              */
  //             console.log(error.response.data);
  //             console.log(error.response.status);
  //             console.log(error.response.headers);
  //             if (error.response) {
  //               let errmessage = "";
  //               let keys = Object.keys(error.response.data.errors);
  //               for (let i = 0; i < keys.length; i++) {
  //                 errmessage +=
  //                   keys[i] +
  //                   " " +
  //                   error.response.data.errors[keys[i]][0] +
  //                   "<br/>";
  //               }
  //               Swal.fire({
  //                 icon: "error",
  //                 html: errmessage,
  //               });
  //             }
  //           } else if (error.request) {
  //             /*
  //              * The request was made but no response was received, `error.request`
  //              * is an instance of XMLHttpRequest in the browser and an instance
  //              * of http.ClientRequest in Node.js
  //              */
  //             console.log(error.request);
  //           } else {
  //             // Something happened in setting up the request and triggered an Error
  //             console.log("Error", error.message);
  //           }
  //           console.log(error.config);
  //         });
  //     } else {
  //       Swal.close();
  //     }
  //   });
  // };

  beActiveProduct = (productId, statusProduct, sellerId) => {
    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    // const sellerId = Cookies.get("qqq");

    const data = {
      is_active: parseInt(statusProduct) === 1 ? "0" : "1",
    };

    Swal.fire({
      title: parseInt(statusProduct) === 1 ? "Nonaktifkan?" : "Aktifkan?",
      icon: "warning",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.value) {
        return API.put(
          `/group_product/is_active/${sellerId}/${productId}`,
          data,
          config
        )
          .then((res) => {
            Swal.fire({
              title: "Berhasil",
              text:
                parseInt(statusProduct) === 1
                  ? "Produk anda telah dinonaktifkan"
                  : "Produk anda telah diaktifkan",
              icon: "success",
              showConfirmButton: true,
              confirmButtonText: "Tutup",
            }).then(() => this.listSellerProducts(sellerId));
          })
          .catch((error) => {
            Swal.disableLoading();
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              if (error.response) {
                let errmessage = "";
                let keys = Object.keys(error.response.data.errors);
                for (let i = 0; i < keys.length; i++) {
                  errmessage +=
                    keys[i] +
                    " " +
                    error.response.data.errors[keys[i]][0] +
                    "<br/>";
                }
                Swal.fire({
                  icon: "error",
                  html: errmessage,
                });
              }
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      } else {
        Swal.close();
      }
    });
  };

  //setState untuk kategori show
  setProductIdCategoryActive = (categoryId) => {
    this.setState(
      (prevState) => ({
        listCategoryActiveTemp: prevState.listCategoryActiveTemp.map((e) =>
          e.value === categoryId ? { ...e, checked: true } : e
        ),
      }),
      () =>
        this.setState({ listCategoryActive: this.state.listCategoryActiveTemp })
    );
  };

  // setState untuk alamat show
  setSellerLocationActive = (sellerLocationId) => {
    this.setState((prevState) => ({
      listSellerLocationsArr: prevState.listSellerLocationsArr.map((e) =>
        e.value === sellerLocationId ? { ...e, checked: true } : e
      ),
    }));
  };

  // SHOW SELLER PRODUCTS & LIST SELLER DETAILS PRODUCT
  showSellerProducts = (groupProductId, sellerId) => {
    // console.log(sellerId);
    this.setState({ isLoading: true });
    this.clearForm();

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    // const sellerId = Cookies.get("qqq");
    // ${this.state.sellerId}

    API.get(`/group_product/${sellerId}/${groupProductId}`, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.data) {
          // SHOW SELLER PRODUCTS
          this.setState(
            {
              name: res.data.data.group_product.name,
              categoryId: res.data.data.group_product.category_id,
              description: res.data.data.group_product.description,
              previewProductPhoto: res.data.data.group_product.photo_product,
              nameReset: res.data.data.group_product.name,
              categoryIdReset: res.data.data.group_product.category_id,
              descriptionReset: res.data.data.group_product.description,
            },
            () => {
              let tempArr;
              this.setState(
                (state) => {
                  if (state.previewProductPhoto.length < 6) {
                    for (let index = 0; index < 5; index++) {
                      if (state.previewProductPhoto.hasOwnProperty(index)) {
                        if (index === 0) {
                          tempArr = [state.previewProductPhoto[0]];
                        } else {
                          tempArr = [
                            ...tempArr,
                            state.previewProductPhoto[index],
                          ];
                        }
                      } else {
                        tempArr = [...tempArr, []];
                      }
                    }
                  }
                  return { previewProductPhoto: tempArr };
                }
              );
              this.setProductIdCategoryActive(this.state.categoryId);
            }
          );
          // LIST SELLER DETAILS PRODUCT
          let listDataTemp = res.data.data.group_product.dtl_product.map(
            (list) => ({
              id: list.id,
              reference_product_id: list.reference_product_id,
              name: list.name,
              price: list.price,
              stock: list.stock,
              hpp: list.hpp,
              weight: list.weight,
              seller_location_id: list.seller_location_id,
              seller_location: this.state.listSellerLocationsArr
                ? this.state.listSellerLocationsArr.find(
                    (e) => e.value === list.seller_location_id
                  ).text
                : "",
              group_product_id: list.group_product_id,
              is_active: list.is_active,
              photo_product: list.photo_product[0],
            })
          );
          let dataTableTemp = {
            columns: [
              {
                label: "Detail Produk",
                field: "name",
                sort: "asc",
              },
              {
                label: "Harga",
                field: "price",
                sort: "asc",
              },
              {
                label: "Stok/Quantity",
                field: "stock",
                sort: "asc",
              },
              {
                label: "HPP",
                field: "hpp",
                sort: "asc",
              },
              {
                label: "Lokasi",
                field: "sellerLocation",
                sort: "asc",
              },
              {
                label: "Foto",
                field: "photoDetailProduct",
                sort: "asc",
              },
              {
                label: "Action",
                field: "action",
              },
            ],
            rows: listDataTemp.map((list) => ({
              name: list.name,
              price: list.price,
              stock: list.stock,
              hpp: list.hpp,
              sellerLocation: list.seller_location,
              photoDetailProduct: list.photo_product ? (
                <img
                  style={{ maxWidth: 128, maxHeight: 128 }}
                  src={list.photo_product.link}
                  alt={"detailPhoto" + list.id}
                />
              ) : (
                "Tidak ada gambar"
              ),
              isActive:
                parseInt(list.is_active) === 1 ? "Aktif" : "Tidak Aktif",
              action: (
                <>
                  <>
                    <MDBBtn
                      size="sm"
                      color={parseInt(list.is_active) !== 1 ? "green" : "red"}
                      onClick={() =>
                        this.beActiveDetailProduct(
                          list.id,
                          list.group_product_id,
                          list.is_active,
                          sellerId
                        )
                      }
                    >
                      {parseInt(list.is_active) !== 1 ? (
                        <>
                          {" "}
                          <MDBIcon
                            icon="toggle-on"
                            className="mr-1"
                          /> Aktifkan{" "}
                        </>
                      ) : (
                        <>
                          {" "}
                          <MDBIcon icon="toggle-off" className="mr-1" />{" "}
                          Nonaktifkan{" "}
                        </>
                      )}
                    </MDBBtn>
                    <br />
                  </>

                  <MDBBtn
                    size="sm"
                    onClick={() => this.modalHandler("UPDATE", list)}
                  >
                    <MDBIcon icon="edit" className="mr-1" /> Ubah
                  </MDBBtn>
                  {/* <MDBBtn
                    size="sm"
                    onClick={() =>
                      this.deleteSellerCategory(list.seller_id, list.id)
                    }
                  >
                    <MDBIcon icon="trash-alt" className="mr-1" /> Hapus
                  </MDBBtn> */}
                </>
              ),
            })),
          };
          this.setState({
            // data: listDataForHeader,
            dataTableDetail: dataTableTemp,
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  storeSellerDetailProduct = (groupProductId, sellerId) => {
    // console.log(sellerId);
    this.setState({ isLoading: true });
    let token = Cookies.get("access_token");
    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    // const sellerId = Cookies.get("qqq");
    // console.log(this.state.sellerId);

    const data = new FormData();
    data.append("name", this.state.nameDetail);
    data.append("price", this.state.price);
    data.append("stock", this.state.qty);
    data.append("hpp", this.state.hpp);
    data.append("weight", this.state.weight);
    data.append("seller_location_id", this.state.sellerLocationId);
    data.append("group_product_id", groupProductId);

    if (
      this.state.nameDetail &&
      this.state.price &&
      this.state.qty &&
      this.state.hpp &&
      this.state.weight &&
      this.state.sellerLocationId
    ) {
      API.post(`/detail_product/${sellerId}`, data, config)
        .then((res) => {
          this.setState({ isLoading: false });
          if (parseInt(res.data.code) !== 200) {
            console.log(res);
          } else {
            // console.log(res);
            this.setState(
              {
                nameDetail: "",
                price: "",
                qty: "",
                hpp: "",
                weight: "",
                sellerLocationId: "",
                groupProductId: "",
              },
              () => {
                this.props.history.push(
                  `/admin/sellerproducts/edit-product/${groupProductId}`
                );
                if (this.state.photoDetailProductState) {
                  this.storeDetailProductPhoto(
                    groupProductId,
                    res.data.data.detail_product.id,
                    undefined,
                    sellerId
                  );
                } else {
                  this.showSellerProducts(groupProductId, sellerId);
                  this.modalHandler("CLOSE");
                }
              }
            );
          }
        })
        .catch((error) => {
          Swal.disableLoading();
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            if (error.response) {
              let errmessage = "";
              let keys = Object.keys(error.response.data.errors);
              for (let i = 0; i < keys.length; i++) {
                errmessage +=
                  keys[i] +
                  " " +
                  error.response.data.errors[keys[i]][0] +
                  "<br/>";
              }
              Swal.fire({
                icon: "error",
                html: errmessage,
              });
            }
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    } else {
      Swal.fire({
        icon: "error",
        text: "Data tidak boleh ada yang kosong",
      });
    }
  };

  beActiveDetailProduct = (
    detailProductId,
    groupProductId,
    statusProduct,
    sellerId
  ) => {
    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    const data = {
      is_active: parseInt(statusProduct) === 1 ? "0" : "1",
    };

    Swal.fire({
      title: parseInt(statusProduct) === 1 ? "Nonaktifkan?" : "Aktifkan?",
      icon: "warning",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.value) {
        return API.put(
          `/detail_product/is_active/${sellerId}/${detailProductId}`,
          data,
          config
        )
          .then((res) => {
            Swal.fire({
              title: "Berhasil",
              text:
                parseInt(statusProduct) === 1
                  ? "Detail produk telah dinonaktifkan"
                  : "Detail produk telah diaktifkan",
              icon: "success",
              showConfirmButton: true,
              confirmButtonText: "Tutup",
            }).then(() => this.showSellerProducts(groupProductId, sellerId));
          })
          .catch((error) => {
            Swal.disableLoading();
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              if (error.response) {
                let errmessage = "";
                let keys = Object.keys(error.response.data.errors);
                for (let i = 0; i < keys.length; i++) {
                  errmessage +=
                    keys[i] +
                    " " +
                    error.response.data.errors[keys[i]][0] +
                    "<br/>";
                }
                Swal.fire({
                  icon: "error",
                  html: errmessage,
                });
              }
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      } else {
        Swal.close();
      }
    });
  };

  updateSellerDetailProduct = (sellerId) => {
    // console.log("update -> sellerID : " + sellerId);
    this.setState({ isLoading: true });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    // const sellerId = Cookies.get("qqq");
    // console.log(this.state.sellerId);

    const data = new FormData();
    data.append("id", this.state.detailProductId);
    data.append("name", this.state.nameDetail);
    data.append("price", this.state.price);
    data.append("stock", this.state.qty);
    data.append("hpp", this.state.hpp);
    data.append("weight", this.state.weight);
    data.append("seller_location_id", this.state.sellerLocationId);
    data.append("group_product_id", this.state.groupProductId);

    if (
      this.state.nameDetail &&
      this.state.price &&
      this.state.qty &&
      this.state.weight &&
      this.state.sellerLocationId
    ) {
      API.post(`/detail_product/${sellerId}`, data, config)
        .then((res) => {
          this.setState({ isLoading: false });
          if (parseInt(res.data.code) !== 200) {
            console.log(res);
          } else {
            if (
              this.state.photoDetailProductId &&
              this.state.photoDetailProductState
            ) {
              // gambar sdh ada, diisi foto baru
              this.storeDetailProductPhoto(
                this.state.groupProductId,
                this.state.detailProductId,
                this.state.photoDetailProductId,
                sellerId
              );
            } else if (
              !this.state.photoDetailProductId &&
              this.state.photoDetailProductState
            ) {
              // gambar belum ada, diisi foto baru
              this.storeDetailProductPhoto(
                this.state.groupProductId,
                this.state.detailProductId,
                undefined,
                sellerId
              );
            } else {
              // tidak diisi gambar (ada atau tidak ada)
              this.showSellerProducts(this.state.groupProductId, sellerId);
              this.modalHandler("CLOSE");
            }
          }
        })
        .catch((error) => {
          Swal.disableLoading();
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            if (error.response) {
              let errmessage = "";
              let keys = Object.keys(error.response.data.errors);
              for (let i = 0; i < keys.length; i++) {
                errmessage +=
                  keys[i] +
                  " " +
                  error.response.data.errors[keys[i]][0] +
                  "<br/>";
              }
              Swal.fire({
                icon: "error",
                html: errmessage,
              });
            }
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    } else {
      Swal.fire({
        icon: "error",
        text: "Data tidak boleh ada yang kosong",
      });
    }
  };

  //untuk update show
  listSellerCategoryActive = (groupProductId, pageState, sellerId) => {
    // console.log(sellerId);
    this.setState({ isLoading: true });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    // const sellerId = Cookies.get("qqq");
    // ${this.state.sellerId}

    API.get(`/seller_category/${sellerId}?active=1`, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.data) {
          let listData = res.data.data.category.map((list, idx) => ({
            //select
            text: list.name,
            value: list.id.toString(),
            checked: false,
          }));
          pageState === "ADD"
            ? this.setState({
                listCategoryActive: listData,
              })
            : this.setState(
                {
                  listCategoryActiveTemp: listData,
                },
                () => this.showSellerProducts(groupProductId, sellerId)
              );
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  //untuk update detail
  listSellerLocation = (sellerId) => {
    this.setState({ isLoading: true });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    // const sellerId = Cookies.get("qqq");
    // ${this.state.sellerId}

    API.get(`/seller_location/${sellerId}`, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.data) {
          let listDataTemp = res.data.data.seller_location.map((list) => ({
            text: list.alias,
            value: list.id,
            checked: false,
          }));
          this.setState({
            listSellerLocationsArr: listDataTemp,
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  clearForm = () => {
    this.setState(
      {
        name: "",
        categoryId: "",
        description: "",
        previewProductPhoto: "",
      },
      () =>
        this.setState(
          (prevState) => ({
            listCategoryActiveTemp: prevState.listCategoryActiveTemp.map((e) =>
              e.checked == true ? { ...e, checked: false } : e
            ),
          }),
          () =>
            this.setState({
              listCategoryActive: this.state.listCategoryActiveTemp,
            })
        )
    );
  };

  // mengembalikan form group product di edit page
  resetForm = () => {
    this.setState(
      {
        name: this.state.nameReset,
        categoryId: this.state.categoryIdReset,
        description: this.state.descriptionReset,
      },
      () =>
        this.setState(
          (prevState) => ({
            listCategoryActive: prevState.listCategoryActive.map((e) =>
              e.checked == true ? { ...e, checked: false } : e
            ),
          }),
          () =>
            this.setState(
              (prevState) => ({
                listCategoryActive: prevState.listCategoryActive.map((e) =>
                  e.value == this.state.categoryIdReset
                    ? { ...e, checked: true }
                    : e
                ),
              }),
              () => {
                console.log(this.state.categoryIdReset);
                console.log(this.state.listCategoryActive);
              }
            )
        )
    );
  };

  clearDetailForm = () => {
    this.setState(
      {
        detailProductId: "",
        nameDetail: "",
        price: 0,
        hpp: 0, //hargapokok
        qty: 0, //stock
        weight: 0, //in gram
        sellerLocationId: "",
        groupProductId: "",
        photoDetailProductState: "",
        previewDetailProductPhoto: "",
        photoDetailProductId: "",
      },
      () =>
        this.setState((prevState) => ({
          listSellerLocationsArr: prevState.listSellerLocationsArr.map((e) =>
            e.checked == true ? { ...e, checked: false } : e
          ),
        }))
    );
  };

  render() {
    return (
      <SellerProductsContext.Provider
        value={{
          ...this.state,
          changeHandler: this.changeHandler,
          modalHandler: this.modalHandler,
          selectProductPhoto: this.selectProductPhoto,
          selectDetailProductPhoto: this.selectDetailProductPhoto,
          selectProductCategory: this.selectProductCategory,
          selectSellerLocation: this.selectSellerLocation,
          listSellerProducts: this.listSellerProducts,
          storeSellerProduct: this.storeSellerProduct,
          updateSellerProduct: this.updateSellerProduct,
          updateSellerDetailProduct: this.updateSellerDetailProduct,
          beActiveProduct: this.beActiveProduct,
          showSellerProducts: this.showSellerProducts,
          storeSellerDetailProduct: this.storeSellerDetailProduct,
          listSellerCategoryActive: this.listSellerCategoryActive,
          listSellerLocation: this.listSellerLocation,
          clearForm: this.clearForm,
          resetForm: this.resetForm,
        }}
      >
        {this.props.children}
      </SellerProductsContext.Provider>
    );
  }
}

export default withRouter(SellerProductsProvider);
