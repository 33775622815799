import React, { Component } from "react";
import API from "./utils/API";
import Cookies from "js-cookie";
import SellerPrmContext from "./SellerPrmContext";

class SellerPrmProvider extends Component {
  state = {
    link: "",
    subDomainData: {}, //func checkUserDomain
    isShopDomain: false,
    isSeller: false,
    isLoading: false,
    isError: false,
    isLoginState: false,
    isLogFromCookies: false,
    dataUser: {},
    dataSeller: {},
  };

  getHostName = (link) => {
    this.setState({ link }, () => this.checkUserDomain());
  };

  checkUserDomain = () => {
    this.setState({ isLoading: true });
    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };

    API.post("/auth/check_sub_domain", { web: this.state.link }, config)
      .then((res) => {
        document.title = res.data.data.seller
          ? res.data.data.seller.name_web
          : "1Toko";
        if (!Array.isArray(res.data.data)) {
          // console.log(res.data.data);
          this.setState(
            {
              subDomainData: res.data.data.seller,
              isShopDomain: res.data.data.seller.length > 0 ? true : false,
            },
            () => {
              // console.log(this.state.subDomainData);
              this.checkDataUser();
            }
          );
        } else {
          this.checkDataUser();
          this.setState({ isLoading: false });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  };

  // check data yang login
  checkDataUser = () => {
    if (Cookies.get("access_token")) {
      let token = Cookies.get("access_token");

      const config = {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + token,
        },
      };

      API.get(`/auth/user/${parseInt(Cookies.get("logged"))}`, config)
        .then((res) => {
          if (res.data.data) {
            this.setState(
              {
                dataUser: res.data.data ? res.data.data.user : "",
                dataSeller: res.data.data ? res.data.data.seller : "",
              },
              () => {
                this.checkSellerLogin();
              }
            );
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          console.log(err);
          if (err.response.status === 401) {
            Cookies.remove("access_token", { domain: "1toko.com" });
            Cookies.remove("logged", { domain: "1toko.com" });
            
            Cookies.remove("access_token");
            Cookies.remove("logged");
            window.location.reload();
          }
        });
    } else {
      if (this.state.subDomainData) {
        this.checkSellerLogin();
        this.setState({
          isSeller: false,
          isLoading: false,
          isLoginState: false,
        });
      }
    }
  };

  //check kalau yg login seller
  checkSellerLogin = () => {
    if (Cookies.get("access_token")) {
      //check jika yg login seller sama dengan website
      // console.log(this.state.subDomainData);
      // console.log(Cookies.get("logged"));
      // console.log(`location:${window.location.hostname}`);
      if (
        parseInt(Cookies.get("logged")) ===
        parseInt(this.state.subDomainData.id)
      ) {
        this.setState({ isSeller: true, isLoading: false, isLoginState: true });
      } else {
        this.setState({
          isSeller: false,
          isLoading: false,
          isLoginState: true,
        });
      }
    }
  };

  render() {
    return (
      <SellerPrmContext.Provider
        value={{
          ...this.state,
          checkUserDomain: this.checkUserDomain,
          checkDataUser: this.checkDataUser,
          getHostName: this.getHostName,
        }}
      >
        {this.props.children}
      </SellerPrmContext.Provider>
    );
  }
}

export default SellerPrmProvider;
