import React, { Component } from "react";
import { MDBRow, MDBCol, MDBBtn, MDBContainer } from "mdbreact";
import SectionContainer from "../sectionContainer";
import ProductView from "./ProductView";
import { Link } from "react-router-dom";
import { CMDBBtn } from "../StyledComponent";
import "../../../assets/css/fonts.css";

export default class ProductCategoryView extends Component {
  render() {
    const p = this.props.producthome;
    const { seller_style } = this.props;
    const jml_item = p.product !== undefined ? p.product.length : "";
    // console.log(this.props)
    return (
      <>
        {this.props.selectedTab === 0 ? (
          p.category.map((data, index) => {
            if (data.product.length > 0) {
              return (
                <div key={index}>
                  <MDBContainer>
                    {/* <SectionContainer key={data.id} header={data.name}> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "Lato Semi Bold",
                      }}
                    >
                      <div>
                        <p
                          style={{
                            color: this.props.btn_color,
                            fontSize: 18,
                            fontWeight: "bold",
                            margin: 0,
                          }}
                        >
                          {data.name}
                        </p>
                      </div>
                      <div>
                        <Link to={"/category/" + data.id}>
                          <p
                            style={{
                              color: "gray",
                              fontSize: 18,
                              fontWeight: "bold",
                              margin: 0,
                            }}
                          >
                            Lihat Semua
                          </p>
                        </Link>
                      </div>
                    </div>

                    <MDBContainer style={{ padingTop: 1, fontFamily: "Lato Regular", }}>
                      <MDBRow>
                        <ProductView
                          type="all"
                          product={data.product}
                          seller_style={seller_style}
                        />
                      </MDBRow>
                      {/* <MDBRow center>
                        <Link to={"/category/" + data.id}>
                          <CMDBBtn
                            active
                            style={{ width: "240px" }}
                            bg_color={this.props.btn_color}
                          >
                            Lihat Lebih Banyak
                          </CMDBBtn>
                        </Link>
                      </MDBRow> */}
                    </MDBContainer>
                    {/* </SectionContainer> */}
                  </MDBContainer>
                  <hr />
                </div>
              );
            } else {
              return "";
            }
          })
        ) : p.product !== undefined ? (
          <MDBContainer>
            {/* <SectionContainer> */}
            <MDBContainer>
              <MDBRow>
                <ProductView
                  type="product"
                  product={p.product}
                  seller_style={seller_style}
                />
              </MDBRow>
              <MDBRow center>
                {jml_item !== p.totalItem ? (
                  <CMDBBtn
                    active
                    bg_color={this.props.btn_color}
                    style={{ width: "240px" }}
                    onClick={() => this.props.loadMoreProduct()}
                  >
                    Lihat Lebih Banyak
                  </CMDBBtn>
                ) : (
                  ""
                )}
              </MDBRow>
            </MDBContainer>
            {/* </SectionContainer> */}
          </MDBContainer>
        ) : (
          ""
        )}
      </>
    );
  }
}
