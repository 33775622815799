import { MDBIcon } from "mdbreact";
import React from "react";
import SlideCarousel from "./SlideCarousel";
import {
  SearchBarContainer,
  TopSearchBarInput,
  TopSearchBarSpan,
  TopSearchBarWrapper,
} from "../Header/Header.styles";
import { PageContainer } from "./Landing.styles";
import StoreListLanding from "./StoreListLanding";

class LandingView extends React.Component {
  render() {
    return (
      <>
        <PageContainer>
          {/* search */}
          <div style={{ paddingTop: "5%", marginBottom: "5%" }}>
            <form
              onSubmit={(event) => this.props.searchSubmitHandler(event)}
              autoComplete="off"
            >
              <SearchBarContainer>
                <TopSearchBarWrapper>
                  <TopSearchBarSpan>
                    <MDBIcon className="white-text" size="sm" icon="search" />
                  </TopSearchBarSpan>
                  <TopSearchBarInput
                    name="query"
                    onChange={this.props.searchChangeHandler}
                  />
                </TopSearchBarWrapper>
              </SearchBarContainer>
            </form>
          </div>
          {/* banner */}
          <SlideCarousel />
          {/* shops list */}
          <StoreListLanding />
        </PageContainer>
      </>
    );
  }
}

export default LandingView;
