import React, { Component } from "react";
import SellerPrmContext from "../../SellerPrmContext";
import CartContext from "../context/CartContext";
import ShipmentMain from "../components/Shipment/ShipmentMain";

export default class Shipment extends Component {
  render() {
    return (
      <div>
        <SellerPrmContext.Consumer>
          {(sellerPrm) => (
            <CartContext.Consumer>
              {(cart) =>
              <ShipmentMain
                sellerPrm={sellerPrm}
                cart={cart}
              />}
            </CartContext.Consumer>
          )}
        </SellerPrmContext.Consumer>
      </div>
    );
  }
}
