import React, { Component } from "react";
import SellerPrmContext from "../../../SellerPrmContext";
import { MDBCard, MDBView, MDBContainer, MDBRow } from "mdbreact";

export default class Banner extends Component {
  static contextType = SellerPrmContext;

  render() {
    return (
      <div style={{paddingTop:'6rem'}}>
        {/* <MDBCard> */}
          <MDBContainer>
            <MDBRow center>
              <img
                className="img-fluid"
                src={this.context.subDomainData.banner}
                style={{
                  width: "100%",
                }}
                alt="Banner Template"
              />
            </MDBRow>
          </MDBContainer>
        {/* </MDBCard> */}
      </div>
    );
  }
}
