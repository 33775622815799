import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCol,
  MDBContainer,
} from "mdbreact";
import { Link, NavLink } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";
import { StyledButtonTheming } from "../_UtilityComponent/Button/StyledButton";

export const ContainerPayment = styled(MDBContainer)``;

export const CardContainerPayment = styled(MDBCard)``;

export const CardHeaderPayment = styled(MDBCardHeader)`
  background-color: white;
`;

export const CardHeaderWithFlag = styled.div`
  display: flex;
  align-items: center;
`;

export const CardBodyPayment = styled(MDBCardBody)``;

export const CardFooterPayment = styled.div`
  text-align: center;
  margin-bottom: 1rem;
`;

export const CardInfoName = styled.div`
  font-weight: 600;
`;

export const CardInfoContent = styled.div``;

export const CardItemDtlContainer = styled.div`
  padding: 1.25rem;
`;

export const CardItemDtlRow = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  flex-flow: row nowrap;
  margin-left: auto;
  margin-right: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const CardItemDtlWrapper = styled.div`
  margin: 0 1rem;
`;

export const CardImageContainer = styled.div`
  width: 150px;
  padding-bottom: 1rem;
`;

export const CardItemInfo = styled.div``;

export const CardItemButton = styled(StyledButtonTheming)`
  font-size: 15px;
  color: #ffffff;
  border-radius: 4px;
  margin: 10px 0;
`;

export const CardItemReqCancelBtn = styled(CardItemButton)`
  border: 1px solid red;
  background-color: red !important;
`;

export const CardItemTrackBtn = styled(CardItemButton)`
  border: 1px solid green;
  background-color: green !important;
`;

export const CardItemDoneBtn = styled(CardItemButton)`
  border: 1px solid green;
  background-color: green !important;
`;

//
export const CardItemButtonDash = styled(MDBBtn)`
  font-size: 15px;
  color: #ffffff;
  border-radius: 4px;
  border: 1px solid purple;
  background-color: purple !important;
  /* margin: 10px 0; */
  
  /* ${props => props.small && css`

  `}; */
`;

export const CardItemReqCancelBtnDash = styled(MDBBtn)`
  border: 1px solid red;
  background-color: red !important;
`;

export const CardItemTrackBtnDash = styled(MDBBtn)`
  border: 1px solid green;
  background-color: green !important;
`;

export const CardItemDoneBtnDash = styled(MDBBtn)`
  border: 1px solid green;
  background-color: green !important;
`;
