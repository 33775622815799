import React, { Component } from "react";
import API from "../../utils/API";
import Swal from "sweetalert2";
import RegisterView from "../components/RegisterView/RegisterView";

class RegisterUser extends Component {
  state = {
    name: "",
    email: "",
    no_tlp: "",
    password: "",
    passwordConfirmation: "",
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  submitHandler = (event) => {
    const { name, email, no_tlp, password, passwordConfirmation } = this.state;
    //Swal loading
    Swal.fire({
      title: "Mohon menunggu...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    event.preventDefault();
    event.target.className += " was-validated";
    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };
    const data = new FormData();
    data.append("name", name);
    data.append("email", email);
    data.append("no_tlp", no_tlp);
    data.append("password", password);
    data.append("password_confirmation", passwordConfirmation);
    data.append("url", window.location.hostname);

    const emailregex = new RegExp("^.*@.*\\..*");
    const passregex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");
    if (
      name !== "" &&
      email !== "" &&
      password !== "" &&
      passwordConfirmation !== ""
    ) {
      if (emailregex.test(email)) {
        if (passregex.test(password)) {
          if (password === passwordConfirmation) {
            API.post(`auth/register_user`, data, config)
              .then((res) => {
                if (parseInt(res.data.code) === 200) {
                  Swal.hideLoading();
                  Swal.fire({
                    icon: "success",
                    title: "Berhasil!",
                    text:
                      "Silahkan cek email anda untuk konfirmasi pendaftaran!",
                  });
                  this.props.history.push("/login");
                }
              })
              .catch((err) => {
                Swal.disableLoading();
                console.log(err);
              });
          } else {
            Swal.fire({
              icon: "error",
              title: "Sorry...",
              text: "Password and Confirmation Password not same",
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            html:
              "Password must be 6 character or more and " +
              "contain lowercase and uppercase alphabet, " +
              "and numeric character",
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Sorry...",
          text: "Silahkan masukan email yang benar",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Sorry...",
        text: "Tidak boleh ada yang kosong!",
      });
    }
  };

  render() {
    return (
      <>
        <RegisterView
          {...this.state}
          isStore={true}
          changeHandler={this.changeHandler}
          submitHandler={this.submitHandler}
        />
      </>
    );
  }
}

export default RegisterUser;
