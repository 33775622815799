import React, { useContext } from "react";
import { Text } from "../../context/Language";
import {
  MDBNavLink,
  MDBNavItem,
  MDBDropdownItem,
  MDBDropdownToggle,
  MDBDropdown,
  MDBDropdownMenu,
} from "mdbreact";
import SellerPrmContext from "../../../SellerPrmContext";
import { CMDBDropdownItem } from "../../components/StyledComponent";

export const HeaderMenuSeller = (props) => {
  const sellerPrmContext = useContext(SellerPrmContext);

  if (sellerPrmContext.isSeller) {
    return (
      <div>
        <MDBNavItem>
          <MDBDropdown>
            <MDBDropdownToggle nav caret className={`${props.text_color}-text`}>
              Seller Menu
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              <CMDBDropdownItem color={props.seller_style.color_primary}>
                <MDBNavLink className="black-text" to="/admin/sellerprofile">
                  <Text pid="sellerMenu_header" tid="sellerProfileBtn" />
                </MDBNavLink>
              </CMDBDropdownItem>
              <CMDBDropdownItem color={props.seller_style.color_primary}>
                <MDBNavLink className="black-text" to="/admin/storeprofile">
                  <Text pid="sellerMenu_header" tid="sellerAboutSettingBtn" />
                </MDBNavLink>
              </CMDBDropdownItem>
              <CMDBDropdownItem color={props.seller_style.color_primary}>
                <MDBNavLink className="black-text" to="/admin/bank_account">
                  <Text pid="sellerMenu_header" tid="sellerBankBtn" />
                </MDBNavLink>
              </CMDBDropdownItem>
              <CMDBDropdownItem color={props.seller_style.color_primary}>
                <MDBNavLink className="black-text" to="/admin/sellerlocation">
                  <Text pid="sellerMenu_header" tid="sellerLocationBtn" />
                </MDBNavLink>
              </CMDBDropdownItem>
              <CMDBDropdownItem color={props.seller_style.color_primary}>
                <MDBNavLink className="black-text" to="/admin/sellercategory">
                  <Text pid="sellerMenu_header" tid="sellerCategoryBtn" />
                </MDBNavLink>
              </CMDBDropdownItem>
              <CMDBDropdownItem color={props.seller_style.color_primary}>
                <MDBNavLink className="black-text" to="/admin/sellerproducts">
                  <Text pid="sellerMenu_header" tid="sellerProductBtn" />
                </MDBNavLink>
              </CMDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavItem>
      </div>
    );
  } else {
    return <></>;
  }
};
