import React, { useContext, useEffect, useState } from "react";
import MainRoutes from "../../MainRoutes";
import { ThemeProvider } from "styled-components";
import useTheme from "../../../utils/_CustomHooks/useTheme";
import SellerPrmContext from "../../../SellerPrmContext";
import { useHistory, withRouter } from "react-router-dom";

//global shop header
import Header from "../../components/Header";
//global shop routes
import Routes from "../../ShopRoutes";

// import Header1 from "../../template1/Header";
// import Routes1 from "../../template1/Routes";

// import Header2 from "../../template2/Header";
// import Routes2 from "../../template2/Routes";

// import Header3 from "../../template3/Header";
// import Routes3 from "../../template3/Routes";

// import Header4 from "../../template4/Header";
// import Routes4 from "../../template4/Routes";

// import Header5 from "../../components/Header";
// import Routes5 from "../../template5/Routes";

import MainHeader from "../MainHeader/MainHeader";
import API from "../../../utils/API";
import Cookies from "js-cookie";
import { CenterLoader } from "../_UtilityComponent/Loader/CenterLoader";
import BottomNavigation from "../BottomNavigation/BottomNavigation";
import { PageContainer } from "../LandingView/Landing.styles";

const TemplateSwitcher = () => {
  let history = useHistory();
  const sellerPrmContext = useContext(SellerPrmContext);
  const userTheme = useTheme();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [bottomNavHeight, setBottomNavHeight] = useState(0);
  // const headerHeightRef = useRef(null);

  // console.log(sellerPrmContext);

  const checkHeaderHeight = (headerHeight) => {
    setHeaderHeight(headerHeight);
  };

  const checkBottomNavHeight = (bottomHeight) => {
    setBottomNavHeight(bottomHeight);
    // console.log(bottomHeight);
  };

  const logout = () => {
    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
    API.get("/auth/logout", config)
      .then((res) => {
        Cookies.remove("access_token", { domain: "1toko.com" });
        Cookies.remove("logged", { domain: "1toko.com" });
        history.push("/");
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (!sellerPrmContext.isLoading) {
    // if (sellerPrmContext.subDomainData) {
    //   console.log(sellerPrmContext.subDomainData.template_id)
    //   switch (sellerPrmContext.subDomainData.template_id) {
    //     case 1:
    //       return (
    //         <div>
    //           <ThemeProvider theme={userTheme}>
    //             <Header template={1} logout={logout} />
    //             <Routes />
    //           </ThemeProvider>
    //         </div>
    //       );
    //     case 2:
    //       return (
    //         <div>
    //           <ThemeProvider theme={userTheme}>
    //             <Header template={2} logout={logout} />
    //             <Routes />
    //           </ThemeProvider>
    //         </div>
    //       );
    //     case 3:
    //       return (
    //         <div>
    //           <ThemeProvider theme={userTheme}>
    //             <Header template={3} logout={logout} />
    //             <Routes />
    //           </ThemeProvider>
    //         </div>
    //       );
    //     case 4:
    //       return (
    //         <div>
    //           <ThemeProvider theme={userTheme}>
    //             <Header template={4} logout={logout} />
    //             <Routes />
    //           </ThemeProvider>
    //         </div>
    //       );
    //     case 5:
    //       return (
    //         <div>
    //           <ThemeProvider theme={userTheme}>
    //             <Header template={5} logout={logout} />
    //             <Routes />
    //           </ThemeProvider>
    //         </div>
    //       );
    //     case 6:
    //       return (
    //         <div>
    //           <ThemeProvider theme={userTheme}>
    //             <Header template={6} logout={logout} />
    //             <Routes />
    //           </ThemeProvider>
    //         </div>
    //       );
    //     default:
    //       return (
    //         <>
    //           <MainHeader
    //             dataUser={sellerPrmContext}
    //             logout={logout}
    //             checkHeaderHeight={checkHeaderHeight}
    //           />
    //           <div style={{ marginTop: headerHeight + 16 }}>
    //             <MainRoutes />
    //           </div>
    //         </>
    //       );
    //   }
    // }
    if (
      sellerPrmContext.subDomainData &&
      parseInt(sellerPrmContext.subDomainData.template_id) === 1
    ) {
      return (
        <div>
          <ThemeProvider theme={userTheme}>
            <Header template={1} logout={logout} />
            <Routes />
            <div style={{ paddingBottom: bottomNavHeight }}>
              <BottomNavigation checkBottomNavHeight={checkBottomNavHeight} />
            </div>
          </ThemeProvider>
        </div>
      );
    } else if (
      sellerPrmContext.subDomainData &&
      parseInt(sellerPrmContext.subDomainData.template_id) === 2
    ) {
      return (
        <div>
          <ThemeProvider theme={userTheme}>
            <Header template={2} logout={logout} />
            <div style={{ paddingBottom: bottomNavHeight }}>
              <Routes />
            </div>
            <BottomNavigation checkBottomNavHeight={checkBottomNavHeight} />
          </ThemeProvider>
        </div>
      );
    } else if (
      sellerPrmContext.subDomainData &&
      parseInt(sellerPrmContext.subDomainData.template_id) === 3
    ) {
      return (
        <div>
          <ThemeProvider theme={userTheme}>
            <Header template={3} logout={logout} />
            <div style={{ paddingBottom: bottomNavHeight }}>
              <Routes />
            </div>
            <BottomNavigation checkBottomNavHeight={checkBottomNavHeight} />
          </ThemeProvider>
        </div>
      );
    } else if (
      sellerPrmContext.subDomainData &&
      parseInt(sellerPrmContext.subDomainData.template_id) === 4
    ) {
      return (
        <div>
          <ThemeProvider theme={userTheme}>
            <Header template={4} logout={logout} />
            <div style={{ paddingBottom: bottomNavHeight }}>
              <Routes />
            </div>
            <BottomNavigation checkBottomNavHeight={checkBottomNavHeight} />
          </ThemeProvider>
        </div>
      );
    } else if (
      sellerPrmContext.subDomainData &&
      parseInt(sellerPrmContext.subDomainData.template_id) === 5
    ) {
      return (
        <div>
          <ThemeProvider theme={userTheme}>
            <Header template={5} logout={logout} />
            <div style={{ paddingBottom: bottomNavHeight }}>
              <Routes />
            </div>
            <BottomNavigation checkBottomNavHeight={checkBottomNavHeight} />
          </ThemeProvider>
        </div>
      );
    } else if (
      sellerPrmContext.subDomainData &&
      parseInt(sellerPrmContext.subDomainData.template_id) === 6
    ) {
      return (
        <div>
          <ThemeProvider theme={userTheme}>
            <Header template={6} logout={logout} />
            <div style={{ paddingBottom: bottomNavHeight }}>
              <Routes />
            </div>
            <BottomNavigation checkBottomNavHeight={checkBottomNavHeight} />
          </ThemeProvider>
        </div>
      );
    } else {
      return (
        //  <PageContainer>
        <>
          <MainHeader
            dataUser={sellerPrmContext}
            logout={logout}
            checkHeaderHeight={checkHeaderHeight}
          />
          <MainRoutes />
         {/* </PageContainer> */}
        </>
      );
    }
  } else {
    return <CenterLoader />;
  }
};

export default withRouter(TemplateSwitcher);
