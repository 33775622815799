import {
  MDBBadge,
  MDBCard,
  MDBCol,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
} from "mdbreact";
import React, { Component } from "react";
import * as S from "../_Styled/CardPayment.styles.js";

export default class DashboardSellerOrderPickupLocationModal extends Component {
  SellerLocationDetail = (data, index) => {
    const selected_id = this.props.selectedLocation.id;
    return (
      <MDBCard
        key={index}
        style={{ border: "1px solid black", marginBottom: "5%" }}
      >
        <MDBContainer style={{ maxWidth: "90%" }}>
          <MDBCol>
            <MDBRow>
              {`${data.name} (${data.alias}) `}
              {data.is_primary === "1" ? (
                <span>
                  &nbsp;
                  <MDBBadge pill>Primary</MDBBadge>
                </span>
              ) : (
                ""
              )}
            </MDBRow>
            <MDBRow>{data.hp}</MDBRow>
            <MDBRow>{data.address}</MDBRow>
          </MDBCol>
          <MDBCol className="text-center">
            {selected_id !== data.id ? (
              <S.CardItemButtonDash
                size="sm"
                onClick={() => {
                  this.props.locationSelectHandler(index);
                  this.props.setLocationModal(false);
                }}
              >
                Pilih
              </S.CardItemButtonDash>
            ) : (
              ""
            )}
          </MDBCol>
        </MDBContainer>
      </MDBCard>
    );
  };

  render() {
    return (
      <>
        <MDBModal
          isOpen={this.props.locationModal}
          toggle={() => this.props.setLocationModal(false)}
          size="lg"
          centered
        >
          <MDBModalHeader toggle={() => this.props.setLocationModal(false)}>
            <MDBContainer>
              <MDBRow>
                <MDBCol>Pilih Alamat Pickup</MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBModalHeader>
          <MDBModalBody>
            <div className="overflow-auto" style={{ maxHeight: "250px" }}>
              {this.props.sellerLocationList.length > 0
                ? this.props.sellerLocationList.map((data, index) => {
                    return this.SellerLocationDetail(data, index);
                  })
                : ""}
            </div>
          </MDBModalBody>
        </MDBModal>
      </>
    );
  }
}
