import { MDBBtn, MDBCol, MDBContainer, MDBDataTable, MDBRow } from "mdbreact";
import React, { Component } from "react";
import { CMDBBtn } from "../StyledComponent";
import '../../../assets/css/fonts.css'

export default class BankAccountView extends Component {
  render() {
    const { data, popupModal, seller_style } = this.props;
    // console.log(this.props)
    return (
      <MDBContainer style={{fontFamily:'Lato Regular'}}>
        <MDBRow>
          <MDBCol>
            <CMDBBtn
              active
              bg_color={seller_style.color_btn}
              onClick={() => popupModal("ADD", "1")}
            >
              TAMBAH REKENING
            </CMDBBtn>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <MDBDataTable hover noBottomColumns responsive fixed data={data} />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}
