import { MDBCard, MDBCol, MDBContainer, MDBRow } from "mdbreact";
import styled, { css, keyframes } from "styled-components";

export const StyledContainerTabs = styled(MDBContainer)`
  padding: 2% 0;
`;

export const StyledRowTabs = styled(MDBRow)`
  overflow-x: auto;
  white-space: nowrap;
  flex-flow: row nowrap;
  /* margin-top: 10%; */
  margin-left: auto;
  margin-right: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledTab = styled(MDBCol).attrs({
  size: "5",
})`
  min-width: fit-content;
`;

export const StyledTabCard = styled(MDBCard)`
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.color.primary};
  /* font-family: ${(props) =>
    props.theme.font.primary
      ? props.theme.font.primary
      : "Lato, sans-serif"}; */
  /* min-width: min-content; */
  /* max-width: unset; */
  color: ${(props) => props.theme.font.dark};
  text-align: center;
  font-weight: bold;

  ${(props) =>
    //transient-props
    props.$activeTab
      ? css`
          background-color: ${({ theme }) => theme.color.primary};
          color: ${(props) =>
            props.theme.color.type === "dark"
              ? props.theme.font.light
              : props.theme.font.dark};
        `
      : ""}

  /* Template 5 */
  
    ${(props) =>
    props.theme.template.id === "5" &&
    css`
      color: #000000;
    `}
  
      ${(props) =>
    props.theme.template.id === "5" && props.$activeTab
      ? css`
          background-color: ${({ theme }) => theme.color.primary};
          color: #ffffff;
        `
      : ""}
`;
