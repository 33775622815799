import { Link, NavLink } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";

export const ProductAboutContainer = styled.div`
  background-color: /* ${(props) => props.theme.color.secondary}; */ white;
  padding: 5vh 5vw;
`;

export const TitleProductAbout = styled.div`
  margin-bottom: 15px;
  /* font-family: ${(props) =>
    props.theme.font.primary ? props.theme.font.primary : "Lato, sans-serif"}; */
    font-family: "Lato Bold", sans-serif;
  font-size: 23px;
  font-weight: 600;
  text-align: center;
  color: ${(props) =>
    props.theme.font.dark ? props.theme.font.dark : "#000000"};
  ${(props) =>
    props.productAbout &&
    css`
      margin-bottom: 2vh;
    `}
`;

export const ProductAboutImage = styled.img`
  width: 100%;
  min-height: auto;
  margin-bottom: 15px;
`;

export const ProductAboutText = styled.div`
  line-height: 1.5;
  word-spacing: 5px;
  color: ${(props) =>
    props.theme.font.dark ? props.theme.font.dark : "#000000"};
  font-family: "Lato Regular", sans-serif;  
`;
