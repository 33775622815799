import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBDataTable,
  MDBBtn,
  MDBCol,
  MDBRow,
} from "mdbreact";

const SellerLocationView = (props) => {
  return (
    <MDBContainer>
      <MDBCard>
        <MDBRow>
          <MDBCol>
            <MDBBtn onClick={() => props.addModal("ADD")}>Tambah Alamat</MDBBtn>
          </MDBCol>
          <MDBCol />
        </MDBRow>
        <MDBCardBody>
          <MDBDataTable
            hover
            noBottomColumns
            responsive
            data={props.data}
          />
        </MDBCardBody>
      </MDBCard>
    </MDBContainer>
  );
};

export default SellerLocationView;
