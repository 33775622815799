import {
  MDBCard,
  MDBCol,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
} from "mdbreact";
import React, { Component } from "react";
// import SellerLocationFormModal from "../../SellerLocation/SellerLocationFormModal";
import { CMDBBadge, CMDBBtn } from "../../StyledComponent";

export default class SellerLocationListModal extends Component {
  SellerLocationDetail = (data, index) => {
    const selected_id = this.props.selected_location.id;
    return (
      <div key={index}>
        <MDBCard>
          <MDBContainer style={{ maxWidth: "90%" }}>
            <MDBRow>
              <MDBCol>
                <MDBContainer style={{ padding: "5px" }}>
                  <MDBRow>
                    {`${data.name} (${data.alias}) `}
                    {data.is_primary === "1" ? (
                      <span>
                        &nbsp;
                        <CMDBBadge bg_color="#4a148c" pill>
                          Primary
                        </CMDBBadge>
                      </span>
                    ) : (
                      ""
                    )}
                  </MDBRow>
                  <MDBRow>{data.hp}</MDBRow>
                  <MDBRow>{data.address}</MDBRow>
                </MDBContainer>
              </MDBCol>
              {selected_id !== data.id ? (
                <MDBCol size="2" middle>
                  <CMDBBtn
                    active
                    bg_color="#4a148c"
                    onClick={() => {
                      this.props.handelSellerLocation(index);
                      this.props.popupModal("CLOSE", "1");
                    }}
                  >
                    Pilih
                  </CMDBBtn>
                </MDBCol>
              ) : (
                ""
              )}
            </MDBRow>
          </MDBContainer>
        </MDBCard>
        <br />
      </div>
    );
  };

  render() {
    const { modal1, seller_location_list, popupModal } = this.props;
    return (
      <>
        <MDBModal
          isOpen={modal1}
          toggle={() => popupModal("CLOSE", "1")}
          size="lg"
          centered
        >
          <MDBModalHeader toggle={() => popupModal("CLOSE", "1")}>
            <MDBContainer>
              <MDBRow>
                <MDBCol>Pilih Almat Pickup</MDBCol>
                {/* <MDBCol>
                  <CMDBBtn
                    bg_color="#4a148c"
                    onClick={() => this.props.s_loc.modalHandler("ADD")}
                  >
                    Tambah Alamat
                  </CMDBBtn>
                </MDBCol> */}
              </MDBRow>
            </MDBContainer>
          </MDBModalHeader>
          <MDBModalBody>
            <div className="overflow-auto" style={{ maxHeight: "250px" }}>
              {seller_location_list !== undefined
                ? seller_location_list.map((data, index) => {
                    return this.SellerLocationDetail(data, index);
                  })
                : ""}
            </div>
          </MDBModalBody>
        </MDBModal>
      </>
    );
  }
}
