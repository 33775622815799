import { MDBBtn, MDBContainer, MDBInput } from "mdbreact";
import React from "react";
import { ManageSellerAddContainer } from "./ManageSellerLocationCategory.styles";

export const ManageSellerCategoryAdd = (props) => {
  return (
    <ManageSellerAddContainer>
      <MDBContainer>
        <form
          className="needs-validation"
          onSubmit={(e) => {
            props.submitHandler(e, props.sellerId);
          }}
          noValidate
        >
          <MDBInput
            value={props.name}
            name="name"
            onChange={props.changeHandler}
            type="text"
            className="form-control"
            label="Nama Kategori/Category Name"
            required
          >
            <div className="invalid-feedback">Masukan Nama Kategori</div>
          </MDBInput>
          <MDBBtn
            className="w-100 mr-0 ml-0 mt-2"
            color="deep-orange"
            type="submit"
          >
            Tambah Kategori
          </MDBBtn>
        </form>
      </MDBContainer>
    </ManageSellerAddContainer>
  );
};
