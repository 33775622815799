import React, { Component } from "react";
import { Editor } from "@tinymce/tinymce-react";
import {
  MDBCol,
  MDBContainer,
  MDBFileInput,
  MDBIcon,
  MDBInput,
  MDBRow,
} from "mdbreact";
// import { SketchPicker } from "react-color";
import '../../../assets/css/fonts.css';

export default class AboutFormView extends Component {
  render() {
    const props = this.props;
    const {
      changeTextHandler,
      changeColorHandler,
      changeImageHandler,
      changeTitleHandler,
      deleteImageHandler,
    } = this.props;
    return (
      <MDBContainer style={{fontFamily:'Lato Regular'}}>
        <MDBRow>
          {/* <MDBCol size="3">
            <p>Background Color :</p>
            <h3
              style={{
                borderStyle: "solid",
                textAlign: "center",
                backgroundColor: props.background_color,
              }}
            >
              {props.background_color}
            </h3>
            <SketchPicker
              color={props.background_color}
              onChangeComplete={changeColorHandler}
            />
          </MDBCol> */}
          <MDBCol size='12'>
            <div>Header Image :</div>
            <div
              hidden={props.header_img_prev === ""}
              style={{ position: "relative" }}
            >
              <MDBIcon
                icon="times"
                size="2x"
                onClick={() => deleteImageHandler("header")}
                style={{
                  position: "absolute",
                  zIndex: "30",
                  right: "0",
                  color: "red",
                  cursor: "pointer",
                  backgroundColor:'white'
                }}
              />
              <img
                className=" img-fluid img-thumbnail z-depth-1 mx-auto d-block"
                src={props.header_img_prev}
                style={{ maxHeight: "310px" }}
              />
            </div>
            <MDBFileInput getValue={(e) => changeImageHandler(e, "header")} />
          </MDBCol>
        </MDBRow>
        <hr />
        <MDBRow>
          <MDBCol lg='4' md='12' sm='12'>
            <div>{"Company Image :"}</div>
            <div
              hidden={props.company_img_prev === ""}
              style={{ position: "relative" }}
            >
              <MDBIcon
                icon="times"
                size="2x"
                onClick={() => deleteImageHandler("company")}
                style={{
                  position: "absolute",
                  zIndex: "30",
                  right: "0",
                  color: "red",
                  cursor: "pointer",
                  backgroundColor:'white'
                }}
              />
              <img
                className=" img-fluid img-thumbnail z-depth-1 mx-auto d-block"
                src={props.company_img_prev}
                style={{ maxHeight: "168px" }}
              />
            </div>
            <MDBFileInput getValue={(e) => changeImageHandler(e, "company")} />
            <MDBInput
              value={props.company_title}
              onChange={changeTitleHandler}
              type="text"
              className="form-control"
              name="company_title"
              label="About Company Title"
            />
          </MDBCol>
          <MDBCol>
            <div>About Company :</div>
            <Editor
              apiKey="uiufc92ypcz3slpvjw31kxplkjk4dab3au761a8n1ycf65kq"
              value={props.company_txt}
              init={{
                height: 290,
                menubar: false,
                resize: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic underline link | fontselect forecolor backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent",
              }}
              onEditorChange={(event, editor) =>
                changeTextHandler(event, editor, "company")
              }
            />
          </MDBCol>
        </MDBRow>
        <hr />
        <MDBRow>
          <MDBCol  lg='4' md='12' sm='12'>
            <div>{"Product Image :"}</div>
            <div
              hidden={props.product_img_prev === ""}
              style={{ position: "relative" }}
            >
              <MDBIcon
                icon="times"
                size="2x"
                onClick={() => deleteImageHandler("product")}
                style={{
                  position: "absolute",
                  zIndex: "30",
                  right: "0",
                  color: "red",
                  cursor: "pointer",
                  backgroundColor:'white'
                }}
              />
              <img
                className=" img-fluid img-thumbnail z-depth-1 mx-auto d-block"
                src={props.product_img_prev}
                style={{ maxHeight: "168px" }}
              />
            </div>
            <MDBFileInput getValue={(e) => changeImageHandler(e, "product")} />
            <MDBInput
              value={props.product_title}
              onChange={changeTitleHandler}
              type="text"
              className="form-control"
              name="product_title"
              label="About Product Title"
            />
          </MDBCol>
          <MDBCol>
            <div>About Product :</div>
            <Editor
              apiKey="uiufc92ypcz3slpvjw31kxplkjk4dab3au761a8n1ycf65kq"
              value={props.product_txt}
              init={{
                height: 290,
                menubar: false,
                resize: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic underline link | fontselect forecolor backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent",
              }}
              onEditorChange={(event, editor) =>
                changeTextHandler(event, editor, "product")
              }
            />
          </MDBCol>
        </MDBRow>
        <hr />
        <MDBRow>
          <MDBCol lg='4' md='12' sm='12'>
            <div>{"Other Image :"}</div>
            <div
              hidden={props.other_img_prev === ""}
              style={{ position: "relative" }}
            >
              <MDBIcon
                icon="times"
                size="2x"
                onClick={() => deleteImageHandler("other")}
                style={{
                  position: "absolute",
                  zIndex: "30",
                  right: "0",
                  color: "red",
                  cursor: "pointer",
                  backgroundColor:'white'
                }}
              />
              <img
                className=" img-fluid img-thumbnail z-depth-1 mx-auto d-block"
                src={props.other_img_prev}
                style={{ maxHeight: "168px" }}
              />
            </div>
            <MDBFileInput getValue={(e) => changeImageHandler(e, "other")} />
            <MDBInput
              value={props.other_title}
              onChange={changeTitleHandler}
              type="text"
              className="form-control"
              name="other_title"
              label="About Other Title"
            />
          </MDBCol>
          <MDBCol>
            <div>About Other :</div>
            <Editor
              apiKey="uiufc92ypcz3slpvjw31kxplkjk4dab3au761a8n1ycf65kq"
              value={props.other_txt}
              init={{
                height: 240,
                menubar: false,
                resize: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic underline link | fontselect forecolor backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent",
              }}
              onEditorChange={(event, editor) =>
                changeTextHandler(event, editor, "other")
              }
            />
          </MDBCol>
        </MDBRow>
        <hr />
      </MDBContainer>
    );
  }
}
