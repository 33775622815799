import React from "react";

export const SearchBox = (props) => {
  return (
    <div>
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text" id="basic-addon">
            <i className="fa fa-search prefix"></i>
          </span>
        </div>
        <input
          type="text"
          className="form-control"
          name={props.inputName}
          onChange={props.onChange}
          placeholder={props.placeholder}
        />
      </div>
    </div>
  );
};
