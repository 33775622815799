import React, { Component } from "react";
import API from "../../../utils/API";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import AboutFormView from "./AboutFormView";
import SectionContainer from "../sectionContainer";
import { MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdbreact";
// import AboutPreviewModal from "./AboutPreviewModal";
import '../../../assets/css/fonts.css';

export default class AboutSettingMain extends Component {
  state = {
    id: "",
    background_color: "#ffffff",
    header_img: "",
    company_img: "",
    product_img: "",
    other_img: "",

    company_title: "",
    product_title: "",
    other_title: "",

    company_txt: "",
    product_txt: "",
    other_txt: "",

    header_img_prev: "",
    company_img_prev: "",
    product_img_prev: "",
    other_img_prev: "",
    //delete
    header_img_del: "",
    company_img_del: "",
    product_img_del: "",
    other_img_del: "",
    modal1: false,
  };

  componentDidMount() {
    this.getAbout(this.props.sellerPrm.link);
  }

  createConfig = (token) => {
    let config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
    return config;
  };

  //handler
  changeTextHandler = (content, editor, _for) => {
    const param = _for + "_txt";
    this.setState({ [param]: content });
  };

  changeTitleHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  changeColorHandler = (color) => {
    this.setState({ background_color: color.hex });
  };

  changeImageHandler = (event, type) => {
    if (event[0]) {
      const param = type + "_img";
      const preview = type + "_img_prev";
      this.setState({
        [param]: event[0],
        [preview]: URL.createObjectURL(event[0]),
      });
    }
  };

  popupModal = (no) => {
    let modal = "modal" + no;
    this.setState({ [modal]: !this.state[modal] });
  };

  //get data
  getAbout = (web_link) => {
    // web_link = web_link.substring(0, web_link.indexOf("."));
    console.log(web_link);
    API.get(`about/${web_link}`, this.createConfig(Cookies.get("access_token")))
      .then((res) => {
        const data = res.data.data.about;
        // console.log(data)
        if (data !== null) {
          let header_img = data.header_image === null ? "" : data.header_image;
          let company_img =
            data.company_image === null ? "" : data.company_image;
          let product_img =
            data.product_image === null ? "" : data.product_image;
          let other_img = data.other_image === null ? "" : data.other_image;

          this.setState(
            {
              id: data.id,
              background_color: data.background_color,
              company_txt:
                data.about_company === null ? "" : data.about_company,
              product_txt:
                data.about_product === null ? "" : data.about_product,
              other_txt: data.about_other === null ? "" : data.about_other,

              company_title: data.company_title,
              product_title: data.product_title,
              other_title: data.other_title,

              header_img: header_img,
              company_img: company_img,
              product_img: product_img,
              other_img: other_img,

              header_img_prev: header_img,
              company_img_prev: company_img,
              product_img_prev: product_img,
              other_img_prev: other_img,
            },
            () => {
              // console.log(this.state);
            }
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //modify data
  deleteImageHandler = (type) => {
    const img_param = type + "_img";
    const img_delete = type + "_img_del";
    const img_preview = type + "_img_prev";
    this.setState({
      [img_param]: "",
      [img_delete]: this.state[img_preview],
      [img_preview]: "",
    });
  };

  storeAbout = (sellerId) => {
    const data = this.state;
    const sent_data = new FormData();
    sent_data.append("id", data.id);
    sent_data.append("background_color", data.background_color);
    sent_data.append("about_product", data.product_txt);
    sent_data.append("about_company", data.company_txt);
    sent_data.append("about_other", data.other_txt);

    sent_data.append(
      "company_title",
      data.company_title === "" ? "Company Profile" : data.company_title
    );
    sent_data.append(
      "product_title",
      data.product_title === "" ? "My Product" : data.product_title
    );
    sent_data.append(
      "other_title",
      data.other_title === "" ? "Other" : data.other_title
    );
    sent_data.append(
      typeof data.header_img === "object"
        ? "header_image"
        : "header_image_link",
      data.header_img
    );
    sent_data.append(
      typeof data.company_img === "object"
        ? "company_image"
        : "company_image_link",
      data.company_img
    );
    sent_data.append(
      typeof data.product_img === "object"
        ? "product_image"
        : "product_image_link",
      data.product_img
    );
    sent_data.append(
      typeof data.other_img === "object" ? "other_image" : "other_image_link",
      data.other_img
    );

    sent_data.append("delete_other_image", data.other_img_del);
    sent_data.append("delete_product_image", data.product_img_del);
    sent_data.append("delete_company_image", data.company_img_del);
    sent_data.append("delete_header_image", data.header_img_del);

    API.post(
      `about/${sellerId}`,
      sent_data,
      this.createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Data berhasil terupdate",
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <>
        {/* <AboutPreviewModal {...this.state} popupModal={this.popupModal} /> */}
        <MDBContainer style={{ paddingTop: "6rem", fontFamily:'Lato Semi Bold' }}>
          <SectionContainer header="ABOUT SETTING">
            <MDBRow>
              <MDBCol>
                <AboutFormView
                  {...this.state}
                  changeTextHandler={this.changeTextHandler}
                  changeColorHandler={this.changeColorHandler}
                  changeImageHandler={this.changeImageHandler}
                  changeTitleHandler={this.changeTitleHandler}
                  deleteImageHandler={this.deleteImageHandler}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              {/* <MDBCol>
                <MDBBtn
                  className="w-100"
                  color="deep-purple"
                  onClick={() => this.popupModal("1")}
                >
                  PREVIEW
                </MDBBtn>
              </MDBCol> */}
              <MDBCol>
                <MDBBtn
                  className="w-100"
                  color="dark-green"
                  onClick={() =>
                    this.storeAbout(this.props.sellerPrm.subDomainData.id)
                  }
                >
                  SAVE
                </MDBBtn>
              </MDBCol>
              <MDBCol>
                <MDBBtn
                  className="w-100"
                  color="danger"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  RESET CHANGE
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </SectionContainer>
        </MDBContainer>
      </>
    );
  }
}
