import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import SellerLocationContext from "../../context/SellerLocationContext";
import { PageContainer } from "../LandingView/Landing.styles";
import ModalOption from "../_UtilityComponent/ModalOption/ModalOption";
import { ManageSellerListItems } from "./ManageSellerListItems";
import { ManageSellerLocationAdd } from "./ManageSellerLocationAdd";
import { ManageSellerLocationEdit } from "./ManageSellerLocationEdit";

export const ManageSellerLocation = () => {
  let params = useParams();
  let history = useHistory();
  let page = params.page;
  let s_id = params.s_id;
  let process = params.s_name; //BUAT PROCESS
  // let process = params.process;
  const LocContext = useContext(SellerLocationContext);

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  /////SELLER LOCATION
  useEffect(() => {
    if (page === "manage_location" && s_id) {
      LocContext.listSellerLocation(s_id);
    }
    return () => {};
  }, [page, s_id]);

  /////////////////////
  // console.log(process);
  if (process === "add") {
    return (
      <ManageSellerLocationAdd
        sellerId={s_id}
        changeHandler={LocContext.changeHandler}
        submitHandler={LocContext.storeSellerLocation}
        alias={LocContext.alias}
        name={LocContext.name}
        hp={LocContext.hp}
        address={LocContext.address}
        district={LocContext.selectedAddress.name}
        addressSelectorHandler={LocContext.addressSelectorHandler}
        addressSelectorModal={LocContext.modalAddress}
        searchLocation={LocContext.searchLocation}
        textLocation={LocContext.textLocation}
        locationList={LocContext.locationList}
        selectedLocation={LocContext.selectedLocation}
      />
    );
  } else if (process === "edit") {
    return <ManageSellerLocationEdit />;
  } else {
    return (
      <>
        <ModalOption
          showModal={LocContext.modalOption}
          toggleHandler={LocContext.modalHandler}
          itemId={LocContext.selectedItemId}
          primaryId={LocContext.primaryAddressId}
          sellerId={s_id}
          actionTypeName={"CLOSE"}
          firstButtonTitle={"Jadikan Alamat Utama"}
          firstButtonClick={LocContext.bePrimarySellerLocation}
          secondButtonTitle={"Hapus"}
          secondButtonClick={LocContext.deleteSellerLocation}
        />
        <PageContainer>
          <ManageSellerListItems
            itemDashboard={LocContext.data}
            page={page}
            modalHandler={LocContext.modalHandler}
            history={history}
          />
        </PageContainer>
      </>
    );
  }
};
