import React, { Component } from "react";
import SellerProductsView from "./SellerProductsView";

export default class SellerProductsMain extends Component {
  componentDidMount() {
    if (this.props.sellerPrm.subDomainData) {
      this.props.sellerProducts.listSellerProducts(
        this.props.sellerPrm.subDomainData.id
      );
      // this.props.sellerLocation.getZipLocation();
    }
  }

  render() {
    // if (
    //   !this.props.sellerLocation.isLoading &&
    //   this.props.sellerLocation.dataTable
    // ) {
    const { seller_style } = this.props.sellerPrm.dataSeller;
    return (
      <div>
        {/* <SellerLocationFormModal
          sellerLocationId={this.props.sellerLocation.updateSellerLocationId}
          name={this.props.sellerLocation.name}
          hp={this.props.sellerLocation.hp}
          alias={this.props.sellerLocation.alias}
          zipCode={this.props.sellerLocation.zipCode}
          zipId={this.props.sellerLocation.zipId}
          address={this.props.sellerLocation.address}
          changeHandler={this.props.sellerLocation.changeHandler}
          isCheckGudang={this.props.sellerLocation.isCheckGudang}
          modalState={this.props.sellerLocation.modalState}
          toggleHandler={this.props.sellerLocation.modalHandler}
          actionType={this.props.sellerLocation.actionType}
          checkGudangHandler={this.props.sellerLocation.checkGudangHandler}
          storeSellerLocation={this.props.sellerLocation.storeSellerLocation}
          updateSellerLocation={this.props.sellerLocation.updateSellerLocation}
        /> */}
        <SellerProductsView
          seller_style={seller_style}
          data={this.props.sellerProducts.dataTable}
          //   addModal={this.props.sellerLocation.modalHandler}
        />
      </div>
    );
  }
}
