import React, { Component } from "react";
import API from "../../utils/API";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import ProfileViewSeller from "../components/ProfileView/ProfileViewSeller";

export default class ProfileSeller extends Component {
  constructor(props) {
    super(props);
    let token = Cookies.get("access_token");
    this.state = {
      //data seller
      id: "",
      user_id: "",
      facebook: "",
      twitter: "",
      instagram: "",
      nameWeb: "",
      subDomain: "",
      domain: "",
      logoToko: "",
      bannerToko: "",
      template_id: "",

      //variable buat tampilan
      renderLoaderSub: false,
      renderLoaderDomain: false,
      isSubDomainChecked: false,
      isDomainChecked: false,
      isAvailableSubDomain: "",
      isAvailableDomain: "",

      //variable buat template
      customLogoToko: "",
      previewLogoToko: "",
      customBannerToko: "",
      previewBannerToko: "",
      selectedTemplate: "",
      previewCustomLogoToko: "",
      previewCustomBannerToko: "",
      isTOSChecked: false,
      template: [
        {
          text: "Tema 1",
          value: "theme1",
        },
        {
          text: "Tema 2",
          value: "theme2",
        },
      ],

      //Other
      isEdit: true,
    };
    this._isMounted = false;
    this.config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
  }

  componentDidMount() {
    let id = Cookies.get("qqq");
    this._isMounted = true;
    this.getProfile(id);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  isEditChange = () => {
    this.setState({ isEdit: !this.state.isEdit });
  };

  getProfile = (id) => {
    API.get("auth/user/" + id, this.config)
      .then((res) => {
        // console.log(res.data)
        let data = res.data.data;
        data = data.seller;
        let sub = "" + data.subdomain;
        this.setState({
          id: data.id,
          user_id: data.user_id,
          domain: data.domain,
          subDomain: sub.substring(0, sub.indexOf(".")),
          facebook: data.facebook,
          instagram: data.instagram,
          twitter: data.twitter,
          nameWeb: data.name_web,
          template_id: data.template_id,
          logoToko: data.logo,
          previewCustomLogoToko: data.logo,
          bannerToko: data.banner,
          previewCustomBannerToko: data.banner,
        });
        this.getTemplateList();
        // console.log(this.state)
      })
      .catch((error) => {});
  };

  getTemplateList = () => {
    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };

    API.get("/prm/template", config)
      .then((res) => {
        console.log();
        if (res.data.code !== "200") {
          alert(res.data.errors);
        } else {
          let templateTemp = res.data.data.template.map((template) => ({
            checked: this.state.template_id == template.id ? true : false,
            text: template.name,
            value: template.id.toString(),
            banner: template.banner,
            logo: template.logo,
          }));
          if (this._isMounted) {
            this.setState({ template: templateTemp });
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  selectTemplateHandler = (event) => {
    let tempSelected;
    tempSelected = this.state.template.find((item) => item.value === event[0]);
    if (event[0]) {
      this.setState({
        selectedTemplate: event[0],
        previewBannerToko: tempSelected.banner,
        bannerToko: tempSelected.banner,
        previewLogoToko: tempSelected.logo,
        logoToko: tempSelected.logo,
      });
    }
  };

  checkDomainAvailability = (domain, type) => {
    let link;
    if (type === "sub") {
      this.setState({ renderLoaderSub: true });
      link = domain + ".1toko.com";
    } else {
      this.setState({ renderLoaderDomain: true });
      link = domain;
    }

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };

    API.post("/auth/check_sub_domain", { web: link }, config)
      .then((res) => {
        let temp = res.data.data;
        if (!Array.isArray(temp) && type === "sub") {
          this.setState({
            isAvailableSubDomain: false,
            isSubDomainChecked: true,
            renderLoaderSub: false,
          });
        } else if (!Array.isArray(temp) && type === "domain") {
          this.setState({
            isAvailableDomain: false,
            isDomainChecked: true,
            renderLoaderDomain: false,
          });
        } else if (Array.isArray(temp) && type === "sub") {
          this.setState({
            isAvailableSubDomain: true,
            isSubDomainChecked: true,
            renderLoaderSub: false,
          });
        } else if (Array.isArray(temp) && type === "domain") {
          this.setState({
            isAvailableDomain: true,
            isDomainChecked: true,
            renderLoaderDomain: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ renderLoader: false });
        // if (err.response) {
        //   let errmessage = "";
        //   let keys = Object.keys(err.response.data.errors);
        //   for (let i = 0; i < keys.length; i++) {
        //     errmessage +=
        //       keys[i] + " " + err.response.data.errors[keys[i]][0] + "<br/>";
        //   }
        //   Swal.fire({
        //     icon: "error",
        //     html: errmessage,
        //   });
        // }
      });
  };

  updateSellerProfile = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    // Swal.fire("test");
    this.isEditChange();
  };

  render() {
    return (
      <>
        <ProfileViewSeller
          //data atau variable
          data={this.state}
          isEdit={this.state.isEdit}
          isAvailableSubDomain={this.state.isAvailableSubDomain}
          isAvailableDomain={this.state.isAvailableDomain}
          isSubDomainChecked={this.state.isSubDomainChecked}
          isDomainChecked={this.state.isDomainChecked}
          renderLoaderSub={this.state.renderLoaderSub}
          renderLoaderDomain={this.state.renderLoaderDomain}
          //function
          isEditChange={this.isEditChange}
          changeHandler={this.changeHandler}
          updateSellerProfile={this.updateSellerProfile}
          selectTemplateHandler={this.selectTemplateHandler}
          checkDomainAvailability={this.checkDomainAvailability}
        />
      </>
    );
  }
}
