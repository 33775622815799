import React, { useContext } from "react";
import { Text } from "../../context/Language";
import {
  MDBNavLink,
  MDBNavItem,
  MDBDropdownItem,
  MDBDropdownToggle,
  MDBDropdown,
  MDBDropdownMenu,
} from "mdbreact";
import SellerPrmContext from "../../../SellerPrmContext";
import { CMDBDropdownItem } from "../../components/StyledComponent";

export const HeaderMenuUser = (props) => {
  const sellerPrmContext = useContext(SellerPrmContext);
  return (
    <div>
      {props.logged ? (
        <MDBNavItem>
          <MDBDropdown>
            <MDBDropdownToggle nav caret className={`${props.text_color}-text`}>
              <Text pid="userMenu_header" tid="helloText" />
              {sellerPrmContext.dataUser ? sellerPrmContext.dataUser.name : ""}
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              <CMDBDropdownItem color={props.seller_style.color_primary}>
                <MDBNavLink className="black-text" to="/profile">
                  <Text pid="userMenu_header" tid="profileBtn" />
                </MDBNavLink>
              </CMDBDropdownItem>
              <CMDBDropdownItem color={props.seller_style.color_primary}>
                <MDBNavLink className="black-text" to="/transaction">
                  Transaction
                </MDBNavLink>
              </CMDBDropdownItem>
              {props.IsSeller ? (
                <CMDBDropdownItem color={props.seller_style.color_primary}>
                  <MDBNavLink
                    className="black-text"
                    to="#"
                    onClick={() => {
                      window.location.href =
                        "http://merchant.1toko.com/seller_dashboard";
                    }}
                  >
                    Seller Dashboard
                  </MDBNavLink>
                </CMDBDropdownItem>
              ) : (
                <CMDBDropdownItem color={props.seller_style.color_primary}>
                  <MDBNavLink
                    className="black-text"
                    to="#"
                    onClick={() => {
                      window.location.href =
                        "http://merchant.1toko.com/";
                    }}
                  >
                    Go To 1Toko
                  </MDBNavLink>
                </CMDBDropdownItem>
              )}
              <CMDBDropdownItem color={props.seller_style.color_primary}>
                <MDBNavLink
                  className="black-text"
                  to="#"
                  onClick={props.logout}
                >
                  <Text pid="userMenu_header" tid="logoutBtn" />
                </MDBNavLink>
              </CMDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavItem>
      ) : (
        <MDBNavItem>
          <MDBNavLink
            className={`${props.text_color}-text align-self-center col-example text-left`}
            to="/login"
          >
            <Text pid="t1_header" tid="loginBtn" />
          </MDBNavLink>
        </MDBNavItem>
      )}
    </div>
  );
};
