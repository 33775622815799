import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import API from "../../../utils/API";
import OrderConfirmation from "../../pages/OrderConfirmation";
import { Sidebar, StyledLink } from "../StyledComponent";
import StoreListView from "./StoreListView";
import Cookies from "js-cookie";

class SellerDashboardMain extends Component {
  _isMounted = false;
  state = {
    selected_page: 0,
    store_list: undefined,
  };

  componentDidMount() {
    this._isMounted = true;
    let page =
      this.props.match.params.page === undefined
        ? 0
        : this.props.match.params.page;
    this.getStoreList();
    this.setState({ selected_page: page });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      let page =
        this.props.match.params.page === undefined
          ? 0
          : this.props.match.params.page;
      this.getStoreList();
      this.setState({ selected_page: page });
    }
  }

  selectMenuHandler = (page) => {
    this.setState({ selected_page: page });
  };

  createConfig = (token) => {
    let config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
    return config;
  };

  getStoreList = () => {
    API.get("auth/store", this.createConfig(Cookies.get("access_token")))
      .then((res) => {
        if (this._isMounted) {
          this.setState({ store_list: res.data.data.store_list });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <div style={{ display: "flex" }}>
        <Sidebar bg_color="#000000" style={{ paddingTop: "6rem" }}>
          <StyledLink exact to="/seller_dashboard">
            Dashboard
          </StyledLink>
          <StyledLink to="/seller_dashboard/store_list">Store List</StyledLink>
          <StyledLink to="/seller_dashboard/order_confirmation">
            Order Confirmation
          </StyledLink>
        </Sidebar>
        <div
          style={{
            width: "100%",
            marginLeft: "15rem",
            marginRight: "5rem",
            marginTop: "6rem",
          }}
        >
          {this.state.selected_page === 0 ? <p>Dashboard</p> : ""}
          {this.state.selected_page === "store_list" ? (
            <StoreListView {...this.state} />
          ) : (
            ""
          )}
          {this.state.selected_page === "order_confirmation" ? (
            this.state.store_list ? (
              <OrderConfirmation store_list={this.state.store_list} />
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(SellerDashboardMain);
