import { Link, NavLink } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";

export const StyledProductAllRows_TitleContainer = styled.div`
  margin-top: 5vh;
  display: flex;
  border-bottom: 1px solid black;
`;

export const StyledProductAllRows_Title = styled.div`
  /* color: ${(props) =>
    props.theme.color.type === "light"
      ? (props) => props.theme.font.light
      : (props) => props.theme.font.dark}; */
  /* font-family: ${(props) =>
    props.theme.font.primary
      ? props.theme.font.primary
      : "Libre Caslon Display, serif"}; */
  flex: 1 1 auto;
  font-size: 18px;
  font-family: "Lato Bold", sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  /* ${(props) =>
    props.theme.template.id === "5" &&
    css`
      color: #000000;
    `} */
  color: ${(props) => props.theme.color.primary};
`;

export const StyledProductAllRows_ShowAllLink = styled.div`
  font-family: "Lato Bold", sans-serif;
  font-weight: 600;
  font-size: 12px;
  text-transform: capitalize;
  color: ${(props) => props.theme.color.primary};
  align-self: center;
  flex: 0 0 30%;
  text-align: right;
`;
