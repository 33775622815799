import React, { Component } from "react"; 
import SellerCategoryView from "./SellerCategoryView";
import SellerCategoryFormModal from "./SellerCategoryFormModal";

export default class SellerCategoryMain extends Component {
  componentDidMount() {
    this.props.sellerCategory.listSellerCategory();
    this.props.sellerCategory.getDefaultCategoryIcon();
  }

  render() {
    // if (
    //   !this.props.sellerCategory.isLoading &&
    //   this.props.sellerCategory.dataTable
    // ) {
      
    return (
      <div>
        <SellerCategoryFormModal
          sellerCategoryId={this.props.sellerCategory.updateSellerCategoryId}
          checkDefaultIconHandler={this.props.sellerCategory.checkDefaultIconHandler}
          updateIconOriginal={this.props.sellerCategory.updateIconOriginal}
          selectDefaultIconHandler={this.props.sellerCategory.selectDefaultIconHandler}
          selectIconCategoryHandler={this.props.sellerCategory.selectIconCategoryHandler}
          defaultIconList={this.props.sellerCategory.defaultIconList}
          name={this.props.sellerCategory.name}
          isDefaultIcon={this.props.sellerCategory.isDefaultIcon}
          previewIcon={this.props.sellerCategory.previewIcon}
          previewIconDefault={this.props.sellerCategory.previewIconDefault}
          changeHandler={this.props.sellerCategory.changeHandler}
          modalState={this.props.sellerCategory.modalState}
          toggleHandler={this.props.sellerCategory.modalHandler}
          actionType={this.props.sellerCategory.actionType}
          storeSellerCategory={this.props.sellerCategory.storeSellerCategory}
          updateSellerCategory={this.props.sellerCategory.updateSellerCategory}
        />
        <SellerCategoryView
          data={this.props.sellerCategory.dataTable}
          addModal={this.props.sellerCategory.modalHandler}
        />
      </div>
    );
    // } else {
    //   return <div>Sedang loading data alamat</div>;
    // }
  }
}
