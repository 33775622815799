import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import API from "../../../utils/API";
import Cookies from "js-cookie";
import { DashboardSellerOrderView } from "./DashboardSellerOrderView";
import { CenterLoader } from "../_UtilityComponent/Loader/CenterLoader";
import Swal from "sweetalert2";

export const DashboardSellerOrderProcess = () => {
  //PAGE & PARAMS
  let location = useLocation();
  let params = useParams();
  let storeId = params.s_id;
  let storeName = params.s_name;
  let currentProcess = params.process;

  let mounted = false;

  //REQ & ERR
  const [requests, setRequests] = useState(0); //render 2x dari atas
  const [errors, setErrors] = useState(0);
  const requestChecker = (condition) => {
    if (condition === "FETCH") {
      setRequests(requests + 1);
    } else if (condition === "DONE") {
      setRequests(requests - 1);
    } else if (condition === "ERROR") {
      setRequests(requests - 1);
      setErrors(errors + 1);
    }
  };

  const createConfig = (token) => {
    let config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
    return config;
  };

  //STATE
  const [currentPage, setCurrentPage] = useState(1);
  const [invoiceList, setInvoiceList] = useState([]);
  const [trackingStatus, setTrackingStatus] = useState(undefined);
  const [trackingStatusModal, setTrackingStatusModal] = useState(false);

  const getTrackingStatus = (courier, tracking_code) => {
    API.get(
      `/courier/tracking_status/${courier}/${tracking_code}`,
      createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        if (courier === "NCS") {
          if (!res.data.track.hasOwnProperty("message")) {
            setTrackingStatus(res.data);
          }
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Tracking sedang terjadi masalah silahkan coba lagi.",
          icon: "error",
          showCloseButton: true,
          allowOutsideClick: true,
        }).then((res) => {
          if (res.isDismissed) {
            setTrackingStatus(undefined);
            setTrackingStatusModal(false);
          }
        });
        console.log(err);
      });
  };

  const getAllStoreInvoice = (status, storeId, courier = "") => {
    requestChecker("FETCH");
    const data = new FormData();
    data.append("status", JSON.stringify(status));
    data.append("courier", courier);
    data.append("storeId", storeId);
    API.post(
      `payment/all_store_invoice`,
      data,
      createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        const tempInvoiceList = res.data.invoice_list.map((item) => ({
          ...item,
          showItemState: false,
        }));
        if (mounted) {
          setInvoiceList(tempInvoiceList);
          requestChecker("DONE");
        }
      })
      .catch((err) => {
        requestChecker("ERROR");
        console.log(err);
      });
  };

  const showItemHandler = (action, index) => {
    let invoiceListTemp = [...invoiceList];
    if (action === "OPEN") {
      invoiceListTemp[index].showItemState = true;
      setInvoiceList(invoiceListTemp);
    } else if (action === "CLOSE") {
      invoiceListTemp[index].showItemState = false;
      setInvoiceList(invoiceListTemp);
    }
  };

  const trackingModalHandler = (action, courierName, trackingCode) => {
    if (action === "OPEN") {
      setTrackingStatusModal(true);
      getTrackingStatus(courierName, trackingCode);
    } else if (action === "CLOSE") {
      setTrackingStatus(undefined);
      setTrackingStatusModal(false);
    }
  };

  useEffect(() => {
    mounted = true;
    getAllStoreInvoice([3, 4], storeId, "");
    return () => {
      setInvoiceList([]);
      mounted = false;
    };
  }, []);

  if (requests <= 0 && errors <= 0) {
    return (
      <DashboardSellerOrderView
        invoiceList={invoiceList}
        titleProcess={"Pesanan Diproses"}
        showItemHandler={showItemHandler}
        trackingModalHandler={trackingModalHandler}
        trackingStatus={trackingStatus}
        trackingStatusModal={trackingStatusModal}
      />
    );
  } else if (requests <= 0 && errors > 0) {
    return <div>Maaf, sepertinya sedang ada kendala silahkan coba lagi</div>;
  } else {
    return <CenterLoader />;
  }
};
