import React, { Component } from "react";
import SellerProductsMain from "../components/SellerProducts/SellerProductsMain";
import SellerProductsContext from "../context/SellerProductsContext";

export default class SellerProducts extends Component {
  render() {
    return (
      <SellerProductsContext.Consumer>
        {(sellerProducts) => (
          <SellerProductsMain sellerProducts={sellerProducts} />
        )}
      </SellerProductsContext.Consumer>
    );
  }
}
