import React from "react";
import { useParams } from "react-router-dom";
import {
  DashboardItemCardThumbnail,
  DashboardItemTextStyled,
} from "./DashboardSeller.styles";

export const DashboardItems = (props) => {
  let params = useParams();
  let s_id = params.s_id;
  let s_name = params.s_name;
  return props.itemDashboard.map((item, index) => {
    return (
      <DashboardItem
        item={item}
        key={index}
        page={props.page}
        s_id={s_id}
        s_name={s_name}
      />
    );
  });
};

const DashboardItem = (props) => {
  switch (props.page) {
    case "store_list":
      return (
        <DashboardItemCardThumbnail
          to=""
          onClick={() => {
            window.location = `http://${props.item.subdomain}`;
          }}
        >
          {props.item.icon}
          <DashboardItemTextStyled>{props.item.text}</DashboardItemTextStyled>
        </DashboardItemCardThumbnail>
      );
    case "order_confirmation":
      if (props.process) {
        // dengan process
      } else {
        return (
          <DashboardItemCardThumbnail to={`${props.item.link}`}>
            {props.item.icon}
            <DashboardItemTextStyled>{props.item.text}</DashboardItemTextStyled>
          </DashboardItemCardThumbnail>
        );
      }
    case "manage_products":
    case "manage_category":
    case "manage_location":
      return (
        <DashboardItemCardThumbnail to={`${props.item.link}`}>
          {props.item.icon}
          <DashboardItemTextStyled>{props.item.text}</DashboardItemTextStyled>
        </DashboardItemCardThumbnail>
      );
    default:
      return (
        <DashboardItemCardThumbnail to={props.item.link}>
          {props.item.icon}
          <DashboardItemTextStyled>{props.item.text}</DashboardItemTextStyled>
        </DashboardItemCardThumbnail>
      );
  }
};
