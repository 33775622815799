import React, { Component } from "react";
import { withRouter } from "react-router-dom";

// context
import SellerPrmProvider from "../SellerPrmProvider";
import TemplateSwitcher from "./components/TemplateSwitcher/TemplateSwitcher";
import { LanguageProvider } from "./context/Language";
import SellerLocationProvider from "./context/SellerLocationProvider";
import SellerCategoryProvider from "./context/SellerCategoryProvider";
import SellerProductsProvider from "./context/SellerProductsProvider";
import HomeProductProvider from "./context/HomeProductProvider";
import CartProvider from "./context/CartProvider";
import SellerPrmContext from "../SellerPrmContext";

class App extends Component {
  componentDidMount() {
    const link = window.location.hostname;

    // const link = "tokosekolah.1toko.com";//template 1
    // const link = "abc.1toko.com"; //template 2

    //WITH TEMPLATE
    // const link = "coolmusic.1toko.com"; //template 5

    // const link = "foodstore.1toko.com"; //template 1
    // const link = "makeupstore.1toko.com"; //template 2
    // const link = "fashion-store.1toko.com"; //template 3
    // const link = "drug-store.1toko.com"; //template 4
    // const link = "gudang-kopi.1toko.com"; //template 5
    // const link = "freshnfrozen.1toko.com"; //template 6
    // const link = "nagaagung.1toko.com";
    // const link = "multipro.1toko.com";
    // const link ="family.1toko.com";
    // const link = "nttonline.1toko.com";
    // const link = "agronic.1toko.com";

    this.context.getHostName(link);
  }

  render() {
    return (
      // <Provider store={store}>
      <CartProvider>
        <HomeProductProvider>
          <SellerProductsProvider>
            <SellerCategoryProvider>
              <SellerLocationProvider>
                <LanguageProvider>
                  <TemplateSwitcher />
                </LanguageProvider>
              </SellerLocationProvider>
            </SellerCategoryProvider>
          </SellerProductsProvider>
        </HomeProductProvider>
      </CartProvider>
      // </Provider>
    );
  }
}

App.contextType = SellerPrmContext;

export default withRouter(App);
