import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
} from "mdbreact";
import React, { Component } from "react";
import TrackingDetailComponent from "./TrackingDetailComponent";

export default class TrackingViewModal extends Component {
  render() {
    const { active, no_modal, tracking, popupModal } = this.props;
    // console.log(this.props);
    return (
      <MDBContainer>
        <MDBModal
          isOpen={active}
          toggle={() => popupModal("CLOSE", no_modal)}
          size="md"
          centered
        >
          <MDBModalHeader toggle={() => popupModal("CLOSE", no_modal)}>
            Status Pengiriman
          </MDBModalHeader>
          <MDBModalBody>
            <div className="overflow-auto" style={{ maxHeight: "400px" }}>
              <MDBContainer>
                <MDBRow>
                  {tracking !== undefined ? (
                    <TrackingDetailComponent
                      courier={tracking.courier}
                      track={tracking.track}
                    />
                  ) : (
                    <MDBCol size="12" middle style={{ marginBottom: "1rem" }}>
                      <MDBCard>
                        <MDBCardBody className="z-depth-2">
                          <div style={{ display: "flex" }}>
                            <MDBIcon icon="comment-dots" />
                            <div
                              style={{ marginLeft: "5px", fontSize: "15px" }}
                            ></div>
                          </div>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBCol>
                  )}
                </MDBRow>
              </MDBContainer>
            </div>
          </MDBModalBody>
        </MDBModal>
      </MDBContainer>
    );
  }
}
