import {
  MDBBtn,
  MDBCard,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
} from "mdbreact";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import ProductCategoryView from "./ProductCategoryView";
import style from "./Product.module.css";
import parse from "html-react-parser";
import { AboutDiv, CMDBBtn } from "../StyledComponent";
import "../../../assets/css/fonts.css";

class ProductHomeView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      product_page: 1,
    };
    this.sellerId = this.props.sellerprm.subDomainData.id;
  }

  componentDidMount() {
    const sellerId = this.props.sellerprm.subDomainData.id;
    const web_link = this.props.sellerprm.link;
    let page =
      this.props.match.params.page === undefined
        ? 0
        : this.props.match.params.page;

    this.props.producthome.getProductAllCategory(sellerId);
    this.props.producthome.setAboutProduct(web_link);
    this.props.producthome.getProductCategory(this.sellerId, page);
    this.setState({ selectedTab: page });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      let page =
        this.props.match.params.page === undefined
          ? 0
          : this.props.match.params.page;
      if (page !== 0) {
        this.props.producthome.getProductCategory(this.sellerId, page);
      }
      this.setState({ selectedTab: page, product_page: 1 });
    }
  }

  loadMoreProduct = () => {
    console.log(this.state.product_page);
    this.setState({ product_page: this.state.product_page + 1 }, () => {
      this.props.producthome.getProductCategory(
        this.sellerId,
        this.state.selectedTab,
        this.state.product_page
      );
    });
  };

  sideScroll = (element, direction, speed, distance, step) => {
    let scrollAmount = 0;
    var slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft =
          element.scrollLeft - step < 50 ? 0 : element.scrollLeft - step;
      } else {
        element.scrollLeft =
          element.scrollLeft + step - distance < 0
            ? distance
            : element.scrollLeft + step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  scrollLeft = () => {
    var container = document.getElementById("container");
    this.sideScroll(container, "left", 50, 250, 50);
  };

  scrollRight = () => {
    var container = document.getElementById("container");
    this.sideScroll(container, "right", 50, 250, 50);
  };

  render() {
    const p = this.props.producthome;
    const { selectedTab } = this.state;
    const active = {
      height: 50,
      minWidth: 240,
      textAlign: "center",
      paddingTop: "5px",
      border: `1.5px solid ${this.props.btn_color}`,
      color: "white",
      backgroundColor: this.props.btn_color,
    };

    const non_active = {
      height: 50,
      minWidth: 240,
      textAlign: "center",
      paddingTop: "5px",
      border: `1.5px solid ${this.props.btn_color}`,
      color: this.props.btn_color,
      backgroundColor: "white",
    };

    let selectedCategory = "OUR PRODUCT";
    if (p.category !== undefined && selectedTab !== 0) {
      let temp = p.category.find((data) => {
        return data.id === selectedTab;
      });
      selectedCategory = temp.name;
    } else {
      selectedCategory = "OUR PRODUCT";
    }

    return (
      <div>
        {p.category === undefined ? (
          ""
        ) : (
          //about
          <>
            {p.about !== undefined ? (
              <div
                style={{
                  backgroundColor: this.props.product_about_color,
                  background: this.props.background_transparent,
                }}
              >
                <br />
                <MDBContainer>
                  {/* about */}
                  <MDBRow id="about" className="w-100" center>
                    <p
                      style={{
                        fontSize: 30,
                        fontWeight: "bold",
                        fontFamily: "Lato Bold",
                      }}
                    >
                      {p.about_title}
                    </p>
                  </MDBRow>
                  <MDBRow>
                    {p.about_img === "" || p.about_img === undefined ? (
                      ""
                    ) : (
                      <MDBCol lg="6" md="12" sm="12" middle>
                        <MDBRow center>
                          {p.about_img ? (
                            <img
                              className="img-fluid img-thumbnail z-depth-1"
                              src={p.about_img}
                              style={{ maxHeight: "400px" }}
                            />
                          ) : (
                            ""
                          )}
                        </MDBRow>
                      </MDBCol>
                    )}
                    <MDBCol
                      lg={
                        p.about_img === "" || p.about_img === undefined
                          ? "12"
                          : "6"
                      }
                      md="12"
                      sm="12"
                      middle
                    >
                      <AboutDiv
                        className="overflow-auto"
                        hidden={this.state.show}
                        style={{
                          width: "100%",
                          maxHeight: "400px",
                          marginTop: "1rem",
                          fontFamily: "Lato Regular",
                        }}
                      >
                        {parse(p.about)}
                      </AboutDiv>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
                <br />
              </div>
            ) : (
              ""
            )}
            <MDBContainer style={{ padding: "0" }}>
              <div
                style={{
                  backgroundColor: "#FFFFFF", //this.props.tab_color,
                  background: this.props.background_transparent,
                  fontFamily: "Lato Regular",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    maxHeight: "100px",
                    position: "relative",
                  }}
                >
                  <button
                    style={{
                      border: `2px solid ${this.props.btn_color}`,
                      borderRadius: "50%",
                      backgroundColor: this.props.btn_color,
                    }}
                    onClick={() => this.scrollLeft()}
                  >
                    <MDBIcon
                      icon="angle-double-left"
                      style={{ paddingTop: "5px", color: "white" }}
                    />
                  </button>
                  <div className={style.rowProduct} id="container">
                    <Link to="/">
                      <CMDBBtn
                        active={selectedTab === 0}
                        bg_color={this.props.btn_color}
                        size="sm"
                        style={{
                          borderRadius: "0.5rem",
                          marginLeft: "3px",
                          marginRight: "3px",
                        }}
                      >
                        Semua
                      </CMDBBtn>
                    </Link>
                    {p.category.map((data, index) => {
                      if (data.product.length > 0) {
                        return (
                          <Link
                            to={`/category/${data.id}`}
                            key={data.id + index}
                          >
                            <CMDBBtn
                              active={selectedTab === data.id}
                              bg_color={this.props.btn_color}
                              size="sm"
                              style={{
                                borderRadius: "0.5rem",
                                marginLeft: "3px",
                                marginRight: "3px",
                              }}
                            >
                              {data.name}
                            </CMDBBtn>
                          </Link>
                        );
                      }
                    })}
                  </div>
                  <button
                    style={{
                      border: `2px solid ${this.props.btn_color}`,
                      borderRadius: "50%",
                      backgroundColor: this.props.btn_color,
                    }}
                    onClick={() => this.scrollRight()}
                  >
                    <MDBIcon
                      icon="angle-double-right"
                      style={{ paddingTop: "5px", color: "white" }}
                    />
                  </button>
                </div>
              </div>
            </MDBContainer>
            <div
              id="product"
              style={{
                backgroundColor: "#FFFFFF", //this.props.product_color,
                background: this.props.background_transparent,
              }}
            >
              <br />
              <MDBContainer>
                <p
                  style={{
                    color: "black",
                    fontSize: 18,
                    fontWeight: "bold",
                    fontFamily: "Lato Semi Bold",
                    margin: 0,
                  }}
                >
                  {selectedCategory}
                </p>
                <hr />
                <ProductCategoryView
                  {...this.props}
                  sellerId={this.sellerId}
                  selectedTab={this.state.selectedTab}
                  loadMoreProduct={this.loadMoreProduct}
                />
                <br />
              </MDBContainer>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default withRouter(ProductHomeView);
