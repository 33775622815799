import React, { Component } from "react";
import { UserLoactionContext } from "../context/UserLocationProvider";
import UserLocationMain from "../components/UserLocation/UserLocationMain";

export default class UserLocation extends Component {
  render() {
    return (
      <UserLoactionContext.Consumer>
        {(user_location_prm) => (
          <UserLocationMain
            seller_style={this.props.seller_style}
            user_location_prm={user_location_prm}  />
        )}
      </UserLoactionContext.Consumer>
    );
  }
}
