import React, { Component } from "react";
import SellerLocationView from "./SellerLocationView";
import SellerLocationFormModal from "./SellerLocationFormModal";

export default class SellerLocationMain extends Component {
  componentDidMount() {
    if (this.props.sellerPrm.subDomainData) {
      this.props.sellerLocation.listSellerLocation(
        this.props.sellerPrm.subDomainData.id
      );
    }
  }

  render() {
    const { seller_style } = this.props.sellerPrm.dataSeller;
    return (
      <div>
        <SellerLocationFormModal
          sellerLocationId={this.props.sellerLocation.updateSellerLocationId}
          name={this.props.sellerLocation.name}
          hp={this.props.sellerLocation.hp}
          alias={this.props.sellerLocation.alias}
          zipCode={this.props.sellerLocation.zipCode}
          zipId={this.props.sellerLocation.zipId}
          address={this.props.sellerLocation.address}
          selectedAddressDefault={
            this.props.sellerLocation.selectedAddressDefault
          }
          sellerId={this.props.sellerPrm.subDomainData.id}
          handleChangeSelect={this.props.sellerLocation.handleChangeSelect}
          loadOptions={this.props.sellerLocation.loadOptions}
          changeHandler={this.props.sellerLocation.changeHandler}
          isCheckGudang={this.props.sellerLocation.isCheckGudang}
          modalState={this.props.sellerLocation.modalState}
          toggleHandler={this.props.sellerLocation.modalHandler}
          actionType={this.props.sellerLocation.actionType}
          checkGudangHandler={this.props.sellerLocation.checkGudangHandler}
          storeSellerLocation={this.props.sellerLocation.storeSellerLocation}
          updateSellerLocation={this.props.sellerLocation.updateSellerLocation}
        />
        <SellerLocationView
          seller_style={seller_style}
          data={this.props.sellerLocation.dataTable}
          addModal={this.props.sellerLocation.modalHandler}
        />
      </div>
    );
    // } else {
    //   return <div>Sedang loading data alamat</div>;
    // }
  }
}
