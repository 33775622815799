import React, { useEffect, useState } from "react";
import API from "../../utils/API";
import Cookies from "js-cookie";
import UserPaymentStatusView from "../components/UserPaymentStatusView";

const UserPaymentStatus = () => {
  const [paymentStatus, setPaymentStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // getUserPaymentStatus
  useEffect(() => {
    getPaymentStatus();
  }, []);

  const token = Cookies.get("access_token");
  const createConfig = (token) => {
    let config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
    return config;
  };

  const getPaymentStatus = async () => {
    setIsLoading(true);
    try {
      const response = await API.get(
        "payment/check_pending",
        createConfig(token)
      );
      setIsLoading(false);
      setPaymentStatus(response.data.data);
    } catch (error) {
      setIsLoading(false);
      console.log(error); // catches both errors
    }
  };

  return (
    <UserPaymentStatusView
      paymentStatus={paymentStatus}
      isLoading={isLoading}
    />
  );
};

export default UserPaymentStatus;
