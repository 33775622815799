import React from "react";
import ReactDOM from "react-dom";
import './index.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import { BrowserRouter as Router } from "react-router-dom";
import AppWeb from "./web/App";
import AppMobile from "./mobile/App";

import SellerPrmProvider from "./SellerPrmProvider";

import registerServiceWorker from "./registerServiceWorker";
import { isMobile } from "react-device-detect";

ReactDOM.render(
  <Router>
    <SellerPrmProvider>
      {isMobile ? <AppMobile /> : <AppWeb />}
    </SellerPrmProvider>
  </Router>,
  document.getElementById("root")
);

registerServiceWorker();
