import React, { Component } from "react";
import LoginView from "../../components/LoginView/LoginView";
import Swal from "sweetalert2";
import API from "../../../utils/API";
import Cookies from "js-cookie";
import moment from "moment";
import SellerPrmContext from "../../../SellerPrmContext";

export default class Login extends Component {
  static contextType = SellerPrmContext;
  constructor(props) {
    super(props);
    this.state = { email: "", password: "", isRememberLogin: false };
    this.config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };
  }

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  checkRememberMeHandler = () => {
    this.setState({ isRememberLogin: !this.state.isRememberLogin });
  };

  submitHandler = (event) => {
    const { email, password, isRememberLogin } = this.state;

    //Swal loading
    // Swal.fire({
    //   title: "Login...",
    //   allowOutsideClick: false,
    //   onBeforeOpen: () => {
    //     Swal.showLoading();
    //   },
    // });

    event.preventDefault();
    event.target.className += " was-validated";

    let remember_me;
    if (isRememberLogin) {
      remember_me = 1;
    } else {
      remember_me = 0;
    }

    const data = new FormData();
    data.append("email", email);
    data.append("password", password);
    data.append("remember_me", remember_me);

    if (email !== "" && password !== "") {
      API.post("/auth/login", data, this.config)
        .then((res) => {
          if (res.data.code !== 200) {
            console.log(res);
          } else {
            this.context.checkUserDomain();
            const expired = moment(res.data.data.expires_at).toDate();
            Cookies.set("access_token", res.data.data.access_token, {
              expires: expired,
              domain: "1toko.com",
            });
            Cookies.set("logged", res.data.data.seller_id, {
              expires: expired,
              domain: "1toko.com",
            });
            //
            // if (this.context.isLoginState) {
            // if (this.context.isSeller) {
            //   if (this.state.loginData.total_seller_location_count !== 0) {
            //     // BELUM BISA REDIRECT KE ADMIN SELLER LOCATION KETIKA LOCATION COUNT 0
            this.props.history.push("/");
            //   } else {
            //     this.props.history.push("/admin/sellerlocation");
            //   }
            // } else {
            //   this.setState(
            //     { isSeller: false, isLoading: false, isLoginState: true },
            //     () => {
            //       this.props.history.push("/");
            //     }
            //   );
            // }
          }
        })
        .catch((error) => {
          Swal.disableLoading();
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            if (error.response) {
              let errmessage = "";
              let keys = Object.keys(error.response.data.errors);
              for (let i = 0; i < keys.length; i++) {
                errmessage +=
                  keys[i] +
                  " " +
                  error.response.data.errors[keys[i]][0] +
                  "<br/>";
              }
              Swal.fire({
                icon: "error",
                html: errmessage,
              });
            }
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Sorry...",
        text: "Silahkan masukkan email dan password anda",
      });
    }
  };

  forgotPassword = async () => {
    const { value: email } = await Swal.fire({
      title: "Input Email Address",
      input: "email",
      inputPlaceholder: "Enter your email address",
      confirmButtonText: "Submit",
      showCancelButton: true,
    });

    if (email) {
      Swal.fire({
        title: "Sending request...",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      const data = new FormData();
      data.append("email", email);
      data.append("url", `${window.location.hostname}/change_password_token/`);
      API.post("password/request_reset", data, this.config)
        .then((res) => {
          Swal.disableLoading();
          Swal.fire("Request has been sent");
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            if (error.response) {
              Swal.fire({
                icon: "error",
                html: email + " is not registered",
              });
            }
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    }
  };

  render() {
    return (
      <>
        <LoginView
          changeHandler={this.changeHandler}
          submitHandler={this.submitHandler}
          checkRememberMeHandler={this.checkRememberMeHandler}
          forgotPassword={this.forgotPassword}
        />
      </>
    );
  }
}
