import React, { Component } from "react";
import API from "../../utils/API";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import OrderConfirmationView from "../components/SellerDashboard/OrderConfirmation/OrderConfirmationView";
import SellerLocationContext from "../context/SellerLocationContext";

const date = new Date();
export default class OrderConfirmation extends Component {
  state = {
    invoice_list: [],
    courier_list: undefined,
    vehicle_list: undefined,
    tracking: undefined,
    seller_location_list: undefined,
    selected_vehicle: undefined,
    selected_location: undefined,
    pickup_date:
      date.getFullYear() +
      "-" +
      parseInt(date.getMonth() + 1) +
      "-" +
      date.getDate(),
    pickup_timefrom: date.getHours() + ":" + date.getMinutes(),
    pickup_timeto: date.getHours() + 1 + ":" + date.getMinutes(),
    pickup_point: "",
    pickup_tlp: "",
    pickup_contact: "",
    note: "",
    isReady: false,
    modal1: false,
    modal2: false,
  };

  createConfig = (token) => {
    let config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
    return config;
  };

  getAllStoreInvoice = (status, storeId, courier = "") => {
    const data = new FormData();
    data.append("status", JSON.stringify(status));
    data.append("courier", courier);
    data.append("storeId", storeId);
    API.post(
      `payment/all_store_invoice`,
      data,
      this.createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        // console.log(res)
        this.setState({ invoice_list: res.data.invoice_list });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCourierList = () => {
    API.get(`prm/courier`)
      .then((res) => {
        let temp_list = res.data.data.courier.map((data) => ({
          id: data.id,
          value: data.name,
        }));
        this.setState({ courier_list: temp_list });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getNCSVehickeList = () => {
    API.get(`prm/ncs/vehicle`)
      .then((res) => {
        let temp_list = res.data.data.vehicle.map((data) => ({
          id: data.vehicle_code,
          value: data.vehicle_name,
        }));
        this.setState({ vehicle_list: temp_list });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getSellerLocationList = (sellerId) => {
    API.get(
      `seller_location/${sellerId}`,
      this.createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        // console.log(res.data.data.seller_location);
        this.setState(
          { seller_location_list: res.data.data.seller_location },
          () => {
            this.handelSellerLocation(0);
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getTrackingStatus = (courier, tracking_code) => {
    //Swal loading
    Swal.showLoading();

    API.get(
      `/courier/tracking_status/${courier}/${tracking_code}`,
      this.createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        if (courier === "NCS") {
          // console.log(res)
          this.setState(
            { tracking: res.data.track !== null ? res.data : undefined },
            () => {
              Swal.close();
              if (this.state.tracking !== undefined) {
                this.popupModal("SHOW", "2");
              }else{
                Swal.fire({
                  icon:'info',
                  title:'Tracking',
                  text:'Barang belum diambil pihak NCS'
                });
              }
            }
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateInvoiceStatus = (invoice_id, status) => {
    console.log(typeof status);
    const data = new FormData();
    data.append("invoice_id", invoice_id);
    data.append("status", status);
    API.put(
      `payment/up_invoice_status/${invoice_id}/${status}`,
      null,
      this.createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        Swal.fire({
          title: "Berhasil",
          icon: "success",
          showConfirmButton: true,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handelPickup = (index) => {
    let pickup = [...this.state.invoice_list];
    pickup[index].flag = !pickup[index].flag;
    let ready = pickup.find((data) => data.flag === true);
    this.setState({ invoice_list: pickup, isReady: ready });
  };

  handelTimePickerValue = (value, type) => {
    const time = `pickup_${type}`;
    this.setState({ [time]: value });
  };

  handelDatePickerValue = (date) => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    this.setState({ pickup_date: year + "-" + month + "-" + day });
  };

  handelPickupVehicle = (event) => {
    this.setState({ selected_vehicle: event[0] });
  };

  handelChange = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  handelSellerLocation = (index) => {
    this.setState({
      selected_location: this.state.seller_location_list[index],
    });
  };

  pickupValidation = (courier) => {
    let flag = true;
    const { selected_vehicle } = this.state;
    if (courier === "NCS") {
      if (selected_vehicle === undefined) flag = false;
    }
    return flag;
  };

  handelPickupSubmit = (store, courier) => {
    const invoice_list = this.state.invoice_list.filter(
      (data) => data.flag === true
    );

    const pickup_data = {
      note: this.state.note,
      date: this.state.pickup_date,
      from_time: this.state.pickup_timefrom,
      to_time: this.state.pickup_timeto,
      vehicle: this.state.selected_vehicle,
      pickup_point: this.state.pickup_point,
      pickup_contact: this.state.pickup_contact,
      pickup_tlp: this.state.pickup_tlp,
    };

    const params = {
      store: store,
      courier: courier,
      pickup_address: this.state.selected_location,
      pickup_data: pickup_data,
      invoice_list: invoice_list,
    };
    // console.log(params);
    if (this.pickupValidation(courier)) {
      API.post(
        "courier/pickup_request",
        params,
        this.createConfig(Cookies.get("access_token"))
      ).then((res) => {
        // console.log(res);
        Swal.fire({
          title: "Berhasil",
          icon: "success",
          showConfirmButton: true,
        }).then(() => {
          window.location.reload();
        });
      });
    }
  };

  popupModal = (act, no) => {
    let modal = "modal" + no;
    if (act === "CLOSE") {
      this.setState({ [modal]: !this.state[modal] });
    } else if (act === "SHOW") {
      this.setState({ [modal]: !this.state[modal] });
    }
  };

  render() {
    return (
      <SellerLocationContext.Consumer>
        {(s_loc) => (
          <OrderConfirmationView
            {...this.state}
            {...this.props}
            s_loc={s_loc}
            popupModal={this.popupModal}
            handelPickup={this.handelPickup}
            handelChange={this.handelChange}
            handelPickupVehicle={this.handelPickupVehicle}
            handelPickupSubmit={this.handelPickupSubmit}
            handelSellerLocation={this.handelSellerLocation}
            handelTimePickerValue={this.handelTimePickerValue}
            handelDatePickerValue={this.handelDatePickerValue}
            getCourierList={this.getCourierList}
            getNCSVehickeList={this.getNCSVehickeList}
            getAllStoreInvoice={this.getAllStoreInvoice}
            getSellerLocationList={this.getSellerLocationList}
            getTrackingStatus={this.getTrackingStatus}
            updateInvoiceStatus={this.updateInvoiceStatus}
          />
        )}
      </SellerLocationContext.Consumer>
    );
  }
}
