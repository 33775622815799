import { MDBCard, MDBCardBody, MDBCol, MDBContainer } from "mdbreact";
import styled, { css } from "styled-components";

export const ProductCard_OuterContainer = styled(MDBCol)`
  padding: 3vw;
`;

export const ProductCard_InnerContainer = styled(MDBCard)`
  background: ${(props) => props.theme.color.primary};
  border-radius: 5%;
`;

export const ProductCard_CardBody = styled(MDBCardBody)`
  padding: 0;
`;

export const ProductCard_NameTag = styled.div`
  /* color: ${(props) =>
    props.theme.color.type === "dark"
      ? (props) => props.theme.color.primary
      : (props) => props.theme.color.secondary}; */
  /* color: ${(props) => props.theme.color.secondary}; */
  color: white;
  font-weight: 400;
  letter-spacing: 0;
  margin: 0 auto;
  width: 90%;
  /* white-space: initial; */
  overflow: hidden;
  white-space: nowrap;
  /* word-wrap: break-word; */
  text-overflow: ellipsis;
  font-size: 14px;
  font-family: "Lato Bold", sans-serif;
  /* line-clamp: 2;
  -webkit-line-clamp: 2; */
`;

export const ProductCard_WeightTag = styled.div`
  /* color: ${(props) =>
    props.theme.color.type === "dark"
      ? (props) => props.theme.color.primary
      : (props) => props.theme.color.secondary}; */
  /* color: ${(props) => props.theme.color.secondary}; */
  color: white;
  font-weight: 400;
  letter-spacing: 0;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  margin: 0 auto;
  font-family: "Lato Medium", sans-serif;
`;

export const ProductCard_PriceTag = styled.div`
  /* color: ${(props) =>
    props.theme.color.type === "dark"
      ? (props) => props.theme.color.primary
      : (props) => props.theme.color.secondary}; */
  /* color: ${(props) => props.theme.color.secondary}; */
  color: white;
  font-size: 14px;
  padding: 0;
  font-weight: 600;
  letter-spacing: 0;
  box-sizing: border-box;
  white-space: normal;
  overflow-wrap: hidden;
  margin: 0 auto;
  width: 90%;
  font-family: "Lato SemiBold", sans-serif;
`;

export const ProductCard_ImageBorder = styled.div`
  /* border: 3px ${(props) => props.theme.color.primary} solid; */
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background: white;
  border-radius: 6%;
`;

export const ProductCard_TitleToCategory = styled.div`
  font-weight: 500;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  overflow-wrap: break-word;
  white-space: nowrap;
`;

export const ProductCard_TitleToCategoryContainer = styled(MDBCard).attrs({
  cascade: true,
  narrow: true,
  ecommerce: true,
})`
  text-align: center;
  background-color: ${(props) => props.theme.color.primary};
  ${(props) =>
    props.theme.template.id === "5" &&
    css`
      color: ${(props) => props.theme.font.light};
    `}
`;
