import { Link, NavLink } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";

export const TabSectionContainer = styled.div`
  background-color: ${(props) => props.theme.color.secondary};

  ${(props) =>
    props.theme.template.id === "5" &&
    css`
      background-color: #ffffff;
    `}
`;

export const ProductSectionContainer = styled.div`
  background-color: ${(props) => props.theme.color.secondary};
  padding-bottom: 10vh;

  ${(props) =>
    props.theme.template.id === "5" &&
    css`
      background-color: #ffffff;
    `}
`;

export const ProductSectionTitle = styled.div`
  font-family: ${(props) =>
    props.theme.font.primary ? props.theme.font.primary : "Lato, serif"};
  font-size: 20px;
  padding-left: 20px;
  padding-top: 20px;
  color: ${(props) =>
    props.theme.color.type === "light"
      ? props.theme.font.light
      : props.theme.font.dark};

  ${(props) =>
    props.theme.template.id === "5" &&
    css`
      color: #000000;
    `}
`;
