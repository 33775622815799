import React from "react";
import {
  MDBBadge,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBDatePicker,
  MDBIcon,
  MDBInput,
  MDBRow,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions,
  MDBTimePicker,
} from "mdbreact";
import * as S from "../_Styled/CardPayment.styles.js";
import ImageComponent from "../_UtilityComponent/Image/ImageComponent.js";
import { NCSPickupForm } from "./DashboardSellerNCSPickupForm.styles.js";
import DashboardSellerOrderPickupLocationModal from "./DashboardSellerOrderPickupLocationModal.js";
import DashboardSellerOrderProcessTrackingModal from "./DashboardSellerOrderProcessTrackingModal.js";

const status = {
  1: "Meminta Konfirmasi Pesanan",
  2: "Meminta Pesanan Diambil",
  3: "Pesanan dalam Pengiriman",
  4: "Menunggu Konfirmasi Selesai",
  5: "Pesanan Selesai",
  98: "Meminta Pembatalan Pesanan",
  99: "Pesanan Dibatalkan",
};

const date = new Date();

const formatDate = (string) => {
  let options = { day: "numeric", month: "long", year: "numeric" };
  return new Date(Date.parse(string)).toLocaleDateString("ID", options);
};

const formatCurrency = (country, currency, text) => {
  return new Intl.NumberFormat(country, {
    style: "currency",
    currency: currency,
  }).format(text);
};

export const DashboardSellerOrderView = (props) => {
  return (
    <S.ContainerPayment>
      {props.selectedLocation !== undefined ? (
        <DashboardSellerOrderPickupLocationModal {...props} />
      ) : (
        ""
      )}
      {props.trackingStatus !== undefined ? (
        <DashboardSellerOrderProcessTrackingModal {...props} />
      ) : (
        ""
      )}
      <MDBRow>
        <MDBCol className="text-center mt-3">
          <h4>{props.titleProcess}</h4>
        </MDBCol>
      </MDBRow>
      {props.currentProcess === "2" ? (
        <MDBSelect
          className="w-100"
          options={props.courierList}
          selected="Pilih Kurir"
          label="Pilih Kurir"
          selectAllValue={props.selectedCourier}
          getValue={(e) => props.courierSelectHandler(e)}
        />
      ) : (
        ""
      )}
      {props.currentProcess === "2" &&
      props.selectedCourier &&
      props.invoiceList.length > 0 ? (
        <>
          <OrderList
            invoiceList={props.invoiceList}
            flagChecker={props.flagChecker}
            showItemHandler={props.showItemHandler}
          />
        </>
      ) : (
        ""
      )}
      {props.currentProcess !== "2" ? (
        <>
          <OrderList
            invoiceList={props.invoiceList}
            showItemHandler={props.showItemHandler}
            trackingModalHandler={props.trackingModalHandler}
          />
        </>
      ) : (
        ""
      )}
      {props.currentProcess === "2" ? (
        <DashboardPickupForm
          storeId={props.storeId}
          storeName={props.storeName}
          invoiceList={props.invoiceList}
          courierList={props.courierList}
          selectedCourier={props.selectedCourier}
          courierSelectHandler={props.courierSelectHandler}
          vehicleList={props.vehicleList}
          locationModal={props.locationModal}
          setLocationModal={props.setLocationModal}
          selectedLocation={props.selectedLocation}
          pickupContact={props.pickupContact}
          pickupTelp={props.pickupTelp}
          pickupPoint={props.pickupPoint}
          pickupNote={props.pickupNote}
          datePickerValueHandler={props.datePickerValueHandler}
          setPickupTimeFrom={props.setPickupTimeFrom}
          setPickupTimeTo={props.setPickupTimeTo}
          pickupVehicleHandler={props.pickupVehicleHandler}
          setPickupPoint={props.setPickupPoint}
          setPickupContact={props.setPickupContact}
          setPickupTelp={props.setPickupTelp}
          setPickupNote={props.setPickupNote}
          formSubmitReady={props.formSubmitReady}
          formSubmitHandler={props.formSubmitHandler}
        />
      ) : (
        ""
      )}
    </S.ContainerPayment>
  );
};

const DashboardPickupForm = (props) => {
  return (
    <>
      <MDBCard
        style={{
          display:
            props.selectedCourier !== undefined && props.invoiceList.length > 0
              ? ""
              : "none",
        }}
        className="mt-3 mb-3"
      >
        <MDBCardBody>
          {props.selectedCourier === "NCS" ? (
            <NCSPickupForm>
              <div>Tentukan waktu pengambilan</div>
              <div
                style={{
                  position: "relative",
                  marginTop: "7px",
                }}
              >
                <MDBDatePicker
                  disablePast
                  format="DD MMMM YYYY"
                  getValue={(e) => props.datePickerValueHandler(e)}
                />
                <label
                  className="black-text"
                  style={{
                    fontSize: "11px",
                    position: "absolute",
                    top: "-15px",
                    left: "0px",
                  }}
                >
                  Date
                </label>
              </div>
              <MDBTimePicker
                id="timePicker1"
                label="From"
                hours={date.getHours()}
                minutes={date.getMinutes()}
                hoursFormat={24}
                getValue={(e) => props.setPickupTimeFrom(e)}
              />
              <MDBTimePicker
                id="timePicker2"
                label="To"
                hours={date.getHours() + 1}
                minutes={date.getMinutes()}
                hoursFormat={24}
                getValue={(e) => props.setPickupTimeTo(e)}
              />
              {props.vehicleList.length > 0 ? (
                <MDBSelect
                  label="Pickup Vehicle"
                  getValue={(e) => props.pickupVehicleHandler(e)}
                >
                  <MDBSelectInput selected="Choose vehicle" />
                  <MDBSelectOptions>
                    <MDBSelectOption disabled>Choose vehicle</MDBSelectOption>
                    {props.vehicleList.map((data, index) => {
                      return (
                        <MDBSelectOption key={index} value={data.id}>
                          {data.value}
                        </MDBSelectOption>
                      );
                    })}
                  </MDBSelectOptions>
                </MDBSelect>
              ) : (
                ""
              )}
              <MDBInput
                label="Contact Person Name"
                name="pickupContact"
                value={props.pickupContact}
                onChange={(e) => {
                  props.setPickupContact(e.target.value);
                }}
              />
              <MDBInput
                label="Pickup Phone Number"
                name="pickupTelp"
                value={props.pickupTelp}
                onChange={(e) => {
                  props.setPickupTelp(e.target.value);
                }}
              />
              <MDBInput
                label="Pickup Location Name"
                name="pickupPoint"
                value={props.pickupPoint}
                onChange={(e) => {
                  props.setPickupPoint(e.target.value);
                }}
              />
              <MDBInput
                label="Note"
                name="pickupNote"
                value={props.pickupNote}
                onChange={(e) => {
                  props.setPickupNote(e.target.value);
                }}
              />
              <MDBContainer>
                <MDBCol size="12">
                  <MDBRow>Alamat Pickup :</MDBRow>
                  <MDBRow>
                    {`${props.selectedLocation.name} (${props.selectedLocation.alias}) `}
                    {props.selectedLocation.is_primary === "1" ? (
                      <span>
                        &nbsp;
                        <MDBBadge pill>Primary</MDBBadge>
                      </span>
                    ) : (
                      ""
                    )}
                  </MDBRow>
                  <MDBRow>
                    {props.selectedLocation !== undefined
                      ? props.selectedLocation.address
                      : ""}
                  </MDBRow>
                </MDBCol>
                <MDBCol style={{ textAlign: "center" }}>
                  <S.CardItemButtonDash
                    size="sm"
                    onClick={() => props.setLocationModal(!props.locationModal)}
                  >
                    CHANGE
                  </S.CardItemButtonDash>
                </MDBCol>
              </MDBContainer>
            </NCSPickupForm>
          ) : (
            ""
          )}
        </MDBCardBody>
        <S.CardItemButtonDash
          disabled={!props.formSubmitReady}
          onClick={() =>
            props.formSubmitHandler(
              {
                id: props.storeId,
                name: props.storeName,
              },
              props.selectedCourier
            )
          }
        >
          SUBMIT
        </S.CardItemButtonDash>
      </MDBCard>
    </>
  );
};

const OrderList = (props) => {
  return props.invoiceList.map((item, idx) => {
    return (
      <OrderItem
        item={item}
        key={idx}
        idx={idx}
        flagChecker={props.flagChecker}
        showItemHandler={props.showItemHandler}
        trackingModalHandler={props.trackingModalHandler}
      />
    );
  });
};

const OrderItem = (props) => {
  return (
    <div>
      <S.CardContainerPayment className="mt-3 mb-3">
        <S.CardHeaderPayment>
          {props.item.status === "2" ? (
            <>
              <S.CardHeaderWithFlag>
                <div style={{ flex: "1 1 auto" }}>
                  <S.CardInfoName>No. Invoice</S.CardInfoName>
                  <S.CardInfoContent>{props.item.id}</S.CardInfoContent>
                  {props.item.showItemState ? (
                    <div
                      onClick={() => props.showItemHandler("CLOSE", props.idx)}
                    >
                      Tutup Detail
                    </div>
                  ) : (
                    <div
                      onClick={() => props.showItemHandler("OPEN", props.idx)}
                    >
                      Tampilkan Detail
                    </div>
                  )}
                </div>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={`itemFlag${props.item.id}`}
                    onClick={() => props.flagChecker(props.idx)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`itemFlag${props.item.id}`}
                  ></label>
                </div>
              </S.CardHeaderWithFlag>
            </>
          ) : (
            <>
              <S.CardInfoName>No. Invoice</S.CardInfoName>
              <S.CardInfoContent>{props.item.id}</S.CardInfoContent>
              {props.item.showItemState ? (
                <div onClick={() => props.showItemHandler("CLOSE", props.idx)}>
                  Tutup Detail
                </div>
              ) : (
                <div onClick={() => props.showItemHandler("OPEN", props.idx)}>
                  Tampilkan Detail
                </div>
              )}
            </>
          )}
        </S.CardHeaderPayment>
        {props.item.showItemState ? (
          <>
            <S.CardBodyPayment>
              <S.CardInfoName>Status</S.CardInfoName>
              <S.CardInfoContent>{status[props.item.status]}</S.CardInfoContent>
              <S.CardInfoName>Pengiriman</S.CardInfoName>
              <S.CardInfoContent>{`${props.item.courier_name} (${props.item.courier_service})`}</S.CardInfoContent>
              <S.CardInfoName>Total Pembayaran</S.CardInfoName>
              <S.CardInfoContent>
                {formatCurrency("ID", "IDR", props.item.payment.payment_total)}
              </S.CardInfoContent>
            </S.CardBodyPayment>
            <div style={{ borderBottom: "1px solid rgba(0,0,0,.125)" }} />
            <S.CardBodyPayment>
              <S.CardInfoName>Nama Pelanggan</S.CardInfoName>
              <S.CardInfoContent>{props.item.customer_name}</S.CardInfoContent>
              <S.CardInfoName>Nomor Telepon</S.CardInfoName>
              <S.CardInfoContent>
                {props.item.customer_tlp ? props.item.customer_tlp : "-"}
              </S.CardInfoContent>
              <S.CardInfoName>Lokasi</S.CardInfoName>
              <S.CardInfoContent>{props.item.user_location}</S.CardInfoContent>
            </S.CardBodyPayment>
            {props.item.status === "1" ? (
              <S.CardItemDoneBtnDash
                onClick={() => props.updateInvoiceStatus(props.item.id, 2)}
              >
                <MDBIcon icon="thumbs-up" style={{ marginRight: "10px" }} />
                {` Konfirmasi`}
              </S.CardItemDoneBtnDash>
            ) : (
              ""
            )}
            {props.item.status === "1" || props.item.status === "2" ? (
              <S.CardItemReqCancelBtnDash
                onClick={() => props.updateInvoiceStatus(props.item.id, 99)}
              >
                <MDBIcon icon="trash-alt" style={{ marginRight: "10px" }} />
                {`Tolak Pesanan`}
              </S.CardItemReqCancelBtnDash>
            ) : (
              ""
            )}
            {props.item.status === "3" ? (
              <S.CardItemDoneBtnDash
                onClick={() =>
                  props.trackingModalHandler(
                    "OPEN",
                    props.item.courier_name,
                    props.item.tracking_code
                  )
                }
              >
                <MDBIcon icon="map" style={{ marginRight: "10px" }} />
                {` Lacak`}
              </S.CardItemDoneBtnDash>
            ) : (
              ""
            )}
            {props.item.status === "98" ? (
              <S.CardItemReqCancelBtnDash
                onClick={() => props.updateInvoiceStatus(props.item.id, 2)}
              >
                <MDBIcon icon="trash-alt" style={{ marginRight: "10px" }} />
                {` Konfirmasi Pembatalan`}
              </S.CardItemReqCancelBtnDash>
            ) : (
              ""
            )}
            {<OrderDetails detail={props.item.dtl_invoice} />}
            <S.CardFooterPayment></S.CardFooterPayment>{" "}
          </>
        ) : (
          ""
        )}
      </S.CardContainerPayment>
    </div>
  );
};

const OrderDetails = (props) => {
  return (
    <S.CardItemDtlContainer>
      <S.CardItemDtlRow>
        {props.detail.map((item, idx) => (
          <OrderDetailItem key={idx} item={item} />
        ))}
      </S.CardItemDtlRow>
    </S.CardItemDtlContainer>
  );
};

const OrderDetailItem = (props) => {
  let weight =
    parseInt(props.item.product_quantity) * parseInt(props.item.product_weight);
  let price =
    parseInt(props.item.product_quantity) * parseInt(props.item.product_price);
  let isCourier = props.item.product_id.toString().split("-")[0] === "COURIER";

  if (!isCourier) {
    return (
      <S.CardItemDtlWrapper>
        <S.CardImageContainer>
          <ImageComponent isSquare={true} img={props.item.product_image} />
        </S.CardImageContainer>
        <S.CardItemInfo>
          <div style={{ fontWeight: 600 }}>{props.item.product_name}</div>
          <div> {props.item.product_weight} gr</div>
          <div>{`${new Intl.NumberFormat("ID", {
            style: "currency",
            currency: "IDR",
          }).format(price)} (${props.item.product_quantity} item)`}</div>
        </S.CardItemInfo>
      </S.CardItemDtlWrapper>
    );
  } else {
    return <div></div>;
  }
};
