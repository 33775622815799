import React from "react";
import { MDBCol, MDBBtn } from "mdbreact";

export const RoundedBlackButton = (props) => {
  return (
    <MDBCol>
      <MDBBtn
        {...props}
        color="black"
        style={{ borderRadius: "10px" }}
      >
        {props.children}
      </MDBBtn>
    </MDBCol>
  );
};

export const RoundedRedButton = (props) => {
  return (
    <MDBCol>
      <MDBBtn
        {...props}
        color="red"
        style={{ borderRadius: "10px" }}
      >
        {props.children}
      </MDBBtn>
    </MDBCol>
  );
};

export const RoundedOrangeButton = (props) => {
  return (
    <MDBCol>
      <MDBBtn
        {...props}
        color="orange"
        style={{ borderRadius: "10px" }}
      >
        {props.children}
      </MDBBtn>
    </MDBCol>
  );
};

export const RoundedBlueButton = (props) => {
  return (
    <MDBCol>
      <MDBBtn
        {...props}
        color="blue"
        style={{ borderRadius: "10px" }}
      >
        {props.children}
      </MDBBtn>
    </MDBCol>
  );
};

export const RoundedDefaultButton = (props) => {
  return (
    <MDBCol>
      <MDBBtn
        {...props}
        style={{ borderRadius: "10px" }}
      >
        {props.children}
      </MDBBtn>
    </MDBCol>
  );
};

export const RoundedWhiteButton = (props) => {
  return (
    <MDBCol>
      <MDBBtn
        {...props}
        color="white"
        style={{ borderRadius: "10px" }}
      >
        {props.children}
      </MDBBtn>
    </MDBCol>
  );
};

export const RoundedPurpleButton = (props) => {
  return (
    <MDBCol>
      <MDBBtn
        {...props}
        color="purple"
        style={{ borderRadius: "10px" }}
      >
        {props.children}
      </MDBBtn>
    </MDBCol>
  );
};