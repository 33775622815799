import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import SectionContainer from "../sectionContainer";
import { CMDBBtn } from "../StyledComponent";
import TransactionPendingView from "./TransactionPendingView";
import TransactionInvoiceView from "./TransactionInvoiceView";
import TrackingViewModal from "../Tracking/TrackingViewModal";
import "../../../assets/css/fonts.css";

class TransactionMain extends Component {
  state = {
    selected_page: "1",
    selected_process: [0],
  };

  componentDidMount() {
    this.initPage();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.initPage();
    }
  }

  initPage = () => {
    const { match } = this.props;
    const process = match.params.page ? match.params.page : "[0]";
    this.handelChangeProcess(JSON.parse(process));
  };

  handelChangeProcess = (process) => {
    const { getPendingTransaction, getUserInvoice } = this.props.transprm;
    const sellerId = this.props.sellerprm.subDomainData.id;
    if (process[0] === 0) {
      getPendingTransaction();
    } else {
      getUserInvoice(process);
    }
    this.setState({ selected_process: process });
  };

  render() {
    const { selected_process } = this.state;
    const { seller_style } = this.props.sellerprm.subDomainData;
    const {
      modal1,
      tracking,
      trans_pending_list,
      trans_invoice_list,
      popupModal,
      getTrackingStatus,
      updateInvoiceStatus,
    } = this.props.transprm;
    // console.log(transaction_list)
    return (
      <>
        <TrackingViewModal
          active={modal1}
          no_modal={"1"}
          tracking={tracking}
          popupModal={popupModal}
        />
        {seller_style !== undefined ? (
          <MDBContainer style={{ paddingTop: "6rem", fontFamily:'Lato Semi Bold' }}>
            <SectionContainer header={"Transaction"}>
              <MDBRow center>
                <Link to={`/transaction/[0]`}>
                  <CMDBBtn
                    active={selected_process[0] === 0}
                    bg_color={seller_style.color_btn}
                    onClick={() => this.handelChangeProcess([0])}
                    style={{ borderRadius: "0.5rem" }}
                  >
                    Pending Order
                  </CMDBBtn>
                </Link>
                <Link to={`/transaction/[1,2,3,4]`}>
                  <CMDBBtn
                    active={selected_process[0] === 1}
                    bg_color={seller_style.color_btn}
                    onClick={() => this.handelChangeProcess([1, 2, 3, 4])}
                    style={{ borderRadius: "0.5rem" }}
                  >
                    Processing Order
                  </CMDBBtn>
                </Link>
                <Link to={`/transaction/[5]`}>
                  <CMDBBtn
                    active={selected_process[0] === 5}
                    bg_color={seller_style.color_btn}
                    onClick={() => this.handelChangeProcess([5])}
                    style={{ borderRadius: "0.5rem" }}
                  >
                    Complete Order
                  </CMDBBtn>
                </Link>
                <Link to={`/transaction/[99]`}>
                  <CMDBBtn
                    active={selected_process[0] === 99}
                    bg_color={seller_style.color_btn}
                    onClick={() => this.handelChangeProcess([99])}
                    style={{ borderRadius: "0.5rem" }}
                  >
                    Canceled Order
                  </CMDBBtn>
                </Link>
              </MDBRow>
              <hr />
              <MDBContainer style={{fontFamily:'Lato Regular'}}>
                <MDBRow>
                  {this.state.selected_process !== undefined
                    ? this.state.selected_process[0] === 0
                      ? trans_pending_list !== undefined
                        ? trans_pending_list.map((data, index) => {
                            return (
                              <MDBCol lg="6" md="12" sm="12" key={index}>
                                <TransactionPendingView
                                  data={data}
                                  seller_style={seller_style}
                                />
                              </MDBCol>
                            );
                          })
                        : ""
                      : trans_invoice_list !== undefined
                      ? trans_invoice_list.map((data, index) => {
                          return (
                            <MDBCol lg="6" md="12" sm="12" key={index}>
                              <TransactionInvoiceView
                                data={data}
                                tracking={tracking}
                                seller_style={seller_style}
                                popupModal={popupModal}
                                getTrackingStatus={getTrackingStatus}
                                updateInvoiceStatus={updateInvoiceStatus}
                              />
                            </MDBCol>
                          );
                        })
                      : ""
                    : ""}
                </MDBRow>
              </MDBContainer>
            </SectionContainer>
          </MDBContainer>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default withRouter(TransactionMain);
