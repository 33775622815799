import React, { Component } from "react";
import { MDBContainer, MDBCol, MDBBtn } from "mdbreact";
import { UserLocationList } from "./UserLocationList/UserLocationList";
import { CenterLoader } from "../_UtilityComponent/Loader/CenterLoader";
import { StyledButtonTheming } from "../_UtilityComponent/Button/StyledButton";

class UserLocationView extends Component {
  render() {
    return (
      <>
        <MDBContainer>
          <MDBCol className="text-center">
            <StyledButtonTheming
              className="w-100"
              onClick={() => this.props.popUpModal("ADD", 1)}
            >
              Tambah Alamat
            </StyledButtonTheming>
          </MDBCol>
        </MDBContainer>
        {!this.props.isLoading ? (
          <>
            <UserLocationList
              toggle={this.props.popUpModal}
              addressList={this.props.addressList}
            />
          </>
        ) : (
          <MDBContainer className="mt-5 mb-5">
            <CenterLoader />
          </MDBContainer>
        )}
      </>
    );
  }
}

export default UserLocationView;
