import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBDataTable,
  MDBCol,
  MDBRow,
} from "mdbreact";
import { CMDBBtn } from "../StyledComponent";
import "../../../assets/css/fonts.css";
import SectionContainer from "../sectionContainer";

const SellerLocationView = (props) => {
  // console.log(props);
  return (
    <MDBContainer
      style={{
        minWidth: "1000px",
        paddingTop: "6rem",
        fontFamily: "Lato Semi Bold",
      }}
    >
      <SectionContainer header={`Manage Warehouse Location`}>
        <MDBCard style={{ fontFamily: "Lato Regular" }}>
          <MDBRow>
            <MDBCol>
              <CMDBBtn
                active
                bg_color={props.seller_style.color_btn}
                onClick={() => props.addModal("ADD")}
              >
                Tambah Alamat
              </CMDBBtn>
            </MDBCol>
            <MDBCol />
          </MDBRow>
          <MDBCardBody>
            <MDBDataTable hover noBottomColumns responsive data={props.data} />
          </MDBCardBody>
        </MDBCard>
      </SectionContainer>
    </MDBContainer>
  );
};

export default SellerLocationView;
