import React, { Component } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBBtn,
  MDBLink,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBInput,
} from "mdbreact";
import CartDetailView from "./CartDetailView";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import UserLocationFormModal from "../UserLocation/UserLocationFormModal";
import SectionContainer from "../sectionContainer";
import { CMDBBtn } from "../StyledComponent";

class CartView extends Component {
  componentDidMount() {
    if (
      parseInt(Cookies.get("logged")) !==
      parseInt(this.props.sellerprm.subDomainData.id)
    ) {
      this.props.cartprm.checkLUserLocation();
      const sellerId = this.props.sellerprm.subDomainData.id;
      this.props.cartprm.getCartList(sellerId);
    } else {
      this.props.history.push("/");
    }
  }

  transProccess = async () => {
    if (this.props.cartprm.user_location_num === 0) {
      this.props.userlocationprm.popupModal("ADD", "1", true);
    } else {
      if (await this.props.cartprm.cekQuantity()) {
        this.props.history.push("/cart/shipment");
      }
    }
  };

  render() {
    const {
      total_price,
      total_item,
      cart_list,
      select_all,
      deleteCart,
      changeHandlerNote,
      changeQuantityHandler,
      quantityAction,
      changeHandlerChecked,
      changeHandlerCheckedAll,
      changeHandlerCheckedGroup,
    } = this.props.cartprm;
    const sellerId = this.props.sellerprm.subDomainData.id;
    const { seller_style } = this.props.sellerprm.subDomainData;
    return (
      <>
        <UserLocationFormModal
          {...this.props.userlocationprm}
          seller_style={seller_style}
        />
        <MDBContainer style={{ paddingTop: "50px", minWidth: "1100px" }}>
          {cart_list === undefined ? (
            <MDBRow style={{ paddingTop: "50px" }}>
              <MDBCol>
                <MDBRow center>Keranjang Belanjamu Kosong</MDBRow>
                <MDBRow center>
                  <MDBLink to="/">
                    <CMDBBtn active bg_color={seller_style.color_btn}>
                      Mulai Belanja
                    </CMDBBtn>
                  </MDBLink>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          ) : (
            <SectionContainer header="CART" style={{ minWidth: "1100px" }}>
              <MDBRow>
                <MDBCol size="8">
                  <div style={{ minWidth: "650px" }}>
                    <MDBCard>
                      <MDBCardBody>
                        <MDBRow
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingRight: "1rem",
                          }}
                        >
                          <MDBInput
                            id={"checkedall"}
                            label="Pilih Semua Barang"
                            type="checkbox"
                            checked={select_all}
                            onChange={() => changeHandlerCheckedAll(sellerId)}
                            filled
                          />
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </div>
                  <hr />
                  <div
                    className="overflow-auto"
                    style={{ minWidth: "650px", maxHeight: "470px" }}
                  >
                    {cart_list.map((cart, index) => {
                      // console.log(cart);
                      return (
                        <div key={cart.cart_id}>
                          <MDBCard>
                            <MDBCardBody>
                              <MDBRow>
                                <MDBInput
                                  id={"checked_group_" + cart.cart_id}
                                  label={cart.seller_location.zip.sub_district}
                                  type="checkbox"
                                  checked={cart.is_selected}
                                  onChange={() =>
                                    changeHandlerCheckedGroup(
                                      index,
                                      cart.seller_location.id,
                                      sellerId
                                    )
                                  }
                                  filled
                                />
                              </MDBRow>
                              <hr />
                              <CartDetailView
                                cartIndex={index}
                                seller_style={seller_style}
                                sellerId={sellerId}
                                cart_detail={cart.cart_detail}
                                deleteCart={deleteCart}
                                changeHandlerNote={changeHandlerNote}
                                changeQuantityHandler={changeQuantityHandler}
                                quantityAction={quantityAction}
                                changeHandlerChecked={changeHandlerChecked}
                              />
                            </MDBCardBody>
                          </MDBCard>
                          <br />
                        </div>
                      );
                    })}
                  </div>
                </MDBCol>
                <MDBCol size="4">
                  <MDBCard>
                    <MDBCardBody>
                      <MDBCardTitle>Ringkasan Belanja</MDBCardTitle>
                      <MDBCardText
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ textAlign: "left" }}>Total Harga</span>
                        <span style={{ textAlign: "right" }}>
                          {new Intl.NumberFormat("ID", {
                            style: "currency",
                            currency: "IDR",
                          }).format(total_price)}
                        </span>
                      </MDBCardText>
                      <MDBRow>
                        <MDBCol>
                          <CMDBBtn
                            active
                            bg_color={seller_style.color_btn}
                            className="w-100"
                            onClick={() => this.transProccess()}
                            disabled={parseInt(total_item) === 0 ? true : false}
                          >{`Beli (${total_item})`}</CMDBBtn>
                        </MDBCol>
                      </MDBRow>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </SectionContainer>
          )}
        </MDBContainer>
      </>
    );
  }
}

export default withRouter(CartView);
