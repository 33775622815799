import React from "react";
import { Redirect } from "react-router-dom";
import { PageContainer } from "../LandingView/Landing.styles";
import { DashboardItems } from "./DashboardItems";
import {
  DashboardItemContainerGrid,
} from "./DashboardSeller.styles";
import { DashboardSellerOrderComplete } from "./DashboardSellerOrderComplete";
import { DashboardSellerOrderConfirmation } from "./DashboardSellerOrderConfirmation";
import { DashboardSellerOrderPickup } from "./DashboardSellerOrderPickup";
import { DashboardSellerOrderProcess } from "./DashboardSellerOrderProcess";
import { DashboardSellerOrderRejected } from "./DashboardSellerOrderRejected";
import { DashboardSellerOrderReqCancel } from "./DashboardSellerOrderReqCancel";

export const DashboardSellerView = (props) => {
  if (props.page === "order_confirmation" && props.process) {
    switch (props.process) {
      case "1":
        return <DashboardSellerOrderConfirmation />;
      case "2":
        return <DashboardSellerOrderPickup />;
      case "3":
        return <DashboardSellerOrderProcess />;
      case "4":
        return <DashboardSellerOrderComplete />;
      case "5":
        return <DashboardSellerOrderReqCancel />;
      case "6":
        return <DashboardSellerOrderRejected />;
      default:
        return <Redirect push to="/seller_dashboard/order_confirmation" />;
    }
  } else {
    return (
      <PageContainer>
        <DashboardItemContainerGrid>
          <DashboardItems
            page={props.page}
            itemDashboard={props.itemDashboard}
          />
        </DashboardItemContainerGrid>
      </PageContainer>
    );
  }
};
