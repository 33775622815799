import React, { Component } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBListGroupItem,
  MDBListGroup,
} from "mdbreact";
import "./ModalOption.css";

class ModalOption extends Component {
  render() {
    return (
      <MDBContainer>
        <MDBModal
          isOpen={this.props.showModal}
          toggle={() => this.props.toggleHandler(this.props.actionTypeName)}
          className="modal-menu-option"
          position="bottom"
        >
          <MDBListGroup className="" style={{ width: "100%" }}>
            {this.props.primaryId !== this.props.itemId ? (
              this.props.firstButtonClick ? (
              <MDBListGroupItem
                style={{ color: "purple" }}
                onClick={(e) =>
                  this.props.firstButtonClick(
                    this.props.sellerId,
                    this.props.itemId
                  )
                }
              >
                {this.props.firstButtonTitle}
              </MDBListGroupItem>) : ""
            ) : (
              ""
            )}
            <MDBListGroupItem
              style={{ color: "purple" }}
              onClick={(e) =>
                this.props.secondButtonClick(
                  this.props.sellerId,
                  this.props.itemId
                )
              }
            >
              {this.props.secondButtonTitle}
            </MDBListGroupItem>
          </MDBListGroup>
        </MDBModal>
      </MDBContainer>
    );
  }
}

export default ModalOption;
