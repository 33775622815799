const en = {
  landing: {
    welcome: "Welcome to 1toko",
    loginBtn: "Login",
    registerBtn: "Register",
    landingRegisterBtn: "Make your own shop",
  },
  registerPage: {
    makeAccountTitle: "MAKE NEW ACCOUNT",
    toRegister: "Already have an account?",
    submitRegister: "Register",
    emailInput: "Email",
    nameInput: "Name",
    passInput: "Password",
    passInputHint: "Minimum of 6 characters, maximum of 20 characters, and at least have one capital character.",
    passConfirmInput: "Password Confirmation",
    phoneNumberInput: "Phone Number",
    isSellerRegister: "Register as seller",
    setupStoreTitle: "Store Setup",
    storeNameInput: "Store Name",
    subDomainInput: "Website Address 1toko.com",
    domainInput: "Website Address External (Optional)",
    domainInputHint: 'Put your valid domain URL for the store "ex : store.com"',
    storeTemplateInput: "Store Template",
    isCustomBannerCheck: "Use custom banner?",
    isCustomLogoCheck: "Use custom logo?",
    logoStoreInput: "Store Logo",
    logoStoreInputHint: "Maximum upload size for logo is 100KB.",
    bannerStoreInput: "Banner Toko",
    bannerStoreInputHint: "Maximum upload size for banner is 100KB.",
    checkingDomain: "Mengecek domain...",
    availableDomain: "Domain tersedia!",
    unavailableDomain:
      "Maaf, domain ini tidak tersedia, silahkan gunakan domain lain",
  },
  loginPage: {
    forgotPass: "Forgot Password?",
    login: "Login",
    toRegister: "Don't have an account yet?",
    emailInput: "Email",
    passInput: "Password",
    rememberMeCheck: "Remember Me",
  },
  t1_header: {
    productBtn: "Products",
    aboutBtn: "About",
    faqBtn: "FAQ",
    cartBtn: "Cart",
    loginBtn: "Login",
  },
  t2_header: {
    productBtn: "Products",
    aboutBtn: "About",
    faqBtn: "FAQ",
    cartBtn: "Cart",
    loginBtn: "Login",
  },
  sellerMenu_header: {
    sellerLocationBtn: "Manage Location",
    sellerCategoryBtn: "Manage Category",
    sellerProfileBtn: "Seller Profile",
    sellerProductBtn: "Manage Products",
  },
  userMenu_header: {
    helloText: "Hello, ",
    profileBtn: "Profile",
    discussionBtn: "Discussion",
    logoutBtn: "Logout",
  },
  changePass: {
    title: "Reset Password",
    title2: "Change Password",
    old_passInput: "Old Password",
    new_passInput: "New Password",
    conf_passInput: "Confirmation Password",
    submitBtn: "Submit",
    closeBtn: "Close",
  },
  profileUser: {
    title: "User Profile",
    tap_1: "Profile",
    tap_2: "User Location",
    name: "Name",
    email: "E-mail",
    submitBtn: "Update Profile",
    editBtn: "Edit Profile",
    changePassBtn: "Change Password",
    addressListBtn: "Address List",
    transactionStatusBtn: "Transaction Status",
    paymentStatusBtn: "Payment Status"
  },
  profileSeller: {
    title: "Seller Profile",
  },
  productPage: {
    addToCart: "Add To Cart",
    quantityInput: "Quantity",
    detailSelect: "Variation",
    productDescription: "Product Description",
    productDiscussion: "Discussion",
    showMore: "Read More",
    showLess: "Minimize",
  },
  cartPage: {
    checkout: "Checkout",
    quantity: "Quantity",
    subTotal: "Subtotal",
    note: "Note",
  },
};

// const en = {
//   welcome: "Welcome",
// };

export default en;
