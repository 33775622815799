import React, { Component } from "react";
import { RoundedOrangeButton } from "../../_UtilityComponent/Button/RoundedButton";
import style from "./Discussion.module.css";

export default class DiscussionForm extends Component {
  render() {
    return (
      <form className={style.discPage__discNew_mainCont}>
        <div className={style.discPage__discNew_title}>
          Kirim Pertanyaan Diskusi
        </div>
        <InputBox
          rows={6}
          inputValue={this.props.topic}
          inputName={"topic"}
          onChange={this.props.changeHandler}
        />
        <SubmitButton
          onClick={this.props.postDiscussionTopic}
          prm1={this.props.id}
        />
      </form>
    );
  }
}

const InputBox = (props) => {
  return (
    <div className={style.discPage__discNew_topicEditor}>
      <textarea
        className={`${style.discPage__discNew_topicInputBox}`}
        onChange={(e) => {
          props.onChange(e);
        }}
        value={props.inputValue}
        name={props.inputName}
        rows={props.rows}
      />
    </div>
  );
};

const SubmitButton = (props) => {
  return (
    <div className={style.discPage__discNew_replyButtonCont}>
      <button
        className={style.discPage__discNew_replyButton}
        onClick={(e) => props.onClick(e, props.prm1)}
      >
        KIRIM
      </button>
    </div>
  );
};
