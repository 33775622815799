import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon, MDBFooter } from "mdbreact";
import Banner from "../../components/HeaderAfterLogin/Banner";
import ProductHome from "../../pages/ProductHome";
import SellerPrmContext from "../../../SellerPrmContext";
import "../../../assets/css/fonts.css";
import Footer from "../Footer";

class Home extends React.Component {
  render() {
    const { seller_style } = this.context.subDomainData;
    // console.log(this.context);
    const banner_color = seller_style.color_secondary;
    const product_about_color = "#FFFFFF";
    const product_color = seller_style.color_secondary;
    const footer_color = seller_style.color_primary;

    const btn_color = seller_style.color_btn;
    const tab_color = seller_style.color_primary;
    const tab_color_btn = seller_style.color_secondary;
    const border_color = "#FFFFFF";
    const background_transparent = "color";

    return (
      <>
        <div id="banner">
          <Banner />
        </div>
        <ProductHome
          seller_style={seller_style}
          background_transparent={background_transparent}
          product_color={product_color}
          product_about_color={product_about_color}
          tab_color={tab_color}
          btn_color={btn_color}
          border_color={border_color}
          tab_color_btn={tab_color_btn}
        />
        <Footer subDomainData={this.context.subDomainData} />
      </>
    );
  }
}

export default Home;
Home.contextType = SellerPrmContext;
