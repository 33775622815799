import React, { Component } from "react";
import SellerProductsView from "./SellerProductsView";

export default class SellerProductsMain extends Component {
  componentDidMount() {
    this.props.sellerProducts.listSellerProducts();
  }

  render() {
    return (
      <div>
        <SellerProductsView data={this.props.sellerProducts.dataTable} />
      </div>
    );
  }
}
