import { MDBBtn, MDBContainer, MDBInput, MDBSelect } from "mdbreact";
import React from "react";
import {
  ManageSellerAddProductsSubTitle,
  ManageSellerProductsPhotos,
} from "./ManageSellerProducts.styles";
import MDBFileUpload from "mdb-react-fileupload";

export const ManageSellerProductsVariantForm = (props) => {
  // console.log(props.detailProductId)
  return (
    <>
      <ManageSellerAddProductsSubTitle>
        {props.action === "ADD"
          ? "Tambah Variasi Produk"
          : "Update Variasi Produk"}
      </ManageSellerAddProductsSubTitle>
      <MDBInput
        value={props.nameDetail}
        onChange={props.changeHandler}
        type="text"
        className="form-control"
        name="nameDetail"
        label="Nama Variasi Utama"
        required
      />
      <small>
        *Tips:Masukkan variasi dari barang, seperti warna Merah atau ukuran XL
      </small>
      <MDBInput
        value={props.qty}
        onChange={props.changeHandler}
        type="number"
        className="form-control"
        name="qty"
        label="Stok Barang"
        required
      />
      <MDBInput
        value={props.price}
        onChange={props.changeHandler}
        type="number"
        className="form-control"
        name="price"
        label="Harga"
        required
      />
      <MDBInput
        value={props.weight}
        onChange={props.changeHandler}
        type="number"
        className="form-control"
        name="weight"
        label="Berat (Gram)"
        required
      />
      <MDBSelect
        search
        color="primary"
        options={props.sellerLocationList}
        selected="Pilih lokasi barang"
        label="Lokasi barang"
        getValue={props.selectSellerLocation}
        required
      />
      <ManageSellerProductsPhotos>
        {props.previewDetailProductPhoto && props.renderDetailProductPhoto? (
          <MDBFileUpload
            showRemove={false}
            containerHeight={150}
            messageDefault={"Gambar Detail Produk"}
            messageReplace={"Detail Produk"}
            getValue={(e) => props.selectDetailProductPhoto(e)}
            defaultFileSrc={props.previewDetailProductPhoto}
          />
        ) : (
          <MDBFileUpload
            showRemove={false}
            containerHeight={150}
            messageDefault={"Gambar Detail Produk"}
            messageReplace={"Detail Produk"}
            getValue={(e) => props.selectDetailProductPhoto(e)}
          />
        )}
      </ManageSellerProductsPhotos>
      <MDBContainer style={{ paddingTop: "5%", paddingBottom: "10%" }}>
        {props.pageState === "EDIT" ? (
          props.action === "ADD" ? (
            <MDBBtn
              className="w-100 mr-0 ml-0 mt-2"
              onClick={() => {
                props.storeSellerDetailProduct(
                  props.groupProductId,
                  props.sellerId,
                  props.pageState
                );
              }}
              color="deep-orange"
            >
              Tambah
            </MDBBtn>
          ) : (
            <MDBBtn
              className="w-100 mr-0 ml-0 mt-2"
              color="deep-orange"
              onClick={() => {
                props.updateSellerDetailProduct(
                  props.groupProductId,
                  props.detailProductId,
                  props.sellerId,
                  props.pageState
                );
              }}
            >
              Update
            </MDBBtn>
          )
        ) : (
          "ADD PAGE STATE"
        )}
      </MDBContainer>
    </>
  );
};
