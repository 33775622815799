import React, { Component } from "react";
import SellerProductsFormMain from "../components/SellerProducts/SellerProductsFormMain";
import SellerProductsContext from "../context/SellerProductsContext";
// import SellerCategoryContext from "../context/SellerCategoryContext";
import SellerLocationContext from "../context/SellerLocationContext";

export default class SellerProductsForm extends Component {
  render() {
    return (
      <>
        <SellerLocationContext.Consumer>
          {(sellerLocation) => (
            <SellerProductsContext.Consumer>
              {(sellerProducts) => (
                <SellerProductsFormMain
                  sellerLocation={sellerLocation}
                  sellerProducts={sellerProducts}
                />
              )}
            </SellerProductsContext.Consumer>
          )}
        </SellerLocationContext.Consumer>
      </>
    );
  }
}
