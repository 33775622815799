import React, { useEffect, useRef, useState } from "react";
import API from "../../../../utils/API";
import { Stores } from "./Stores";
import { StoreListLandingContainer } from "./StoreListLanding.styles";

const StoreListLanding = () => {
  const [storeList, setStoreList] = useState([]);

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    getStoreList();
    return () => {
      setStoreList([]);
    };
  }, []);

  const getStoreList = async () => {
    try {
      const resp = await API.get("get_all_store");
      if (componentIsMounted.current) {
        const storeList = resp.data.data.stores;

        setStoreList(storeList);
      }
    } catch (err) {
      // Handle Error Here
      console.log(err);
    }
  };

  return (
    <StoreListLandingContainer>
      <Stores storeList={storeList} />
    </StoreListLandingContainer>
  );
};

export default StoreListLanding;
