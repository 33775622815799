import React, { Component } from "react";
import style from "./Discussion.module.css";

export default class DiscussionReplyForm extends Component {
  render() {
    return (
      <form className={style.discPage__discThread_replyFormCont}>
        <InputBox
          inputName={this.props.inputName}
          inputValue={this.props.inputValue}
          onChange={this.props.onChange}
        />
        <ReplyButton
          onClick={this.props.onClick}
          prm1={this.props.prm1}
          prm2={this.props.prm2}
        />
      </form>
    );
  }
}

const InputBox = (props) => {
  return (
    <div className={style.discPage__discThread_replyEditor}>
      <textarea
        className={`${style.discPage__discThread_replyInputBox}`}
        onChange={(e) => {
          props.onChange(e);
        }}
        value={props.inputValue}
        name={props.inputName}
      />
    </div>
  );
};

const ReplyButton = (props) => {
  return (
    <button
      className={style.discPage__discThread_replyButton}
      onClick={(e) => props.onClick(e, props.prm1, props.prm2)} 
      type={"submit"}
    >
      Send
    </button>
  );
};
