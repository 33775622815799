import React, { Component } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBListGroup,
  MDBListGroupItem,
  MDBCollapseHeader,
  MDBCollapse,
  MDBModalHeader,
} from "mdbreact";
import style from "./ShipmentCourierSelector.module.css";
import { CenterLoader } from "../../../../_UtilityComponent/Loader/CenterLoader";

export default class ShipmentCourierSelector extends Component {
  state = {
    collapseID: "",
  };
  toggleCollapse = (collapseID) => {
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));
  };
  render() {
    const {
      showModal,
      toggleHandler,
      courierList,
      setSelectedService,
      actionModalCourier,
    } = this.props;
    return (
      <MDBModal
        className={`${style.modalShipmentCourier}`}
        isOpen={showModal}
        toggle={() => {
          toggleHandler("CLOSE");
          this.setState({ collapseID: "" });
        }}
      >
        <MDBModalHeader
          toggle={() => {
            toggleHandler("CLOSE");
            this.setState({ collapseID: "" });
          }}
        >
          Pilih Layanan Kurir
        </MDBModalHeader>
        {actionModalCourier === "COURIER" ? (
          <>
            {courierList.length > 0 ? (
              <MDBListGroup className={`w-100 ${style.modalCourierContainer}`}>
                {courierList.map((item, index) => {
                  return (
                    <MDBListGroupItem key={index}>
                      <MDBCollapseHeader
                        onClick={() => this.toggleCollapse("collapse" + index)}
                      >
                        <div style={{ display: "inline-block" }}>
                          {item.courier_name}
                        </div>
                      </MDBCollapseHeader>
                      <MDBCollapse
                        id={"collapse" + index}
                        isOpen={this.state.collapseID}
                      >
                        <MDBListGroup className="w-100">
                          {item.courier_data.map((sub, subindex) => {
                            return (
                              <MDBListGroupItem
                                key={subindex}
                                onClick={() => {
                                  setSelectedService(sub);
                                  this.setState({ collapseID: "" });
                                }}
                              >
                                <div className="font-weight-bold">
                                  {sub.service}
                                </div>
                                <div>{sub.estimate} hari</div>
                                <div>
                                  {new Intl.NumberFormat("id-ID", {
                                    style: "currency",
                                    currency: "IDR",
                                  }).format(sub.courier_price)}
                                </div>
                              </MDBListGroupItem>
                            );
                          })}
                        </MDBListGroup>
                      </MDBCollapse>
                    </MDBListGroupItem>
                  );
                })}
              </MDBListGroup>
            ) : (
              <CenterLoader />
            )}
          </>
        ) : (
          ""
        )}
      </MDBModal>
    );
  }
}
