import React from "react";
import {
  MDBContainer,
  MDBCard,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBInput,
} from "mdbreact";

import style from "./UserLocation.module.css";

export const UserLocationList = (props) => {
  return (
    <>
      {props.addressList.length > 0 ? (
        props.addressList.map((item) => {
          return (
            <MDBContainer style={{ maxWidth: "280px" }} key={item.id}>
              <MDBCard
                className="mb-3 pt-3 pb-3"
                onClick={
                  props.isFromShipment
                    ? () => props.setSelectedAddress(item.id)
                    : () => {}
                }
              >
                <MDBContainer>
                  <MDBRow>
                    <MDBCol size="9">
                      <span className="font-weight-bold">{item.alias} </span>
                      <br />
                      {item.is_primary == 1 ? (
                        <span style={{ backgroundColor: "lightgrey" }}>
                          {" "}
                          Alamat Utama{" "}
                        </span>
                      ) : (
                        ""
                      )}
                    </MDBCol>
                    {!props.isFromShipment ? (
                      <MDBCol size="2">
                        <MDBIcon
                          icon="ellipsis-h"
                          onClick={(e) =>
                            props.toggle("MODALOPTION", 2, item.id)
                          }
                        />
                      </MDBCol>
                    ) : (
                      <MDBCol size="2">
                        <MDBInput
                          containerClass={style.radioButton}
                          checked={
                            props.selectedAddressId === item.id ? true : false
                          }
                          label=" "
                          type="radio"
                          id={item.id}
                        />
                      </MDBCol>
                    )}
                    <MDBCol size="12">{item.name}</MDBCol>
                    <MDBCol size="12">{item.hp}</MDBCol>
                    <MDBCol size="12">{item.address}</MDBCol>
                  </MDBRow>
                </MDBContainer>
                {!props.isFromShipment ? (
                  <>
                    <hr />
                    <MDBCol
                      onClick={(e) => props.toggle("UPDATE", 1, item.id)}
                      className="text-center"
                      size="12"
                      style={{ color: "blue" }}
                    >
                      Ubah Alamat
                    </MDBCol>
                  </>
                ) : (
                  ""
                )}
              </MDBCard>
            </MDBContainer>
          );
        })
      ) : (
        <MDBContainer>
          <MDBRow>
            <MDBCol className="text-center">
              Anda belum memiliki alamat pengiriman. Silahkan tambahkan alamat
              pengiriman anda.
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      )}
    </>
  );
};
