import React, { createContext, Component } from "react";
//import for provider
import API from "../../utils/API";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import Axios from "axios";

//Context
export const ShipmentContext = createContext();

export default class ShipmentProvider extends Component {
  state = {
    //cart
    cart_list: undefined,
    total_price: 0,
    total_product: 0,
    total_shipment_service: 0,
    //location
    user_location_list: undefined,
    selected_location: undefined,
    //other
    is_ready: false,
    modal1: false,
  };

  //function
  createConfig = (token) => {
    let config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
    return config;
  };

  setInit = async (sellerId) => {
    //location
    let user_location_list = await this.getUserLocationList(sellerId);
    let temp_select_loc = user_location_list.filter(
      (data) => data.is_primary === "1"
    )[0];
    //cart
    let cart_list = await this.getCartList(sellerId);
    this.calculateTotal(cart_list);
    cart_list = await this.getCourierList(cart_list, temp_select_loc);
    this.setState({
      user_location_list: user_location_list,
      selected_location: temp_select_loc,
      cart_list: cart_list,
    });
  };

  //get data
  getUserLocationList = async (sellerId) => {
    let temp_loc = await API.get(
      "/user_location",
      this.createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        if (res.data.data) {
          let listDataTemp = res.data.data.user_location.map((list) => ({
            id: list.id,
            user_id: list.user_id,
            alias: list.alias,
            name: list.name,
            hp: list.hp,
            address: list.address,
            zip_id: {
              label:
                list.zip.province +
                ", " +
                list.zip.sub_province +
                ", " +
                list.zip.district +
                ", " +
                list.zip.sub_district +
                ", " +
                list.zip.zip,
              value: list.zip,
            },
            latitude: list.latitude,
            longitude: list.longitude,
            is_primary: list.is_primary,
            created_at: list.created_at,
            updated_at: list.updated_at,
            text: list.alias,
            value: list.id,
          }));
          return listDataTemp;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return temp_loc;
  };

  getCourierList = async (cart_list, user_loc) => {
    let res_array = [];
    cart_list.map((cart) => {
      const data = new FormData();
      data.append("cart_id", cart.cart_id);
      data.append("origin", cart.seller_location.zip.id);
      data.append("destination", user_loc.zip_id.value.id);
      data.append("cart_weight", cart.total_weight);
      data.append("cart_price", cart.total_price);
      res_array.push(
        API.post(
          `/prm/courier/check-price`,
          data,
          this.createConfig(Cookies.get("access_token"))
        )
      );
    });
    let res = await Axios.all(res_array);
    let new_list = [];
    res.map((res, index) => {
      if (res.data.data) {
        if (parseInt(res.data.code) === 200) {
          let temp_c = [];
          let temp_s = [];
          res.data.data.courier.map((data, index) => {
            if (!data.courier_data.code) {
              temp_c.push({ value: index.toString(), text: data.courier_name });
              temp_s.push({
                value: index.toString(),
                service: [...data.courier_data],
              });
            }
          });
          new_list.push({
            ...cart_list[index],
            courier_list: temp_c,
            service_list: temp_s,
          });
        }
      }
    });
    return new_list;
  };

  getCartList = async (sellerId) => {
    let temp_cart = await API.get(
      `cart/${sellerId}`,
      this.createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        console.log(res);
        let temp_cart_list = res.data.data.cart;
        if (temp_cart_list.length > 0) {
          temp_cart_list = temp_cart_list.map((cart) => {
            let temp_detail = cart.dtl_cart.map((data) => ({
              detail_id: data.id,
              product_id: data.dtl_product.id,
              group_product_id: data.product_group_id,
              product_name: data.group_product.name,
              varian_name: data.dtl_product.name,
              price: parseInt(data.dtl_product.price),
              quantity: parseInt(data.qty),
              note: data.note,
              stock: parseInt(data.dtl_product.stock),
              weight: parseInt(data.dtl_product.weight),
              sub_total: parseInt(data.dtl_product.price) * parseInt(data.qty),
              img: data.group_product.thumbnail.link,
              is_selected: data.checked,
            }));

            let total = this.calculateCartTotal(temp_detail);
            return {
              cart_id: cart.id,
              seller_location: cart.seller_location,
              cart_detail: temp_detail,
              total_price: total.subtotal_price,
              total_weight: total.subtotal_weight,
              total_product: total.subtotal_product,
              //shipment
              courier_is_selected: false,
              service_is_selected: false,
              selected_courier: undefined,
              selected_service: undefined,
              service_price: 0,
            };
          });
          temp_cart_list = temp_cart_list
            .map((a) => {
              const cartDetailActive = a.cart_detail.filter(
                (b) => parseInt(b.is_selected) === 1
              );
              return { ...a, cart_detail: cartDetailActive };
            })
            .filter((c) => c.cart_detail.length > 0);
          return temp_cart_list;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return temp_cart;
  };

  //handel
  changeUserLocationHandler = async (index) => {
    let updateCart = [...this.state.cart_list];
    updateCart.map((data) => {
      data.courier_is_selected = false;
      data.service_is_selected = false;
      data.selected_courier = undefined;
      data.selected_service = undefined;
    });
    const cart_list = await this.getCourierList(
      updateCart,
      this.state.user_location_list[index]
    );
    this.checkIsReady();
    this.setState({
      cart_list: cart_list,
      selected_location: this.state.user_location_list[index],
    });
  };

  changeCourierHandler = async (event, payload) => {
    const courier_index = event[0];
    if (courier_index) {
      const cart_index = payload.cart_index;
      const updateCart = [...this.state.cart_list];
      updateCart[cart_index].courier_is_selected = true;
      updateCart[cart_index].selected_courier =
        updateCart[cart_index].courier_list[courier_index];
      this.setState({ cart_list: updateCart });
    }
  };

  changeServiceHandler = (event, payload) => {
    const service = event[0];
    const price = event[0].courier_price;
    const cart_index = payload.cart_index;
    const updateCart = [...this.state.cart_list];
    updateCart[cart_index].service_is_selected = true;
    updateCart[cart_index].selected_service = service;
    updateCart[cart_index].service_price = price;
    this.setState({ cart_list: updateCart }, () => {
      this.calculateTotal(this.state.cart_list);
      this.checkIsReady();
    });
  };

  popupModal = (act, no) => {
    let modal = "modal" + no;
    if (act === "CLOSE") {
      this.setState({ [modal]: !this.state[modal] });
    } else if (act === "SHOW") {
      this.setState({ [modal]: !this.state[modal] });
    }
  };

  //cart list function
  calculateCartTotal = (list) => {
    let temp_price =
      list.length > 0
        ? list
            .filter((data) => parseInt(data.is_selected) === 1)
            .reduce((accumulator, currentValue) => {
              return accumulator + parseInt(currentValue.sub_total);
            }, 0)
        : 0;
    let temp_product =
      list.length > 0
        ? list
            .filter((data) => parseInt(data.is_selected) === 1)
            .reduce((accumulator, currentValue) => {
              return accumulator + parseInt(currentValue.quantity);
            }, 0)
        : 0;
    let temp_weight =
      list.length > 0
        ? list
            .filter((data) => parseInt(data.is_selected) === 1)
            .reduce((accumulator, currentValue) => {
              let weight =
                parseInt(currentValue.weight) * parseInt(currentValue.quantity);
              return accumulator + weight;
            }, 0)
        : 0;
    return {
      subtotal_price: temp_price,
      subtotal_product: temp_product,
      subtotal_weight: temp_weight,
    };
  };

  calculateTotal = (list) => {
    let temp_price = list.reduce((accumulator, currentValue) => {
      return accumulator + parseInt(currentValue.total_price);
    }, 0);
    let temp_product = list.reduce((accumulator, currentValue) => {
      return accumulator + parseInt(currentValue.total_product);
    }, 0);
    let temp_service = list.reduce((accumulator, currentValue) => {
      return accumulator + parseInt(currentValue.service_price);
    }, 0);

    this.setState({
      total_price: temp_price,
      total_item: temp_product,
      total_shipment_service: temp_service,
    });
  };

  checkIsReady = () => {
    let ready = this.state.cart_list.find(
      (data) => data.service_is_selected === false
    );
    this.setState({ is_ready: !ready ? true : false });
  };

  //Payment
  paymentHandler = async (user, sellerId, sellerName, seller_data) => {
    // console.log(this.state);
    if (await this.cekQuantity()) {
      const primary_address = this.state.user_location_list.find(
        (data) => data.is_primary === "1"
      );
      const cart_details = this.state.cart_list.map((cart) => {
        let seller_location = cart.seller_location;
        let cart_item = cart.cart_detail.map((item) => ({
          detail_id: item.detail_id,
          item_id: item.product_id,
          price: item.price,
          quantity: item.quantity,
          name: item.product_name,
          group_product_id: item.group_product_id,
          detail_name: item.varian_name,
          note: item.note,
          img: item.img,
          weight: item.weight,
        }));
        cart_item.push({
          detail_id: "-",
          item_id: "COURIER-" + cart.cart_id,
          price: cart.service_price,
          quantity: 1,
          name: cart.selected_courier.text,
          group_product_id: "",
          detail_name: cart.selected_service.service,
          note: "",
          img: "",
          weight: "",
        });

        return {
          id: cart.cart_id,
          data_json: { 
            cus_profile: user,
            cus_location: this.state.selected_location,
            seller_profile: seller_data,
            seller_location :seller_location,
          },
          total_weight: cart.total_weight,
          courier_detail: {
            courier_name: cart.selected_courier.text,
            courier_service: cart.selected_service.service,
            courier_price: cart.service_price,
          },
          item_details: cart_item,
        };
      });

      const customer_details = {
        id: user.id,
        first_name: user.name,
        last_name: "",
        email: user.email,
        phone: user.no_tlp,
        billing_address: {
          first_name: user.name,
          last_name: "",
          email: user.email,
          phone: user.no_tlp,
          address: primary_address.address,
          city: primary_address.zip_id.value.sub_province,
          postal_code: primary_address.zip_id.value.zip,
          country_code: "IDN",
        },
        shipping_address: {
          first_name: this.state.selected_location.name,
          last_name: "",
          email: user.email,
          phone: this.state.selected_location.hp,
          address: this.state.selected_location.address,
          city: this.state.selected_location.zip_id.value.sub_province,
          postal_code: this.state.selected_location.zip_id.value.zip,
          country_code: "IDN",
        },
      };

      const params = {
        transaction_details: {
          gross_amount:
            this.state.total_price + this.state.total_shipment_service,
        },
        seller_details: { id: sellerId, seller_name: sellerName },
        cart_details: cart_details,
        customer_details: customer_details,
        callbacks: {
          finish: `http://${window.location.hostname}/transaction`,
        },
      };
      // console.log(JSON.stringify(params))

      const enabledPayments = [
        "credit_card",
        "bca_klikpay",
        "bri_epay",
        "qris",
        "permata_va",
        "bca_va",
        "bni_va",
        "other_va",
        "echannel", //mandiri
        "indomaret",
        "alfamart",
      ];

      API.post(
        "payment/send-to-snap",
        params,
        this.createConfig(Cookies.get("access_token"))
      )
        .then(function (response) {
          console.log(response);
          window.snap.pay(response.data.midtrans_response.data.token, {
            enabledPayments,
            onSuccess: function (result) {
              let url = new URL(window.location.href);
              url.pathname = `/transaction/[1,2,3,4]`;
              // url.search = `?order_id=${result.order_id}&status_code=${result.status_code}&transaction_status=${result.transaction_status}&payment_type=${result.payment_type}`;
              window.location.href = url.toString();
            },
            onPending: function (result) {
              let url = new URL(window.location.href);
              url.pathname = `/transaction/[0]`;
              // url.search = `?order_id=${result.order_id}&status_code=${result.status_code}&transaction_status=${result.transaction_status}&payment_type=${result.payment_type}`;
              window.location.href = url.toString();
            },
            onError: function (result) {
              // console.log("error");
              // console.log(result);
            },
            onClose: function () {
              Swal.fire({
                icon: "error",
                text:
                  "Layar pembayaran telah ditutup, silahkan ulangi pembayaran.",
              });
            },
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      console.log("habis");
    }
  };

  cekQuantity = async () => {
    let res_array = [];
    this.state.cart_list.map((cart) => {
      const data = new FormData();
      data.append("cart_id", cart.cart_id);
      res_array.push(
        API.post(
          `detail_product/cek_stock`,
          data,
          this.createConfig(Cookies.get("access_token"))
        )
      );
    });
    let res = await Axios.all(res_array);
    res = res.find((data) => data.data === false) === undefined ? true : false;
    return res;
  };

  render() {
    return (
      <ShipmentContext.Provider
        value={{
          ...this.state,
          setInit: this.setInit,
          //handler
          popupModal: this.popupModal,
          paymentHandler: this.paymentHandler,
          changeCourierHandler: this.changeCourierHandler,
          changeServiceHandler: this.changeServiceHandler,
          changeUserLocationHandler: this.changeUserLocationHandler,
        }}
      >
        {this.props.children}
      </ShipmentContext.Provider>
    );
  }
}
