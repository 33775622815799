import React, { useEffect, useRef, useState } from "react";
import API from "../../utils/API";
import Cookies from "js-cookie";
import UserTransactionStatusView from "../components/UserTransactionStatusView/UserTransactionStatusView";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CenterLoader } from "../components/_UtilityComponent/Loader/CenterLoader";
import Swal from "sweetalert2";

const UserTransactionStatus = () => {
  let params = useParams();
  const { page } = params;
  const [tracking, setTracking] = useState(undefined);
  const [trPendingList, setTrPendingList] = useState([]);
  const [trInvoiceList, setTrInvoiceList] = useState([]);
  const [requests, setRequests] = useState(0); //render 2x dari atas
  const [errors, setErrors] = useState(0);
  // order process
  const [selectedProcess, setSelectedProcess] = useState(1);
  const [pageProcessList, setpageProcessList] = useState([
    { id: 1, page: "pending-order", name: "Pending Order" },
    { id: 2, page: "processing-order", name: "Processing Order" },
    { id: 3, page: "complete-order", name: "Complete Order" },
    { id: 4, page: "canceled-order", name: "Canceled Order" },
  ]);
  // check if on-going request exist
  const requestChecker = (condition) => {
    if (condition === "FETCH") {
      setRequests(requests + 1);
    } else if (condition === "DONE") {
      setRequests(requests - 1);
    } else if (condition === "ERROR") {
      setRequests(requests - 1);
      setErrors(errors + 1);
    }
  };
  // token
  const token = Cookies.get("access_token");
  const createConfig = () => {
    let config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
    return config;
  };
  const isInitialMount = useRef(true);
  // getUserTransactionStatus
  useEffect(() => {
    let process;
    // console.log(page);
    switch (page) {
      case "pending-order":
        process = [0];
        getPendingTransaction();
        setSelectedProcess(1);
        break;
      case "processing-order":
        process = [1, 2, 3, 4];
        getUserInvoice(process);
        setSelectedProcess(2);
        break;
      case "complete-order":
        process = [5];
        getUserInvoice(process);
        setSelectedProcess(3);
        break;
      case "canceled-order":
        process = [99];
        getUserInvoice(process);
        setSelectedProcess(4);
        break;
      default:
        process = [0];
        getPendingTransaction();
        setSelectedProcess(1);
    }
  }, [params.page]);
  //  run only when update
  // useEffect(() => {
  //   if (isInitialMount.current) {
  //     isInitialMount.current = false;
  //   } else {
  //     const { page } = params;
  //     if (page && trPendingList) {
  //       setIsLoading(false);
  //     }
  //   }
  // }, [listStatus, listPrmStatus]);
  //////////////////////////////////////////////////////////////////////////

  const getPendingTransaction = () => {
    requestChecker("FETCH");
    API.get(`/payment/check_pending`, createConfig(Cookies.get("access_token")))
      .then((res) => {
        setTrPendingList(res.data.data);
        requestChecker("DONE");
      })
      .catch((err) => {
        requestChecker("ERROR");
        console.log(err);
      });
  };

  const getUserInvoice = (status) => {
    requestChecker("FETCH");
    const data = new FormData();
    data.append("status", JSON.stringify(status));
    data.append("courier", "");
    API.post(
      `payment/user_invoice`,
      data,
      createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        setTrInvoiceList(res.data.invoice_list);
        requestChecker("DONE");
      })
      .catch((err) => {
        requestChecker("ERROR");
        console.log(err);
      });
  };

  const getTrackingStatus = (courier, tracking_code) => {
    requestChecker(true);
    API.get(
      `/courier/tracking_status/${courier}/${tracking_code}`,
      this.createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        if (courier === "NCS") {
          if (!res.data.track[0].hasOwnProperty("message")) {
            setTracking(res.data);
          }
        }
      })
      .catch((err) => {
        requestChecker(false);
        console.log(err);
      });
  };

  const updateInvoiceStatus = (invoice_id, status) => {
    const data = new FormData();
    data.append("invoice_id", invoice_id);
    data.append("status", status);
    API.put(
      `payment/up_invoice_status/${invoice_id}/${status}`,
      null,
      this.createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        Swal.fire({
          title: "Berhasil",
          icon: "success",
          showConfirmButton: true,
        }).then(() => {
          this.props.history.push("/transaction/[5]");
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (requests <= 0 && errors <= 0) {
    return (
      <UserTransactionStatusView
        page={page}
        pageProcessList={pageProcessList}
        trPendingList={trPendingList}
        trInvoiceList={trInvoiceList}
        updateInvoiceStatus={updateInvoiceStatus}
        getTrackingStatus={getTrackingStatus}
        selectedProcess={selectedProcess}
      />
    );
  } else if (requests <= 0 && errors > 0) {
    return <div>Maaf, sepertinya sedang ada kendala silahkan coba lagi</div>;
  } else {
    return <CenterLoader />;
  }
};

export default UserTransactionStatus;
