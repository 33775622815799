import { MDBContainer } from "mdbreact";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import SellerProductsContext from "../../context/SellerProductsContext";
import { PageContainer } from "../LandingView/Landing.styles";
import { CenterLoader } from "../_UtilityComponent/Loader/CenterLoader";
import ModalOption from "../_UtilityComponent/ModalOption/ModalOption";
import { ManageSellerListItems } from "./ManageSellerListItems";
import { ManageSellerProductsAdd } from "./ManageSellerProductsAdd";
import { ManageSellerProductsEdit } from "./ManageSellerProductsEdit";
import { ManageSellerProductsVariantForm } from "./ManageSellerProductsVariantForm";
import { ManageSellerProductsVariantLists } from "./ManageSellerProductsVariantLists";

export const ManageSellerProducts = () => {
  let params = useParams();
  let history = useHistory();
  let page = params.page;
  let s_id = params.s_id;
  let process = params.s_name; //BUAT PROCESS
  let p_id = params.process; //PRODUCT_ID
  let d_process = params.detail; //EDIT DETAIL PRODUCT
  let d_process_id = params.detail_id;

  const ProdContext = useContext(SellerProductsContext);

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    // console.log(ProdContext.photoProductState);
    // console.log(ProdContext.renderProductPhoto);
    // console.log(ProdContext.previewProductPhoto);
  }, [ProdContext.photoProductState, ProdContext.previewProductPhoto, ProdContext.renderProductPhoto])

  /////SELLER PRODUCTS
  useEffect(() => {
    if (page === "manage_products" && s_id && !d_process) {
      // console.log(d_process);
      ProdContext.listSellerProducts(s_id);
      if (process === "add") {
        ProdContext.listSellerCategoryActive(p_id, "ADD", s_id);
        // ProdContext.listSellerLocation(s_id);
      } else if (process === "edit") {
        ProdContext.listSellerCategoryActive(p_id, "EDIT", s_id);
        // ProdContext.listSellerLocation(s_id);
      }
    }
    return () => {};
  }, [page, s_id, process, p_id, d_process]);

  // for variant details update set data
  useEffect(() => {
    if (d_process === "edit" && d_process_id) {
      // ProdContext.showSellerDetailProducts(d_process_id, s_id);
      ProdContext.listSellerLocation(s_id, d_process_id);
      // callback showSellerDetailProducts
    }
  }, [d_process, d_process_id]);

  /////////////////////
  // console.log(process);
  if (process === "add" && !ProdContext.isLoading) {
    return (
      <ManageSellerProductsAdd
        // STATE
        sellerId={s_id}
        name={ProdContext.name}
        description={ProdContext.description}
        // METHOD
        changeHandler={ProdContext.changeHandler}
        categoryActiveList={ProdContext.listCategoryActive}
        sellerLocationList={ProdContext.listSellerLocationsArr}
        selectProductCategory={ProdContext.selectProductCategory}
        selectProductPhoto={ProdContext.selectProductPhoto}
        selectSellerLocation={ProdContext.selectSellerLocation}
        selectDetailProductPhoto={ProdContext.selectDetailProductPhoto}
        storeSellerProduct={ProdContext.storeSellerProduct}
      />
    );
  } else if (process === "edit" && !ProdContext.isLoading) {
    // console.log(ProdContext.isLoading);
    if (d_process === "add") {
      //add detail product
      return (
        <MDBContainer>
          <ManageSellerProductsVariantForm
            action="ADD"
            pageState="EDIT"
            // STATE
            sellerId={s_id}
            groupProductId={p_id}
            sellerLocationList={ProdContext.listSellerLocationsArr}
            // METHOD
            changeHandler={ProdContext.changeHandler}
            selectSellerLocation={ProdContext.selectSellerLocation}
            selectDetailProductPhoto={ProdContext.selectDetailProductPhoto}
            storeSellerDetailProduct={ProdContext.storeSellerDetailProduct}
          />
        </MDBContainer>
      );
    } else if (d_process === "edit" && d_process_id) {
      //edit detail product
      if (ProdContext.isDetailLoaded) {
        return (
          <MDBContainer>
            <ManageSellerProductsVariantForm
              action="EDIT"
              pageState="EDIT"
              // STATE
              sellerId={s_id}
              groupProductId={p_id}
              detailProductId={d_process_id}
              sellerLocationList={ProdContext.listSellerLocationsArr}
              nameDetail={ProdContext.nameDetail}
              price={ProdContext.price}
              hpp={ProdContext.hpp}
              qty={ProdContext.qty}
              weight={ProdContext.weight}
              previewDetailProductPhoto={ProdContext.previewDetailProductPhoto}
              renderDetailProductPhoto={ProdContext.renderDetailProductPhoto}
              // METHOD
              changeHandler={ProdContext.changeHandler}
              selectSellerLocation={ProdContext.selectSellerLocation}
              selectDetailProductPhoto={ProdContext.selectDetailProductPhoto}
              updateSellerDetailProduct={ProdContext.updateSellerDetailProduct}
            />
          </MDBContainer>
        );
      } else {
        return (
          <div style={{ height: "100vh" }}>
            <CenterLoader />
          </div>
        );
      }
    } else {
      //edit product //pakai loader kalau belum keload detail listnya
      if (ProdContext.isDataLoaded) {
        return (
          <ManageSellerProductsEdit
            // STATE
            sellerId={s_id}
            groupProductId={p_id}
            name={ProdContext.name}
            description={ProdContext.description}
            history={history}
            renderProductPhoto={ProdContext.renderProductPhoto}
            // STATE-VARIANT
            dataDetail={ProdContext.dataDetailList}
            // METHOD
            changeHandler={ProdContext.changeHandler}
            previewProductPhoto={ProdContext.previewProductPhoto}
            categoryActiveList={ProdContext.listCategoryActive}
            selectProductPhoto={ProdContext.selectProductPhoto}
            updateSellerProduct={ProdContext.updateSellerProduct}
            selectProductCategory={ProdContext.selectProductCategory}
            beActiveDetailProduct={ProdContext.beActiveDetailProduct}
          />
        );
      } else {
        return (
          <div style={{ height: "100vh" }}>
            <CenterLoader />
          </div>
        );
      }
    }
  } else if (!process && !ProdContext.isLoading) {
    return (
      <PageContainer>
        <ManageSellerListItems
          itemDashboard={ProdContext.data}
          page={page}
          modalHandler={ProdContext.modalHandler}
          history={history}
          isLoading={ProdContext.isLoading}
          beActiveProduct={ProdContext.beActiveProduct}
        />
      </PageContainer>
    );
  } else if (!process && ProdContext.isLoading) {
    return (
      <div style={{ height: "100vh" }}>
        <CenterLoader />
      </div>
    );
  } else {
    //error
    return <div></div>;
  }
};
