import React, { Component } from "react";
import CarouselProduct from "../Carousel/CarouselProduct/CarouselProduct";
import { withRouter } from "react-router-dom";
import ProductPageView from "./ProductPageView";
import { CenterLoader } from "../_UtilityComponent/Loader/CenterLoader";

class ProductPageMain extends Component {
  state = {
    activeItemPhoto: 1,
  };

  componentDidMount() {
    if (this.props.sellerPrm.subDomainData) {
      const { id } = this.props.match.params;
      this.props.homeProduct.homeProductDetail(
        this.props.sellerPrm.subDomainData.id,
        id,
        false
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (this.props.sellerPrm.subDomainData) {
        const { id } = this.props.match.params;
        this.props.homeProduct.homeProductDetail(
          this.props.sellerPrm.subDomainData.id,
          id,
          false
        );
      }
    }
  }

  render() {
    const { homeProduct, sellerPrm } = this.props;
    const { activeItemPhoto } = this.state;
    return (
      <>
        {!homeProduct.isLoading ? (
          <>
            <CarouselProduct
              photo={homeProduct.productDetailPhoto}
              activeItem={activeItemPhoto}
            />
            <ProductPageView
              // state
              product={homeProduct.productDetail}
              detailSelect={homeProduct.productDetailSelect}
              selectedDetail={homeProduct.selectedDetail}
              qty={homeProduct.qty}
              sellerId={sellerPrm.subDomainData.id}
              showDesc={homeProduct.showDesc}
              discussionList={homeProduct.discussionListLimit}
              discIsLoading={homeProduct.discIsLoading}
              isLoading={homeProduct.isLoading}
              // method
              readMore={homeProduct.readMore}
              changeHandler={homeProduct.changeHandler}
              selectDetailHandler={homeProduct.selectDetailHandler}
              inpNumberChange={homeProduct.inpNumberChange}
              addCart={homeProduct.addCartFromPage}
            />
          </>
        ) : (
          <CenterLoader />
        )}
      </>
    );
  }
}

export default withRouter(ProductPageMain);
