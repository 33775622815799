import { MDBCol, MDBRow } from "mdbreact";
import React from "react";
import * as S from "../_Styled/CardPayment.styles.js";

export const UserTransactionStatusEmpty = () => {
  return (
    <div>
      <S.CardContainerPayment className="mt-3 mb-3">
        <MDBRow>
          <MDBCol>Transaksi Kosong</MDBCol>
        </MDBRow>
      </S.CardContainerPayment>
    </div>
  );
};
