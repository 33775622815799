import React, { Component } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBCard,
  MDBSpinner,
  // MDBSelect,
  MDBFileInput,
  // MDBSelectInput,
  // MDBSelectOptions,
  // MDBSelectOption,
  MDBIcon,
} from "mdbreact";
import { Text } from "../../context/Language";
import "../../../assets/css/fonts.css";
import { CMDBBtn } from "../StyledComponent";

export default class ProfileViewSeller extends Component {
  render() {
    const props = this.props;
    const {
      isEdit,
      isEditChange,
      changeHandler,
      updateSellerProfile,
      checkDomainAvailability,
      selectTemplateHandler,
      // selectDetailProductPhoto,
      changeImageHandler,
      isDefaultHandler,
    } = props;
    return (
      <MDBCard style={{ fontFamily: "Lato Regular" }}>
        <form
          className="needs-validation"
          onSubmit={updateSellerProfile}
          noValidate
        >
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <MDBInput
                  value={props.nameWeb}
                  onChange={changeHandler}
                  type="text"
                  className="form-control"
                  name="nameWeb"
                  label={<Text pid="registerPage" tid="storeNameInput" />}
                  disabled={isEdit}
                  required
                />
              </MDBCol>
            </MDBRow>
            {/* <MDBRow> */}
            {/*sub domain */}
            {/* <MDBCol lg="6" md="12" sm="12">
                <MDBRow>
                  <MDBCol lg="9" md="6" sm="6">
                    <MDBInput
                      label={<Text pid="registerPage" tid="subDomainInput" />}
                      value={props.new_subDomain}
                      onChange={changeHandler}
                      onBlur={
                        props.new_subDomain === props.old_subDomain
                          ? ""
                          : () =>
                              checkDomainAvailability(
                                props.new_subDomain,
                                "sub"
                              )
                      }
                      disabled={isEdit}
                      name="new_subDomain"
                      required
                    />
                  </MDBCol>
                  <MDBCol size="3" style={{ padding: "0" }} middle>
                    .1toko.com
                  </MDBCol>
                  <MDBCol size="4" middle>
                    {this.props.renderLoaderSub ? (
                      <>
                        <MDBSpinner />
                        <Text pid="registerPage" tid="checkingDomain" />
                      </>
                    ) : (
                      ""
                    )}
                    {!this.props.renderLoaderSub &&
                    this.props.isAvailableSubDomain &&
                    this.props.isSubDomainChecked ? (
                      <div style={{ color: "green" }}>
                        <Text pid="registerPage" tid="availableDomain" />
                      </div>
                    ) : (
                      ""
                    )}
                    {!this.props.renderLoaderSub &&
                    !this.props.isAvailableSubDomain &&
                    this.props.isSubDomainChecked ? (
                      <div style={{ color: "red" }}>
                        <Text pid="registerPage" tid="unavailableDomain" />
                      </div>
                    ) : (
                      ""
                    )}
                  </MDBCol>
                </MDBRow>
              </MDBCol> */}
            {/*domaim*/}
            {/* <MDBCol lg="6" md="12" sm="12">
                <MDBRow>
                  <MDBCol lg="12" md="8" sm="8">
                    <MDBInput
                      value={props.new_domain}
                      onChange={changeHandler}
                      onBlur={
                        props.new_domain === props.old_domain
                          ? ""
                          : () =>
                              checkDomainAvailability(
                                props.new_domain,
                                "domain"
                              )
                      }
                      name="new_domain"
                      label={<Text pid="registerPage" tid="domainInput" />}
                      disabled={isEdit}
                    />
                  </MDBCol>
                  <MDBCol size="4" middle>
                    {this.props.renderLoaderDomain ? (
                      <>
                        <MDBSpinner />
                        <Text pid="registerPage" tid="checkingDomain" />
                      </>
                    ) : (
                      ""
                    )}
                    {!this.props.renderLoaderDomain &&
                    this.props.isAvailableDomain &&
                    this.props.isDomainChecked ? (
                      <div style={{ color: "green" }}>
                        <Text pid="registerPage" tid="availableDomain" />
                      </div>
                    ) : (
                      ""
                    )}
                    {!this.props.renderLoaderDomain &&
                    !this.props.isAvailableDomain &&
                    this.props.isDomainChecked ? (
                      <div style={{ color: "red" }}>
                        <Text pid="registerPage" tid="unavailableDomain" />
                      </div>
                    ) : (
                      ""
                    )}
                  </MDBCol>
                </MDBRow>
              </MDBCol> */}
            {/* </MDBRow> */}
            {/* sosmed */}
            <MDBRow>
              <MDBCol lg="4" sm="12">
                <MDBRow>
                  <MDBCol
                    middle
                    size="1"
                    className="md-form form-control border-0"
                    style={{ marginRight: "5px" }}
                  >
                    <MDBIcon
                      fab
                      icon="facebook-square"
                      style={{ color: "#3b5998" }}
                      size="2x"
                    />
                  </MDBCol>
                  <MDBCol middle>
                    <MDBInput
                      value={this.props.facebook}
                      onChange={this.props.changeHandler}
                      type="text"
                      className="form-control"
                      name="facebook"
                      label="Facebook"
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol lg="4" sm="12" middle>
                <MDBRow>
                  <MDBCol
                    middle
                    size="1"
                    className="md-form form-control border-0"
                    style={{ marginRight: "5px" }}
                  >
                    <MDBIcon
                      fab
                      icon="twitter-square"
                      style={{ color: "#00acee" }}
                      size="2x"
                    />
                  </MDBCol>
                  <MDBCol middle>
                    <MDBInput
                      value={this.props.twitter}
                      onChange={this.props.changeHandler}
                      type="text"
                      className="form-control"
                      name="twitter"
                      label="Twitter"
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol lg="4" sm="12">
                <MDBRow>
                  <MDBCol
                    middle
                    size="1"
                    className="md-form form-control border-0"
                    style={{ marginRight: "5px" }}
                  >
                    <MDBIcon fab icon="instagram" size="2x" />
                  </MDBCol>
                  <MDBCol middle>
                    <MDBInput
                      value={this.props.instagram}
                      onChange={this.props.changeHandler}
                      type="text"
                      className="form-control"
                      name="instagram"
                      label="Instagram"
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>

            {/* template */}
            {/* <MDBRow>
              <MDBCol>
                <MDBSelect
                  search
                  options={props.template}
                  selected="Pilih tema anda"
                  label="Template"
                  getValue={selectTemplateHandler}
                  disabled={isEdit}
                  required
                />
              </MDBCol>
            </MDBRow> */}
            {/* <MDBRow>
              <MDBCol>
                <MDBSelect
                  label="Template Color"
                  getValue={(e) => this.props.handelChangeColor(e)}
                >
                  <MDBSelectInput selected="Choose color" />
                  <MDBSelectOptions>
                    <MDBSelectOption disabled>Choose color</MDBSelectOption>
                    {this.props.color_list.map((data, index) => {
                      return (
                        <MDBSelectOption key={index} value={data.id}>
                          {data.value}
                        </MDBSelectOption>
                      );
                    })}
                  </MDBSelectOptions>
                </MDBSelect>
              </MDBCol>
            </MDBRow> */}
            {/* color */}
            {this.props.color_list !== undefined ? (
              <>
                <MDBContainer
                  style={{ marginTop: "1rem", marginBottom: "1rem" }}
                >
                  <MDBRow hidden={isEdit}>
                    <span>{`Template Color List :`}</span>
                  </MDBRow>
                  <MDBRow hidden={isEdit}>
                    {this.props.color_list.map((data, index) => {
                      return (
                        <MDBIcon
                          key={index}
                          icon="square-full"
                          size="2x"
                          style={{
                            color: data.id,
                            marginRight: "2px",
                            cursor: "pointer",
                            border: data.id === this.props.primary_color ? '2px solid black' : 'none'
                          }}
                          onClick={() =>
                            this.props.handelChangeColor(
                              data.id,
                              data.value,
                              "primary"
                            )
                          }
                        />
                      );
                    })}
                  </MDBRow>
                  {this.props.prev_primary_color_name !== "" ? (
                    <>
                      <MDBRow>
                        <span>{`Selected Color : `}</span>
                      </MDBRow>
                      <MDBRow>
                        <span
                          style={{
                            color: "white",
                            backgroundColor: this.props.prev_primary_color,
                            padding: "10px",
                            width: "200px",
                            textAlign: "center",
                          }}
                        >
                          {this.props.prev_primary_color_name}
                        </span>
                      </MDBRow>
                    </>
                  ) : (
                    ""
                  )}
                </MDBContainer>
              </>
            ) : (
              ""
            )}
            {/* logo dan banner */}
            <MDBRow>
              <MDBCol lg="6" md="12" sm="12">
                <MDBContainer>
                  <MDBRow style={{ paddingBottom: "5px" }}>
                    <MDBCol>{"CURRENT LOGO"}</MDBCol>
                    {/* <MDBCol>
                      <MDBRow end>
                        <MDBInput
                          label="use default"
                          id="checkLogo"
                          type="checkbox"
                          onChange={() => isDefaultHandler("logo")}
                          checked={props.is_default_logo}
                          disabled={isEdit}
                        />
                      </MDBRow>
                    </MDBCol> */}
                  </MDBRow>
                  <MDBRow>
                    <MDBCol middle>
                      <img
                        className=" img-fluid img-thumbnail z-depth-1 mx-auto d-block"
                        src={
                          props.is_default_logo
                            ? props.default_selected_logo
                            : props.preview_logo
                        }
                        style={{ maxHeight: "150px" }}
                      />
                      <div hidden={isEdit || props.is_default_logo}>
                        <MDBFileInput
                          getValue={(e) => changeImageHandler(e, "logo")}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </MDBCol>
              <MDBCol lg="6" md="12" sm="12">
                <MDBContainer>
                  <MDBRow style={{ paddingBottom: "5px" }}>
                    <MDBCol>{"CURRENT BANNER"}</MDBCol>
                    {/* <MDBCol>
                      <MDBRow end>
                        <MDBInput
                          label="use default"
                          id="checkBanner"
                          type="checkbox"
                          onChange={() => isDefaultHandler("banner")}
                          checked={props.is_default_banner}
                          disabled={isEdit}
                        />
                      </MDBRow>
                    </MDBCol> */}
                  </MDBRow>
                  <MDBRow>
                    <MDBCol middle>
                      <img
                        className=" img-fluid img-thumbnail z-depth-1 mx-auto d-block"
                        src={
                          props.is_default_banner
                            ? props.default_selected_banner
                            : props.preview_banner
                        }
                        style={{ maxHeight: "150px" }}
                      />
                      <div hidden={isEdit || props.is_default_banner}>
                        <MDBFileInput
                          getValue={(e) => changeImageHandler(e, "banner")}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </MDBCol>
            </MDBRow>
            <hr />
            {/* button */}
            <MDBRow>
              <MDBCol size="6" hidden={isEdit ? true : false}>
                <CMDBBtn
                  active
                  bg_color={this.props.btn_color}
                  className="w-100"
                  type="submit"
                >
                  {<Text pid="profileUser" tid="submitBtn" />}
                </CMDBBtn>
              </MDBCol>
              <MDBCol size="6" hidden={isEdit ? true : false}>
                <CMDBBtn
                  active
                  bg_color={this.props.btn_color}
                  className="w-100"
                  onClick={() => isEditChange("cancel")}
                >
                  {<Text pid="profileUser" tid="cancelBtn" />}
                </CMDBBtn>
              </MDBCol>
              <MDBCol hidden={isEdit ? false : true}>
                <CMDBBtn
                  active
                  bg_color={this.props.btn_color}
                  className="w-100"
                  onClick={() => isEditChange("edit")}
                >
                  {<Text pid="profileUser" tid="editBtn" />}
                </CMDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </form>
      </MDBCard>
    );
  }
}
