import React, { Component } from "react";
import Cookies from "js-cookie";
import API from "../../utils/API";
import Swal from "sweetalert2";

import UserLocationView from "../components/UserLocation/UserLocationView";
import UserLocationFormModal from "../components/UserLocation/UserLocationFormModal";
import ModalOption from "../components/UserLocation/UserLocationModalOption/ModalOption";

class UserLocation extends Component {
  state = {
    //data
    name: "",
    hp: "",
    alias: "",
    address: "",
    //state-address-selector
    selectedAddress: "",
    //data alamat
    addressList: [],
    zipCode: [],
    //other
    modifiedItemId: "",
    actionType: "CLOSE",
    isEdit: false,
    isLoading: false,
    modal1: false,
    modal2: false,
  };

  componentDidMount() {
    // this.getZipLocation();
    this.getListUserLocation();
  }

  // untuk react-select async
  loadOptions = (inputValuePrm, callback) => {
    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };

    let inputValue = inputValuePrm ? `keyword=${inputValuePrm}` : "";
    let limit = "&limit=50";
    // let limit = limitPrm ? `&limit=${limitPrm}` : "";

    if (inputValuePrm.length >= 3) {
      API.get(`/prm/zipcode?${inputValue}${limit}`, config).then((res) => {
        // console.log(res);
        if (parseInt(res.data.code) !== 200) {
          alert(res.data.errors);
        } else {
          let zipCodeTemp = res.data.data.zipcode.map((zipCode) => ({
            value: zipCode.value, //id
            label: zipCode.label.toString(), //address
          }));
          callback(zipCodeTemp);
        }
      });
    }
  };

  getZipLocation = () => {
    this.setState({ isLoading: true });

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };

    API.get(`/prm/zipcode`, config)
      .then((res) => {
        if (parseInt(res.data.code) !== 200) {
          alert(res.data.errors);
        } else {
          let zipCodeTemp = res.data.data.zipcode.map((zipCode) => ({
            value: zipCode.value, //id
            label: zipCode.label.toString(), //address
          }));
          this.setState({ zipCode: zipCodeTemp, isLoading: false }, () =>
            this.getListUserLocation()
          );
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  changeSelectHandler = (selectedAddress) => {
    this.setState({ selectedAddress }, () =>
      /* {} */
      console.log(`Option selected:`, this.state.selectedAddress)
    );
  };

  getListUserLocation = async () => {
    let addressTemp;
    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + Cookies.get("access_token"),
      },
    };

    if (Cookies.get("access_token")) {
      await API.get("/user_location", config)
        .then((res) => {
          if (res.data.data) {
            addressTemp = res.data.data.user_location;
            const primaryAddressId = addressTemp.find(
              (address) => parseInt(address.is_primary) === 1
            );
            this.setState(
              {
                addressList: addressTemp,
                primaryAddressId: primaryAddressId ? primaryAddressId.id : "",
                isLoading: false,
              } /* ,
              () => {
                console.log(this.state.addressList);
              } */
            );
          }
        })
        .catch((error) => {
          Swal.disableLoading();
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            if (error.response) {
              let errmessage = "";
              let keys = Object.keys(error.response.data.errors);
              for (let i = 0; i < keys.length; i++) {
                errmessage +=
                  keys[i] +
                  " " +
                  error.response.data.errors[keys[i]][0] +
                  "<br/>";
              }
              Swal.fire({
                icon: "error",
                html: errmessage,
              });
            }
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    }
  };

  storeUserLocation = () => {
    this.setState({ isLoading: true });

    //Swal loading
    Swal.fire({
      title: "Menambahkan...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    console.log(this.state.name);
    console.log(typeof this.state.name);
    console.log(this.state.hp);
    console.log(typeof this.state.hp);
    console.log(this.state.alias);
    console.log(typeof this.state.alias);
    console.log(this.state.address);
    console.log(typeof this.state.address);
    console.log(this.state.selectedAddress);
    console.log(typeof this.state.selectedAddress);

    if (
      this.state.name !== "" &&
      this.state.hp !== "" &&
      this.state.alias !== "" &&
      this.state.address !== "" &&
      this.state.selectedAddress !== ""
    ) {
      const data = new FormData();
      data.append("name", this.state.name);
      data.append("hp", this.state.hp);
      data.append("alias", this.state.alias);
      data.append("zip_id", this.state.selectedAddress.value);
      data.append("address", this.state.address);
      // data.append("latitude", this.state.latitude);
      // data.append("longitude", this.state.longitude);

      API.post(`/user_location`, data, config)
        .then((res) => {
          this.setState({ isLoading: false });
          if (parseInt(res.data.code) !== 200) {
            console.log(res);
          } else {
            Swal.fire({
              icon: "success",
              text: "Berhasil menambahkan alamat",
            }).then((res) => {
              this.setState(
                {
                  name: "",
                  hp: "",
                  alias: "",
                  address: "",
                  selectedAddress: "",
                },
                () => {
                  this.getListUserLocation();
                  this.formModalHandler("CLOSE", 1);
                }
              );
            });
          }
        })
        .catch((error) => {
          Swal.disableLoading();
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            if (error.response) {
              let errmessage = "";
              let keys = Object.keys(error.response.data.errors);
              for (let i = 0; i < keys.length; i++) {
                errmessage +=
                  keys[i] +
                  " " +
                  error.response.data.errors[keys[i]][0] +
                  "<br/>";
              }
              Swal.fire({
                icon: "error",
                html: errmessage,
              });
            }
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Sorry...",
        text: "Tidak boleh ada yang kosong!",
      });
    }
  };

  updateUserLocation = (addressId) => {
    this.setState({
      isLoading: true,
    });

    //Swal loading
    Swal.fire({
      title: "Mengubah...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    console.log(this.state);

    // if (
    //   this.state.name === "" &&
    //   this.state.hp === "" &&
    //   this.state.alias === "" &&
    //   this.state.address === "" &&
    //   this.state.selectedAddress === ""
    // ) {
    const data = {
      name: this.state.name,
      hp: this.state.hp,
      alias: this.state.alias,
      zip_id: this.state.selectedAddress.value,
      address: this.state.address,
    };

    // const sellerId = Cookies.get("qqq");
    // console.log(this.state.sellerId);

    // const data = new FormData();
    // data.append("name", this.state.name);
    // data.append("hp", this.state.hp);
    // data.append("alias", this.state.alias);
    // data.append("zip_id", this.state.zipId);
    // data.append("address", this.state.address);
    // data.append("is_check_gudang", this.state.isCheckGudang ? 1 : 0);

    API.put(`/user_location/${addressId}`, data, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (parseInt(res.data.code) !== 200) {
        } else {
          this.setState(
            {
              name: "",
              hp: "",
              alias: "",
              address: "",
              selectedAddress: "",
            },
            () => {
              this.getListUserLocation();
              this.formModalHandler("CLOSE", 1);
            }
          );
          Swal.fire({
            icon: "success",
            text: "Alamat telah berhasil diupdate",
            onBeforeOpen: () => {
              Swal.hideLoading();
            },
          });
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
    // } else {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Sorry...",
    //     text: "Tidak boleh ada yang kosong!",
    //   });
    // }
  };

  bePrimaryUserLocation = (addressId) => {
    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    const data = {};

    Swal.fire({
      title: "Anda yakin jadikan alamat ini sebagai alamat utama?",
      icon: "warning",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.value) {
        return API.put(`/user_location/set_primary/${addressId}`, data, config)
          .then((res) => {
            Swal.fire({
              icon: "success",
              text: "Alamat utama anda telah dirubah",
            }).then(() => {
              this.getListUserLocation();
              this.formModalHandler("MODALOPTION", 2);
            });
          })
          .catch((error) => {
            Swal.disableLoading();
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              if (error.response) {
                let errmessage = "";
                let keys = Object.keys(error.response.data.errors);
                for (let i = 0; i < keys.length; i++) {
                  errmessage +=
                    keys[i] +
                    " " +
                    error.response.data.errors[keys[i]][0] +
                    "<br/>";
                }
                Swal.fire({
                  icon: "error",
                  html: errmessage,
                });
              }
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      } else {
        Swal.close();
      }
    });
  };

  deleteUserLocation = (addressId) => {
    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    Swal.fire({
      title: "Anda yakin akan menghapus alamat ini?",
      icon: "warning",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.value) {
        return API.delete(`/user_location/${addressId}`, config)
          .then((res) => {
            console.log(res);
            if (parseInt(res.data.code) !== 200) {
              console.log(res.data);
            } else {
              Swal.fire({
                icon: "success",
                text: "Alamat telah dihapus",
              }).then(() => {
                this.getListUserLocation();
                this.formModalHandler("MODALOPTION", 2);
              });
            }
          })
          .catch((error) => {
            Swal.disableLoading();
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              if (error.response) {
                let errmessage = "";
                let keys = Object.keys(error.response.data.errors);
                for (let i = 0; i < keys.length; i++) {
                  errmessage +=
                    keys[i] +
                    " " +
                    error.response.data.errors[keys[i]][0] +
                    "<br/>";
                }
                Swal.fire({
                  icon: "error",
                  html: errmessage,
                });
              }
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      } else {
        Swal.close();
      }
    });
  };

  setModifiedAddress = (addressId) => {
    const addressTemp = this.state.addressList.find(
      (address) => parseInt(address.id) === parseInt(addressId)
    );
    // console.log(addressTemp);
    // const zipCode = this.state.zipCode.find(
    //   (zipCode) => parseInt(zipCode.value) === parseInt(addressTemp.zip_id)
    // );
    this.setState(
      {
        name: addressTemp.name,
        hp: addressTemp.hp,
        alias: addressTemp.alias,
        address: addressTemp.address,
        selectedAddress: {
          value: addressTemp.zip.id.toString(),
          label:
            addressTemp.zip.province +
            ", " +
            addressTemp.zip.sub_province +
            ", " +
            addressTemp.zip.district +
            ", " +
            addressTemp.zip.sub_district +
            ", " +
            addressTemp.zip.zip,
        },
        // selectedAddress: zipCode
      },
      () => console.log(this.state.selectedAddress)
    );
  };

  unsetModifiedAddress = () => {
    this.setState({
      name: "",
      hp: "",
      alias: "",
      address: "",
      selectedAddress: "",
    });
  };

  //modal function
  formModalHandler = (actionType, no, modifiedItemId) => {
    // console.log(actionType + " " + no);
    const modal = "modal" + no;
    if (actionType === "CLOSE") {
      this.setState(
        {
          [modal]: !this.state[modal],
          actionType,
          modifiedItemId: "",
          isEdit: false,
        },
        () => this.unsetModifiedAddress()
      );
    } else if (actionType === "ADD") {
      this.setState({ [modal]: !this.state[modal], actionType, isEdit: false });
    } else if (actionType === "UPDATE") {
      this.setState(
        {
          [modal]: !this.state[modal],
          actionType,
          modifiedItemId,
          isEdit: true,
        },
        () => this.setModifiedAddress(modifiedItemId)
      );
    } else if (actionType === "MODALOPTION") {
      this.setState({
        [modal]: !this.state[modal],
        actionType,
        isEdit: false,
        modifiedItemId: this.state.modal2 === false ? modifiedItemId : "",
      });
    }
  };

  render() {
    return (
      <>
        <UserLocationFormModal
          {...this.state}
          openState={this.state.modal1}
          // method
          popUpModal={this.formModalHandler}
          loadOptions={this.loadOptions}
          changeHandler={this.changeHandler}
          changeSelectHandler={this.changeSelectHandler}
          storeUserLocation={this.storeUserLocation}
          updateUserLocation={this.updateUserLocation}
        />
        <ModalOption
          showModal={this.state.modal2}
          toggleHandler={this.formModalHandler}
          item_id={this.state.modifiedItemId}
          primaryAddressId={this.state.primaryAddressId}
          modalNumber={2}
          actionTypeName={"MODALOPTION"}
          firstButtonTitle={"Jadikan Alamat Utama"}
          firstButtonClick={this.bePrimaryUserLocation}
          secondButtonTitle={"Hapus"}
          secondButtonClick={this.deleteUserLocation}
        />
        <UserLocationView
          popUpModal={this.formModalHandler}
          addressList={this.state.addressList}
          isLoading={this.state.isLoading}
        />
      </>
    );
  }
}

export default UserLocation;
