import React from 'react'
import { MDBSpinner } from 'mdbreact'

import style from "./CenterLoader.module.css";

export const CenterLoader = () => {
    return (
        <div className={style.center}>
            <MDBSpinner/>
        </div>
    )
}
