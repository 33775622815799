import React, { useRef, useEffect } from "react";
import moment from "moment/min/moment-with-locales";
import style from "./Discussion.module.css";
import { CenterLoader } from "../../_UtilityComponent/Loader/CenterLoader";
import DiscussionReplyForm from "./DiscussionReplyForm";
const formatDate = "ll";

const DiscussionThread = (props) => {
  //ref
  const mesEndRef = useRef(null);
  const dis = props.discussionSingle;

  useEffect(() => {
    scrollToBottomChat();
  }, [dis.single_discussion]);

  const scrollToBottomChat = () => {
    if (mesEndRef && mesEndRef.current) {
      mesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const {
    postDiscussionReply,
    changeHandler,
    id,
    discussionId,
    message,
  } = props;
  if (props.discussionSingle) {
    return (
      <div className={style.discPage__discThread_container}>
        <DiscussionThreadTopic dis={dis} />
        <DiscussionThreadReplies dis={dis.single_discussion} ref={mesEndRef}/>
        <div className={style.discPage__discThread_groupCont}>
          <DiscussionReplyForm
            inputValue={message}
            inputName={"message"}
            onChange={changeHandler}
            onClick={postDiscussionReply}
            prm1={id}
            prm2={discussionId}
          />
        </div>
      </div>
    );
  } else {
    return <CenterLoader />;
  }
};

const DiscussionThreadTopic = (props) => {
  return (
    <div className={style.discPage__discThread_topicCont}>
      <div className={style.discPage__discThread_top}>
        <div className={style.discPage__discThread_name}>
          {`${props.dis.user_data.name}`}
        </div>
        <div className={style.discPage__discThread_time}>
          {` | ${moment(props.dis.update_at).format(formatDate)}`}
        </div>
      </div>
      <div className={style.discPage__discThread_bottom}>
        <div className={style.discPage__discThread_topic}>
          {props.dis.topic}
        </div>
      </div>
    </div>
  );
};

const DiscussionThreadReplies = React.forwardRef((props, ref) => {
  return (
    <>
      {props.dis.length > 0 ? (
        <div className={style.discPage__discThread_answerTitle}>Balasan :</div>
      ) : (
        <div className={style.discPage__discThread_answerTitleNoMsg}>
          Diskusi ini belum mempunyai balasan
        </div>
      )}
      {props.dis.map((msg, idx) => {
        return (
          <div key={idx} className={style.discPage__discThread_answerCont}>
            <div className={style.discPage__discThread_top}>
              <div
                className={
                  msg.is_seller === "0"
                    ? style.discPage__discThread_name
                    : style.discPage__discThread_seller
                }
              >
                {msg.is_seller === "0" ? msg.user_data.name : "Seller"}
              </div>
              <div className={style.discPage__discThread_time}>
                {` | ${moment(msg.update_at).format(formatDate)}`}
              </div>
            </div>
            <div className={style.discPage__discThread_bottom}>
              <div className={style.discPage__discThread_answerMessage}>
                {msg.message}
              </div>
            </div>
          </div>
        );
      })}
      <div ref={ref} />
    </>
  );
});

export default DiscussionThread;
