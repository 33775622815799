import { MDBCol, MDBRow } from "mdbreact";
import styled, { css, keyframes } from "styled-components";
import ImageComponent from "../_UtilityComponent/Image/ImageComponent";

export const ManageSellerListItemProductsStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px 10px;
  color: black;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  :last-child {
    margin-bottom: 0;
  }
  min-width: 0;
  overflow-wrap: break-word;
`;

// list variant detail
export const ManageSellerListItemProductsVariant = styled(
  ManageSellerListItemProductsStyled
)`
  box-shadow: none;
  border-bottom: 1px solid black;
  border-radius: 0;
  padding: 3% 0;
  margin-bottom: 0;
  :last-child {
    border: 0;
    margin-bottom: 10px;
  }
`;

export const ManageSellerListProductsPhotoContainer = styled.div`
  width: 100%;
  max-width: 50px;
  flex: 1 0 50px;
`;

export const ManageSellerListProductsPhoto = styled(ImageComponent).attrs({
  isSquare: true,
  //   roundedFrame: true,
})``;

export const ManageSellerListItemProductsDesc = styled.div`
  flex: 1 0 50%;
  margin-left: 10px;
  min-width: 0; //inside flex
`;

export const ManageSellerListItemProductsTitle = styled.div``;

export const ManageSellerListItemProductsStat = styled.div`
  color: ${(props) => (props.isActive ? "green" : "red")};
`;

export const ManageSellerListProductsIconContainer = styled.div``;

export const ManageSellerAddProductsSubTitle = styled.div`
  font-weight: 600;
  border-bottom: black 1px solid;
  margin: 15% 0 10% 0;
`;

export const ManageSellerAddProductsSubSection = styled.div`
  display: flex;
  font-weight: 400;
  justify-content: space-between;
  align-items: baseline;
  margin: 15% 0 10% 0;
  border-bottom: black 1px solid;
`;

export const ManageSellerProductsPhotoContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  min-width: 0;
`;

export const ManageSellerProductsPhotos = styled(MDBCol)`
  box-sizing: content-box;
  width: 150px;
  flex: none;
`;
