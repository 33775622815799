import { MDBIcon } from "mdbreact";
import { Link, NavLink } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";

export const BottomNavigationContainer = styled.div`
  background-color: #fff;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 100vw;
  display: flex;
  height: 3.5rem;
  text-align: center;
`;

const activeClassName = "active";
export const BottomNavigationDiv = styled(NavLink).attrs({
  activeClassName: activeClassName,
})`
  color: #000;
  width: 100%;
  padding: 10px 0;
  background-color: #fff;
  :hover {
    color: #000;
  }

  &.${activeClassName} {
    :hover {
      color: #fff;
    }
    background-color: ${(props) => props.theme.color.primary};
    color: #fff;
  }
`;

export const BottomNavigationIconDiv = styled.div``;

export const BottomNavigationIcon = styled(MDBIcon)`
  font-size: 1.3rem;
`;

export const BottomNavigationText = styled.div`
  font-size: 0.7rem;
`;
