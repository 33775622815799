import React, { Component } from "react";
import API from "../../utils/API";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

import HomeProductContext from "./HomeProductContext";
import { withRouter } from "react-router-dom";

class HomeProductProvider extends Component {
  state = {
    // state
    listCategory: [],
    listProdFromCat: [],
    // pagination-home
    deviationPageItem: "",
    totalItem: "",
    // product-page
    productDetail: {},
    productDetailPhoto: [],
    productDetailSelect: [],
    showDesc: false,
    // state-select
    selectedDetail: {},
    // state-add-to-cart
    selectedDetailId: "",
    qty: 1,
    // discussion
    discussionList: [],
    discussionListLimit: [],
    discussionSingle: "",
    topic: "",
    message: "",
    discState: "CREATE",
    discIsLoading: false,
    //
    isLoading: false,
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  homeProduct = (sellerId) => {
    this.setState({ isLoading: true });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    API.get(`/home/${sellerId}`, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.data) {
          let listCategory = res.data.data.category.filter((list) => {
            if (list.product.length > 0) {
              return {
                id: list.id,
                seq: list.seq,
                name: list.name,
                img: list.img,
                product: list.product,
              };
            }
          });
          this.setState({
            listCategory: listCategory,
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  homeProductCategory = (sellerId, categoryId, pagePrm = 1, limitPrm) => {
    this.setState({ isLoading: true });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    const page = `?page=${pagePrm}`;
    const limit = limitPrm ? `&limit=${limitPrm}` : ``;
    // const limit = `&limit=${1}`;

    API.get(`/home/${sellerId}/cat/${categoryId}${page}${limit}`, config)
      .then((res) => {
        // console.log(res.data.data);
        if (res.data.data) {
          let listProdFromCat = res.data.data.product.data.map((list) => {
            return {
              id: list.id,
              name: list.name,
              categoryId: list.category_id,
              img: list.thumbnail.link,
              price: list.price_product_single,
            };
          });
          // listProdFromCat = listProdFromCat.filter((list) => {
          //   return list.price !== null && list.price !== undefined;
          // });
          // console.log(listProdFromCat);
          this.setState((prevState) => ({
            listProdFromCat:
              pagePrm !== 1
                ? [...prevState.listProdFromCat, ...listProdFromCat]
                : listProdFromCat,
            isLoading: false,
            totalItem: res.data.data.product.total,
          }));
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  homeProductDetail = (sellerId, groupProductId, discussionPage) => {
    this.setState({ isLoading: true });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    API.get(`/home/${sellerId}/${groupProductId}`, config)
      .then((res) => {
        if (res.data.data) {
          this.setState(
            {
              productDetail: res.data.data.product[0],
            },
            () => {
              if (discussionPage) {
                this.getDiscussionList(this.state.productDetail.id);
              } else {
                this.getDiscussionList(this.state.productDetail.id, 2);
                this.setProductDetailPhoto();
              }
            }
          );
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  setProductDetailPhoto = () => {
    const { productDetail } = this.state;
    let allPhotoTemp;
    let productPhotoTemp;
    let productDetailPhotoTemp;

    productPhotoTemp = productDetail.photo_product.map((item) => ({
      ...item,
      detail_photo: false,
    }));

    productDetailPhotoTemp = productDetail.dtl_product_active.map((item) => ({
      ...item.photo_product[0],
      detail_photo: true,
    }));

    allPhotoTemp = [...productPhotoTemp, ...productDetailPhotoTemp];

    allPhotoTemp = allPhotoTemp.map((item, index) => ({
      ...item,
      itemId: index + 1,
    }));

    this.setState(
      {
        productDetailPhoto: allPhotoTemp,
      },
      () => this.setProductDetailSelect()
    );
  };

  setProductDetailSelect = () => {
    const { productDetail } = this.state;

    const productDetailSelect = productDetail.dtl_product_active.map(
      (item, index) => ({
        ...item,
        text: item.name,
        value: item.id,
        checked: index === 0 ? true : false,
      })
    );

    const tempSelected = productDetailSelect.find(
      (item) => item.checked === true
    );

    // string
    const selectedDetailId = tempSelected.id;

    this.setState({
      productDetailSelect,
      selectedDetail: tempSelected,
      selectedDetailId,
      qty: 1,
      isLoading: false,
    });
  };

  selectDetailHandler = (event) => {
    let tempSelected;
    tempSelected = this.state.productDetailSelect.find(
      (item) => item.id === event[0]
    );
    if (event[0]) {
      this.setState({
        selectedDetailId: event[0],
        qty: 1,
        selectedDetail: tempSelected,
      });
    }
  };

  inpNumberChange = (actionType) => {
    if (actionType === "INC") {
      if (this.state.qty < this.state.selectedDetail.stock) {
        this.setState({ qty: this.state.qty + 1 });
      }
    } else if (actionType === "DEC") {
      if (this.state.qty != 1) {
        this.setState({ qty: this.state.qty - 1 });
      }
    }
  };

  readMore = (state) => {
    this.setState({ showDesc: state });
  };

  addCartFromPage = (sellerId) => {
    this.setState({ isLoading: true });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    const data = new FormData();
    data.append("product_id", this.state.selectedDetailId);
    data.append("qty", this.state.qty);
    // data.append("note", this.state.note);
    data.append("checked", 1);
    data.append("process", "new");

    API.post(`/cart/${sellerId}`, data, config)
      .then((res) => {
        if (parseInt(res.data.code) !== 200) {
          console.log(res);
        } else {
          this.setState({ isLoading: false });
          // this.props.history.push("/cart");
          // Swal.fire({

          // });
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  ///////////////////////////////////////////
  ////Discussion
  getDiscussionList = (groupProductId, limit) => {
    this.setState({
      discIsLoading: true,
      discussionList: [],
      discussionListLimit: [],
    });
    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };
    const url = limit
      ? `${groupProductId}?limit=${limit}`
      : `${groupProductId}`;
    API.get(`/discussion/topic/${url}`, config)
      .then((res) => {
        if (parseInt(res.data.code) !== 200) {
          console.log(res);
        } else {
          // if (limit) {
          //   this.setState(() => {
          //     const discussionList = res.data.data.slice(0, limit);
          //     return { discussionList };
          //   });
          // } else {
          //   this.setState({ discussionList: res.data.data.discussion });
          // }
          this.setState(() => {
            const discussion = res.data.data.discussion;
            discussion.map((item) => {
              return {
                ...item,
                dtl_discussion: item.DtlDiscussion,
                DtlDiscussion: "",
              };
            });
            return { discussionList: !limit ? res.data.data.discussion : "" };
          });
          this.setState({
            discussionListLimit: limit ? res.data.data.discussion : "",
            discussionSingle: "",
            discIsLoading: false,
            discState: res.data.data.discussion.length > 0 ? "LIST" : "CREATE",
          });
        }
      })
      .catch((error) => {
        this.setState({ discIsLoading: false });
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  getSingleDiscussion = (groupProductId, discussionId) => {
    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };
    API.get(`/discussion/message/${groupProductId}/${discussionId}`, config)
      .then((res) => {
        if (parseInt(res.data.code) !== 200) {
          console.log(res);
        } else {
          this.setState(() => {
            res.data.data.discussion.single_discussion.reverse();
            return {
              discussionSingle: res.data.data.discussion,
            };
          });
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  postDiscussionTopic = (e, groupProductId) => {
    e.preventDefault();
    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    const data = new FormData();
    data.append("topic", this.state.topic);

    API.post(`/discussion/topic/${groupProductId}`, data, config)
      .then((res) => {
        if (parseInt(res.data.code) !== 200) {
          console.log(res);
        } else {
          this.setState({ topic: "" });
          this.props.history.push(
            `/product/${groupProductId}/discussion/${res.data.data.discussion.id}`
          );
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  postDiscussionReply = (e, groupProductId, discussionId) => {
    e.preventDefault();
    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    const data = new FormData();
    data.append("discussion_id", discussionId);
    data.append("message", this.state.message);

    API.post(`/discussion/message/${groupProductId}`, data, config)
      .then((res) => {
        if (parseInt(res.data.code) !== 200) {
          console.log(res);
        } else {
          this.setState(() => {
            res.data.data.discussion.single_discussion.reverse();
            return {
              discussionSingle: res.data.data.discussion,
              message: "",
            };
          });
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  deleteDiscussionTopic = (discussionId) => {
    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    API.delete(`/discussion/topic/${discussionId}`, config)
      .then((res) => {
        if (parseInt(res.data.code) !== 200) {
          console.log(res);
        } else {
          // this.setState({ discussionList: res.data.data.discussion });
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  deleteDiscussionReply = (messageId) => {
    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    API.delete(`/discussion/message/${messageId}`, config)
      .then((res) => {
        if (parseInt(res.data.code) !== 200) {
          console.log(res);
        } else {
          // this.setState({ discussionList: res.data.data.discussion });
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  discStateChangeHandler = (actionType) => {
    switch (actionType) {
      case "CREATE":
        this.setState({ discState: "CREATE" });
        break;
      case "LIST":
        this.setState({ discState: "LIST" });
        break;
    }
  };

  render() {
    return (
      <HomeProductContext.Provider
        value={{
          ...this.state,
          changeHandler: this.changeHandler,
          homeProduct: this.homeProduct,
          homeProductCategory: this.homeProductCategory,
          homeProductDetail: this.homeProductDetail,
          // method
          selectDetailHandler: this.selectDetailHandler,
          inpNumberChange: this.inpNumberChange,
          addCartFromPage: this.addCartFromPage,
          readMore: this.readMore,
          getDiscussionList: this.getDiscussionList,
          getSingleDiscussion: this.getSingleDiscussion,
          postDiscussionTopic: this.postDiscussionTopic,
          postDiscussionReply: this.postDiscussionReply,
          discStateChangeHandler: this.discStateChangeHandler,
        }}
      >
        {this.props.children}
      </HomeProductContext.Provider>
    );
  }
}

export default withRouter(HomeProductProvider);
