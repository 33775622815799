import { MDBBtn, MDBContainer, MDBInput, MDBSelect } from "mdbreact";
import React from "react";
import { ManageSellerAddContainer } from "./ManageSellerLocationCategory.styles";

export const ManageSellerBankAccountAdd = (props) => {
  return (
    // <ManageSellerAddContainer>
    <MDBContainer>
      <form
        className="needs-validation"
        onSubmit={(e) => {
          props.submitHandler(e, props.sellerId);
        }}
        noValidate
      >
        {props.bankList.length > 0 ? (
          <MDBSelect
            className="w-100"
            options={props.bankList}
            selected="Pilih Bank"
            label="Pilih Bank"
            selectAllValue={props.selectedItemId}
            getValue={(e) => props.bankIdSelectorHandler(e)}
          />
        ) : (
          ""
        )}
        <MDBInput
          value={props.accountNumber}
          onChange={e => props.setAccountNumber(e.target.value)}
          type="number"
          className="form-control"
          label="Nomor Rekening"
          required
        >
          <div className="invalid-feedback">Nomor rekening harus diisi!</div>
        </MDBInput>
        <MDBInput
          value={props.alias}
          onChange={e => props.setAlias(e.target.value)}
          type="text"
          className="form-control"
          label="Nama Pemilik Rekening"
          required
        >
          <div className="invalid-feedback">Nama pemilik rekening harus diisi!</div>
        </MDBInput>
        <MDBBtn
          className="w-100 mr-0 ml-0 mt-2"
          color="deep-orange"
          type="submit"
        >
          Simpan
        </MDBBtn>
      </form>
    </MDBContainer>
    // </ManageSellerAddContainer>
  );
};
