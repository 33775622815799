import React, { Component } from "react";
import AboutViewMain from "../components/About/AboutViewMain";
import SellerPrmContext from "../../SellerPrmContext";

export default class About extends Component {
  render() {
    return (
      <SellerPrmContext.Consumer>
        {(sellerPrm) => <AboutViewMain sellerPrm={sellerPrm} />}
      </SellerPrmContext.Consumer>
    );
  }
}
