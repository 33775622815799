import React, { Component } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBCard,
} from "mdbreact";
import { Text } from "../../context/Language";
import { Link } from "react-router-dom";
import { StyledButtonTheming } from "../_UtilityComponent/Button/StyledButton";

export default class ProfileViewUser extends Component {
  componentDidMount() {}

  render() {
    let { isEdit, isEditChange, updateUserProfile } = this.props;
    return (
      <form
        className="needs-validation"
        onSubmit={this.props.updateUserProfile}
        noValidate
      >
        <MDBContainer>
          <MDBRow>
            <MDBCol>
              <MDBInput
                value={this.props.data.name}
                onChange={this.props.changeHandler}
                type="text"
                className="form-control"
                id="data"
                name="name"
                label={<Text pid="profileUser" tid="name" />}
                required
                disabled={isEdit}
              />
              <MDBInput
                value={this.props.data.email}
                onChange={this.props.changeHandler}
                type="email"
                className="form-control"
                id="data"
                name="email"
                label={<Text pid="profileUser" tid="email" />}
                required
                disabled={isEdit}
              />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol hidden={isEdit ? true : false}>
              <StyledButtonTheming className="w-100" type="submit">
                {<Text pid="profileUser" tid="submitBtn" />}
              </StyledButtonTheming>
            </MDBCol>
            <MDBCol hidden={isEdit ? false : true}>
              <StyledButtonTheming
                className="w-100"
                onClick={() => isEditChange()}
              >
                {<Text pid="profileUser" tid="editBtn" />}
              </StyledButtonTheming>
            </MDBCol>
            <MDBCol>
              <StyledButtonTheming
                className="w-100"
                onClick={() => this.props.popupModal("1")}
              >
                {<Text pid="profileUser" tid="changePassBtn" />}
              </StyledButtonTheming>
            </MDBCol>
            <MDBCol>
              <Link to="/profile/address">
                <StyledButtonTheming className="w-100">
                  {<Text pid="profileUser" tid="addressListBtn" />}
                </StyledButtonTheming>
              </Link>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <Link to="/transaction-status">
                <StyledButtonTheming className="w-100">
                  {<Text pid="profileUser" tid="transactionStatusBtn" />}
                </StyledButtonTheming>
              </Link>
            </MDBCol>
          </MDBRow>
          {/* <MDBRow>
            <MDBCol>
              <Link to="/payment-status">
                <StyledButtonTheming className="w-100">
                  {<Text pid="profileUser" tid="paymentStatusBtn" />}
                </StyledButtonTheming>
              </Link>
            </MDBCol>
          </MDBRow> */}
        </MDBContainer>
      </form>
    );
  }
}
