import React, { Component } from "react";
import moment from "moment/min/moment-with-locales";
import style from "./Discussion.module.css";
import { Link, withRouter } from "react-router-dom";
import { CenterLoader } from "../../_UtilityComponent/Loader/CenterLoader";
const formatDate = "ll";

class DiscussionList extends Component {
  render() {
    const { id } = this.props.match.params;
    const { discIsLoading, discussionPage, discussionList } = this.props;
    if (!discIsLoading) {
      if (discussionList.length > 0) {
        return (
          <div className={style.discPage__discList_mainCont}>
            {discussionList.map((dis, disIdx) => {
              return (
                <div
                  className={style.discPage__discList_discItemCont}
                  key={dis.id}
                  onClick={() => {
                    this.props.history.push(
                      `/product/${dis.group_product_id}/discussion/${dis.id}`
                    );
                  }}
                >
                  <div className={style.discPage__discList_top}>
                    <div className={style.discPage__discList_name}>
                      {`${dis.user_data.name}`}
                    </div>
                    <div className={style.discPage__discList_time}>
                      {` | ${moment(dis.update_at).format(formatDate)}`}
                    </div>
                  </div>
                  <div className={style.discPage__discList_bottom}>
                    <div className={style.discPage__discList_topic}>
                      {dis.topic}
                    </div>
                  </div>
                  <div className={style.discPage__discList_replyContainer}>
                    {discussionPage === "PRODUCT"
                      ? dis.dtl_discussion_limit.map((rep, replyIdx) => {
                          return (
                            <div
                              className={style.discPage__discList_reply}
                              key={rep.id}
                            >
                              <div className={style.discPage__discList_top}>
                                {rep.is_seller === "0" ? (
                                  <div
                                    className={style.discPage__discList_name}
                                  >
                                    {rep.user_data.name}
                                  </div>
                                ) : (
                                  <div
                                    className={style.discPage__discList_seller}
                                  >
                                    {" "}
                                    Seller{" "}
                                  </div>
                                )}
                                <div className={style.discPage__discList_time}>
                                  {` | ${moment(rep.update_at).format(
                                    formatDate
                                  )}`}
                                </div>
                              </div>
                              <div className={style.discPage__discList_bottom}>
                                <div
                                  className={style.discPage__discList_message}
                                >
                                  {rep.message}
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : ""}
                    {discussionPage === "DISCUSSION"
                      ? dis.dtl_discussion.map((rep, replyIdx) => {
                          return (
                            <div
                              className={style.discPage__discList_reply}
                              key={rep.id}
                            >
                              <div className={style.discPage__discList_top}>
                                {rep.is_seller === "0" ? (
                                  <div
                                    className={style.discPage__discList_name}
                                  >
                                    {rep.user_data.name}
                                  </div>
                                ) : (
                                  <div
                                    className={style.discPage__discList_seller}
                                  >
                                    {" "}
                                    Seller{" "}
                                  </div>
                                )}
                                <div className={style.discPage__discList_time}>
                                  {` | ${moment(rep.update_at).format(
                                    formatDate
                                  )}`}
                                </div>
                              </div>
                              <div className={style.discPage__discList_bottom}>
                                <div className={style.discPage__discList_topic}>
                                  {rep.message}
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </div>
              );
            })}
            {discussionPage === "PRODUCT" ? (
              <Link to={`/product/${id}/discussion/`}>
                <div className={style.discPage__viewAll}>
                  {" "}
                  Lihat Semua Diskusi{" "}
                </div>
              </Link>
            ) : (
              ""
            )}
          </div>
        );
      } else {
        return (
          <div>
            <div>Produk ini masih belum memiliki diskusi.</div>
            {discussionPage === "PRODUCT" ? (
              <Link to={`/product/${id}/discussion/`}>
                <div className={style.discPage__viewAll}>
                  Buka pertanyaan baru untuk produk ini.
                </div>
              </Link>
            ) : (
              ""
            )}
          </div>
        );
      }
    } else {
      return <CenterLoader />;
    }
  }
}

export default withRouter(DiscussionList);
