import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import API from "../../../utils/API";
import Cookies from "js-cookie";
import { ManageSellerBankAccountAdd } from "./ManageSellerBankAccountAdd";
import ModalOption from "../_UtilityComponent/ModalOption/ModalOption";
import { ManageSellerBankAccountEdit } from "./ManageSellerBankAccountEdit";
import { PageContainer } from "../LandingView/Landing.styles";
import { ManageSellerListItems } from "./ManageSellerListItems";
import { CenterLoader } from "../_UtilityComponent/Loader/CenterLoader";
import Swal from "sweetalert2";

export const ManageSellerBankAccount = () => {
  let params = useParams();
  let history = useHistory();
  let page = params.page;
  let s_id = params.s_id;
  let process = params.s_name; //BUAT PROCESS
  // let process = params.process;

  let mounted = false;

  //REQ & ERR
  const [requests, setRequests] = useState(0); //render 2x dari atas
  const [errors, setErrors] = useState(0);
  const requestChecker = (condition) => {
    if (condition === "FETCH") {
      setRequests(requests + 1);
    } else if (condition === "DONE") {
      setRequests(requests - 1);
    } else if (condition === "ERROR") {
      setRequests(requests - 1);
      setErrors(errors + 1);
    }
  };

  const createConfig = (token) => {
    let config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
    return config;
  };

  //  STATE
  const [accountList, setAccountList] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [actionType, setActionType] = useState("CLOSE");
  const [modalOption, setModalOption] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(undefined);
  const [primaryAccountId, setPrimaryAccountId] = useState(undefined);
  // INPUT STATE
  const [alias, setAlias] = useState("");
  const [selectedBankId, setSelectedBankId] = useState(undefined);
  const [accountNumber, setAccountNumber] = useState(undefined); //no_rek

  const getSellerBankAccount = (sellerId) => {
    requestChecker("FETCH");
    API.get(`bank/${sellerId}`, createConfig(Cookies.get("access_token")))
      .then((res) => {
        if (mounted) {
          const primaryAccountIdTemp = res.data.data.seller_bank.find(
            (item) => {
              return item.is_primary === "1";
            }
          );
          setPrimaryAccountId(primaryAccountIdTemp.id);
          setAccountList(res.data.data.seller_bank);
          requestChecker("DONE");
        }
      })
      .catch((err) => {
        requestChecker("ERROR");
        console.log(err);
      });
  };

  const getBankPrmList = () => {
    requestChecker("FETCH");
    API.get(`bank/list`, createConfig(Cookies.get("access_token")))
      .then((res) => {
        if (mounted) {
          const bankListTemp = res.data.data.bank.map((item) => ({
            value: item.id,
            text: item.name,
          }));
          setBankList(bankListTemp);
          requestChecker("DONE");
        }
      })
      .catch((err) => {
        requestChecker("ERROR");
        console.log(err);
      });
  };

  const submitBankAccount = (e, sellerId) => {
    e.preventDefault();

    const data = {
      alias: alias,
      bank_id: selectedBankId,
      no_rek: accountNumber,
    };

    requestChecker("FETCH");
    API.post(
      `bank/${sellerId}`,
      data,
      createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        Swal.fire({
          title: "Berhasil",
          icon: "success",
          showConfirmButton: true,
        }).then(() => {
          history.push(`/seller_dashboard/manage_bank_account/${sellerId}`);
          setAlias("");
          setSelectedBankId(undefined);
          setAccountNumber(undefined);
        });
        requestChecker("DONE");
      })
      .catch((err) => {
        requestChecker("ERROR");
        console.log(err);
      });
  };

  const bePrimarySellerBank = (sellerId, selectedBankId) => {
    const data = {};

    Swal.fire({
      title: "Anda yakin jadikan ini sebagai akun utama?",
      icon: "warning",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.value) {
        return API.put(
          `/bank/set_primary/${sellerId}/${selectedBankId}`,
          data,
          createConfig(Cookies.get("access_token"))
        )
          .then((res) => {
            Swal.fire({
              title: "Berhasil",
              text: "Akun bank utama telah dirubah",
              type: "success",
              showConfirmButton: true,
              confirmButtonText: "Tutup",
            }).then(() => {
              getSellerBankAccount(sellerId);
              window.location.reload();
            });
          })
          .catch((error) => {
            Swal.disableLoading();
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              if (error.response) {
                let errmessage = "";
                let keys = Object.keys(error.response.data.errors);
                for (let i = 0; i < keys.length; i++) {
                  errmessage +=
                    keys[i] +
                    " " +
                    error.response.data.errors[keys[i]][0] +
                    "<br/>";
                }
                Swal.fire({
                  icon: "error",
                  html: errmessage,
                });
              }
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      } else {
        Swal.close();
      }
    });
  };

  const deleteSellerBankAccount = (sellerId, selectedBankId) => {
    Swal.fire({
      title: "Anda yakin akan menghapus akun bank ini?",
      icon: "warning",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.value) {
        return API.delete(
          `/bank/${sellerId}/${selectedBankId}`,
          createConfig(Cookies.get("access_token"))
        )
          .then((res) => {
            if (parseInt(res.data.code) !== 200) {
              console.log(res.data);
            } else {
              Swal.fire({
                title: "Berhasil",
                text: "Akun bank telah berhasil dihapus",
                type: "success",
                showConfirmButton: true,
                confirmButtonText: "Tutup",
              }).then(() => {
                getSellerBankAccount(sellerId);
                window.location.reload();
              });
            }
          })
          .catch((error) => {
            Swal.disableLoading();
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              if (error.response) {
                let errmessage = "";
                let keys = Object.keys(error.response.data.errors);
                for (let i = 0; i < keys.length; i++) {
                  errmessage +=
                    keys[i] +
                    " " +
                    error.response.data.errors[keys[i]][0] +
                    "<br/>";
                }
                Swal.fire({
                  icon: "error",
                  html: errmessage,
                });
              }
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      } else {
        Swal.close();
      }
    });
  };

  useEffect(() => {
    mounted = true;
    if (page === "manage_bank_account" && s_id) {
      getSellerBankAccount(s_id);
      getBankPrmList();
    }
    return () => {
      setAccountList([]);
      mounted = false;
    };
  }, [page, s_id]);

  //METHOD
  const modalHandler = (actionType, itemId) => {
    if (actionType === "CLOSE") {
      setActionType("CLOSE");
      setSelectedItemId(undefined);
      setModalOption(false);
    } else if (actionType === "MODALOPTION") {
      setActionType("MODALOPTION");
      setSelectedItemId(itemId);
      setModalOption(true);
    }
  };

  const bankIdSelectorHandler = (event) => {
    setSelectedBankId(event[0]);
  };

  if (requests <= 0 && errors <= 0) {
    if (process === "add") {
      return (
        <ManageSellerBankAccountAdd
          sellerId={s_id}
          bankList={bankList}
          itemId={selectedBankId}
          primaryId={primaryAccountId}
          setAccountNumber={setAccountNumber}
          setAlias={setAlias}
          setAccountNumber={setAccountNumber}
          submitHandler={submitBankAccount}
          alias={alias}
          accountNumber={accountNumber}
          bankIdSelectorHandler={bankIdSelectorHandler}
        />
      );
    } else {
      return (
        <>
          <ModalOption
            showModal={modalOption}
            toggleHandler={modalHandler}
            itemId={selectedItemId}
            primaryId={primaryAccountId}
            sellerId={s_id}
            actionTypeName={"CLOSE"}
            firstButtonTitle={"Jadikan Sebagai Rekening Utama"}
            firstButtonClick={bePrimarySellerBank}
            secondButtonTitle={"Hapus Rekening"}
            secondButtonClick={deleteSellerBankAccount}
          />
          <PageContainer>
            <ManageSellerListItems
              itemDashboard={accountList}
              page={page}
              modalHandler={modalHandler}
              history={history}
            />
          </PageContainer>
        </>
      );
    }
  } else if (requests <= 0 && errors > 0) {
    return <div>Maaf, sepertinya sedang ada kendala silahkan coba lagi</div>;
  } else {
    return <CenterLoader />;
  }
};
