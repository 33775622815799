import React from "react";
import { MDBRow, MDBCol, MDBCard, MDBContainer } from "mdbreact";
import "../components/LandingView/Landing.css";
import { Text } from "../context/Language";
import { withRouter } from "react-router-dom";
import landing_img from "../../assets/landing_img_2.png";
import API from "../../utils/API";

class Landing extends React.Component {
  _isMounted = false;
  state = {
    search: "",
    store_list: undefined,
  };

  componentDidMount() {
    this._isMounted = true;
    this.getStoreList();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getStoreList = () => {
    API.get(`get_all_store`)
      .then((res) => {
        // console.log(res.data.data.stores);
        if (this._isMounted) {
          this.setState({ store_list: res.data.data.stores });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <div>
        <MDBRow
          style={{
            paddingTop: "5rem",
            height: "100vh",
            backgroundColor: "#804199",
            margin: "0",
          }}
        >
          <MDBCol middle sm="12" md="12" lg="6" style={{ margin: "0" }}>
            <MDBRow center>
              <img src={landing_img} style={{ maxHeight: "550px" }} />
            </MDBRow>
          </MDBCol>
          <MDBCol sm="12" md="12" lg="6" style={{ backgroundColor: "#804199" }}>
            <MDBContainer style={{ marginTop: "3rem", paddingTop: "1rem" }}>
              <MDBRow className="d-flex w-100 justify-content-center align-items-center">
                <div
                  className="input-group"
                  style={{ paddingLeft: "3%", width: "70%" }}
                >
                  <div
                    className="input-group-prepend"
                    style={{ maxHeight: "30px" }}
                  >
                    <span className="input-group-text" id="basic-addon">
                      <i className="fa fa-search prefix"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    value={this.state.search}
                    onChange={(e) => {
                      this.setState({ search: e.target.value });
                    }}
                    className="form-control form-control-lg"
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="basic-addon"
                    style={{ maxHeight: "30px" }}
                  />
                </div>
                <div className="input-group-prepend">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => {
                      this.props.history.push(`/search/${this.state.search}`);
                    }}
                    style={{
                      maxHeight: "30px",
                      margin: "0",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    SEARCH
                  </button>
                </div>
              </MDBRow>
              <div
                className="overflow-auto"
                style={{ maxHeight: "400px", marginTop: "1rem" }}
              >
                <MDBRow style={{ margin: "0", paddingLeft: "20px" }}>
                  {this.state.store_list !== undefined
                    ? this.state.store_list.map((data, index) => {
                        return (
                          <MDBCol
                            lg="3"
                            md="3"
                            sm="4"
                            key={index}
                            style={{
                              marginBottom: "1rem",
                              paddingLeft: "1px",
                            }}
                          >
                            <a
                              style={{ width: "100%", color: "black" }}
                              href={`http://${data.subdomain}`}
                            >
                              <MDBCard
                                style={{
                                  maxWidth: "120px",
                                  height: "150px",
                                  background: "transparent",
                                }}
                              >
                                <MDBContainer>
                                  <MDBRow center middle>
                                    <MDBContainer
                                      className="d-flex w-100 justify-content-center align-items-center"
                                      style={{ minHeight: "90px" }}
                                    >
                                      <img
                                        className="img-thumbnail"
                                        src={data.logo}
                                        style={{ maxWidth: "80px" }}
                                      />
                                    </MDBContainer>
                                  </MDBRow>
                                  <MDBRow>
                                    <span className="product_name">
                                      {data.name_web}
                                    </span>
                                  </MDBRow>
                                </MDBContainer>
                              </MDBCard>
                            </a>
                          </MDBCol>
                        );
                      })
                    : ""}
                </MDBRow>
              </div>
            </MDBContainer>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default withRouter(Landing);
