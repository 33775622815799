import React, { Component } from "react";
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBCollapse,
  MDBContainer,
  MDBNavbarToggler,
  MDBIcon,
  MDBCol,
  MDBRow,
} from "mdbreact";
import Cookies from "js-cookie";

import { withRouter } from "react-router-dom";
import "./css/Header.css";
import { Text } from "../context/Language";
import HeaderProducts from "../components/HeaderAfterLogin/HeaderProducts";
import { HeaderMenuSeller } from "../components/HeaderAfterLogin/HeaderMenuSeller";
import { HeaderMenuUser } from "../components/HeaderAfterLogin/HeaderMenuUser";
// import styles from "./css/Header.module.css";

class Header extends Component {
  constructor() {
    super();
    this.state = {
      collapseID: "",
    };
    this.headerHeight = React.createRef();
  }

  componentDidMount() {
    let headerHeight = this.headerHeight.current.offsetHeight;
    this.props.checkHeaderHeight(headerHeight);
  }

  toggleCollapse = (collapseID) => () =>
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));

  render() {
    const logoContainerStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
    // console.log(this.props)
    const nav_color = this.props.seller_style.color_primary;
    const icon_color = "#006738";
    const text_color = "black";

    return (
      <MDBNavbar
        color={nav_color}
        light
        expand="lg"
        fixed="top"
        scrolling
        style={{ backgroundColor: nav_color, padding: "0" }}
      >
        <span
          style={{ width: "100%", backgroundColor: nav_color }}
          ref={this.headerHeight}
        >
          <MDBContainer>
            <MDBNavLink className={`${text_color}-text`} to="/">
            <MDBRow style={{ width: "450px" }}>
                <MDBCol style={logoContainerStyle} size="4">
                  <img
                    src={this.props.subDomainData.logo}
                    className="img-fluid"
                    style={{ maxHeight: "4rem" }}
                    alt="aligment"
                  />
                </MDBCol>
                <MDBCol
                  style={{
                    fontFamily: "Alegreya",
                    fontWeight: "500",
                    fontSize: "25px",
                    color: icon_color,
                  }}
                  middle
                >
                  {this.props.subDomainData.name_web}
                </MDBCol>
              </MDBRow>
            </MDBNavLink>
            <MDBNavbarToggler
              onClick={this.toggleCollapse("navbarCollapse")}
              style={{ color: icon_color }}
            />
            <MDBCollapse
              id="navbarCollapse"
              isOpen={this.state.collapseID}
              navbar
            >
              <MDBNavbarNav right>
                <MDBNavItem>
                  {/* <HeaderProducts /> */}
                  {/* <MDBNavLink className="white-text" to="/" hash="#product">
                Products
              </MDBNavLink> */}
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink className={`${text_color}-text`} to="/about">
                    <Text pid="t1_header" tid="aboutBtn" />
                  </MDBNavLink>
                </MDBNavItem>
                {this.props.isSeller ? (
                  Cookies.get("access_token") ? (
                    <>
                      <HeaderMenuSeller text_color={text_color} />
                      {/* <MDBNavItem>
                    <MDBNavLink
                      className="white-text align-self-center col-example text-left"
                      to="/seller_dashboard"
                    >
                      Seller Menu
                    </MDBNavLink>
                  </MDBNavItem> */}
                    </>
                  ) : (
                    ""
                  )
                ) : (
                  <div className="icon-cart">
                    <MDBNavItem>
                      <MDBNavLink
                        className={`${text_color}-text align-self-center col-example text-left`}
                        to="/cart"
                      >
                        <MDBIcon
                          icon="shopping-cart"
                          style={{ color: icon_color }}
                        />
                        <Text pid="t1_header" tid="cartBtn" />
                      </MDBNavLink>
                    </MDBNavItem>
                  </div>
                )}
                {Cookies.get("access_token") ? (
                  <HeaderMenuUser
                    logged={true}
                    logout={this.props.logout}
                    text_color={text_color}
                    seller_style={this.props.seller_style}
                  />
                ) : (
                  <HeaderMenuUser
                    logged={false}
                    text_color={text_color}
                    seller_style={this.props.seller_style}
                  />
                )}
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBContainer>
        </span>
      </MDBNavbar>
    );
  }
}

export default withRouter(Header);
