import { useState, useEffect, useContext } from "react";
import SellerPrmContext from "../../SellerPrmContext";
function useTheme(
  defaultTheme = {
    template: {
      id: 5,
    },
    color: {
      primary: null,
      secondary: null,
      button: null,
      type: null, //dark/light
    },
    font: {
      primary: null, //font_style_1
      secondary: null, //font_style_2
      light: null, //font_light
      dark: null, //font_dark
    },
  }
) {
  const sellerPrmContext = useContext(SellerPrmContext);
  const [theme, _setTheme] = useState(getThemeFromDB);

  function getThemeFromDB() {
    const templateId = sellerPrmContext.subDomainData.template_id;
    const sellerStyle = sellerPrmContext.subDomainData.seller_style;
    let userTheme;
    if (sellerStyle) {
      userTheme = {
        template: {
          id: templateId,
        },
        color: {
          primary: sellerStyle.color_primary,
          secondary: sellerStyle.color_secondary,
          button: sellerStyle.color_btn,
          type: sellerStyle.color_type,
        },
        font: {
          primary: sellerStyle.font_style_1,
          secondary: sellerStyle.font_style_2,
          light: sellerStyle.font_light,
          dark: sellerStyle.font_dark,
        },
      };
    }
    return userTheme ? userTheme : defaultTheme;
  }

  useEffect(() => {
    _setTheme(getThemeFromDB);
  }, [sellerPrmContext]);

  return {
    ...theme,
    setTheme: ({ setTheme, ...theme }) => _setTheme(theme),
  };
}
export default useTheme;
