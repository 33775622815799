import { MDBCard, MDBCardBody, MDBCol, MDBRow, MDBIcon } from "mdbreact";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import '../../../assets/css/fonts.css'

export default class TransactionInvoiceView extends Component {
  state = {
    show: true,
  };

  handleShowDetail = () => {
    this.setState({ show: !this.state.show });
  };

  formatDate = (string) => {
    var options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(string).toLocaleDateString("ID", options);
  };

  formatCurrency = (country, currency, text) => {
    return new Intl.NumberFormat(country, {
      style: "currency",
      currency: currency,
    }).format(text);
  };

  handelTracking = (courier, tracking_code) => {
    const { popupModal, getTrackingStatus } = this.props;
    getTrackingStatus(courier, tracking_code);
    popupModal("SHOW", "1");
  };

  render() {
    const { data, seller_style, updateInvoiceStatus } = this.props;
    const status = {
      1: "Menunggu Konfirmasi",
      2: "Menunggu Pesanan Diambil",
      3: "Pesanan dalam Pengiriman",
      4: "Meminta Konfirmasi Pesanan Selesai",
      5: "Pesanan Selesai",
      98: "Meminta Pembatalan Pesanan",
      99: "Pesanan Dibatalkan",
    };

    return (
      <MDBCard className="w-100" style={{ marginBottom: "1rem", fontFamily:'Lato Regular' }}>
        <MDBCardBody>
          <MDBRow>
            <MDBCol middle style={{ fontSize: "15px" }}>
              <table className="w-100">
                <tbody>
                  <tr>
                    <td>No. Invoice</td>
                    <td>:</td>
                    <td>{data.id}</td>
                  </tr>
                  <tr>
                    <td>Tanggal Pembelian</td>
                    <td>:</td>
                    <td>{this.formatDate(data.payment.created_at)}</td>
                  </tr>
                  <tr>
                    <td>Status Pesanan</td>
                    <td>:</td>
                    <td>{status[parseInt(data.status)]}</td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <hr style={{ padding: "0", margin: "0" }} />
                    </td>
                  </tr>
                  <tr>
                    <td>Pengiriman</td>
                    <td>:</td>
                    <td>{`${data.courier_name} (${data.courier_service})`}</td>
                  </tr>
                  <tr>
                    <td>Total Bayar</td>
                    <td>:</td>
                    <td>
                      {this.formatCurrency(
                        "ID",
                        "IDR",
                        data.payment.payment_total
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </MDBCol>
          </MDBRow>
          <MDBRow style={{ margin: "8px" }}>
            <MDBRow>
              <button
                style={{
                  fontSize: "15px",
                  backgroundColor: seller_style.color_btn,
                  color: "white",
                  border: `1px solid ${seller_style.color_btn}`,
                  borderRadius: "4px",
                  marginLeft: "5px",
                }}
                onClick={() => this.handleShowDetail()}
              >
                <MDBIcon far icon="eye" />
                {` Lihat Detail`}
              </button>
              <Link to={`/print/invoice/${data.id}`} target="_blank">
                <button
                  style={{
                    fontSize: "15px",
                    backgroundColor: seller_style.color_btn,
                    color: "white",
                    border: `1px solid ${seller_style.color_btn}`,
                    borderRadius: "4px",
                    marginLeft: "5px",
                  }}
                >
                  <MDBIcon icon="print" />
                  {` Cetak`}
                </button>
              </Link>
              {data.status === "1" ? (
                <button
                  style={{
                    fontSize: "15px",
                    backgroundColor: "red",
                    color: "white",
                    border: `1px solid red`,
                    borderRadius: "4px",
                    marginLeft: "5px",
                  }}
                  onClick={() => updateInvoiceStatus(data.id, 98)}
                >
                  <MDBIcon icon="trash-alt" />
                  {` Request Cancel`}
                </button>
              ) : (
                ""
              )}
              {data.status === "3" ? (
                <button
                  style={{
                    fontSize: "15px",
                    backgroundColor: "green",
                    color: "white",
                    border: `1px solid green`,
                    borderRadius: "4px",
                    marginLeft: "5px",
                  }}
                  onClick={() =>
                    this.handelTracking(data.courier_name, data.tracking_code)
                  }
                >
                  <MDBIcon icon="map" />
                  {` Lacak`}
                </button>
              ) : (
                ""
              )}
              {data.status === "4" ? (
                <button
                  style={{
                    fontSize: "15px",
                    backgroundColor: "green",
                    color: "white",
                    border: `1px solid green`,
                    borderRadius: "4px",
                    marginLeft: "5px",
                  }}
                  onClick={() => updateInvoiceStatus(data.id, 5)}
                >
                  <MDBIcon icon="thumbs-up" />
                  {` Selesai`}
                </button>
              ) : (
                ""
              )}
            </MDBRow>
            <div
              className="overflow-auto"
              hidden={this.state.show}
              style={{
                width: "100%",
                height: "180px",
                marginTop: "1rem",
              }}
            >
              {data.dtl_invoice.map((item, index) => {
                let price =
                  parseInt(item.product_quantity) *
                  parseInt(item.product_price);
                let isCourier =
                  item.product_id.toString().split("-")[0] === "COURIER";
                if (!isCourier) {
                  return (
                    <div
                      key={index}
                      style={{ marginBottom: "1rem", display: "flex" }}
                    >
                      <img
                        className="img-fluid img-thumbnail z-depth-1"
                        src={item.product_image}
                        style={{ maxHeight: "4rem" }}
                      />
                      <div style={{ marginLeft: "5px", fontSize: "15px" }}>
                        <span>{`${item.product_name} ${item.product_weight} gr`}</span>
                        <br />
                        <span>
                          {`${new Intl.NumberFormat("ID", {
                            style: "currency",
                            currency: "IDR",
                          }).format(price)} (${item.product_quantity} item)`}
                        </span>
                        <br />
                        <span>{`Note : ${item.product_note}`}</span>
                      </div>
                    </div>
                  );
                } else {
                  return "";
                }
              })}
            </div>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    );
  }
}
