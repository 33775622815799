import React, { Component } from "react";
import ProfileUserMain from "../components/ProfileView/ProfileUserMain";
import SellerPrmContext from "../../SellerPrmContext";

export default class ProfileUser extends Component {
  render() {
    return (
      <SellerPrmContext.Consumer>
        {(sellerprm) => <ProfileUserMain sellerprm={sellerprm} />}
      </SellerPrmContext.Consumer>
    );
  }
}
