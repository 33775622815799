import { Link, NavLink } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";
import ImageComponent from "../_UtilityComponent/Image/ImageComponent";

export const HeaderContainer = styled.div`
  overflow: hidden;
  background: ${(props) => props.theme.color.primary};
  position: relative;
  margin: 0;
`;

export const WaveWrapper = styled.div`
  svg {
    overflow: hidden;
    width: 100%;
    fill: white;
    display: block;
  }
`;

export const HeaderMenu = styled.div`
  display: grid;
  background-color: white;
  grid-template-columns: 1fr 1fr 1fr;
  height: 10vh;
`;

export const BurgerBtn = styled.div`
  font-size: 14px;
  padding: 10px;
  color: black;
  display: grid;
  place-items: center;
`;

export const SearchBarContainer = styled.div`
  display: grid;
  place-items: center;
`;

export const TopSearchBarWrapper = styled.div`
  position: relative;
  display: table;
  border-collapse: separate;
  border-radius: 10px;
  padding: 3px;
  background-color: grey;
  width: 100%;
`;

export const TopSearchBarSpan = styled.span`
  display: table-cell;
  padding-left: 5px;
`;

export const TopSearchBarInput = styled.input.attrs({
  placeholder: "Cari...",
})`
  padding-left: 5px;
  display: table-cell;
  border-style: none;
  background-color: transparent;
  color: white;
  font-size: 1rem;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;

  @media (max-width: 300px) {
    width: 150px;
  }

  @media (max-width: 270px) {
    width: 120px;
  }

  :focus {
    outline: none;
  }
  ::placeholder {
    color: white;
  }
`;

export const Logo = styled.img`
  height: auto;
  max-width: 70%;
  object-fit: cover;
`;

export const CartLinkBtn = styled(Link)`
  font-size: 14px;
  padding: 10px;
  color: black;
  display: grid;
  place-items: center;
`;

export const HeaderDropDown = styled.div`
  max-height: ${(props) => (props.dropDownState ? "500px" : "0px")};
  transition: ${(props) =>
    props.dropDownState
      ? "max-height 0.5s ease-in, opacity 0.4s ease-in, visibility 0s linear 300ms"
      : "max-height 0.5s ease-out, opacity 0.4s ease-out, visibility 0s linear 300ms"};
  opacity: ${(props) => (props.dropDownState ? 1 : 0)};
  visibility: ${(props) => (props.dropDownState ? "visible" : "hidden")};
  grid-template-rows: 1fr;
  color: black;

  a:active {
    color: white;
  }
`;

export const ItemDropDownDiv = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  padding: 15px;
  color: ${(props) =>
    props.theme.color.type === "dark"
      ? (props) => props.theme.font.light
      : (props) => props.theme.font.dark};

  ${(props) =>
    props.theme.template.id === "5" &&
    css`
      color: ${(props) => props.theme.font.light};
    `}
`;

const activeClassName = "active";
export const ItemDropDownNavLink = styled(NavLink).attrs({
  activeClassName: activeClassName,
})`
  width: 100%;
  height: 100%;
  display: grid;
  padding: 15px;
  color: ${(props) =>
    props.theme.color.type === "dark"
      ? (props) => props.theme.font.light
      : (props) => props.theme.font.dark};

  &.${activeClassName} {
    background-color: #f7a689;
    color: ${(props) =>
      props.theme.color.type === "dark"
        ? (props) => props.theme.font.light
        : (props) => props.theme.font.dark};
  }

  ${(props) =>
    props.theme.template.id === "5" &&
    css`
      color: ${(props) => props.theme.font.light};
      &.${activeClassName} {
        background-color: #f7a689;
        color: ${(props) => props.theme.font.dark};
      }
    `}
`;

export const ShopTitleWrapper = styled.div`
  padding: 5% 5%;
  display: grid;
  grid-template-columns: minmax(100px, 25%) 1fr;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  background: ${(props) => props.theme.color.primary};
  ${(props) =>
    props.theme.template.id === "5" &&
    css`
      color: ${(props) => props.theme.font.light};
    `}

  ${(props) =>
    props.theme.template.id === "6" &&
    css`
      color: ${(props) => props.theme.font.light};
    `} /* ${(props) =>
    props.sellerId === 26 &&
    css`
      color: black;
    `} */
`;

export const ShopTitleImage = styled(ImageComponent).attrs({
  isSquare: true,
  // roundedFrame: true,
  noPadding: true,
  transparent: true,
})`
  background-color: white;
`;

export const ShopTitleImageContainer = styled.div`
  display: grid;
  place-items: center;
  margin-right: 10px;
`;

export const ShopTitle = styled.div`
  font-size: 1.1rem;
  text-align: center;
  font-weight: bold;
  place-items: center;
  margin-bottom: 10px;

  font-family: 'Lato Bold', sans-serif;
  text-transform: uppercase;
`;

export const ShopSubTitle = styled.div`
  font-size: 0.9rem;
  line-height: 1.5;
  margin: none;

  font-family: 'Lato Regular', sans-serif;

  p {
    margin: 0;
  }
`;
