import React, { Component } from "react";
import SellerProductsMain from "../components/SellerProducts/SellerProductsMain";
import SellerProductsContext from "../context/SellerProductsContext";
import SellerPrmContext from "../../SellerPrmContext";

export default class SellerProducts extends Component {
  render() {
    return (
      <SellerPrmContext.Consumer>
        {(sellerPrm) => (
          <SellerProductsContext.Consumer>
            {(sellerProducts) => (
              <SellerProductsMain sellerProducts={sellerProducts} sellerPrm={sellerPrm}/>
            )}
          </SellerProductsContext.Consumer>
        )}
      </SellerPrmContext.Consumer>
    );
  }
}
