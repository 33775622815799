import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { CenterLoader } from "../../_UtilityComponent/Loader/CenterLoader";
import DiscussionView from "./DiscussionView";
import DiscussionList from "./DiscussionList";
import DiscussionInboxList from "./DiscussionInboxList";

class DiscussionMain extends Component {
  componentDidMount() {
    if (this.props.discussionPage === "DISCUSSION") {
      const { id, discussionId } = this.props.match.params;
      const subDomainData = this.props.sellerPrm.subDomainData;
      if (!discussionId) {
        console.log("didmount");
        if (subDomainData) {
          this.props.homeProduct.homeProductDetail(
            this.props.sellerPrm.subDomainData.id,
            id,
            true
          );
        }
        this.props.homeProduct.getDiscussionList(id);
      } else {
        this.props.homeProduct.getSingleDiscussion(id, discussionId);
      }
    } else if (this.props.discussionPage === "INBOX-DISCUSSION") {
      const subDomainData = this.props.sellerPrm.subDomainData;
    } else if (this.props.discussionPage === "PRODUCT") {
      // console.log(this.props.discussionList);
      // if (!this.props.homeProduct.discIsLoading && this.props.homeProduct.discussionList.length > 0) {
      //   this.props.homeProduct.discStateChangeHandler("LIST");
      // }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // if (this.props.discussionPage) {
      console.log("didupdate");
      const { id, discussionId } = this.props.match.params;
      if (!discussionId) {
        if (this.props.sellerPrm.subDomainData) {
          this.props.homeProduct.homeProductDetail(
            this.props.sellerPrm.subDomainData.id,
            id,
            true
          );
        }
        this.props.homeProduct.getDiscussionList(id);
      } else {
        this.props.homeProduct.getSingleDiscussion(id, discussionId);
      }
      // }
    }
  }

  render() {
    if (this.props.discussionPage === "DISCUSSION") {
      const {
        discussionList,
        discussionSingle,
        postDiscussionTopic,
        postDiscussionReply,
        topic,
        message,
        changeHandler,
        discState,
        discStateChangeHandler,
        discIsLoading,
        productDetail,
        isLoading,
      } = this.props.homeProduct;
      const { id, discussionId } = this.props.match.params;
      if (!discIsLoading) {
        if (!discussionId) {
          return (
            //list discussion
            <DiscussionView
              //statePage
              discussionPage={this.props.discussionPage}
              id={id}
              topic={topic}
              //
              discState={discState}
              discStateChangeHandler={discStateChangeHandler}
              changeHandler={changeHandler}
              postDiscussionTopic={postDiscussionTopic}
              discussionList={discussionList}
              discIsLoading={discIsLoading}
              productDetail={productDetail}
              isLoading={isLoading}
            />
          );
        } else {
          return (
            // single discussion
            <DiscussionView
              //statePage
              discussionShow={true}
              id={id}
              message={message}
              discussionId={discussionId}
              //
              changeHandler={changeHandler}
              postDiscussionReply={postDiscussionReply}
              discussionSingle={discussionSingle}
              discIsLoading={discIsLoading}
              isLoading={isLoading}
            />
          );
        }
      } else {
        return <CenterLoader />;
      }
    } else if (this.props.discussionPage === "INBOX-DISCUSSION") {
      return <DiscussionInboxList isSeller={false} />;
    } else if (this.props.discussionPage === "PRODUCT") {
      const {
        discussionPage,
        discussionList,
        discIsLoading,
        isLoading,
      } = this.props;
      return (
        <DiscussionList
          discussionPage={discussionPage}
          discussionList={discussionList}
          discIsLoading={discIsLoading}
          isLoading={isLoading}
        />
      );
    }
  }
}

export default withRouter(DiscussionMain);
