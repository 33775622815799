import React, { Component } from "react";
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdown,
} from "mdbreact";
import { Text } from "../../context/Language";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import Cookies from "js-cookie";
import Logo from "../../../assets/lg_1toko.png";
import { ImageLogoContainer } from "./MainHeader.styles";

export default class MainHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseID: "",
    };
    this.headerHeight = React.createRef();
  }

  componentDidMount() {
    let headerHeight = this.headerHeight.current.offsetHeight;
    this.props.checkHeaderHeight(headerHeight);
  }

  toggleCollapse = (collapseID) => () =>
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));

  render() {
    return (
      <div>
        <MDBNavbar
          light
          color="grey lighten-5"
          expand="xs, sm, md, lg, xl"
        >
          <span style={{ width: "100%" }} ref={this.headerHeight}>
            <MDBContainer>
              <MDBNavbarBrand>
                <MDBNavLink to="/" style={{ padding: "0" }}>
                  <ImageLogoContainer src={Logo} alt="1toko-logo" />
                </MDBNavLink>
              </MDBNavbarBrand>
              <MDBNavbarToggler
                onClick={this.toggleCollapse("navbarCollapse")}
              />
              <MDBCollapse
                id="navbarCollapse"
                isOpen={this.state.collapseID}
                navbar
              >
                <MDBNavbarNav right>
                  {!Cookies.get("access_token") ? (
                    <>
                      <MDBNavItem>
                        <MDBNavLink to="/login">
                          <Text pid="landing" tid="loginBtn" />
                        </MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem>
                        <MDBNavLink to="/register">
                          <Text pid="landing" tid="registerBtn" />
                        </MDBNavLink>
                      </MDBNavItem>
                    </>
                  ) : (
                    <MDBNavItem>
                      <MDBDropdown>
                        <MDBDropdownToggle nav caret color="secondary">
                          <Text pid="userMenu_header" tid="helloText" />
                          {this.props.dataUser.dataUser
                            ? this.props.dataUser.dataUser.name
                            : ""}
                        </MDBDropdownToggle>
                        <MDBDropdownMenu color="secondary">
                          <MDBDropdownItem>
                            <MDBNavLink
                              className="black-text"
                              to="#"
                              onClick={this.props.logout}
                            >
                              <Text pid="userMenu_header" tid="logoutBtn" />
                            </MDBNavLink>
                          </MDBDropdownItem>
                        </MDBDropdownMenu>
                        <MDBNavLink
                          className="black-text"
                          to="/seller_dashboard"
                        >
                          Seller Dashboard
                        </MDBNavLink>
                      </MDBDropdown>
                    </MDBNavItem>
                  )}
                  <MDBNavItem>
                    <LanguageSelector />
                  </MDBNavItem>
                </MDBNavbarNav>
              </MDBCollapse>
            </MDBContainer>
          </span>
        </MDBNavbar>
      </div>
    );
  }
}
