import { MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBRow } from "mdbreact";
import React, { Component } from "react";

export default class TrackingDetailComponent extends Component {
  formatDate = (string) => {
    var options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return new Date(string).toLocaleDateString("ID", options);
  };

  render() {
    console.log(this.props);
    const { courier, track } = this.props;
    if (courier === "NCS") {
      return track.map((data, index) => {
        if (
          data.Status !== "DE" &&
          data.Status !== "SD" &&
          data.Status !== "ST"
        ) {
          return (
            <MDBCol
              size="12"
              key={index}
              middle
              style={{ marginBottom: "1rem" }}
            >
              <MDBCard>
                <MDBCardBody
                  className="z-depth-2"
                  style={{ paddingTop: "5px", paddingBottom: "5px" }}
                >
                  <MDBRow className="w-100">
                    <MDBCol middle size="2">
                      <MDBIcon
                        size="3x"
                        icon={
                          data.Status === "OK" ? "thumbs-up" : "check-circle"
                        }
                        style={{ color: "green" }}
                      />
                    </MDBCol>
                    <MDBCol middle>
                      <table
                        style={{
                          width: "100%",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td colSpan="3">
                              {this.formatDate(data.TimeStamp)}
                            </td>
                          </tr>
                          <tr style={{ borderSpacing: "1px" }}>
                            <td>Status</td>
                            <td>:</td>
                            <td>{data.Comment}</td>
                          </tr>
                          <tr>
                            <td>Lokasi</td>
                            <td>:</td>
                            <td>{data.Station}</td>
                          </tr>
                        </tbody>
                      </table>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          );
        }
      });
    }
  }
}
