import React from "react";
import { Route, Switch } from "react-router-dom";

import Register from "./pages/Register";
import Login from "./pages/Login";
import Landing from "./pages/Landing";
import ChangePasswordToken from "./pages/ChangePasswordToken";
import WebSearch from "./pages/WebSearch";
import Activation from "./pages/Activation";
import { PrivateRoute } from "../utils/PrivateRoute";
import { DashboardSeller } from "./pages/DashboardSeller";

class MainRoutes extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/activation/:token" component={Activation} />
        <Route
          exact
          path="/change_password_token/:token?"
          component={ChangePasswordToken}
        />
        <Route exact path="/search/" component={WebSearch} />
        {/* seller dashboard */}
        <PrivateRoute
          exact
          path="/seller_dashboard"
          component={DashboardSeller}
        />
        <PrivateRoute
          exact
          path="/seller_dashboard/:page?/:s_id?/:s_name?/:process?/:detail?/:detail_id?"
          component={DashboardSeller}
        />
      </Switch>
    );
  }
}

export default MainRoutes;
