import React from "react";
import { StoreCardContainer, StoreCardImg } from "./StoreListLanding.styles";

export const Stores = (props) => {
  return (
    <>
      {props.storeList.map((item, idx) => {
        return <Store item={item} key={idx} />;
      })}
    </>
  );
};

const Store = (props) => {
  return (
    <StoreCardContainer
      onClick={() => {
        window.location = `http://${props.item.subdomain}`;
        // window.location = `http://gudang-kopi.1toko.com/`;
      }}
    >
      <StoreCardImg src={props.item.logo} />
    </StoreCardContainer>
  );
};
