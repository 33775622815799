import { MDBCol } from "mdbreact";
import styled, { css, keyframes } from "styled-components";

export const ManageSellerListContainer = styled.div`
  margin-top: 20px;
  height: ${(props) => props.overflowHeight ? "90vh" : "auto"};
  overflow: auto;
`;

export const ManageSellerListItemStyled = styled.div``;

export const ManageSellerListPrimarySign = styled(MDBCol)`
  background-color: #a831b5;
  color: #ffffff;
  margin-bottom: 12px;
`;

export const ManageSellerListItemTitle = styled(MDBCol)`
  font-size: 19px;
  margin-bottom: 12px;
  padding-right: 0;

  ${(props) =>
    props.$noMargin &&
    css`
      margin-bottom: 0;
    `};

  ${(props) => props.$fontSizeHalf && css`
    font-size: 15px;
  `}
`;

export const ManageSellerListItemDesc = styled.div`
  opacity: 0.8;
`;

export const ManageSellerListButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`;

export const ManageSellerListButton = styled.div`
  line-height: 44px;
  padding: 5px;
  margin: 5px;
  font-weight: bold;
  border-radius: 10px;
  border: 1px solid #a831b5;
  color: #a831b5;
  flex-grow: 1;
  text-align: center;
`;

export const ManageSellerListToggle = styled(ManageSellerListButton)`
  border: 1px solid ${(props) => (props.active ? "green" : "red")};
  color: ${(props) => (props.active ? "green" : "red")};
`;

export const ManageSellerAddContainer = styled.div`
  @media (min-width: 250px) {
    margin-top: 80px;
  }
  @media (min-width: 600px) {
    margin-top: 160px;
  }
  @media (min-width: 900px) {
    margin-top: 140px;
  }
`;
