import React, { Component } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBListGroupItem,
  MDBListGroup,
} from "mdbreact";
import "./ModalOption.css";

class ModalOption extends Component {
  render() {
    return (
      <MDBContainer>
        <MDBModal
          isOpen={this.props.showModal}
          toggle={() =>
            this.props.toggleHandler(
              this.props.actionTypeName,
              this.props.modalNumber
            )
          }
          className="modal-menu-option"
          position="bottom"
        >
          <MDBListGroup className="" style={{ width: "100%" }}>
            {this.props.primaryAddressId != this.props.item_id ? (
              <MDBListGroupItem
                style={{ color: "purple" }}
                onClick={(e) => this.props.firstButtonClick(this.props.item_id)}
              >
                {this.props.firstButtonTitle}
              </MDBListGroupItem>
            ) : (
              ""
            )}
            <MDBListGroupItem
              style={{ color: "purple" }}
              onClick={(e) => this.props.secondButtonClick(this.props.item_id)}
            >
              {this.props.secondButtonTitle}
            </MDBListGroupItem>
          </MDBListGroup>
        </MDBModal>
      </MDBContainer>
    );
  }
}

export default ModalOption;
