import React, { Component } from "react";
import { RoundedOrangeButton } from "../../_UtilityComponent/Button/RoundedButton";
import DiscussionForm from "./DiscussionForm";
import DiscussionList from "./DiscussionList";
import DiscussionThread from "./DiscussionThread";

export default class DiscussionView extends Component {
  render() {
    const {
      id,
      discussionId,
      discussionList,
      discussionPage,
      discussionShow,
      discussionSingle,
      topic,
      message,
      postDiscussionTopic,
      postDiscussionReply,
      changeHandler,
      discState,
      discStateChangeHandler,
      discIsLoading,
      isLoading,
    } = this.props;
    if (!discussionShow) {
      if (discState === "CREATE") {
        return (
          <DiscussionForm
            topic={topic}
            changeHandler={changeHandler}
            postDiscussionTopic={postDiscussionTopic}
            id={id}
          />
        );
      } else {
        return (
          <>
            <NewTopicButton discStateChangeHandler={discStateChangeHandler} />
            <DiscussionList
              postDiscussionTopic={postDiscussionTopic}
              discussionList={discussionList}
              discussionPage={discussionPage}
              discIsLoading={discIsLoading}
              isLoading={isLoading}
            />
          </>
        );
      }
    } else {
      return (
        <DiscussionThread
          discussionSingle={discussionSingle}
          postDiscussionReply={postDiscussionReply}
          changeHandler={changeHandler}
          id={id}
          message={message}
          discussionId={discussionId}
        />
      );
    }
  }
}

const NewTopicButton = (props) => {
  return (
    <RoundedOrangeButton
      className={"w-100"}
      onClick={() => props.discStateChangeHandler("CREATE")}
    >
      Tambah Topik Diskusi
    </RoundedOrangeButton>
  );
};
