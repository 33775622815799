import React, { useContext, useEffect, useRef, useState } from "react";
import { MDBContainer, MDBIcon } from "mdbreact";
import Cookies from "js-cookie";
import SellerPrmContext from "../../../SellerPrmContext";
import API from "../../../utils/API";
import parse from "html-react-parser";
//SVG
import { ReactComponent as WaveSVG } from "../../../assets/svg/wave.svg";
import { useHistory, useLocation } from "react-router-dom";
import {
  WaveWrapper,
  TopSearchBarWrapper,
  TopSearchBarSpan,
  TopSearchBarInput,
  BurgerBtn,
  HeaderContainer,
  HeaderMenu,
  CartLinkBtn,
  HeaderDropDown,
  ItemDropDownDiv,
  ItemDropDownNavLink,
  SearchBarContainer,
  ShopTitleWrapper,
  ShopTitleImageContainer,
  ShopTitleImage,
  ShopTitle,
  ShopSubTitle,
} from "./Header.styles";

const Header = (props) => {
  let location = useLocation();
  let history = useHistory();
  //ref
  //   const headerHeightRef = useRef(null);
  //state
  const [dropDownState, setDropDownState] = useState(false);
  const [pageHomeState, setPageHomeState] = useState(true);
  const [pageForceToHome, setPageForceToHome] = useState(false);
  const [aboutOther, setAboutOther] = useState("");
  //context
  const sellerPrmContext = useContext(SellerPrmContext);

  const _redirectOneTokoSellerDashboard = () => {
    window.location = `http://merchant.1toko.com/seller_dashboard`;
  };

  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname.substring(1, 9) === "category" ||
      location.pathname.substring(1, 6) === "search"
    ) {
      setPageHomeState(true);
    } else {
      setPageHomeState(false);
      if (
        location.pathname.includes("transaction-status") ||
        location.pathname.includes("payment-status")
      ) {
        setPageForceToHome(true);
      } else {
        setPageForceToHome(false);
      }
    }
  }, [location]);

  useEffect(() => {
    getProductAbout(sellerPrmContext.link);
  }, [sellerPrmContext.subDomainData]);

  const getProductAbout = (subdomain) => {
    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };
    API.get(`/about/${subdomain}`, config)
      .then((res) => {
        if (parseInt(res.data.code) !== 200) {
          console.log(res);
        } else {
          const about_other = res.data.data.about.about_other;
          setAboutOther(about_other);
        }
      })
      .catch((error) => {
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  const goToHome = () => {
    history.push("/");
  };

  return (
    <>
      <HeaderContainer>
        <HeaderMenu>
          {pageHomeState ? (
            <BurgerBtn onClick={() => setDropDownState(!dropDownState)}>
              <MDBIcon size="lg" icon="bars" />
            </BurgerBtn>
          ) : pageForceToHome ? (
            <BurgerBtn onClick={() => goToHome()}>
              <MDBIcon icon="chevron-left" size="sm" />
            </BurgerBtn>
          ) : (
            <BurgerBtn
              onClick={() => {
                setDropDownState(false);
                history.goBack();
              }}
            >
              <MDBIcon icon="chevron-left" size="sm" />
            </BurgerBtn>
          )}
          <SearchBarContainer to="/" onClick={() => setDropDownState(false)}>
            <TopSearchBarWrapper>
              <TopSearchBarSpan>
                <MDBIcon className="white-text" size="sm" icon="search" />
              </TopSearchBarSpan>
              <TopSearchBarInput />
            </TopSearchBarWrapper>
          </SearchBarContainer>
          <CartLinkBtn to="/cart" onClick={() => setDropDownState(false)}>
            <MDBIcon size="lg" icon="shopping-cart" />
          </CartLinkBtn>
        </HeaderMenu>
        <HeaderDropDown dropDownState={dropDownState}>
          {Cookies.get("access_token") ? (
            <>
              <ItemDropDownDiv>
                Hello,{" "}
                {sellerPrmContext.dataUser
                  ? sellerPrmContext.dataUser.name
                  : ""}
              </ItemDropDownDiv>
              {sellerPrmContext.isSeller ? (
                <ItemDropDownDiv
                  onClick={() => _redirectOneTokoSellerDashboard()}
                >
                  Open Your Seller Dashboard
                </ItemDropDownDiv>
              ) : (
                ""
              )}
              {/* <NavigationButton
                link={"/profile"}
                btnName={"Profile"}
                setDropDownState={setDropDownState}
              />
              <NavigationButton
                link={"/inbox-discussion"}
                btnName={"Discussion"}
                setDropDownState={setDropDownState}
              />
              <NavigationButton
                link={"/about"}
                btnName={"About"}
                setDropDownState={setDropDownState}
              /> */}
              <LogoutButton logout={props.logout} />
            </>
          ) : (
            <>
              <NavigationButton
                link={"/login"}
                btnName={"Login"}
                setDropDownState={setDropDownState}
              />
              <NavigationButton
                link={"/about"}
                btnName={"About"}
                setDropDownState={setDropDownState}
              />
            </>
          )}
        </HeaderDropDown>
        {/* {props.template === 5 ? (
          <WaveWrapper>
            <WaveSVG />
          </WaveWrapper>
        ) : (
          ""
        )} */}
      </HeaderContainer>
      {pageHomeState ? (
        <ShopTitleWrapper sellerId={sellerPrmContext.subDomainData.id}>
          <ShopTitleImageContainer>
            <ShopTitleImage img={sellerPrmContext.subDomainData.logo} />
          </ShopTitleImageContainer>
          <div style={{ display: "grid", placeItems: "center" }}>
            <ShopTitle>{sellerPrmContext.subDomainData.name_web}</ShopTitle>
            <ShopSubTitle>{aboutOther ? parse(aboutOther) : ""}</ShopSubTitle>
          </div>
        </ShopTitleWrapper>
      ) : (
        ""
      )}
    </>
  );
};

const NavigationButton = (props) => {
  return (
    <ItemDropDownNavLink
      to={props.link}
      onClick={() => props.setDropDownState(false)}
    >
      {props.btnName}
    </ItemDropDownNavLink>
  );
};

const LogoutButton = (props) => {
  return (
    <ItemDropDownDiv onClick={() => props.logout()}>Logout</ItemDropDownDiv>
  );
};

export default Header;
