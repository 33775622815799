import {
  MDBBadge,
  MDBCard,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
} from "mdbreact";
import React from "react";
import { AddSellerStyleButton } from "./DashboardSeller.styles";
import {
  ManageSellerListContainer,
  ManageSellerListItemStyled,
  ManageSellerListItemTitle,
  ManageSellerListItemDesc,
  ManageSellerListButtonGroup,
  ManageSellerListButton,
  ManageSellerListPrimarySign,
} from "./ManageSellerLocationCategory.styles";
import {
  ManageSellerListItemProductsStyled,
  ManageSellerListProductsPhoto,
  ManageSellerListProductsPhotoContainer,
  ManageSellerListProductsIconContainer,
  ManageSellerListItemProductsDesc,
  ManageSellerListItemProductsTitle,
  ManageSellerListItemProductsStat,
} from "./ManageSellerProducts.styles";

export const ManageSellerListItems = (props) => {
  return (
    <>
      <AddSellerStyleButton
        size="sm"
        outline
        onClick={() => {
          props.history.push(`${props.history.location.pathname}/add`);
        }}
      >
        <MDBIcon icon="plus" />
      </AddSellerStyleButton>
      <ManageSellerListContainer overflowHeight>
        {props.itemDashboard.map((item, index) => {
          return (
            <ManageSellerListItem
              item={item}
              key={index}
              page={props.page}
              modalHandler={props.modalHandler}
              history={props.history}
              beActiveProduct={props.beActiveProduct}
            />
          );
        })}
      </ManageSellerListContainer>
    </>
  );
};

const ManageSellerListItem = (props) => {
  switch (props.page) {
    case "manage_location":
      return (
        <ManageSellerListItemStyled>
          <MDBCard className="mb-3 pt-3 pb-3">
            <MDBContainer>
              {props.item.is_primary === "1" ? (
                <ManageSellerListPrimarySign>
                  Alamat Utama
                </ManageSellerListPrimarySign>
              ) : (
                ""
              )}
              <MDBRow>
                <MDBCol size="10">
                  <ManageSellerListItemTitle className="font-weight-bold">
                    {props.item.name}
                  </ManageSellerListItemTitle>
                </MDBCol>
                <MDBCol size="2">
                  <MDBIcon
                    style={{ lineHeight: "inherit" }}
                    className="float-right"
                    icon="ellipsis-v"
                    onClick={() => {
                      props.modalHandler("MODALOPTION", props.item.id);
                    }}
                  />
                </MDBCol>
              </MDBRow>
              <ManageSellerListItemDesc>
                <MDBCol size="12">{props.item.address}</MDBCol>
                <MDBCol size="12">{props.item.zip_id.label}</MDBCol>
              </ManageSellerListItemDesc>
              <ManageSellerListButtonGroup>
                <ManageSellerListButton
                  onClick={() => {
                    props.history.push(
                      `${props.history.location.pathname}/edit`
                    );
                  }}
                >
                  Ubah Alamat
                </ManageSellerListButton>
                {/* <ManageSellerListToggle active>Aktifkan</ManageSellerListToggle> */}
              </ManageSellerListButtonGroup>
            </MDBContainer>
          </MDBCard>
        </ManageSellerListItemStyled>
      );
    case "manage_products":
      return (
        <ManageSellerListItemProductsStyled>
          <ManageSellerListProductsPhotoContainer>
            <ManageSellerListProductsPhoto
              img={props.item.photo_product_main.link}
            />
          </ManageSellerListProductsPhotoContainer>
          <ManageSellerListItemProductsDesc>
            <ManageSellerListItemProductsTitle>
              {props.item.name}
            </ManageSellerListItemProductsTitle>
            {parseInt(props.item.is_active) === 1 ? (
              <>
                <ManageSellerListItemProductsStat
                  isActive
                  onClick={() => {
                    props.beActiveProduct(
                      props.item.id,
                      props.item.is_active,
                      props.item.seller_id
                    );
                  }}
                >
                  <MDBIcon style={{ marginRight: "5px" }} icon="check-circle" />
                  Aktif
                </ManageSellerListItemProductsStat>
              </>
            ) : (
              <>
                <ManageSellerListItemProductsStat
                  onClick={() => {
                    props.beActiveProduct(
                      props.item.id,
                      props.item.is_active,
                      props.item.seller_id
                    );
                  }}
                >
                  <MDBIcon style={{ marginRight: "5px" }} icon="times-circle" />
                  Tidak Aktif
                </ManageSellerListItemProductsStat>
              </>
            )}
          </ManageSellerListItemProductsDesc>
          <ManageSellerListProductsIconContainer>
            <MDBIcon
              icon="edit"
              // onClick={() => {
              //   props.modalHandler("MODALOPTION", props.item.id);
              // }}
              onClick={() => {
                props.history.push(
                  `${props.history.location.pathname}/edit/${props.item.id}`
                );
              }}
            />
          </ManageSellerListProductsIconContainer>
        </ManageSellerListItemProductsStyled>
      );
    case "manage_category":
      return (
        <ManageSellerListItemStyled>
          <MDBCard className="mb-3 pt-3 pb-3">
            <MDBContainer>
              <MDBRow>
                <MDBCol size="10">
                  <ManageSellerListItemTitle
                    $noMargin
                    className="font-weight-bold"
                  >
                    {props.item.name}
                  </ManageSellerListItemTitle>
                </MDBCol>
                <MDBCol size="2">
                  <MDBIcon
                    style={{ lineHeight: "inherit" }}
                    className="float-right"
                    icon="ellipsis-v"
                    onClick={() => {
                      props.modalHandler("MODALOPTION", props.item.id);
                    }}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBCard>
        </ManageSellerListItemStyled>
      );
    case "manage_bank_account":
      return (
        <ManageSellerListItemStyled>
          <MDBCard className="mb-3 pt-3 pb-3">
            <MDBContainer>
              <MDBRow>
                <MDBCol size="10">
                  {props.item.is_primary === "1" ? (
                    <MDBCol style={{paddingBottom : "5px"}}>
                      <MDBBadge pill color="purple">
                        Rekening Utama
                      </MDBBadge>
                    </MDBCol>
                  ) : (
                    ""
                  )}
                  <ManageSellerListItemTitle $noMargin $fontSizeHalf>
                    {props.item.bank.name}
                  </ManageSellerListItemTitle>
                  <ManageSellerListItemTitle
                    $noMargin
                    className="font-weight-bold"
                  >
                    {props.item.no_rek}
                  </ManageSellerListItemTitle>
                  <ManageSellerListItemTitle
                    $noMargin
                    $fontSizeHalf
                    className="font-weight-bold"
                  >
                    {props.item.alias}
                  </ManageSellerListItemTitle>
                </MDBCol>
                <MDBCol size="2">
                  <MDBIcon
                    style={{ lineHeight: "inherit" }}
                    className="float-right"
                    icon="ellipsis-v"
                    onClick={() => {
                      props.modalHandler("MODALOPTION", props.item.id);
                    }}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBCard>
        </ManageSellerListItemStyled>
      );
  }
};
