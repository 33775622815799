import React, { useContext, useEffect, useState } from "react";
import Banner from "../../components/HeaderAfterLogin/Banner";
import ProductAbout from "../../components/ProductHomeConsumer/ProductAbout/ProductAbout";
import ProductHomeConsumer from "../../components/ProductHomeConsumer/ProductHomeConsumer";
import {
  StyledAboutButton,
} from "../../components/_UtilityComponent/Button/StyledButton";
import { ProductAboutButtonGroupContainer } from "./Home.styles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Swal from "sweetalert2";
import SellerPrmContext from "../../../SellerPrmContext";

const Home = () => {
  const sellerPrmContext = useContext(SellerPrmContext);
  const [toggleAbout, setToggleAbout] = useState(false);
  const [linkToko, setLinkToko] = useState("");

  const _redirectOneToko = () => {
    window.location = `http://merchant.1toko.com`;
  };

  useEffect(() => {
    setLinkToko(`https://share.1toko.com/?${sellerPrmContext.link}`);
  }, [])

  return (
    <div>
      <Banner />
      <ProductAboutButtonGroup
        _redirectOneToko={_redirectOneToko}
        toggleAbout={toggleAbout}
        setToggleAbout={setToggleAbout}
        linkToko={linkToko}
      />
      <ProductAbout toggleAbout={toggleAbout} />
      <ProductHomeConsumer />
      {/* <Footer /> */}
    </div>
  );
};

export default Home;

const ProductAboutButtonGroup = (props) => {
  const ClipboardToastOption = {
    icon: "success",
    position: "center",
    title: "Tersalin",
    toast: true,
    showConfirmButton: false,
    timer: 2000,
  };

  return (
    <ProductAboutButtonGroupContainer>
      <StyledAboutButton onClick={() => props._redirectOneToko()}>
        Go to 1Toko
      </StyledAboutButton>
      <CopyToClipboard
        onCopy={() => Swal.fire(ClipboardToastOption)}
        text={props.linkToko}
      >
        <StyledAboutButton>Share Link Toko</StyledAboutButton>
      </CopyToClipboard>
      <StyledAboutButton
        onClick={() => props.setToggleAbout(!props.toggleAbout)}
      >
        About
      </StyledAboutButton>
    </ProductAboutButtonGroupContainer>
  );
};
