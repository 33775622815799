import { MDBCol, MDBContainer } from "mdbreact";
import { Link, NavLink } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";

export const LoginLandingContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100vh;
  background: #a831b5 no-repeat center center;
  background-size: cover;
`;

export const LoginBannerImage = styled.img`
  width: auto;
  margin: 0 auto;
  /* border: 2px solid red; */
  @media (min-width: 250px) {
    //galaxy fold
    height: 180px;
  }

  @media (min-width: 300px) {
    //standart
    height: 200px;
  }

  @media (min-width: 500px) {
    //surfaceduo
    height: 300px;
  }

  @media (min-width: 1000px) {
    //ipad
    height: 400px;
  }
`;

export const LoginLandingContent = styled(MDBContainer)`
  height: 100%;
  width: 100%;
  /* border: 2px solid red; */
  background-color: #ffffff;
  border-radius: 30px 30px 0 0;

  @media (min-width: 250px) {
    padding: 10% 10% 0 10%;
  }

  @media (min-width: 500px) {
    padding: 5% 10% 0 10%;
  }
`;

export const WelcomeText = styled(MDBCol)`
  color: purple;
  text-align: center;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 23px;
  @media (min-width: 300px) {
    font-size: 29px;
  }
  @media (min-width: 500px) {
    font-size: 30px;
  }
  @media (min-width: 1024px) {
    font-size: 35px;
  }
`;
