import en from './en';
import id from './id';

export const dictionaryList = {
  en,
  id,
};

export const languageOptions = [
  { id: 'en', index: 0, text: 'English' },
  { id: 'id', index: 1, text: 'Indonesia'}
];
