import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import API from "../../../utils/API";
import Cookies from "js-cookie";
import { DashboardSellerOrderView } from "./DashboardSellerOrderView";
import { CenterLoader } from "../_UtilityComponent/Loader/CenterLoader";
import Swal from "sweetalert2";

export const DashboardSellerOrderPickup = () => {
  //PAGE & PARAMS
  let location = useLocation();
  let params = useParams();
  let storeId = params.s_id;
  let storeName = params.s_name;
  let currentProcess = params.process;

  let mounted = false;

  const date = new Date();

  //REQ & ERR
  const [requests, setRequests] = useState(0); //render 2x dari atas
  const [errors, setErrors] = useState(0);
  const requestChecker = (condition) => {
    if (condition === "FETCH") {
      setRequests(requests + 1);
    } else if (condition === "DONE") {
      setRequests(requests - 1);
    } else if (condition === "ERROR") {
      setRequests(requests - 1);
      setErrors(errors + 1);
    }
  };

  const createConfig = (token) => {
    let config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
    return config;
  };

  //STATE
  const [currentPage, setCurrentPage] = useState(1);
  const [invoiceList, setInvoiceList] = useState([]);
  const [courierList, setCourierList] = useState([]);
  const [selectedCourier, setSelectedCourier] = useState(undefined);
  const [vehicleList, setVehicleList] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(undefined);
  const [sellerLocationList, setSellerLocationList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(undefined);
  const [formSubmitReady, setFormSubmitReady] = useState(false);
  // STATE-FORM
  const [locationModal, setLocationModal] = useState(false);
  const [pickupDate, setPickupDate] = useState(
    date.getFullYear() +
      "-" +
      parseInt(date.getMonth() + 1) +
      "-" +
      date.getDate()
  );
  const [pickupTimeFrom, setPickupTimeFrom] = useState(
    date.getHours() + ":" + date.getMinutes()
  );
  const [pickupTimeTo, setPickupTimeTo] = useState(
    date.getHours() + 1 + ":" + date.getMinutes()
  );
  const [pickupPoint, setPickupPoint] = useState("");
  const [pickupTelp, setPickupTelp] = useState("");
  const [pickupContact, setPickupContact] = useState("");
  const [pickupNote, setPickupNote] = useState("");

  // FETCHERS
  const getCourierList = () => {
    requestChecker("FETCH");
    API.get(`prm/courier`)
      .then((res) => {
        let temp_list = res.data.data.courier.map((data) => ({
          id: data.id,
          value: data.name,
        }));
        if (mounted) {
          setCourierList(temp_list);
          requestChecker("DONE");
        }
      })
      .catch((err) => {
        requestChecker("ERROR");
        console.log(err);
      });
  };

  const getVehicleList = () => {
    requestChecker("FETCH");
    API.get(`prm/ncs/vehicle`)
      .then((res) => {
        let temp_list = res.data.data.vehicle.map((data) => ({
          id: data.vehicle_code,
          value: data.vehicle_name,
        }));
        if (mounted) {
          setVehicleList(temp_list);
          requestChecker("DONE");
        }
      })
      .catch((err) => {
        requestChecker("ERROR");
        console.log(err);
      });
  };

  //////////////////////////////////////////////////
  const getSellerLocationList = (sellerId) => {
    requestChecker("FETCH");
    API.get(
      `seller_location/${sellerId}`,
      createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        if (mounted) {
          setSellerLocationList(res.data.data.seller_location);
          const primaryLocation = res.data.data.seller_location.find((item) => {
            return item.is_primary === "1";
          });
          setSelectedLocation(primaryLocation);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    //   this.handelSellerLocation(0);
  }, [sellerLocationList]);
  //////////////////////////////////////////////////

  const getAllStoreInvoice = (status, storeId, courier = "") => {
    requestChecker("FETCH");
    const data = new FormData();
    data.append("status", JSON.stringify(status));
    data.append("courier", courier);
    data.append("storeId", storeId);
    API.post(
      `payment/all_store_invoice`,
      data,
      createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        const tempInvoiceList = res.data.invoice_list.map((item) => ({
          ...item,
          showItemState: false,
        }));
        // if (mounted) {
        setInvoiceList(tempInvoiceList);
        requestChecker("DONE");
        // }
      })
      .catch((err) => {
        requestChecker("ERROR");
        console.log(err);
      });
  };

  // METHOD FUNCTION
  const courierSelectHandler = (event) => {
    setInvoiceList([]);
    const courier_index = event[0];
    getAllStoreInvoice([2], storeId, courier_index);
    setSelectedCourier(courier_index);
  };

  const locationSelectHandler = (index) => {
    setSelectedLocation(sellerLocationList[index]);
  };

  const showItemHandler = (action, index) => {
    let invoiceListTemp = [...invoiceList];
    if (action === "OPEN") {
      invoiceListTemp[index].showItemState = true;
      setInvoiceList(invoiceListTemp);
    } else if (action === "CLOSE") {
      invoiceListTemp[index].showItemState = false;
      setInvoiceList(invoiceListTemp);
    }
  };

  const datePickerValueHandler = (date) => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    setPickupDate(year + "-" + month + "-" + day);
  };

  const pickupVehicleHandler = (event) => {
    setSelectedVehicle(event[0]);
  };

  const flagChecker = (index) => {
    let pickup = [...invoiceList];
    pickup[index].flag = !pickup[index].flag;
    let ready = pickup.find((data) => data.flag === true);
    setInvoiceList(pickup);
    setFormSubmitReady(ready);
  };

  const pickupFormValidation = (courier) => {
    let flag = true;
    if (courier === "NCS") {
      if (selectedVehicle === undefined) flag = false;
    }
    return flag;
  };

  const formSubmitHandler = (store, courier) => {
    const invoiceListTemp = invoiceList.filter((data) => data.flag === true);

    const pickupData = {
      note: pickupNote,
      date: pickupDate,
      from_time: pickupTimeFrom,
      to_time: pickupTimeTo,
      vehicle: selectedVehicle,
      pickup_point: pickupPoint,
      pickup_contact: pickupContact,
      pickup_tlp: pickupTelp,
    };

    const params = {
      store: store,
      courier: courier,
      pickup_address: selectedLocation,
      pickup_data: pickupData,
      invoice_list: invoiceListTemp,
    };

    if (pickupFormValidation(courier)) {
      API.post(
        "courier/pickup_request",
        params,
        createConfig(Cookies.get("access_token"))
      ).then((res) => {
        // console.log(res);
        Swal.fire({
          title: "Berhasil",
          icon: "success",
          showConfirmButton: true,
        }).then(() => {
          window.location.reload();
        });
      });
    }
  };

  useEffect(() => {
    mounted = true;
    getCourierList();
    getVehicleList();
    getSellerLocationList(storeId);
    return () => {
      setCourierList([]);
      setVehicleList([]);
      setSellerLocationList([]);
      mounted = false;
    };
  }, []);

  if (requests <= 0 && errors <= 0) {
    return (
      <DashboardSellerOrderView
        storeId={storeId}
        storeName={storeName}
        invoiceList={invoiceList}
        titleProcess={"Permintaan Pengambilan Pesanan"}
        currentProcess={currentProcess}
        // pickup
        courierList={courierList}
        selectedCourier={selectedCourier}
        courierSelectHandler={courierSelectHandler}
        vehicleList={vehicleList}
        selectedVehicle={selectedVehicle}
        sellerLocationList={sellerLocationList}
        locationModal={locationModal}
        setLocationModal={setLocationModal}
        locationSelectHandler={locationSelectHandler}
        selectedLocation={selectedLocation}
        pickupContact={pickupContact}
        pickupTelp={pickupTelp}
        pickupPoint={pickupPoint}
        pickupNote={pickupNote}
        datePickerValueHandler={datePickerValueHandler}
        setPickupTimeFrom={setPickupTimeFrom}
        setPickupTimeTo={setPickupTimeTo}
        pickupVehicleHandler={pickupVehicleHandler}
        setPickupPoint={setPickupPoint}
        setPickupContact={setPickupContact}
        setPickupTelp={setPickupTelp}
        setPickupNote={setPickupNote}
        flagChecker={flagChecker}
        formSubmitReady={formSubmitReady}
        formSubmitHandler={formSubmitHandler}
        showItemHandler={showItemHandler}
      />
    );
  } else if (requests <= 0 && errors > 0) {
    return <div>Maaf, sepertinya sedang ada kendala silahkan coba lagi</div>;
  } else {
    return <CenterLoader />;
  }
};
