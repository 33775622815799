import styled, { css, keyframes } from "styled-components";

export const AddressSelectorItemContainer = styled.div`
  margin: 15px 5px;
`;

export const AddressSelectorItem = styled.div`
  border-bottom: 1px solid black;

  :last-child {
    border-bottom: unset;
  }
`;
