import React, { Component } from "react";
import { ProductHomeContext } from "../context/ProductHomeProvider";
import SellerPrmContext from "../../SellerPrmContext";
import ProductDetailView from "../components/ProductDetail/ProductDetailView";

export default class ProductDetail extends Component {
  render() {
    return (
      <SellerPrmContext.Consumer>
        {(sellerprm) => (
          <ProductHomeContext.Consumer>
            {(producthome) => (
              <ProductDetailView sellerprm={sellerprm} producthome={producthome} />
            )}
          </ProductHomeContext.Consumer>
        )}
      </SellerPrmContext.Consumer>
    );
  }
}
