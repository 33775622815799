import React, { Component } from "react";
import { withRouter } from "react-router-dom";

// context
import { LanguageProvider } from "./context/Language";
import SellerLocationProvider from "./context/SellerLocationProvider";
import SellerCategoryProvider from "./context/SellerCategoryProvider";
import SellerProductsProvider from "./context/SellerProductsProvider";
import ProductHomeProvider from "./context/ProductHomeProvider";
import CartProvider from "./context/CartProvider";
import ShipmentProvider from "./context/ShipmentProvider";
// import { isMobile } from "react-device-detect";
import TemplateSwitcher from "./components/TemplateSwitcher/TemplateSwitcher";
import SellerPrmContext from "../SellerPrmContext";
// redux
// import { Provider } from "react-redux";
// import store from "./store";

class App extends Component {
  componentDidMount() {
    const link = window.location.hostname;
    // const link = "coolmusic.1toko.com"; //template 5
    // const link = "tokosekolah.1toko.com";//template 1
    // const link = "drug-store.1toko.com"; //template 4
    // const link = "gudang-kopi.1toko.com"; //template 5
    // const link = "agronic.1toko.com"; //template 5
    this.context.getHostName(link);
  }

  render() {
    return (
      <ShipmentProvider>
        <CartProvider>
          <ProductHomeProvider>
            <SellerProductsProvider>
              <SellerCategoryProvider>
                <SellerLocationProvider>
                  <LanguageProvider>
                    <TemplateSwitcher />
                  </LanguageProvider>
                </SellerLocationProvider>
              </SellerCategoryProvider>
            </SellerProductsProvider>
          </ProductHomeProvider>
        </CartProvider>
      </ShipmentProvider>
    );
  }
}

App.contextType = SellerPrmContext;

export default withRouter(App);
