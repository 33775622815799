import React from "react";
import {
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBContainer,
  MDBIcon,
} from "mdbreact";
import { Link } from "react-router-dom";
import style from "./Product.module.css";
import {
  ProductCard_ImageBorder,
  ProductCard_NameTag,
  ProductCard_PriceTag,
  ProductCard_WeightTag,
  ProductCard_CardBody,
  ProductCard_OuterContainer,
  ProductCard_InnerContainer,
} from "./Product.styles";

export const Product = (props) => {
  const { item } = props;
  return (
    <>
      {props.showingType === "ALL_PRODUCT_ROW" && item.price_product_single ? (
        <ProductCard_OuterContainer size="6">
          <Link to={"/product/" + item.id}>
            <ProductCard_InnerContainer>
              <ProductCard_ImageBorder>
                {/* <ImageComponent
                  isSquare
                  img={item.thumbnail ? item.thumbnail.link : ""}
                /> */}
                <img
                  src={item.thumbnail ? item.thumbnail.link : ""}
                  style={{
                    display: "block",
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                    width: "100%",
                    position: "absolute",
                    borderRadius: "5%",
                  }}
                />
              </ProductCard_ImageBorder>
              <ProductCard_CardBody cascade>
                <ProductCard_NameTag>{item.name}</ProductCard_NameTag>
                <ProductCard_WeightTag>
                  {item.price_product_single.weight} gram
                </ProductCard_WeightTag>
                {"seller_location" in item.price_product_single ? (
                  <ProductCard_WeightTag>
                    <MDBIcon
                      icon="map-marker-alt"
                      style={{ marginRight: "2px" }}
                    />{" "}
                    {item.price_product_single.seller_location.zip.sub_province}
                  </ProductCard_WeightTag>
                ) : (
                  ""
                )}
                <ProductCard_PriceTag>
                  {item.price_product_single
                    ? new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 20,
                      }).format(item.price_product_single.price)
                    : ""}
                </ProductCard_PriceTag>
              </ProductCard_CardBody>
            </ProductCard_InnerContainer>
          </Link>
        </ProductCard_OuterContainer>
      ) : (
        ""
      )}
      {/* {props.showingType === "TO_PRODUCT_CATEGORY" ? (
        <MDBContainer className="text-center">
          <Link to={"/category/" + item.id} style={{ color: "black" }}>
            <ProductCard_TitleToCategoryContainer>
              <MDBCardBody cascade>
                <ProductCard_TitleToCategory>
                  Lihat Lainnya dari {item.name}
                </ProductCard_TitleToCategory>
              </MDBCardBody>
            </ProductCard_TitleToCategoryContainer>
          </Link>
        </MDBContainer>
      ) : (
        ""
      )} */}
      {props.showingType === "CATEGORY_PRODUCT" && item.price ? (
        <ProductCard_OuterContainer size="12">
          <Link to={"/product/" + item.id}>
            <ProductCard_InnerContainer>
              <ProductCard_ImageBorder>
                <img
                  src={item ? item.img : ""}
                  style={{
                    display: "block",
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                    width: "100%",
                    position: "absolute",
                    borderRadius: "5%",
                  }}
                />
              </ProductCard_ImageBorder>
              <ProductCard_CardBody cascade>
                <ProductCard_NameTag>{item.name}</ProductCard_NameTag>
                <ProductCard_WeightTag>
                  {item.price.weight} gram
                </ProductCard_WeightTag>
                {"seller_location" in item.price ? (
                  <ProductCard_WeightTag>
                    <MDBIcon
                      icon="map-marker-alt"
                      style={{ marginRight: "2px" }}
                    />
                    {item.price.seller_location.zip.sub_province}
                  </ProductCard_WeightTag>
                ) : (
                  ""
                )}
                <ProductCard_PriceTag>
                  {item.price
                    ? new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 20,
                      }).format(item.price.price)
                    : ""}
                </ProductCard_PriceTag>
              </ProductCard_CardBody>
            </ProductCard_InnerContainer>
          </Link>
        </ProductCard_OuterContainer>
      ) : (
        ""
      )}
    </>
  );
};
