import React, { Component } from "react";
import {
  MDBCard,
  MDBCol,
  MDBRow,
  MDBContainer,
  MDBCardImage,
  MDBCardBody,
  MDBCardFooter,
  MDBIcon,
} from "mdbreact";
import style from "./Product.module.css";
import { Link, withRouter } from "react-router-dom";
import { Button } from "@material-ui/core";
import "../../../assets/css/fonts.css";

class ProductView extends Component {
  render() {
    const p = this.props.product;
    const { seller_style, type } = this.props;
    const imageContainer_style = {
      height: "120px",
      borderRadius: "0.25rem",
      marginBottom: "10px",
      display: "grid",
      placeItems: "center",
    };
    // console.log(p);
    return (
      <>
        {p === null
          ? " "
          : p.map((data, index) => {
              // console.log(data)
              const detail_flag =
                type === "all" ? data.price_product_single : data.price;
              if (detail_flag && (type === "all" ? index <= 8 : "true")) {
                return (
                  <div key={index}>
                    <Button
                      style={{ padding: "3px", width: "154px" }}
                      onClick={() => {
                        this.props.history.push(`/product_detail/${data.id}`);
                        window.scrollTo(0, 0);
                      }}
                    >
                      <MDBCard cascade ecommerce style={{ minHeight: "180px" }}>
                        <MDBContainer style={imageContainer_style}>
                          <img
                            className="img-fluid img-thumbnail z-depth-1"
                            src={
                              type === "all"
                                ? data.thumbnail.link
                                  ? data.thumbnail.link
                                  : ""
                                : data.img
                                ? data.img
                                : ""
                            }
                            style={{
                              maxHeight: "120px",
                            }}
                          />
                        </MDBContainer>
                        <MDBContainer
                          style={{
                            color: "white",
                            backgroundColor: seller_style.color_btn,
                            borderRadius: "0.3rem",
                            fontFamily: "Lato Regular",
                          }}
                        >
                          <MDBCardBody cascade className={style.cardBody}>
                            <div className={style.title}>{data.name}</div>
                            <div style={{fontSize:'9px'}}>{`${
                              type === "all"
                                ? data.price_product_single.weight
                                : data.price.weight
                            } gram`}</div>
                            {type === "all" ? (
                              "seller_location" in data.price_product_single ? (
                                <div style={{fontSize:'9px'}}>
                                  <MDBIcon
                                    icon="map-marker-alt"
                                    style={{ marginRight: "2px" }}
                                  />
                                  {`${
                                    type === "all"
                                      ? data.price_product_single
                                          .seller_location.zip.sub_province
                                      : data.price.seller_location.zip
                                          .sub_province
                                  }`}
                                </div>
                              ) : (
                                ""
                              )
                            ) : "seller_location" in data.price ? (
                              <div style={{fontSize:'9px'}}>
                                <MDBIcon
                                  icon="map-marker-alt"
                                  style={{ marginRight: "2px" }}
                                />
                                {`${
                                  type === "all"
                                    ? data.price_product_single.seller_location
                                        .zip.sub_province
                                    : data.price.seller_location.zip
                                        .sub_province
                                }`}
                              </div>
                            ) : (
                              ""
                            )}
                            {/* <MDBCardFooter className={style.cardFooter}> */}
                            <MDBCol className={style.price}>
                              {detail_flag
                                ? new Intl.NumberFormat("id-ID", {
                                    style: "currency",
                                    currency: "IDR",
                                  }).format(
                                    type === "all"
                                      ? data.price_product_single.price
                                      : data.price.price
                                  )
                                : ""}
                            </MDBCol>
                            {/* </MDBCardFooter> */}
                          </MDBCardBody>
                        </MDBContainer>
                      </MDBCard>
                    </Button>
                  </div>
                );
              }
            })}
      </>
    );
  }
}

export default withRouter(ProductView);
