import React, { Component } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBCard,
  MDBBtn,
  MDBIcon,
  MDBInputGroup,
} from "mdbreact";
import { Link } from "react-router-dom";
// import satuTokoLogo from "../../assets/toko1_ORIGINAL.askdlaskda.png";
// import styles from "./LoginView.module.css";
import { Text } from "../../context/Language";
import landing_img from "../../../assets/landing_img_2.png";
import { CMDBBtn } from "../StyledComponent";

export class LoginView extends Component {
  state = {
    passhide: true,
  };

  render() {
    return (
      <div style={{ backgroundColor: "#804199" }}>
        <form
          className="needs-validation"
          onSubmit={this.props.submitHandler}
          noValidate
        >
          <MDBRow
            style={{
              marginTop: "5rem",
              height: "100vh",
              backgroundColor: "#804199",
              margin: "0",
            }}
          >
            <MDBCol size="6" middle>
              <MDBRow center>
                <img
                  className="img-fluid"
                  src={landing_img}
                  style={{ maxHeight: "500px" }}
                />
              </MDBRow>
            </MDBCol>
            <MDBCol
              size="6"
              middle
              style={{ paddingLeft: "5%", paddingRight: "5%" }}
            >
              <div
                style={{
                  color: "white",
                  fontSize: "40px",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginBottom: "2rem",
                  padding: "0",
                }}
              >
                WELCOME BACK
              </div>
              <div
                style={{
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "bold",
                  textAlign: "start",
                }}
              >
                SIGN IN
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "1rem",
                }}
              >
                <input
                  value={this.props.email}
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="E-mail"
                  onChange={this.props.changeHandler}
                  style={{ borderRadius: "2rem" }}
                  required
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "1rem",
                }}
              >
                <input
                  className="form-control"
                  value={this.props.password}
                  onChange={this.props.changeHandler}
                  type={this.state.passhide ? "password" : "text"}
                  name="password"
                  placeholder="Password"
                  style={{ borderRadius: "2rem 0 0 2rem" }}
                  required
                />
                <MDBCol
                  middle
                  className="form-control"
                  style={{
                    borderRadius: "0 2rem 2rem 0",
                    backgroundColor: 'lightgray',
                  }}
                >
                  <MDBIcon
                    icon={this.state.passhide ? "eye" : "eye-slash"}
                    onClick={() =>
                      this.setState({ passhide: !this.state.passhide })
                    }
                    style={{ height: "24px", width: "24px" }}
                  />
                </MDBCol>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="custom-control custom-checkbox">
                  <input
                    className="custom-control-input"
                    type="checkbox"
                    id="rememberMe"
                    onChange={this.props.checkRememberMeHandler}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="rememberMe"
                    style={{ color: "white" }}
                  >
                    {<Text pid="loginPage" tid="rememberMeCheck" />}
                  </label>
                </div>
                <div>
                  <Link
                    to="#"
                    onClick={this.props.forgotPassword}
                    style={{ color: "white" }}
                  >
                    {<Text pid="loginPage" tid="forgotPass" />}
                  </Link>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "1rem",
                }}
              >
                <CMDBBtn
                  active
                  type="submit"
                  bg_color="#804199"
                  style={{ border: "2px solid white", borderRadius: "0.5rem" }}
                >
                  {<Text pid="loginPage" tid="login" />}
                </CMDBBtn>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "1rem",
                }}
              >
                <Link to="/register" style={{ color: "white" }}>
                  {<Text pid="loginPage" tid="toRegister" />}
                </Link>
              </div>
            </MDBCol>
          </MDBRow>
        </form>
      </div>
    );
  }
}

export default LoginView;
