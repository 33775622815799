import React, { Component } from "react";
import { MDBContainer, MDBDataTable, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import { CMDBBtn } from "../StyledComponent";
import "../../../assets/css/fonts.css";

class UserLocationView extends Component {
  render() {
    // console.log(this.props)
    const { seller_style } = this.props;
    return (
      <MDBContainer style={{ fontFamily: "Lato Regular" }}>
        <MDBRow>
          <MDBCol>
            <CMDBBtn
              active
              bg_color={seller_style.color_btn}
              onClick={() => this.props.popupModal("ADD", "1")}
            >
              TAMBAH ALAMAT
            </CMDBBtn>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <MDBDataTable
              hover
              noBottomColumns
              responsive
              data={this.props.data}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default UserLocationView;
