import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import ProductVarianPreview from "../ProductDetail/ProductVarianPreview";
import ProductDetailForm from "../ProductDetail/ProductDetailForm";
import SectionContainer from "../sectionContainer";
import ProductView from "../ProductHome/ProductView";
import "../../../assets/css/fonts.css";

class ProductDetailView extends Component {
  componentDidMount() {
    const product_id = this.props.match.params.id;
    const sellerId = this.props.sellerprm.subDomainData.id;
    this.props.producthome.getProductDetail(sellerId, product_id);
    this.props.producthome.getProductAllCategory(sellerId);
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const sellerId = this.props.sellerprm.subDomainData.id;
      let product_id = this.props.match.params.id;
      this.props.producthome.getProductDetail(sellerId, product_id);
    }
  }

  render() {
    const sellerId = this.props.sellerprm.subDomainData.id;
    const isSeller = this.props.sellerprm.isSeller;
    const { seller_style } = this.props.sellerprm.subDomainData;
    const {
      category,
      product_detail,
      productDetailPhoto,
      addToChartFromPage,
    } = this.props.producthome;
    // console.log(this.props)
    return (
      <div style={{ fontFamily: "Lato Regular" }}>
        {product_detail !== undefined ? (
          <MDBContainer style={{ marginTop: "5.5rem", minWidth: "1000px" }}>
            <MDBRow>
              <MDBCol size="6">
                <ProductVarianPreview
                  photo={productDetailPhoto}
                  seller_style={seller_style}
                />
              </MDBCol>
              <MDBCol size="6">
                <ProductDetailForm
                  sellerId={sellerId}
                  isSeller={isSeller}
                  seller_style={seller_style}
                  name={product_detail.name}
                  product_detail={product_detail}
                  addToChartFromPage={addToChartFromPage}
                />
              </MDBCol>
            </MDBRow>
            <hr />
            {/* <MDBRow>
              <MDBContainer style={{ minWidth: "1000px" }}>
                <MDBCard>
                  <MDBCardBody>
                    <MDBCardTitle>DESKRIPSI</MDBCardTitle>
                    <MDBCardText>{product_detail.description}</MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              </MDBContainer>
            </MDBRow> */}
            <br />
            <MDBRow>
              <MDBContainer
                style={{ minWidth: "1000px", fontFamily: "Lato Semi Bold" }}
              >
                <SectionContainer header="Produk Lain">
                  {category ? (
                    <div style={{ fontFamily: "Lato Regular" }}>
                      <MDBContainer style={{padding:'10px'}}>
                        <MDBRow end>
                          <Link to={"/category/" + product_detail.category_id}>
                            <p
                              style={{
                                color: "grey",
                                fontSize: 18,
                                fontWeight: "bold",
                                margin: 0,
                                marginRight: "10px",
                              }}
                            >
                              Lihat Semua
                            </p>
                          </Link>
                        </MDBRow>
                        <MDBRow>
                          <ProductView
                            type="all"
                            product={
                              category.find(
                                (data) => data.id == product_detail.category_id
                              ).product
                            }
                            seller_style={seller_style}
                          />
                        </MDBRow>
                      </MDBContainer>
                    </div>
                  ) : (
                    ""
                  )}
                </SectionContainer>
              </MDBContainer>
            </MDBRow>
          </MDBContainer>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default withRouter(ProductDetailView);
