const en = {
  landing: {
    welcome: "Welcome to 1toko",
    loginBtn: "Login",
    registerBtn: "Register",
    landingRegisterBtn: "Register Now",
  },
  registerPage: {
    makeAccountTitle: "MAKE NEW ACCOUNT",
    toRegister: "Already have an account?",
    submitRegister: "Register",
    emailInput: "Email",
    nameInput: "Name",
    noTlp: "Telephone Number",
    passInput: "Password",
    passInputHint:
      "Minimum of 6 characters, maximum of 20 characters, and at least have one capital character.",
    passConfirmInput: "Password Confirmation",
    setupUserTitle: "User Setup",
    setupStoreTitle: "Store Setup",
    storeNameInput: "Store Name",
    subDomainInput: "Website Address 1toko.com",
    domainInput: "Website Address External (Optional)",
    domainInputHint: 'Put your valid domain URL for the store "ex : store.com"',
    storeTemplateInput: "Store Template",
    isSellerRegister: "Register as seller",
    isCustomBannerCheck: "Banner Toko", //"Use custom banner?",
    isCustomLogoCheck: "Store Logo", //"Use custom logo?",
    logoStoreInput: "Store Logo",
    bannerStoreInput: "Banner Toko",
    checkingDomain: "Mengecek domain...",
    availableDomain: "Domain tersedia!",
    unavailableDomain:
      "Maaf, domain ini tidak tersedia, silahkan gunakan domain lain",
  },
  loginPage: {
    forgotPass: "Forgot Password?",
    login: "Login",
    toRegister: "Don't have an account yet?",
    emailInput: "Email",
    passInput: "Password",
    rememberMeCheck: "Remember Me",
  },
  t1_header: {
    productBtn: "Products",
    aboutBtn: "About Us",
    faqBtn: "FAQ",
    cartBtn: "Cart",
    loginBtn: "Login",
  },
  t2_header: {
    productBtn: "Products",
    aboutBtn: "About",
    faqBtn: "FAQ",
    cartBtn: "Cart",
    loginBtn: "Login",
  },
  sellerMenu_header: {
    sellerLocationBtn: "Manage Location",
    sellerCategoryBtn: "Manage Category",
    sellerProfileBtn: "Seller Profile",
    sellerAboutSettingBtn: "Store Profile",
    sellerProductBtn: "Manage Products",
    sellerBankBtn: "Manage Bank Account",
  },
  userMenu_header: {
    helloText: "Hello, ",
    profileBtn: "Profile",
    logoutBtn: "Logout",
  },
  changePass: {
    title: "Reset Password",
    title2: "Change Password",
    old_passInput: "Old Password",
    new_passInput: "New Password",
    conf_passInput: "Confirmation Password",
    submitBtn: "Submit",
    closeBtn: "Close",
  },
  profileUser: {
    title: "User Profile",
    tap_1: "Profile",
    tap_2: "User Location",
    name: "Name",
    no_tlp: "Telephon Number",
    email: "E-mail",
    submitBtn: "Update Profile",
    editBtn: "Edit Profile",
    cancelBtn: "Cancel",
    changePassBtn: "Change Password",
  },
  profileSeller: {
    title: "Seller Profile",
  },
};

// const en = {
//   welcome: "Welcome",
// };

export default en;
