import React, { Component } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBInput,
  MDBRow,
  MDBCol,
  MDBFileInput,
  MDBSelect,
} from "mdbreact";
import ImagePreview from "../ImagePreview/ImagePreview";

class SellerCategoryFormModal extends Component {
  render() {
    const styleLogo = {
      maxWidth: "150px",
      height: "auto",
    };

    const styleContainerLogo = {
      textAlign: "center",
    };
    return (
      <MDBContainer>
        <MDBModal
          isOpen={this.props.modalState}
          toggle={() => this.props.toggleHandler("CLOSE")}
        >
          <MDBModalHeader>
            {this.props.actionType === "ADD"
              ? "Tambah Kategori"
              : "Ubah Kategori"}
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow>
              <MDBCol>
                <MDBInput
                  onChange={this.props.checkDefaultIconHandler}
                  type="checkbox"
                  className="form-control"
                  name="isDefaultIcon"
                  id="isDefaultIcon"
                  label="Default Icon"
                  checked={this.props.isDefaultIcon}
                />
              </MDBCol>
            </MDBRow>
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    value={this.props.name}
                    onChange={this.props.changeHandler}
                    type="text"
                    className="form-control"
                    name="name"
                    label="Nama Kategori"
                    required
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  {this.props.isDefaultIcon ? (
                    <>
                      <MDBSelect
                        search
                        color="primary"
                        options={this.props.defaultIconList}
                        selected="Pilih default icon"
                        label="Default Icon"
                        getValue={this.props.selectDefaultIconHandler}
                      />
                      {this.props.previewIconDefault ? (
                        <MDBRow>
                          <MDBCol>
                            <ImagePreview
                              img={this.props.previewIconDefault}
                              alt={"previewIconDefault"}
                              style={styleLogo}
                              containerStyle={styleContainerLogo}
                            />
                          </MDBCol>
                        </MDBRow>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <>
                      <MDBFileInput
                        textFieldTitle="Gambar Kategori"
                        getValue={this.props.selectIconCategoryHandler}
                      />
                      {this.props.previewIcon ? (
                        <MDBRow>
                          <MDBCol>
                            <ImagePreview
                              img={this.props.previewIcon}
                              alt={"previewIcon"}
                              style={styleLogo}
                              containerStyle={styleContainerLogo}
                            />
                          </MDBCol>
                        </MDBRow>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </MDBCol>
              </MDBRow>
              {/* {console.log(this.props.updateIconOriginal)}
              {console.log(this.props.previewIcon)}
              {console.log(this.props.previewIconDefault)} */}
              {this.props.updateIconOriginal &&
              !this.props.previewIcon &&
              !this.props.previewIconDefault ? (
                <MDBRow>
                  <MDBCol>
                    <ImagePreview
                      img={this.props.updateIconOriginal}
                      alt={"updateIconOriginal"}
                      style={styleLogo}
                      containerStyle={styleContainerLogo}
                    />
                  </MDBCol>
                </MDBRow>
              ) : (
                ""
              )}
            </MDBContainer>
          </MDBModalBody>
          <MDBModalFooter center>
            <MDBBtn
              color="success"
              onClick={
                this.props.actionType === "ADD"
                  ? this.props.storeSellerCategory
                  : this.props.updateSellerCategory
              }
            >
              {this.props.actionType === "ADD" ? "Tambah" : "Ubah"}
            </MDBBtn>
            <MDBBtn
              onClick={() => this.props.toggleHandler("CLOSE")}
              outline
              color="success"
            >
              Batal
            </MDBBtn>
          </MDBModalFooter>
          {/* </form> */}
        </MDBModal>
      </MDBContainer>
    );
  }
}

export default SellerCategoryFormModal;
