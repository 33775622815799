import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { MDBContainer} from "mdbreact";
import CartItem from "./CartItem/CartItem";
// import { Text } from "../../context/Language";
import { CartTotalWithCheckout } from "./CartTotalWithCheckout/CartTotalWithCheckout";
import UserLocationFormModal from "../UserLocation/UserLocationFormModal";
import { CenterLoader } from "../_UtilityComponent/Loader/CenterLoader";
import { CartTitlePage } from "./CartTitlePage/CartTitlePage";

class CartMain extends Component {
  state = {};

  componentDidMount() {
    if (this.props.sellerPrm.subDomainData) {
      this.props.cart.listData(this.props.sellerPrm.subDomainData.id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (this.props.sellerPrm.subDomainData) {
        this.props.cart.listData(this.props.sellerPrm.subDomainData.id);
      }
    }
  }

  render() {
    const { cart } = this.props;
    return (
      <>
        {!cart.isLoading ? (
          <>
            <UserLocationFormModal
              {...cart}
              openState={cart.modal1}
              fromCart={true}
              // method
              popUpModal={cart.popAddressModal}
              loadOptions={cart.loadOptions}
              changeHandler={cart.changeHandler}
              changeSelectHandler={cart.changeSelectHandler}
              storeUserLocation={cart.storeUserLocation}
            />
            <MDBContainer>
              <CartTitlePage
                cartTotalItem={cart.cartTotalItem}
                checkAll={cart.checkAll}
                changeHandlerCheckAll={cart.changeHandlerCheckAll}
                sellerId={this.props.sellerPrm.subDomainData.id}
              />
              <CartItem
                cart={cart.cart}
                cartTotalItem={cart.cartTotalItem}
                sellerId={this.props.sellerPrm.subDomainData.id}
                //method
                actionUpdate={cart.actionUpdate}
                updateCart={cart.updateCart}
                changeHandlerNote={cart.changeHandlerNote}
                deleteCart={cart.deleteCart}
                changeHandlerCheck={cart.changeHandlerCheck}
                changeHandlerCheckGroup={cart.changeHandlerCheckGroup}
              />
              {cart.cartTotalItem > 0 ? (
                <CartTotalWithCheckout
                  total={cart.totalPriceCart}
                  cart={cart.cart}
                  checkUserLocationData={cart.checkUserLocationData}
                />
              ) : (
                ""
              )}
            </MDBContainer>
          </>
        ) : (
          <CenterLoader />
        )}
      </>
    );
  }
}

export default withRouter(CartMain);
