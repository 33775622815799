import axios from "axios";

export default axios.create({
  // baseURL: "http://api.1toko.spindev.xyz/api",
  // baseURL: "https://api.1toko.kliksekolah.com/public/api",
  // pake https
  baseURL: "https://dev.api.1toko.com/api",
  // baseURL: "http://192.168.0.12/api.1toko/public/api",
  // baseURL: "http://localhost/api.1toko/public/api",
  responseType: "json",
});