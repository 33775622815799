import React, { Component } from "react";
import CPTokenView from "../components/ChangePasswordView/CPTokenView";
import API from "../../utils/API";
import Swal from "sweetalert2";

export default class ChangePasswordToken extends Component{
    constructor(props){
        super(props);
        this.state={
            new_password :"",conf_password:"",flag : 0
        };
        this.config = {
            headers: {
                "X-Requested-With": "XMLHttpRequest",
            },
        };
    }

    componentDidMount(){
        //cek token
        // console.log(this.props)
        let token = this.props.match.params.token;
        this.checkToken(token);
    }

    checkToken = (token) =>{
       API.get("password/check_reset_token/"+token,this.config)
       .then((res)=>{
            this.setState(()=>{ return {flag : 1}})
       }).catch((error)=>{
        Swal.fire({
            icon: "error",
            html: "Invalid Reset Password Token",
          }).then(()=>{
             this.props.history.push("/");
          });
       });
    };

    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    ChangePasswordTokenEvent = (data)=>{
        API.post("password/reset_password_with_token",data,this.config)
        .then((res)=>{
            Swal.fire({
                icon: "success" ,
                html:"Password has been changed"
            }).then(()=>{
                this.props.history.push("/");
            });    
        }).catch((error)=>{
            Swal.disableLoading();
            if (error.response) {
                /*
                * The request was made and the server responded with a
                * status code that falls out of the range of 2xx
                */
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                if (error.response) {
                let errmessage = "";
                let keys = Object.keys(error.response.data.errors);
                for (let i = 0; i < keys.length; i++) {
                    errmessage +=
                    keys[i] + " " + error.response.data.errors[keys[i]][0] + "<br/>";
                }
                Swal.fire({
                    icon: "error",
                    html: errmessage,
                });
                }
            } else if (error.request) {
                /*
                * The request was made but no response was received, `error.request`
                * is an instance of XMLHttpRequest in the browser and an instance
                * of http.ClientRequest in Node.js
                */
                console.log(error.request);
            } else {
                // Something happened in setting up the request and triggered an Error
                console.log("Error", error.message);
            }
            console.log(error.config);
        });
    }

    changePassToken = (event) =>{
        const { new_password,conf_password } = this.state;
        let token = this.props.match.params.token;
        Swal.fire({
            title: "Loading ...",
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });

        event.preventDefault();
        const data = new FormData();
        data.append("password",new_password);
        data.append("password_confirmation",conf_password);
        data.append("token",token);
        data.append("url",window.location.hostname);
        const passregex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");
        if(new_password !== "" && conf_password !== ""){
            if(passregex.test(new_password)){
                if(new_password === conf_password){
                    this.ChangePasswordTokenEvent(data);
                }else{
                    Swal.fire({
                        icon: "error" ,
                        html:"New Password and  Confirmation Password is not match"
                    });
                }
            }else{
                Swal.fire({
                    icon: "error" ,
                    html:"New Password must be 6 character or more and "
                        +"contain lowercase and uppercase alphabet, "
                        +"and numeric character"
                });
            }
        }else{
            Swal.fire({
                icon: "error" ,
                html:"Please fill all data"
            });
        }
    }

    render(){
        if(this.state.flag === 1){
            return(
                <>
                    <CPTokenView
                        changeHandler = {this.changeHandler}
                        changePassToken ={this.changePassToken}
                    />
                </>
            );
        }else{
            return(<></>);
        }
    }
}