import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React, { Component } from "react";
import logo from "../../../assets/lg_1toko.png";

export default class InvoicePrintView extends Component {
  formatDate = (string) => {
    var options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(string).toLocaleDateString("ID", options);
  };

  formatCurrency = (country, currency, text) => {
    return new Intl.NumberFormat(country, {
      style: "currency",
      currency: currency,
    }).format(text);
  };

  render() {
    const container_style = {
      padding: "5rem",
      // border: "solid 2px black",
      minWidth: "595px",
      margin: "0",
    };
    const { data } = this.props;
    const data_json = data !== undefined ? JSON.parse(data.data_json) : "";
    let invoice_subtotal = 0;
    // console.log(data_json);
    // console.log(data);
    return (
      <MDBContainer style={container_style}>
        {data !== undefined ? (
          <div className="w-100">
            <MDBContainer>
              <MDBRow center className="w-100" style={{ marginBottom: "2rem" }}>
                <img
                  className="img-fluid"
                  src={logo}
                  style={{ maxHeight: "7rem" }}
                />
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <span>
                    <span style={{ fontWeight: "bold" }}>
                      Tanggal
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    {this.formatDate(data.payment.created_at)}
                  </span>
                  <br />
                  <span>
                    <span style={{ fontWeight: "bold" }}>
                      Pembayaran &nbsp;&nbsp;&nbsp;
                    </span>
                    {data.payment.payment_method}
                  </span>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <table
                  className="w-100"
                  style={{
                    marginLeft: "10px",
                    marginRight: "20px",
                    textAlign: "center",
                  }}
                >
                  <tr
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    <th
                      colSpan="3"
                      style={{
                        fontWeight: "bold",
                        fontSize: "15",
                        textAlign: "left",
                        backgroundColor: "grey",
                      }}
                    >{`Ringkasan Pembayaran (${data.payment.total_invoice} Invoice)`}</th>
                  </tr>
                  <tr
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    <td colSpan="3">
                      <table style={{ width: "98%", marginLeft: "20px" }}>
                        <tr>
                          <td
                            colSpan="3"
                            style={{
                              textAlign: "left",
                              fontWeight: "bold",
                            }}
                          >
                            Total Belanja
                          </td>
                        </tr>
                        {data.payment.invoice.map((inv, index) => {
                          return (
                            <tr key={index} style={{ textAlign: "center" }}>
                              <td
                                colSpan="3"
                                style={{
                                  minWidth:'300px',
                                  textAlign: "left",
                                  fontWeight: "bold",
                                }}
                              >
                                {`- ${data.id}`}
                              </td>
                              <td
                                style={{textAlign: "end", paddingRight:'30px' }}
                              >
                                {this.formatCurrency(
                                  "ID",
                                  "IDR",
                                  data.subtotal
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </td>
                  </tr>
                  <tr
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    <td
                      colSpan="2"
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        paddingLeft: "20px",
                      }}
                    >
                      Subtotal Belanja
                    </td>
                    <td style={{textAlign:'end', paddingRight:'30px'}}>
                      {this.formatCurrency(
                        "ID",
                        "IDR",
                        data.payment.payment_total
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "500px" }}></td>
                    <td
                      style={{
                        minWidth:'200px',
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                        borderRight: "none",
                      }}
                    >
                      Total Bayar
                    </td>
                    <td
                      style={{ border: "1px solid black", borderLeft: "none",textAlign:'end', paddingRight:'30px' }}
                    >
                      {this.formatCurrency(
                        "ID",
                        "IDR",
                        data.payment.payment_total
                      )}
                    </td>
                  </tr>
                </table>
              </MDBRow>
            </MDBContainer>
            <hr style={{ border: "1px dashed black" }} />
            <hr style={{ border: "1px dashed black" }} />
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <img
                    className="img-fluid"
                    src={logo}
                    style={{ maxHeight: "3rem" }}
                  />
                  <br />
                  <span>Nomor Invoice : {data.id}</span>
                  <br />
                  <span>Diterbitkan atas nama : </span>
                  <br />
                  <span>
                    <span style={{ fontWeight: "bold" }}>
                      Penjual &nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    {data.payment.seller_name}
                  </span>
                  <br />
                  <span>
                    <span style={{ fontWeight: "bold" }}>
                      Tanggal &nbsp;&nbsp;&nbsp;
                    </span>
                    {this.formatDate(data.created_at)}
                  </span>
                  <br />
                </MDBCol>
                <MDBCol>
                  <span style={{ fontWeight: "bold" }}>
                    Tujuan Pengiriman :{" "}
                  </span>
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    {data.customer_name}
                  </span>
                  <br />
                  <span>{`${data_json.cus_location.address}, ${data_json.cus_location.zip_id.value.district} `}</span>
                  <br />
                  <span>{`${data_json.cus_location.zip_id.value.sub_province} ${data_json.cus_location.zip_id.value.zip}`}</span>
                  <br />
                  <span>{`${data_json.cus_location.zip_id.value.province}`}</span>
                  <br />
                  <span>
                    {data.customer_tlp === "" ? "-" : data.customer_tlp}
                  </span>
                  <br />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
            <hr style={{ border: "1px dashed black" }} />
            <MDBContainer>
              <MDBRow>
                <table
                  className="w-100"
                  style={{
                    marginLeft: "10px",
                    marginRight: "20px",
                    border: "thin dashed black",
                    textAlign: "center",
                  }}
                >
                  <tr style={{ backgroundColor: "grey" }}>
                    <th>Nama Produk</th>
                    <th>Jumlah</th>
                    <th>Berat</th>
                    <th>Harga Barang</th>
                    <th>Subtotal</th>
                  </tr>
                  {data.dtl_invoice.map((produk, index) => {
                    if (produk.product_group_id !== "") {
                      let subtotal =
                        Number(produk.product_price) *
                        Number(produk.product_quantity);
                      invoice_subtotal += subtotal;
                      return (
                        <tr key={index}>
                          <td>{produk.product_name}</td>
                          <td>{produk.product_quantity}</td>
                          <td>{`${produk.product_weight} gr`}</td>
                          <td>{produk.product_price}</td>
                          <td style={{ textAlign: "left" }}>
                            {this.formatCurrency("ID", "IDR", subtotal)}
                          </td>
                        </tr>
                      );
                    } else {
                      return "";
                    }
                  })}
                  <tr>
                    <td colSpan="5">
                      <hr style={{ border: "thin solid black" }} />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3" style={{ width: "250px" }}></td>
                    <td style={{ padding: "0", fontWeight: "bold" }}>
                      Subtotal Harga Barang
                    </td>
                    <td style={{ width: "100px", textAlign: "left" }}>
                      {this.formatCurrency("ID", "IDR", invoice_subtotal)}
                    </td>
                  </tr>
                </table>
                <MDBRow
                  className="w-100"
                  end
                  style={{ marginTop: "1rem", paddingRight: "5px" }}
                >
                  <table
                    style={{ width: "300px", border: "thin dashed black" }}
                  >
                    <tr>
                      <td
                        style={{ paddingLeft: "5px" }}
                      >{`${data.courier_name} - ${data.courier_service} (Berat : ${data.total_weight} gr)`}</td>
                      <td>
                        {this.formatCurrency("ID", "IDR", data.courier_price)}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold", paddingLeft: "5px" }}>
                        Ongkos Kirim
                      </td>
                      <td>
                        {this.formatCurrency("ID", "IDR", data.courier_price)}
                      </td>
                    </tr>
                  </table>
                </MDBRow>

                <MDBRow
                  className="w-100"
                  end
                  style={{ marginTop: "1rem", paddingRight: "5px" }}
                >
                  <table
                    style={{ width: "300px", border: "thin dashed black" }}
                  >
                    <tr>
                      <td
                        style={{
                          width: "200px",
                          fontWeight: "bold",
                          paddingLeft: "5px",
                        }}
                      >
                        Total Bayar
                      </td>
                      <td>{this.formatCurrency("ID", "IDR", data.subtotal)}</td>
                    </tr>
                  </table>
                </MDBRow>
              </MDBRow>
            </MDBContainer>
          </div>
        ) : (
          ""
        )}
      </MDBContainer>
    );
  }
}
