import { MDBIcon } from "mdbreact";
import React from "react";
import { PageContainer } from "../LandingView/Landing.styles";
import { ManageSellerListContainer } from "./ManageSellerLocationCategory.styles";
import {
  ManageSellerListItemProductsDesc,
  ManageSellerListItemProductsStat,
  ManageSellerListItemProductsTitle,
  ManageSellerListItemProductsVariant,
  ManageSellerListProductsIconContainer,
} from "./ManageSellerProducts.styles";

export const ManageSellerProductsVariantLists = (props) => {
  return (
    <ManageSellerListContainer>
      {props.dataDetail.map((item, index) => {
        return (
          <ManageSellerProductsVariantList
            item={item}
            key={index}
            page={props.page}
            // modalHandler={props.modalHandler}
            history={props.history}
            beActiveDetailProduct={props.beActiveDetailProduct}
            sellerId={props.sellerId}
          />
        );
      })}
    </ManageSellerListContainer>
  );
};

const ManageSellerProductsVariantList = (props) => {
  console.log(props.item);
  return (
    <ManageSellerListItemProductsVariant>
      <ManageSellerListItemProductsDesc>
        <ManageSellerListItemProductsTitle>
          {props.item.name}
        </ManageSellerListItemProductsTitle>
        {props.item.price && props.item.stock
          ? (<> {new Intl.NumberFormat("id-ID", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 0,
              maximumFractionDigits: 20,
            }).format(props.item.price)} | {props.item.stock} barang </>): ""}
        {parseInt(props.item.is_active) === 1 ? (
          <>
            <ManageSellerListItemProductsStat
              isActive
              onClick={() => {
                props.beActiveDetailProduct(
                  props.item.id,
                  props.item.group_product_id,
                  props.item.is_active,
                  props.sellerId
                );
              }}
            >
              <MDBIcon style={{ marginRight: "5px" }} icon="check-circle" />
              Aktif
            </ManageSellerListItemProductsStat>
          </>
        ) : (
          <>
            <ManageSellerListItemProductsStat
            onClick={() => {
              props.beActiveDetailProduct(
                props.item.id,
                props.item.group_product_id,
                props.item.is_active,
                props.sellerId
              );
            }}
            >
              <MDBIcon style={{ marginRight: "5px" }} icon="times-circle" />
              Tidak Aktif
            </ManageSellerListItemProductsStat>
          </>
        )}
      </ManageSellerListItemProductsDesc>
      <ManageSellerListProductsIconContainer>
        <MDBIcon
          icon="edit"
          onClick={() => {
            props.history.push(
              `${props.history.location.pathname}/edit/${props.item.id}`
            );
          }}
        />
      </ManageSellerListProductsIconContainer>
    </ManageSellerListItemProductsVariant>
  );
};
