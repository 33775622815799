import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBDataTable,
  MDBCol,
  MDBRow,
} from "mdbreact";
import { Link } from "react-router-dom";
import "../../../assets/css/fonts.css";
import SectionContainer from "../sectionContainer";
import { CMDBBtn } from "../StyledComponent";

const SellerProductsView = (props) => {
  return (
    <MDBContainer
      style={{
        minWidth: "1000px",
        paddingTop: "6rem",
        fontFamily: "Lato Semi Bold",
      }}
    >
      <SectionContainer header={`Manage Product`}>
        <MDBCard style={{ fontFamily: "Lato Regular" }}>
          <MDBRow>
            <MDBCol>
              <Link to={"/admin/sellerproducts/add-product"}>
                <CMDBBtn active bg_color={props.seller_style.color_btn}>
                  Tambah Produk
                </CMDBBtn>
              </Link>
            </MDBCol>
            <MDBCol />
          </MDBRow>
          <MDBCardBody>
            <MDBDataTable hover noBottomColumns responsive data={props.data} />
          </MDBCardBody>
        </MDBCard>
      </SectionContainer>
    </MDBContainer>
  );
};

export default SellerProductsView;
