import React, { useState, createContext, useContext } from "react";
import { languageOptions, dictionaryList } from "../languages";
import Cookies from "js-cookie";

// create the language context with default selected language
const langID = Cookies.get("langID");
let languageState;

if (langID) {
  languageState = {
    language: languageOptions[langID],
    dictionary: dictionaryList[languageOptions[langID].id],
  };
} else {
  languageState = {
    language: languageOptions[0],
    dictionary: dictionaryList[languageOptions[0].id],
  };
}

export const LanguageContext = createContext(languageState);

// it provides the language context to app
export function LanguageProvider(props) {
  const languageContext = useContext(LanguageContext);
  const [language, setLanguage] = useState(languageContext.language);
  const [dictionary, setDictionary] = useState(languageContext.dictionary);

  const provider = {
    language,
    dictionary,
    setLanguage: (selectedLanguage) => {
      setLanguage(selectedLanguage);
      setDictionary(dictionaryList[selectedLanguage.id]);
      Cookies.set("langID", selectedLanguage.index);
    },
  };

  return (
    <LanguageContext.Provider value={provider}>
      {props.children}
    </LanguageContext.Provider>
  );
}

// get text according to id & current language
export function Text(props) {
  const languageContext = useContext(LanguageContext);
  const textField = languageContext.dictionary[props.pid][props.tid];
  return textField;
}
