import React, { Component } from "react";
import { MDBCol, MDBRow, MDBContainer, MDBSelect, MDBBtn } from "mdbreact";
import { Text } from "../../context/Language";
import style from "./ProductPage.module.css";
import InputNumber from "./InputNumber/InputNumber";
import Cookies from "js-cookie";
import { withRouter } from "react-router-dom";
import { RoundedDefaultButton } from "../_UtilityComponent/Button/RoundedButton";
import DiscussionMain from "./Discussion/DiscussionMain";
import { StyledButtonTheming } from "../_UtilityComponent/Button/StyledButton";

const nl2br = require("react-nl2br");

class ProductPageView extends Component {
  render() {
    const { product, selectedDetail } = this.props;
    return (
      <MDBContainer className={style.product__container}>
        <MDBRow className={style.product__container_row}>
          <MDBCol className={style.product__container_row_mainTitle}>
            {product.name}
          </MDBCol>
        </MDBRow>
        <MDBRow className={style.product__container_row}>
          <MDBCol className={style.product__container_row_price}>
            {new Intl.NumberFormat("id-ID", {
              style: "currency",
              currency: "IDR",
              minimumFractionDigits: 0,
              maximumFractionDigits: 20,
            }).format(selectedDetail.price)}
          </MDBCol>
        </MDBRow>
        <MDBRow className={style.product__container_row}>
          <MDBCol>
            <div className={style.product__container_row_dtlTitle}>
              <Text pid="productPage" tid="detailSelect" />
            </div>
            <MDBSelect
              className={style.product__container_row_select}
              options={this.props.detailSelect}
              color="primary"
              getValue={this.props.selectDetailHandler}
            />{" "}
          </MDBCol>
        </MDBRow>
        <MDBRow className={style.product__container_row}>
          <MDBCol className={style.product__container_row_dtlTitle}>
            Stok
          </MDBCol>
          <MDBCol className={style.product__container_row_stock}>
            {selectedDetail.stock}
          </MDBCol>
        </MDBRow>
        <MDBRow className={style.product__container_row}>
          <MDBCol className={style.product__container_row_dtlTitle}>
            Berat
          </MDBCol>
          <MDBCol className={style.product__container_row_weight}>
            {selectedDetail.weight} gr
          </MDBCol>
        </MDBRow>
        <MDBRow className={style.product__container_row}>
          <MDBCol>
            <div className={style.product__container_row_dtlTitle}>
              <Text pid="productPage" tid="quantityInput" />
            </div>
            {/* <input
              value={this.props.quantity}
              onChange={this.props.changeHandler}
              type="number"
              className={style.product__container_row_input}
              name="quantity"
              required
            /> */}
            <InputNumber
              value={this.props.qty}
              increase={() => this.props.inpNumberChange("INC")}
              decrease={() => this.props.inpNumberChange("DEC")}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow className={style.product__container_row}>
          <MDBCol className={style.product__container_row_addToCart}>
            {this.props.qty !== 0 || undefined ? (
              Cookies.get("access_token") ? (
                <StyledButtonTheming
                  className="w-100"
                  onClick={() => this.props.addCart(this.props.sellerId)}
                >
                  <Text pid="productPage" tid="addToCart" />
                </StyledButtonTheming>
              ) : (
                <StyledButtonTheming
                  onClick={() =>
                    this.props.history.push({
                      pathname: "/login",
                      state: { from: this.props.location },
                    })
                  }
                >
                  <Text pid="productPage" tid="addToCart" />
                </StyledButtonTheming>
              )
            ) : (
              <StyledButtonTheming disabled>
                <Text pid="productPage" tid="addToCart" />
              </StyledButtonTheming>
            )}
          </MDBCol>
        </MDBRow>
        <MDBRow className={`${style.product__container_row} my-2`}>
          <MDBCol className={style.product__container_row_subTitle}>
            <Text pid="productPage" tid="productDescription" />
          </MDBCol>
        </MDBRow>
        <MDBRow className={`${style.product__container_row} my-2`}>
          <MDBCol className={style.product__container_row_desc}>
            {product.description && product.description.length >= 250 ? (
              this.props.showDesc ? (
                <>
                  {nl2br(this.props.product.description)}
                  <br />
                  <br />
                  <div
                    className="prod-read-more"
                    style={{ fontWeight: "500" }}
                    onClick={() => this.props.readMore(false)}
                  >
                    <Text pid="productPage" tid="showLess" />
                  </div>
                </>
              ) : (
                <>
                  {nl2br(
                    this.props.product.description.substring(0, 250) + "..."
                  )}
                  <br />
                  <br />
                  <div
                    className="prod-read-more"
                    style={{ fontWeight: "500" }}
                    onClick={() => this.props.readMore(true)}
                  >
                    <Text pid="productPage" tid="showMore" />
                  </div>
                </>
              )
            ) : (
              ""
            )}
            {product.description && product.description.length < 250 ? (
              <>{nl2br(product.description)}</>
            ) : (
              ""
            )}
          </MDBCol>
        </MDBRow>
        <MDBRow className={`${style.product__container_row} my-2`}>
          <MDBCol className={style.product__container_row_subTitle}>
            <Text pid="productPage" tid="productDiscussion" />
          </MDBCol>
        </MDBRow>
        <MDBRow className={`${style.product__container_row} my-2`}>
          <MDBCol className={style.product__container_row_discussion}>
            <DiscussionMain
              discIsLoading={this.props.discIsLoading}
              discussionPage={"PRODUCT"}
              discussionList={this.props.discussionList}
              isLoading={this.props.isLoading}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default withRouter(ProductPageView);
