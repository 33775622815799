import React from "react";
import queryString from "query-string";
import LandingView from "../components/LandingView/LandingView";

class Landing extends React.Component {
  state = {
    query: "",
    data: [],
    isLoading: false,
    isError: false,
  };

  searchChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  searchSubmitHandler = (event) => {
    if (event) {
      event.preventDefault();
    }

    const { history } = this.props;
    const { query } = this.state;

    if (history && query) {
      history.push("/search?q=" + this.state.query);
      this.setState({ query: "" });
    }
  };

  render() {
    return (
      <LandingView
        {...this.state}
        searchChangeHandler={this.searchChangeHandler}
        searchSubmitHandler={this.searchSubmitHandler}
      />
    );
  }
}

export default Landing;
