import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon, MDBFooter } from "mdbreact";
import Banner from "../../components/HeaderAfterLogin/Banner";
import ProductHome from "../../pages/ProductHome";
import background from "../../../assets/template/bg_template_1.jpg";
import SellerPrmContext from "../../../SellerPrmContext";
// import "../css/Home.css";
// import styles from "../css/Home.module.css";

class Home extends React.Component {
  render() {
    const { seller_style } = this.context.subDomainData;
    const banner_color = "#FFFFFF";
    const product_about_color = "#FFFFFF";
    const product_color = "#FFFFFF";
    const footer_color = "#FFFFFF";

    const btn_color = "dark";
    const tab_color = "#FFFFFF";
    const tab_color_btn = "#000000";
    const border_color = "#000000";
    const background_transparent = "color";

    return (
      <div>
        <div id="banner" style={{ backgroundColor: banner_color }}>
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <Banner />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <br />
        </div>
        <ProductHome
          seller_style={seller_style}
          background_transparent={background_transparent}
          product_color={product_color}
          product_about_color={product_about_color}
          tab_color={tab_color}
          btn_color={btn_color}
          border_color={border_color}
          tab_color_btn={tab_color_btn}
        />
        <div id="footer" style={{ backgroundColor: footer_color }}>
          {/* <MDBFooter className="pt-0"> */}
          <MDBContainer>
            <MDBRow>
              <MDBCol md="12">
                <div
                  className="text-center d-flex justify-content-center my-4"
                  style={{ color: "black" }}
                >
                  <a href="#!">
                    <MDBIcon
                      fab
                      icon="facebook"
                      size="2x"
                      className="p-2 m-2 fa-lg mr-md-4"
                      style={{ color: "#000000" }}
                    />
                  </a>
                  <a href="#!">
                    <MDBIcon
                      fab
                      icon="twitter"
                      size="2x"
                      className="p-2 m-2 fa-lg mr-md-4"
                      style={{ color: "#000000" }}
                    />
                  </a>
                  <a href="#!">
                    <MDBIcon
                      fab
                      icon="google-plus"
                      size="2x"
                      className="p-2 m-2 fa-lg mr-md-4"
                      style={{ color: "#000000" }}
                    />
                  </a>
                  <a href="#!">
                    <MDBIcon
                      fab
                      icon="linkedin"
                      size="2x"
                      className="p-2 m-2 fa-lg mr-md-4"
                      style={{ color: "#000000" }}
                    />
                  </a>
                  <a href="#!">
                    <MDBIcon
                      fab
                      icon="instagram"
                      size="2x"
                      className="p-2 m-2 fa-lg mr-md-4"
                      style={{ color: "#000000" }}
                    />
                  </a>
                  <a href="#!">
                    <MDBIcon
                      fab
                      icon="pinterest"
                      size="2x"
                      className="p-2 m-2 fa-lg mr-md-4"
                      style={{ color: "#000000" }}
                    />
                  </a>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          {/* </MDBFooter> */}
        </div>
      </div>
    );
  }
}

export default Home;
Home.contextType = SellerPrmContext;
