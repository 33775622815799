import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBDatePicker,
  MDBInput,
  MDBRow,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions,
  MDBTimePicker,
} from "mdbreact";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import SectionContainer from "../../sectionContainer";
import { CMDBBadge, CMDBBtn, NSCPickupForm } from "../../StyledComponent";
import TrackingViewModal from "../../Tracking/TrackingViewModal";
import InvoiceView from "./InvoiceView";
import SellerLocationListModal from "./SellerLocationListModal";

class OrderConfirmationView extends Component {
  state = {
    selected_page: "1",
    selected_store_id: undefined,
    selected_store_name: undefined,
    selected_process: "1",
    selected_courier: undefined,
  };

  componentDidMount() {
    this.initPage();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.initPage();
    }
  }

  initPage = () => {
    // console.log(this.props);
    const { match, getSellerLocationList } = this.props;
    const s_id = match.params.s_id ? match.params.s_id : "";
    const s_name = match.params.s_name ? match.params.s_name : "";
    const process = match.params.process
      ? JSON.parse(match.params.process)
      : "";
    this.props.getCourierList();
    this.props.getNCSVehickeList();
    if (s_id !== "") getSellerLocationList(s_id);
    this.setState(
      {
        selected_store_id: s_id,
        selected_store_name: s_name,
        selected_process: process,
        selected_courier: undefined,
      },
      () => {
        if (process !== "") this.handleSelectedProcess(process, "");
      }
    );
  };

  handleSelectedProcess = (status, courier) => {
    console.log();
    this.props.getAllStoreInvoice(
      status,
      this.state.selected_store_id,
      courier
    );
    this.setState({ selected_process: status });
  };

  handleSelectStore = (store) => {
    const { getSellerLocationList } = this.props;
    getSellerLocationList(store.id);
    this.setState({
      selected_store_id: store.id,
      selected_store_name: store.name,
      selected_process: "",
      selected_courier: undefined,
    });
  };

  handleSelectCourier = (event) => {
    const courier_index = event[0];
    this.props.getAllStoreInvoice(
      this.state.selected_process,
      this.state.selected_store_id,
      courier_index
    );
    this.setState({ selected_courier: courier_index });
  };

  setInvoiceView = (store_name) => {
    const {
      invoice_list,
      handelPickup,
      updateInvoiceStatus,
      getTrackingStatus,
    } = this.props;
    return (
      <div style={{ minWidth: "500px" }}>
        <br />
        {invoice_list.map((invoice, index) => {
          return (
            <InvoiceView
              {...this.props}
              key={index}
              idx={index}
              invoice={invoice}
              getTrackingStatus={getTrackingStatus}
              updateInvoiceStatus={updateInvoiceStatus}
              handelPickup={handelPickup}
            />
          );
        })}
        <hr />
      </div>
    );
  };

  render() {
    // console.log(this.props);
    const date = new Date();
    const {
      isReady,
      note,
      tracking,
      pickup_point,
      pickup_tlp,
      pickup_contact,
      invoice_list,
      courier_list,
      vehicle_list,
      store_list,
      selected_location,
      handelTimePickerValue,
      handelDatePickerValue,
      handelPickupVehicle,
      handelChange,
      handelPickupSubmit,
      popupModal,
      modal2,
    } = this.props;
    // console.log(this.props)
    const s_id = this.state.selected_store_id;
    const s_name = this.state.selected_store_name;
    return (
      <>
        {selected_location !== undefined ? (
          <SellerLocationListModal {...this.props} />
        ) : (
          ""
        )}
        {tracking !== undefined ? (
          <TrackingViewModal
            active={modal2}
            no_modal={"2"}
            tracking={tracking}
            popupModal={popupModal}
          />
        ) : (
          ""
        )}
        <SectionContainer
          header={"Order Confirmation"}
          style={{ minWidth: "800px", minHeight: "500px" }}
        >
          <MDBContainer>
            <MDBRow>
              {store_list.map((data, index) => {
                return (
                  <MDBCol key={index} lg="4" md="4" sm="6">
                    <Link
                      to={`/seller_dashboard/order_confirmation/${data.id}/${data.name_web}/`}
                      style={{ width: "100%" }}
                    >
                      <CMDBBtn
                        active={
                          this.state.selected_store_name === data.name_web
                        }
                        bg_color="#804199"
                        style={{ width: "100%", borderRadius: "0.5rem" }}
                        onClick={() =>
                          this.handleSelectStore({
                            id: data.id,
                            name: data.name_web,
                          })
                        }
                      >
                        {data.name_web}
                      </CMDBBtn>
                    </Link>
                  </MDBCol>
                );
              })}
            </MDBRow>
            <hr />
            {this.state.selected_store_id !== "" ? (
              <>
                <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                  {this.state.selected_store_name}
                </span>
                <hr />
                <MDBRow>
                  <MDBCol lg="4" md="4" sm="6">
                    <Link
                      to={`/seller_dashboard/order_confirmation/${s_id}/${s_name}/[1]`}
                      style={{ width: "100%" }}
                    >
                      <CMDBBtn
                        active={this.state.selected_process[0] === 1}
                        bg_color="#804199"
                        style={{ width: "100%", borderRadius: "0.5rem" }}
                        onClick={() => this.handleSelectedProcess([1], "")}
                      >
                        Confirmation Order
                      </CMDBBtn>
                    </Link>
                  </MDBCol>
                  <MDBCol lg="4" md="4" sm="6">
                    <Link
                      to={`/seller_dashboard/order_confirmation/${s_id}/${s_name}/[2]`}
                      style={{ width: "100%" }}
                    >
                      <CMDBBtn
                        active={this.state.selected_process[0] === 2}
                        bg_color="#804199"
                        style={{ width: "100%", borderRadius: "0.5rem" }}
                        onClick={() => this.handleSelectedProcess([2], "")}
                      >
                        Pickup Order
                      </CMDBBtn>
                    </Link>
                  </MDBCol>
                  <MDBCol lg="4" md="4" sm="6">
                    <Link
                      bg_color="#804199"
                      to={`/seller_dashboard/order_confirmation/${s_id}/${s_name}/[3,4]`}
                      style={{ width: "100%" }}
                    >
                      <CMDBBtn
                        active={this.state.selected_process[0] === 3}
                        bg_color="#804199"
                        style={{ width: "100%", borderRadius: "0.5rem" }}
                        onClick={() => this.handleSelectedProcess([3, 4], "")}
                      >
                        Process Order
                      </CMDBBtn>
                    </Link>
                  </MDBCol>
                  <MDBCol lg="4" md="4" sm="6">
                    <Link
                      bg_color="#804199"
                      to={`/seller_dashboard/order_confirmation/${s_id}/${s_name}/[5]`}
                      style={{ width: "100%" }}
                    >
                      <CMDBBtn
                        active={this.state.selected_process[0] === 5}
                        bg_color="#804199"
                        style={{ width: "100%", borderRadius: "0.5rem" }}
                        onClick={() => this.handleSelectedProcess([5], "")}
                      >
                        Complete Order
                      </CMDBBtn>
                    </Link>
                  </MDBCol>
                  <MDBCol lg="4" md="4" sm="6">
                    <Link
                      to={`/seller_dashboard/order_confirmation/${s_id}/${s_name}/[98]`}
                      style={{ width: "100%" }}
                    >
                      <CMDBBtn
                        active={this.state.selected_process[0] === 98}
                        bg_color="#804199"
                        style={{ width: "100%", borderRadius: "0.5rem" }}
                        onClick={() => this.handleSelectedProcess([98], "")}
                      >
                        Request Cancel Order
                      </CMDBBtn>
                    </Link>
                  </MDBCol>
                  <MDBCol lg="4" md="4" sm="6">
                    <Link
                      to={`/seller_dashboard/order_confirmation/${s_id}/${s_name}/[99]`}
                      style={{ width: "100%" }}
                    >
                      <CMDBBtn
                        active={this.state.selected_process[0] === 99}
                        bg_color="#804199"
                        style={{ width: "100%", borderRadius: "0.5rem" }}
                        onClick={() => this.handleSelectedProcess([99], "")}
                      >
                        Rejected Order
                      </CMDBBtn>
                    </Link>
                  </MDBCol>
                </MDBRow>
                {this.state.selected_process[0] === 2 ? (
                  <>
                    <MDBSelect
                      className="w-100"
                      options={courier_list}
                      selected="Pilih Kurir"
                      label="Pilih Kurir"
                      selectAllValue={this.state.selected_courier}
                      getValue={(e) => this.handleSelectCourier(e)}
                    />
                    {/* pickup confirmation form */}
                    <MDBCard
                      style={{
                        display:
                          this.state.selected_courier !== undefined
                            ? ""
                            : "none",
                      }}
                    >
                      <MDBCardBody>
                        {this.state.selected_courier === "NCS" ? (
                          <NSCPickupForm>
                            <span>Batas Waktu Pengambilan</span>
                            <br />
                            <br />
                            <MDBRow>
                              <MDBCol>
                                <div
                                  style={{
                                    position: "relative",
                                    marginTop: "7px",
                                  }}
                                >
                                  <MDBDatePicker
                                    disablePast
                                    format="DD MMMM YYYY"
                                    getValue={(e) => handelDatePickerValue(e)}
                                  />
                                  <label
                                    className="black-text"
                                    style={{
                                      fontSize: "11px",
                                      position: "absolute",
                                      top: "-15px",
                                      left: "0px",
                                    }}
                                  >
                                    Date
                                  </label>
                                </div>
                              </MDBCol>
                              <MDBCol>
                                <MDBTimePicker
                                  id="timePicker1"
                                  label="From"
                                  hours={date.getHours()}
                                  minutes={date.getMinutes()}
                                  hoursFormat={24}
                                  getValue={(e) =>
                                    handelTimePickerValue(e, "form")
                                  }
                                />
                              </MDBCol>
                              <MDBCol>
                                <MDBTimePicker
                                  id="timePicker2"
                                  label="To"
                                  hours={date.getHours() + 1}
                                  minutes={date.getMinutes()}
                                  hoursFormat={24}
                                  getValue={(e) =>
                                    handelTimePickerValue(e, "to")
                                  }
                                />
                              </MDBCol>
                            </MDBRow>
                            <br />
                            {vehicle_list !== undefined ? (
                              <MDBSelect
                                label="Pickup Vehicle"
                                getValue={(e) => handelPickupVehicle(e)}
                              >
                                <MDBSelectInput selected="Choose vehicle" />
                                <MDBSelectOptions>
                                  <MDBSelectOption disabled>
                                    Choose vehicle
                                  </MDBSelectOption>
                                  {vehicle_list.map((data, index) => {
                                    return (
                                      <MDBSelectOption
                                        key={index}
                                        value={data.id}
                                      >
                                        {data.value}
                                      </MDBSelectOption>
                                    );
                                  })}
                                </MDBSelectOptions>
                              </MDBSelect>
                            ) : (
                              ""
                            )}
                            <MDBRow>
                              <MDBCol>
                                <MDBInput
                                  label="Contact Person Name"
                                  name="pickup_contact"
                                  value={pickup_contact}
                                  onChange={(e) => {
                                    handelChange(e);
                                  }}
                                />
                              </MDBCol>
                              <MDBCol>
                                <MDBInput
                                  label="Pickup Phone Number"
                                  name="pickup_tlp"
                                  value={pickup_tlp}
                                  onChange={(e) => {
                                    handelChange(e);
                                  }}
                                />
                              </MDBCol>
                            </MDBRow>
                            <MDBInput
                              label="Pickup Location Name"
                              name="pickup_point"
                              value={pickup_point}
                              onChange={(e) => {
                                handelChange(e);
                              }}
                            />
                            <MDBInput
                              label="Note"
                              name="note"
                              value={note}
                              onChange={(e) => {
                                handelChange(e);
                              }}
                            />
                            <MDBContainer>
                              <MDBRow>
                                <MDBCol size="8">
                                  <MDBRow>Alamat Pickup :</MDBRow>
                                  <MDBRow>
                                    {`${selected_location.name} (${selected_location.alias}) `}
                                    {selected_location.is_primary === "1" ? (
                                      <span>
                                        &nbsp;
                                        <CMDBBadge bg_color="#4a148c" pill>
                                          Primary
                                        </CMDBBadge>
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </MDBRow>
                                  <MDBRow>
                                    {selected_location !== undefined
                                      ? selected_location.address
                                      : ""}
                                  </MDBRow>
                                </MDBCol>
                                <MDBCol size="4" bottom>
                                  <MDBRow end>
                                    <CMDBBtn
                                      active
                                      bg_color="#804199"
                                      onClick={() => popupModal("SHOW", "1")}
                                    >
                                      CHANGE
                                    </CMDBBtn>
                                  </MDBRow>
                                </MDBCol>
                              </MDBRow>
                            </MDBContainer>
                          </NSCPickupForm>
                        ) : (
                          ""
                        )}
                      </MDBCardBody>
                      <CMDBBtn
                        active
                        bg_color="#804199"
                        disabled={!isReady}
                        onClick={() =>
                          handelPickupSubmit(
                            {
                              id: this.state.selected_store_id,
                              name: this.state.selected_store_name,
                            },
                            this.state.selected_courier
                          )
                        }
                      >
                        SUBMIT
                      </CMDBBtn>
                    </MDBCard>
                  </>
                ) : (
                  ""
                )}
                {/* end pickup confirmation form */}
                <br />
                <MDBRow>
                  <MDBCol lg="6" md="12" sm="12">
                    {invoice_list !== undefined
                      ? this.state.selected_process[0] !== 2
                        ? this.setInvoiceView(this.state.selected_store_name)
                        : this.state.selected_courier !== undefined
                        ? this.setInvoiceView(this.state.selected_store_name)
                        : ""
                      : ""}
                  </MDBCol>
                </MDBRow>
              </>
            ) : (
              ""
            )}
          </MDBContainer>
        </SectionContainer>
      </>
    );
  }
}

export default withRouter(OrderConfirmationView);
