import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import SellerCategoryContext from "../../context/SellerCategoryContext";
import { PageContainer } from "../LandingView/Landing.styles";
import ModalOption from "../_UtilityComponent/ModalOption/ModalOption";
import { ManageSellerListItems } from "./ManageSellerListItems";
import { ManageSellerCategoryAdd } from "./ManageSellerCategoryAdd";
// import { ManageSellerCategoryEdit } from "./ManageSellerCategoryEdit";

export const ManageSellerCategory = () => {
  let params = useParams();
  let history = useHistory();
  let page = params.page;
  let s_id = params.s_id;
  let process = params.s_name; //BUAT PROCESS
  // let process = params.process;
  const CatContext = useContext(SellerCategoryContext);

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  /////SELLER CATEGORY
  useEffect(() => {
    if (page === "manage_category" && s_id) {
      CatContext.listSellerCategory(s_id);
    }
    return () => {};
  }, [page, s_id]);

  /////////////////////
  console.log(process);
  if (process === "add") {
    return (
      <ManageSellerCategoryAdd
        sellerId={s_id}
        changeHandler={CatContext.changeHandler}
        submitHandler={CatContext.storeSellerCategory}
        name={CatContext.name}
      />
    );
  } else if (process === "edit") {
    return <div></div>;
  } else {
    return (
      <>
        <ModalOption
          showModal={CatContext.modalOption}
          toggleHandler={CatContext.modalHandler}
          itemId={CatContext.selectedItemId}
          sellerId={s_id}
          actionTypeName={"CLOSE"}
          // firstButtonTitle={"Ubah"}
          // firstButtonClick={() => {
          //   history.push(`${history.location.pathname}/edit`);
          // }}
          secondButtonTitle={"Hapus"}
          secondButtonClick={CatContext.deleteSellerCategory}
        />
        <PageContainer>
          <ManageSellerListItems
            itemDashboard={CatContext.data}
            page={page}
            modalHandler={CatContext.modalHandler}
            history={history}
          />
        </PageContainer>
      </>
    );
  }
};
