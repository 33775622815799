import {
  MDBBtn,
  MDBCard,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBRow,
  MDBSelect,
} from "mdbreact";
import React, { Component } from "react";

export default class BankAccountFormModal extends Component {
  render() {
    const {
      no_rek,
      alias,
      bank_list,
      modal1,
      isEdit,
      //function
      popupModal,
      changeHandler,
      onSubmitHandler,
      handleOnChangeBank,
    } = this.props;
    return (
      <MDBContainer>
        <MDBModal
          isOpen={modal1}
          toggle={() => popupModal("CLOSE", "1")}
          centered
        >
          <MDBModalHeader toggle={() => popupModal("CLOSE", "1")}>
            {isEdit ? "PERBAHARUI REKENING" : "TAMBAH REKENING"}
          </MDBModalHeader>
          <form
            className="needs-validation"
            onSubmit={(e)=>onSubmitHandler(e)}
            noValidate
          >
            <MDBModalBody>
              <MDBCard>
                <MDBContainer>
                  <MDBRow>
                    <MDBCol>
                      <MDBSelect
                        options={bank_list}
                        selected="Pilih Bank"
                        label="Pilih Bank"
                        getValue={handleOnChangeBank}
                      />
                      <MDBInput
                        value={no_rek}
                        onChange={changeHandler}
                        type="text"
                        className="form-control"
                        name="no_rek"
                        label="No Rekening"
                        required
                      />
                      <MDBInput
                        value={alias}
                        onChange={changeHandler}
                        type="text"
                        className="form-control"
                        name="alias"
                        label="Atas Nama"
                        required
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </MDBCard>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="success" type="submit">
                {isEdit ? "PERBAHARUI" : "TAMBAH"}
              </MDBBtn>
              <MDBBtn
                color="danger"
                onClick={() => popupModal("CLOSE", "1")}
              >
                BATAL
              </MDBBtn>
            </MDBModalFooter>
          </form>
        </MDBModal>
      </MDBContainer>
    );
  }
}
