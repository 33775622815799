import React, { Component } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBInputGroup,
  MDBFileInput,
  MDBSelect,
  MDBBtn,
  MDBSpinner,
  MDBIcon,
} from "mdbreact";

import styles from "./RegisterView.module.css";
import ImagePreview from "../ImagePreview/ImagePreview";
import { Text } from "../../context/Language";

export class RegisterView extends Component {
  state = {
    showPass: false,
    showConfPass: false,
  };
  render() {
    const styleLogo = {
      maxWidth: "100%",
      height: "auto",
    };

    const styleContainerLogo = {
      textAlign: "center",
    };

    const styleBanner = {
      maxWidth: "100%",
      height: "auto",
    };

    const styleContainerBanner = {
      textAlign: "center",
    };

    return (
      <div className={styles.outerContainer}>
        <MDBContainer className={[styles.container].join(" ")}>
          <div className={styles.title}>
            <Text pid="registerPage" tid="makeAccountTitle" />
          </div>

          <form
            className="needs-validation"
            onSubmit={this.props.submitHandler}
            noValidate
          >
            <MDBContainer className={styles.innerContainer}>
              <MDBInput
                value={this.props.email}
                onChange={this.props.changeHandler}
                type="email"
                className="form-control"
                name="email"
                label={<Text pid="registerPage" tid="emailInput" />}
                required
              />
              <MDBInput
                value={this.props.name}
                onChange={this.props.changeHandler}
                type="text"
                className="form-control"
                name="name"
                label={<Text pid="registerPage" tid="nameInput" />}
                required
              />
              <MDBInputGroup
                material
                value={this.props.password}
                onChange={this.props.changeHandler}
                type={this.state.showPass ? "text" : "password"}
                name="password"
                append={
                  <div
                    onClick={() => {
                      this.setState({ showPass: !this.state.showPass });
                    }}
                  >
                    {this.state.showPass ? (
                      <MDBCol middle>
                        <MDBIcon icon="eye-slash" />
                      </MDBCol>
                    ) : (
                      <MDBCol middle>
                        <MDBIcon icon="eye" />
                      </MDBCol>
                    )}
                  </div>
                }
                hint="Password"
                required
              />
              <small>{<Text pid="registerPage" tid="passInputHint" />}</small>
              <MDBInputGroup
                material
                value={this.props.passwordConfirmation}
                onChange={this.props.changeHandler}
                type={this.state.showConfPass ? "text" : "password"}
                name="password"
                append={
                  <div
                    onClick={() => {
                      this.setState({ showConfPass: !this.state.showConfPass });
                    }}
                  >
                    {this.state.showConfPass ? (
                      <MDBCol middle>
                        <MDBIcon icon="eye-slash" />
                      </MDBCol>
                    ) : (
                      <MDBCol middle>
                        <MDBIcon icon="eye" />
                      </MDBCol>
                    )}
                  </div>
                }
                hint="Password Confirmation"
                required
              />
              <MDBInput
                value={this.props.phoneNumber}
                onChange={this.props.changeHandler}
                type="tel"
                className="form-control"
                name="phoneNumber"
                label={<Text pid="registerPage" tid="phoneNumberInput" />}
                required
              />
              <MDBRow>
                <MDBCol className={styles.checkCustomLogo}>
                  <MDBInput
                    label={<Text pid="registerPage" tid="isSellerRegister" />}
                    onChange={this.props.checkIsSeller}
                    type="checkbox"
                    id="isSellerCheck"
                    checked={this.props.isSeller}
                    filled
                  />
                </MDBCol>
              </MDBRow>
              {this.props.isSeller ? (
                <>
                  <MDBRow>
                    <MDBCol>
                      <div className={styles.sub_title}>
                        {<Text pid="registerPage" tid="setupStoreTitle" />}
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        value={this.props.nameWeb}
                        onChange={this.props.changeHandler}
                        type="text"
                        className="form-control"
                        name="nameWeb"
                        label={<Text pid="registerPage" tid="storeNameInput" />}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        value={this.props.subDomain}
                        onChange={this.props.changeHandler}
                        onBlur={() =>
                          this.props.checkDomainAvailability(
                            this.props.subDomain,
                            "sub"
                          )
                        }
                        type="text"
                        className="form-control"
                        name="subDomain"
                        label={<Text pid="registerPage" tid="subDomainInput" />}
                        required
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      {this.props.renderLoaderSub ? (
                        <>
                          <MDBSpinner />
                          <Text pid="registerPage" tid="checkingDomain" />
                        </>
                      ) : (
                        ""
                      )}
                      {!this.props.renderLoaderSub &&
                      this.props.isAvailableSubDomain &&
                      this.props.isSubDomainChecked ? (
                        <div style={{ color: "green" }}>
                          <Text pid="registerPage" tid="availableDomain" />
                        </div>
                      ) : (
                        ""
                      )}
                      {!this.props.renderLoaderSub &&
                      !this.props.isAvailableSubDomain &&
                      this.props.isSubDomainChecked ? (
                        <div style={{ color: "red" }}>
                          <Text pid="registerPage" tid="unavailableDomain" />
                        </div>
                      ) : (
                        ""
                      )}
                    </MDBCol>
                  </MDBRow>
                  {/* <MDBRow>
                    <MDBCol>
                      <MDBInput
                        value={this.props.domain}
                        onChange={this.props.changeHandler}
                        onBlur={() =>
                          this.props.checkDomainAvailability(
                            this.props.domain,
                            "domain"
                          )
                        }
                        type="text"
                        className="form-control"
                        name="domain"
                        label={<Text pid="registerPage" tid="domainInput" />}
                      />
                    </MDBCol>
                  </MDBRow> */}
                  <MDBRow>
                    <MDBCol>
                      {this.props.renderLoaderDomain ? (
                        <>
                          <MDBSpinner />
                          <Text pid="registerPage" tid="checkingDomain" />
                        </>
                      ) : (
                        ""
                      )}
                      {!this.props.renderLoaderDomain &&
                      this.props.isAvailableDomain &&
                      this.props.isDomainChecked ? (
                        <div style={{ color: "green" }}>
                          <Text pid="registerPage" tid="availableDomain" />
                        </div>
                      ) : (
                        ""
                      )}
                      {!this.props.renderLoaderDomain &&
                      !this.props.isAvailableDomain &&
                      this.props.isDomainChecked ? (
                        <div style={{ color: "red" }}>
                          <Text pid="registerPage" tid="unavailableDomain" />
                        </div>
                      ) : (
                        ""
                      )}
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      <small>
                        {<Text pid="registerPage" tid="domainInputHint" />}
                      </small>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      <MDBSelect
                        search
                        options={this.props.colorList}
                        selected="Choose color theme/Pilih tema warna"
                        // label={<Text pid="registerPage" tid="storeTemplateInput" />}
                        label="Color Theme/Warna Tema"
                        getValue={this.props.selectColorHandler}
                      />
                    </MDBCol>
                  </MDBRow>
                  {/* <MDBRow>
                    <MDBCol>
                      <MDBSelect
                        search
                        options={this.props.template}
                        selected="Pilih tema anda"
                        // label={<Text pid="registerPage" tid="storeTemplateInput" />}
                        label="Template"
                        getValue={this.props.selectTemplateHandler}
                      />
                    </MDBCol>
                  </MDBRow> */}
                  {/* CHECK CUSTOM LOGO */}
                  {/* <MDBRow>
                    <MDBCol className={styles.checkCustomLogo}>
                      <MDBInput
                        //UNDONE
                        label={
                          <Text pid="registerPage" tid="isCustomLogoCheck" />
                        }
                        onChange={this.props.checkCustomLogo}
                        type="checkbox"
                        id="customLogo"
                        checked={this.props.isUsingCustomLogo}
                        filled
                      />
                    </MDBCol>
                  </MDBRow> */}
                  {/* {this.props.isUsingCustomLogo ? ( */}
                  <>
                    <MDBRow>
                      <MDBCol>
                        <MDBFileInput
                          textFieldTitle="Logo"
                          getValue={this.props.selectLogoHandler}
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol>
                        <small>
                          {<Text pid="registerPage" tid="logoStoreInputHint" />}
                        </small>
                      </MDBCol>
                    </MDBRow>
                    {this.props.previewCustomLogoToko ? (
                      <MDBRow>
                        <MDBCol>
                          <ImagePreview
                            img={this.props.previewCustomLogoToko}
                            alt={"previewLogoToko"}
                            style={styleLogo}
                            containerStyle={styleContainerLogo}
                          />
                        </MDBCol>
                      </MDBRow>
                    ) : (
                      ""
                    )}
                  </>
                  {/* ) : (
                    <>
                      {this.props.previewLogoToko ? (
                        <MDBRow>
                          <MDBCol>
                            <ImagePreview
                              img={this.props.previewLogoToko}
                              alt={"previewLogoToko"}
                              style={styleLogo}
                              containerStyle={styleContainerLogo}
                            />
                          </MDBCol>
                        </MDBRow>
                      ) : (
                        ""
                      )}
                    </>
                  )} */}
                  {/* CHECK CUSTOM BANNER */}
                  {/* <MDBRow>
                    <MDBCol className={styles.checkCustomBanner}>
                      <MDBInput
                        label={
                          <Text pid="registerPage" tid="isCustomBannerCheck" />
                        }
                        onChange={this.props.checkCustomBanner}
                        type="checkbox"
                        id="customBanner"
                        checked={this.props.isUsingCustomBanner}
                        filled
                      />
                    </MDBCol>
                  </MDBRow> */}
                  {/* {this.props.isUsingCustomBanner ? ( */}
                  <>
                    <MDBRow>
                      <MDBCol>
                        <MDBFileInput
                          textFieldTitle="Banner"
                          getValue={this.props.selectBannerHandler}
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol>
                        <small>
                          {
                            <Text
                              pid="registerPage"
                              tid="bannerStoreInputHint"
                            />
                          }
                        </small>
                      </MDBCol>
                    </MDBRow>
                    {this.props.previewCustomBannerToko ? (
                      <MDBRow>
                        <MDBCol>
                          <ImagePreview
                            img={this.props.previewCustomBannerToko}
                            alt={"previewBannerToko"}
                            style={styleBanner}
                            containerStyle={styleContainerBanner}
                          />
                        </MDBCol>
                      </MDBRow>
                    ) : (
                      ""
                    )}
                  </>
                  {/* ) : (
                    <>
                      {this.props.previewBannerToko ? (
                        <>
                          <MDBRow>
                            <MDBCol>
                              <ImagePreview
                                img={this.props.previewBannerToko}
                                alt={"previewBannerToko"}
                                style={styleBanner}
                                containerStyle={styleContainerBanner}
                              />
                            </MDBCol>
                          </MDBRow>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )} */}
                  <MDBInput
                    value={this.props.facebook}
                    onChange={this.props.changeHandler}
                    type="text"
                    className="form-control"
                    name="facebook"
                    label="Facebook"
                    icon="facebook"
                    iconBrand
                  />
                  <MDBInput
                    value={this.props.twitter}
                    onChange={this.props.changeHandler}
                    type="text"
                    className="form-control"
                    name="twitter"
                    label="Twitter"
                    icon="twitter"
                    iconBrand
                  />
                  <MDBInput
                    value={this.props.instagram}
                    onChange={this.props.changeHandler}
                    type="text"
                    className="form-control"
                    name="instagram"
                    label="Instagram"
                    icon="instagram"
                    iconBrand
                  />
                </>
              ) : (
                ""
              )}
            </MDBContainer>
            <MDBRow className={styles.submitContainer}>
              <MDBCol>
                <MDBBtn type="submit">
                  <Text pid="registerPage" tid="submitRegister" />
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </form>
        </MDBContainer>
      </div>
    );
  }
}

export default RegisterView;
