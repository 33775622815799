import React, { Component } from "react";
import API from "../../utils/API";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

import SellerLocationContext from "./SellerLocationContext";
import { MDBBtn, MDBIcon } from "mdbreact";

// var debounce = require("lodash.debounce");

class SellerLocationProvider extends Component {
  state = {
    // state
    name: "",
    hp: "",
    alias: "",
    address: "",
    isCheckGudang: false,
    selectedAddress: "",
    selectedAddressDefault: "",
    //
    zipCode: "",
    sellerId: "",
    data: [],
    dataTable: {},
    modalState: false,
    updateSellerLocationId: "",
    actionType: "",
    isLoading: false,
    isError: false,
  };

  createConfig = (token) => {
    let config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
    return config;
  };

  // untuk react-select async
  loadOptions = (inputValuePrm, callback) => {
    let inputValue = inputValuePrm ? `keyword=${inputValuePrm}` : "";
    let limit = "&limit=50";
    // let limit = limitPrm ? `&limit=${limitPrm}` : "";

    if (inputValuePrm.length >= 3) {
      API.get(
        `/prm/zipcode?${inputValue}${limit}`,
        this.createConfig(Cookies.get("access_token"))
      ).then((res) => {
        // console.log(res);
        if (parseInt(res.data.code) !== 200) {
          alert(res.data.errors);
        } else {
          let zipCodeTemp = res.data.data.zipcode.map((zipCode) => ({
            value: zipCode.value, //id
            label: zipCode.label.toString(), //address
          }));
          callback(zipCodeTemp);
        }
      });
    }
  };

  handleChangeSelect = (selectedAddress) => {
    this.setState({ selectedAddress }, () =>
      /* {} */
      console.log(`Option selected:`, this.state.selectedAddress)
    );
  };

  getZipLocation = (sellerId) => {
    API.get(`/prm/zipcode`, this.createConfig(Cookies.get("access_token")))
      .then((res) => {
        if (parseInt(res.data.code) !== 200) {
          alert(res.data.errors);
        } else {
          let zipCodeTemp = res.data.data.zipcode.map((zipCode) => ({
            value: zipCode.value, //id
            label: zipCode.label.toString(), //address
          }));
          this.setState({ zipCode: zipCodeTemp, isLoading: false });
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  modalHandler = (actionType, list) => {
    // console.log(this.state.zipCode);
    if (actionType === "ADD") {
      this.setState({
        updateSellerLocationId: "",
        name: "",
        hp: "",
        alias: "",
        selectedAddress: "",
        selectedAddressDefault: "",
        address: "",
        isCheckGudang: "",
        actionType,
        modalState: this.state.modalState ? false : true,
      });
    } else if (actionType === "UPDATE") {
      this.setState(
        {
          updateSellerLocationId: list.id,
          name: list.name,
          hp: list.hp,
          alias: list.alias,
          selectedAddress: list.zip_id,
          selectedAddressDefault: list.zip_id,
          address: list.address,
          isCheckGudang: parseInt(list.is_check_gudang) === 1 ? true : false,
          actionType,
          modalState: this.state.modalState ? false : true,
        },
        () => console.log(this.state.selectedAddressDefault)
      );
    } else if (actionType === "CLOSE") {
      this.setState({
        updateSellerLocationId: "",
        name: "",
        hp: "",
        alias: "",
        selectedAddress: "",
        selectedAddressDefault: "",
        address: "",
        isCheckGudang: "",
        actionType,
        modalState: this.state.modalState ? false : true,
      });
    }
  };

  checkGudangHandler = () => {
    this.setState({
      isCheckGudang: !this.state.isCheckGudang,
    });
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  selectZipCodeHandler = (event) => {
    if (event[0]) {
      this.setState({
        selectedZipCode: event[0],
      });
    }
  };

  listSellerLocation = (sellerId) => {
    // console.log(sellerId);
    this.setState({ isLoading: true });

    let token = Cookies.get("access_token");
    // const sellerId = Cookies.get("qqq");
    // ${this.state.sellerId}

    API.get(
      `/seller_location/${sellerId}`,
      this.createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        // console.log(res)
        this.setState({ isLoading: false });
        if (res.data.data) {
          // console.log(res.data.data);
          let listDataTemp = res.data.data.seller_location.map((list) => ({
            id: list.id,
            seller_id: list.seller_id,
            alias: list.alias,
            name: list.name,
            hp: list.hp,
            address: list.address,
            zip_id: {
              label:
                list.zip.province +
                ", " +
                list.zip.sub_province +
                ", " +
                list.zip.district +
                ", " +
                list.zip.sub_district +
                ", " +
                list.zip.zip,
              value: list.zip.id,
            },
            is_primary: list.is_primary,
            is_check_gudang: list.is_check_gudang,
            created_at: list.created_at,
            updated_at: list.updated_at,
            // listForSellerDetailProduct
            text: list.alias,
            value: list.id,
          }));
          // console.log(listDataTemp);
          let dataTableTemp = {
            columns: [
              // {
              //   label: "ID",
              //   field: "sellerLocationId",
              //   sort: "asc",
              // },
              {
                label: "Atas Nama",
                field: "name",
                sort: "asc",
              },
              {
                label: "Alamat",
                field: "address",
                sort: "asc",
              },
              {
                label: "Alamat Lengkap",
                field: "zipId",
                sort: "asc",
              },
              {
                label: "Alamat Utama",
                field: "isPrimary",
                sort: "asc",
              },
              // {
              //   label: "Check Gudang",
              //   field: "isCheckGudang",
              //   sort: "asc",
              // },
              {
                label: "Action",
                field: "action",
              },
            ],
            rows: listDataTemp.map((list) => ({
              // sellerLocationId: list.id,
              name: (
                <>
                  <div className="font-weight-bold">{list.name}</div>
                  <br />
                  <div>{list.hp}</div>
                </>
              ),
              address: (
                <>
                  <div className="font-weight-bold">{list.alias}</div>
                  <br />
                  <div>{list.address}</div>
                </>
              ),
              zipId: list.zip_id.label,
              isPrimary: parseInt(list.is_primary) === 1 ? "Utama" : "",
              // isCheckGudang: parseInt(list.is_check_gudang) ? "Ya" : "Tidak",
              action: (
                <>
                  {parseInt(list.is_primary) !== 1 ? (
                    <>
                      <MDBBtn
                        size="sm"
                        onClick={() =>
                          this.bePrimarySellerLocation(list.seller_id, list.id)
                        }
                      >
                        <MDBIcon icon="warehouse" className="mr-1" /> Jadikan
                        Utama
                      </MDBBtn>
                      <br />
                    </>
                  ) : (
                    ""
                  )}
                  <MDBBtn
                    color="primary"
                    size="sm"
                    onClick={() => this.modalHandler("UPDATE", list)}
                  >
                    <MDBIcon icon="edit" className="mr-1" />
                  </MDBBtn>
                  <MDBBtn
                    size="sm"
                    color="danger"
                    onClick={() =>
                      this.deleteSellerLocation(list.seller_id, list.id)
                    }
                  >
                    <MDBIcon icon="trash-alt" className="mr-1" />
                  </MDBBtn>
                </>
              ),
            })),
          };
          this.setState({
            data: listDataTemp,
            dataTable: dataTableTemp,
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  storeSellerLocation = (sellerId) => {
    this.setState({ isLoading: true });
    //Swal loading
    Swal.fire({
      title: "Menambahkan...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    const data = new FormData();
    data.append("name", this.state.name);
    data.append("hp", this.state.hp);
    data.append("alias", this.state.alias);
    data.append("zip_id", this.state.selectedAddress.value);
    data.append("address", this.state.address);
    data.append("is_check_gudang", this.state.isCheckGudang ? 1 : 0);

    if (this.state.selectedAddress.value !== undefined) {
      API.post(
        `/seller_location/${sellerId}`,
        data,
        this.createConfig(Cookies.get("access_token"))
      )
        .then((res) => {
          this.setState({ isLoading: false });
          if (parseInt(res.data.code) !== 200) {
            console.log(res);
          } else {
            this.setState(
              {
                name: "",
                hp: "",
                alias: "",
                selectedAddress: "",
                address: "",
                isCheckGudang: false,
                modalState: false,
              },
              () => this.listSellerLocation(sellerId)
            );
            Swal.fire({
              icon: "success",
              text: "Berhasil menambahkan alamat",
              onBeforeOpen: () => {
                Swal.hideLoading();
              },
            });
          }
        })
        .catch((error) => {
          Swal.disableLoading();
          console.log(error);
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Sorry...",
        text: "Silahkan masukan detail alamat",
      });
    }
  };

  updateSellerLocation = (sellerLocationId, sellerId) => {
    this.setState({
      isLoading: true,
    });

    //Swal loading
    Swal.fire({
      title: "Mengubah...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    const data = {
      name: this.state.name,
      hp: this.state.hp,
      alias: this.state.alias,
      zip_id: this.state.selectedAddress.value.toString(),
      address: this.state.address,
      is_check_gudang: this.state.isCheckGudang,
    };
    if (this.state.selectedAddress.value !== undefined) {
      API.put(
        `/seller_location/${sellerId}/${sellerLocationId}`,
        data,
        this.createConfig(Cookies.get("access_token"))
      )
        .then((res) => {
          this.setState({ isLoading: false });
          if (parseInt(res.data.code) !== 200) {
          } else {
            this.setState(
              {
                name: "",
                hp: "",
                alias: "",
                zipId: "",
                address: "",
                isCheckGudang: false,
                modalState: false,
              },
              () => this.listSellerLocation(sellerId)
            );
            Swal.fire({
              icon: "success",
              text: "Alamat telah berhasil diupdate",
              onBeforeOpen: () => {
                Swal.hideLoading();
              },
            });
          }
        })
        .catch((error) => {
          Swal.disableLoading();
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            if (error.response) {
              let errmessage = "";
              let keys = Object.keys(error.response.data.errors);
              for (let i = 0; i < keys.length; i++) {
                errmessage +=
                  keys[i] +
                  " " +
                  error.response.data.errors[keys[i]][0] +
                  "<br/>";
              }
              Swal.fire({
                icon: "error",
                html: errmessage,
              });
            }
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Sorry...",
        text: "Silahkan masukan detail alamat",
      });
    }
  };

  deleteSellerLocation = (sellerId, addressId) => {
    Swal.fire({
      title: "Anda yakin akan menghapus alamat ini?",
      icon: "warning",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.value) {
        return API.delete(
          `/seller_location/${sellerId}/${addressId}`,
          this.createConfig(Cookies.get("access_token"))
        )
          .then((res) => {
            if (parseInt(res.data.code) !== 200) {
              console.log(res.data);
            } else {
              Swal.fire({
                title: "Berhasil",
                text: "Alamat telah berhasil dihapus",
                type: "success",
                showConfirmButton: true,
                confirmButtonText: "Tutup",
              }).then(() => this.listSellerLocation(sellerId));
            }
          })
          .catch((error) => {
            Swal.disableLoading();
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              if (error.response) {
                let errmessage = "";
                let keys = Object.keys(error.response.data.errors);
                for (let i = 0; i < keys.length; i++) {
                  errmessage +=
                    keys[i] +
                    " " +
                    error.response.data.errors[keys[i]][0] +
                    "<br/>";
                }
                Swal.fire({
                  icon: "error",
                  html: errmessage,
                });
              }
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      } else {
        Swal.close();
      }
    });
  };

  bePrimarySellerLocation = (sellerId, addressId) => {
    const data = {};
    Swal.fire({
      title: "Anda yakin jadikan alamat ini sebagai alamat utama?",
      icon: "warning",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.value) {
        return API.put(
          `/seller_location/set_primary/${sellerId}/${addressId}`,
          data,
          this.createConfig(Cookies.get("access_token"))
        )
          .then((res) => {
            Swal.fire({
              title: "Berhasil",
              text: "Alamat utama anda telah dirubah",
              type: "success",
              showConfirmButton: true,
              confirmButtonText: "Tutup",
            }).then(() => this.listSellerLocation(sellerId));
          })
          .catch((error) => {
            Swal.disableLoading();
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              if (error.response) {
                let errmessage = "";
                let keys = Object.keys(error.response.data.errors);
                for (let i = 0; i < keys.length; i++) {
                  errmessage +=
                    keys[i] +
                    " " +
                    error.response.data.errors[keys[i]][0] +
                    "<br/>";
                }
                Swal.fire({
                  icon: "error",
                  html: errmessage,
                });
              }
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      } else {
        Swal.close();
      }
    });
  };

  render() {
    return (
      <SellerLocationContext.Provider
        value={{
          ...this.state,
          loadOptions: this.loadOptions,
          handleChangeSelect: this.handleChangeSelect,
          changeHandler: this.changeHandler,
          // checkGudangHandler: this.checkGudangHandler,
          modalHandler: this.modalHandler,
          getZipLocation: this.getZipLocation,
          listSellerLocation: this.listSellerLocation,
          storeSellerLocation: this.storeSellerLocation,
          updateSellerLocation: this.updateSellerLocation,
          bePrimarySellerLocation: this.bePrimarySellerLocation,
        }}
      >
        {this.props.children}
      </SellerLocationContext.Provider>
    );
  }
}

export default SellerLocationProvider;
