import React, { Component } from "react";
import API from "../../utils/API";
import Swal from "sweetalert2";

export default class Activation extends Component {
  state = {
    codeFeedback: "",
    isLoading: false,
    isError: false,
  };

  componentDidMount() {
    const { token } = this.props.match.params;
    this.checkToken(token);
  }

  checkToken = (token) => {
    this.setState({ isLoading: true });

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };

    API.get(`/auth/activation/${token}`, config)
      .then((res) => {
        this.setState({
          codeFeedback: res.data.code,
          isLoading: false,
        });
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  render() {
    const { codeFeedback, isError, isLoading } = this.state;
    if (!isLoading) {
      if (codeFeedback) {
        if (parseInt(codeFeedback) === 200) {
          return (
            <>
              <div>Token berhasil! </div>
              <div>Silahkan kembali ke login</div>
            </>
          );
        } else {
          return <div></div>;
        }
      } else {
        return <div></div>;
      }
    } else {
      return <div></div>;
    }
  }
}

// if (codeFeedback && !isLoading) {
//   if (parseInt(codeFeedback) === 200) {
//     return(
//     <div>Token berhasil! </div>
//     <div>Silahkan kembali ke login</div>
//     )
//   } else {
//     return
//     <div>Kode token tidak ditemukan</div>;
//   }
// } else {
//   return;
//   <div></div>;
// }
