import { MDBCard, MDBCol, MDBRow } from "mdbreact";
import React, { Component } from "react";
import StoreView from "../Search/StoreView";
import SectionContainer from "../sectionContainer";

export default class StoreListView extends Component {
  render() {
    const { store_list } = this.props;
    return (
      <SectionContainer header={"Store List"} style={{ minWidth: "500px" }}>
        <MDBRow>
            {store_list !== undefined
              ? store_list.map((data, index) => {
                  return (
                    <MDBCol size="6" key={index} style={{ paddingTop: "10px", minWidth:'420px' }}>
                      <StoreView {...data} />
                    </MDBCol>
                  );
                })
              : ""}
        </MDBRow>
      </SectionContainer>
    );
  }
}
