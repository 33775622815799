import React, { Component } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBInput,
  MDBRow,
  MDBCol,
  MDBSelect,
} from "mdbreact";
import MDBFileUpload from "mdb-react-fileupload";
import styles from "./SellerProductDetailFormModal.module.css";

class SellerProductDetailFormModal extends Component {
  render() {
    return (
      <MDBContainer>
        <MDBModal
          isOpen={this.props.modalState}
          toggle={() => this.props.toggleHandler("CLOSE")}
        >
          <MDBModalHeader>
            {this.props.actionType === "ADD" || "ADD_FROM_EDIT_PAGE"
              ? "Tambah Detail Produk"
              : "Ubah Detail Produk"}
          </MDBModalHeader>
          <MDBModalBody>
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    value={this.props.nameDetail}
                    onChange={this.props.changeHandler}
                    type="text"
                    className="form-control"
                    name="nameDetail"
                    label="Nama Detail"
                    required
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    value={this.props.qty}
                    onChange={this.props.changeHandler}
                    type="number"
                    className="form-control"
                    name="qty"
                    label="Stok Barang"
                    required
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    value={this.props.price}
                    onChange={this.props.changeHandler}
                    type="number"
                    className="form-control"
                    name="price"
                    label="Harga"
                    required
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    value={this.props.hpp}
                    onChange={this.props.changeHandler}
                    type="number"
                    className="form-control"
                    name="hpp"
                    label="Harga Pokok Penjualan"
                    required
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    value={this.props.weight}
                    onChange={this.props.changeHandler}
                    type="number"
                    className="form-control"
                    name="weight"
                    label="Berat (Gram)"
                    required
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBSelect
                    search
                    color="primary"
                    options={this.props.sellerLocationList}
                    selected="Pilih lokasi barang"
                    label="Lokasi barang"
                    getValue={this.props.selectSellerLocation}
                    required
                  />
                </MDBCol>
              </MDBRow>
              {this.props.previewDetailProductPhoto ? (
                <MDBRow className={styles.pictureOuterContainer}>
                  <MDBCol className={styles.pictureContainer}>
                    <MDBFileUpload
                      showRemove={false}
                      containerHeight={220}
                      messageDefault={"Gambar Detail Produk"}
                      messageReplace={"Detail Produk"}
                      getValue={(e) => this.props.selectDetailProductPhoto(e)}
                      defaultFileSrc={this.props.previewDetailProductPhoto}
                    />
                  </MDBCol>
                </MDBRow>
              ) : (
                <MDBRow className={styles.pictureOuterContainer}>
                  <MDBCol className={styles.pictureContainer}>
                    <MDBFileUpload
                      showRemove={false}
                      containerHeight={220}
                      messageDefault={"Gambar Detail Produk"}
                      messageReplace={"Detail Produk"}
                      getValue={(e) => this.props.selectDetailProductPhoto(e)}
                    />
                  </MDBCol>
                </MDBRow>
              )}
            </MDBContainer>
          </MDBModalBody>
          <MDBModalFooter center>
            {this.props.actionType === "ADD" ? (
              <MDBBtn color="success" onClick={this.props.storeSellerProduct}>
                Tambah
              </MDBBtn>
            ) : (
              ""
            )}
            {this.props.actionType === "ADD_FROM_EDIT_PAGE" ? (
              <MDBBtn
                color="success"
                onClick={() =>
                  this.props.storeSellerDetailProduct(this.props.groupProductId)
                }
              >
                Tambah
              </MDBBtn>
            ) : (
              ""
            )}
            {this.props.actionType === "UPDATE" ? (
              <MDBBtn
                color="success"
                onClick={() => this.props.updateSellerDetailProduct()}
              >
                Ubah
              </MDBBtn>
            ) : (
              ""
            )}
            <MDBBtn
              onClick={() => this.props.toggleHandler("CLOSE")}
              outline
              color="success"
            >
              Batal
            </MDBBtn>
          </MDBModalFooter>
          {/* </form> */}
        </MDBModal>
      </MDBContainer>
    );
  }
}

export default SellerProductDetailFormModal;
