import React, { Component } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBCard,
  MDBBtn,
  MDBSpinner,
  MDBSelect,
  MDBFileInput,
} from "mdbreact";
import SectionContainer from "../sectionContainer";
import styles from "../RegisterView/RegisterView.module.css";
import ImagePreview from "../ImagePreview/ImagePreview";
import { Link } from "react-router-dom";
import { Text } from "../../context/Language";

export default class ProfileViewUser extends Component{
    render(){
        const {isEdit,isEditChange} =this.props;
        const styleLogo = {
            maxWidth: "100%",
            height: "auto",
          };
      
          const styleContainerLogo = {
            textAlign: "center",
          };
      
          const styleBanner = {
            maxWidth: "100%",
            height: "auto",
          };
      
          const styleContainerBanner = {
            textAlign: "center",
          };
        return(
            <MDBContainer>
                <SectionContainer header={<Text pid="profileSeller" tid="title" />}>
                    <MDBCard>
                    <form
                        className="needs-validation"
                        onSubmit = {this.props.updateSellerProfile}
                        noValidate
                    >
                        <MDBContainer>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput
                                        value={this.props.data.nameWeb}
                                        onChange={this.props.changeHandler}
                                        type="text"
                                        className="form-control"
                                        name="nameWeb"
                                        label={<Text pid="registerPage" tid="storeNameInput" />}
                                        disabled = {isEdit}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size="4">
                                    <MDBInput
                                    value={this.props.data.subDomain}
                                    onChange={this.props.changeHandler}
                                    onBlur={() => this.props.checkDomainAvailability(this.props.data.subDomain, "sub")}
                                    type="text"
                                    className="form-control"
                                    name="subDomain"
                                    label={<Text pid="registerPage" tid="subDomainInput" />}
                                    required
                                    disabled = {isEdit}
                                    />
                                </MDBCol>
                                <MDBCol className="md-form form-control border-0" size="2">
                                    {" "}
                                    .1toko.com{" "}
                                </MDBCol>
                                <MDBCol size="6">
                                    <MDBInput
                                        value={this.props.data.domain}
                                        onChange={this.props.changeHandler}
                                        onBlur={() => this.props.checkDomainAvailability(this.props.data.domain, "domain")}
                                        type="text"
                                        className="form-control"
                                        name="domain"
                                        label={<Text pid="registerPage" tid="domainInput" />}
                                        disabled = {isEdit}
                                    />
                                    <small>
                                        {<Text pid="registerPage" tid="domainInputHint" />}
                                    </small>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size ="6">
                                    {this.props.renderLoaderSub ? <><MDBSpinner /><Text pid="registerPage" tid="checkingDomain" /></> : ""}
                                    {!this.props.renderLoaderSub && this.props.isAvailableSubDomain && this.props.isSubDomainChecked ? <div style={{color: "green"}}><Text pid="registerPage" tid="availableDomain" /></div> : "" }
                                    {!this.props.renderLoaderSub && !this.props.isAvailableSubDomain && this.props.isSubDomainChecked ? <div style={{color: "red"}}><Text pid="registerPage" tid="unavailableDomain" /></div> : ""}
                                </MDBCol>
                                <MDBCol  size="6">
                                    {this.props.renderLoaderDomain ? <><MDBSpinner /><Text pid="registerPage" tid="checkingDomain" /></> : ""}
                                    {!this.props.renderLoaderDomain && this.props.isAvailableDomain && this.props.isDomainChecked ? <div style={{color: "green"}}><Text pid="registerPage" tid="availableDomain" /></div> : "" }
                                    {!this.props.renderLoaderDomain && !this.props.isAvailableDomain && this.props.isDomainChecked ? <div style={{color: "red"}}><Text pid="registerPage" tid="unavailableDomain" /></div> : ""}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBInput
                                        value={this.props.data.facebook}
                                        onChange={this.props.changeHandler}
                                        type="text"
                                        className="form-control"
                                        name="facebook"
                                        label="Facebook"
                                        disabled = {isEdit}
                                    />
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput
                                        value={this.props.data.twitter}
                                        onChange={this.props.changeHandler}
                                        type="text"
                                        className="form-control"
                                        name="twitter"
                                        label="Twitter"
                                        disabled = {isEdit}
                                    />
                                </MDBCol>
                                <MDBCol>
                                    <MDBInput
                                        value={this.props.data.instagram}
                                        onChange={this.props.changeHandler}
                                        type="text"
                                        className="form-control"
                                        name="instagram"
                                        label="Instagram"
                                        disabled = {isEdit}
                                    />
                                </MDBCol>
                            </MDBRow>
                            {/* template view */}
                            <MDBRow>
                                <MDBCol>
                                    <MDBSelect
                                        search
                                        options={this.props.data.template}
                                        selected="Pilih tema anda"
                                        // label={<Text pid="registerPage" tid="storeTemplateInput" />}
                                        label="Template"
                                        getValue={this.props.selectTemplateHandler}
                                        disabled = {isEdit}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size="6">
                                    <ImagePreview
                                        img={this.props.data.previewCustomLogoToko}
                                        alt={"previewLogoToko"}
                                        style={styleLogo}
                                        containerStyle={styleContainerLogo}
                                    />
                                </MDBCol>
                                <MDBCol size="6">
                                    <ImagePreview
                                        img={this.props.data.previewCustomBannerToko}
                                        alt={"previewBannerToko"}
                                        style={styleBanner}
                                        containerStyle={styleContainerBanner}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                Template preview
                            </MDBRow>
                            {/* end template view */}
                            <MDBRow>
                                <MDBCol size="6" hidden = {isEdit ? true : false}>
                                    <MDBBtn className="w-100" type="submit">
                                        { <Text pid="profileUser" tid="submitBtn" />}
                                    </MDBBtn>
                                </MDBCol>
                                <MDBCol size="6" hidden = {isEdit ? false : true}>
                                    <MDBBtn className="w-100" onClick={()=>isEditChange()} >
                                        { <Text pid="profileUser" tid="editBtn" />}
                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </form>
                    </MDBCard>
                </SectionContainer>
            </MDBContainer>
        );
    }
}