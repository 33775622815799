import React, { Component } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBContainer,
  MDBRow,
  // MDBBadge,
  // MDBBtn,
  MDBCol,
} from "mdbreact";
import { CMDBBadge, CMDBBtn } from "../StyledComponent";

export default class ShipmentUserLocationInfo extends Component {
  render() {
    const { selected_location, popupModal, seller_style } = this.props;
    return (
      <MDBCard>
        <MDBCardBody>
          <MDBCardTitle>Alamat Pengirim</MDBCardTitle>
          <hr />
          <MDBContainer>
            <MDBRow>
              {`${selected_location.name} (${selected_location.alias}) `}
              {selected_location.is_primary === "1" ? (
                <span>
                  &nbsp;
                  <CMDBBadge bg_color={seller_style.color_btn} pill>
                    Primary
                  </CMDBBadge>
                </span>
              ) : (
                ""
              )}
            </MDBRow>
            <MDBRow>{selected_location.hp}</MDBRow>
            <MDBRow>{selected_location.address}</MDBRow>
            <MDBRow>{selected_location.zip_id.label}</MDBRow>
          </MDBContainer>
          <hr />
          <MDBRow>
            <MDBCol>
              <CMDBBtn
                active
                bg_color={seller_style.color_btn}
                onClick={()=>popupModal("SHOW","1")}
              >
                Ganti Alamat
              </CMDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    );
  }
}
