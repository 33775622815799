import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCard, MDBCol } from "mdbreact";
import { CenterLoader } from "../../_UtilityComponent/Loader/CenterLoader";
import ShipmentItem from "./ShipmentItem/ShipmentItem";
import {
  StyledButtonTheming,
  StyledButtonThemingOutline,
} from "../../_UtilityComponent/Button/StyledButton";

class ShipmentView extends Component {
  render() {
    return (
      <div>
        <MDBContainer className="shipment--address">
          <MDBRow>
            <MDBCol className="text-center mt-3 mb-3">
              <h4>Shipment</h4>
            </MDBCol>
          </MDBRow>
          <hr />
          {this.props.address ? (
            <>
              <MDBRow className="ship-title">
                <MDBCol className="font-weight-bolder">
                  Alamat Pengiriman
                </MDBCol>
              </MDBRow>
              <MDBRow className="ship-detail">
                <MDBCol>
                  {this.props.address.name} ({this.props.address.alias})
                </MDBCol>
              </MDBRow>
              <MDBRow className="ship-detail">
                <MDBCol>{this.props.address.hp}</MDBCol>
              </MDBRow>
              <MDBRow className="ship-detail">
                <MDBCol>{this.props.address.address}</MDBCol>
              </MDBRow>
              <MDBCol className="text-center mt-3">
                <StyledButtonThemingOutline
                  size="sm"
                  onClick={() => this.props.popAddressSelector("OPEN")}
                  disabled={this.props.disableAddress ? true : false}
                >
                  Pilih Alamat Lain
                </StyledButtonThemingOutline>
              </MDBCol>
            </>
          ) : (
            <CenterLoader />
          )}
        </MDBContainer>

        <ShipmentItem
          cart={this.props.cartActive}
          //method
          isCheckingCourier={this.props.isCheckingCourier}
          popCourierSelector={this.props.popCourierSelector}
        />
        <MDBCard>
          <MDBContainer className="mt-3 mb-3">
            <MDBRow>
              <MDBCol size="12" className="text-center">
                Total Tagihan:
              </MDBCol>
              <MDBCol size="12" className="text-center">
                {new Intl.NumberFormat("id-ID", {
                  style: "currency",
                  currency: "IDR",
                }).format(this.props.sumAllTotal)}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="text-center mt-4 mb-4">
                {this.props.cartsCourierCalculated ? (
                  <StyledButtonTheming
                    $width100
                    onClick={() => {
                      this.props.sendDataToSNAP(
                        this.props.user,
                        this.props.sellerId,
                        this.props.sellerName,
                        this.props.sellerData
                      );
                    }}
                  >
                    Pilih pembayaran
                  </StyledButtonTheming>
                ) : (
                  <>
                    <small className="red-text">
                      *Pastikan semua barang telah memiliki kurir dan layanan
                      pengiriman sebelum melanjutkan.
                    </small>
                    <StyledButtonTheming $width100 disabled>
                      Pilih pembayaran
                    </StyledButtonTheming>
                  </>
                )}
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBCard>
      </div>
    );
  }
}

export default ShipmentView;
