import React from "react";
import { Switch, Route } from "react-router-dom";
import { PrivateRoute } from "../utils/PrivateRoute";
import SellerLocation from "./pages/SellerLocation";
import SellerCategory from "./pages/SellerCategory";
import SellerProducts from "./pages/SellerProducts";
import SellerProductsForm from "./pages/SellerProductsForm";
import ProfileUser from "./pages/ProfileUser";
import ProfileSeller from "./pages/ProfileSeller";
import ProductPage from "./pages/ProductPage";
import Cart from "./pages/Cart";
import UserLocation from "./pages/UserLocation";
import Shipment from "./pages/Shipment";
import Discussion from "./pages/Discussion";
import InboxDiscussion from "./pages/InboxDiscussion";
import TransactionStatus from "./pages/UserTransactionStatus";

import Home from "./pages/shop_pages/Home";
import Login from "./pages/shop_pages/Login";
import Register from "./pages/shop_pages/Register";
import UserTransactionStatus from "./pages/UserTransactionStatus";
import UserPaymentStatus from "./pages/UserPaymentStatus";

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        {/* ROUTE */}
        <Route path="/" exact component={Home} />
        <Route path="/login" exact component={Login} />
        <Route path="/register" exact component={Register} />
        <Route path="/category/:id" exact component={Home} />
        <Route path="/product/:id" exact component={ProductPage} />
        <Route path="/product/:id/discussion" exact component={Discussion} />
        <Route
          path="/product/:id/discussion/:discussionId"
          exact
          component={Discussion}
        />

        {/* PRIVATE ROUTE */}
        <PrivateRoute path="/cart" exact component={Cart} />
        <PrivateRoute path="/cart/shipment" exact component={Shipment} />
        <PrivateRoute
          path="/admin/sellerlocation"
          exact
          component={SellerLocation}
        />
        <PrivateRoute
          path="/admin/sellercategory"
          exact
          component={SellerCategory}
        />
        <PrivateRoute
          path="/admin/sellerproducts"
          exact
          component={SellerProducts}
        />
        <PrivateRoute
          path="/inbox-discussion"
          exact
          component
          component={InboxDiscussion}
        />
        <PrivateRoute
          path="/admin/sellerproducts/add-product"
          exact
          component={SellerProductsForm}
        />
        <PrivateRoute
          path="/admin/sellerproducts/edit-product/:id"
          exact
          component={SellerProductsForm}
        />
        <PrivateRoute
          path="/admin/sellerprofile"
          exact
          component={ProfileSeller}
        />
        <PrivateRoute path="/profile" exact component={ProfileUser} />
        <PrivateRoute path="/profile/address" exact component={UserLocation} />
        <PrivateRoute path="/transaction-status/:page?" exact component={TransactionStatus} />
        <PrivateRoute path="/payment-status" exact component={UserPaymentStatus} />

        {/* <Route path="*" component={"Maaf Halaman Tidak Ada"} /> */}
      </Switch>
    );
  }
}

export default Routes;
