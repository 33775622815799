import React, { Component } from "react";
import { ProductHomeContext } from "../context/ProductHomeProvider"
import ProductHomeView from "../components/ProductHome/ProductHomeView"
import SellerPrmContext from "../../SellerPrmContext"

class ProductHome extends Component {
  render() {
    return (
      <SellerPrmContext.Consumer>
        {(sellerprm)=>(
          <ProductHomeContext.Consumer>
            {(producthome)=>(
              <ProductHomeView
                {...this.props} 
                producthome = {producthome}
                sellerprm = {sellerprm}
              />
            )}
          </ProductHomeContext.Consumer>
        )}
      </SellerPrmContext.Consumer>
    );
  }
}

export default ProductHome;
