import React, { Component } from "react";
import SellerPrmContext from "../../SellerPrmContext";
import CartMain from "../components/Cart/CartMain";
import CartContext from "../context/CartContext";

export default class Cart extends Component {
  render() {
    return (
      <div>
        <SellerPrmContext.Consumer>
          {(sellerPrm) => (
            <CartContext.Consumer>
              {(cart) => <CartMain sellerPrm={sellerPrm} cart={cart} />}
            </CartContext.Consumer>
          )}
        </SellerPrmContext.Consumer>
      </div>
    );
  }
}
