import React, { Component } from "react";
import API from "../../utils/API";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { MDBBtn, MDBIcon } from "mdbreact";
import SellerProductsContext from "./SellerProductsContext";
import { withRouter } from "react-router-dom";

class SellerProductsProvider extends Component {
  state = {
    //state-group-products
    name: "",
    categoryId: "",
    description: "",
    //state-photo-product
    photoProductState: [[], [], [], [], []],
    previewProductPhoto: "",
    photoDetailProductState: "",
    previewDetailProductPhoto: "",
    renderProductPhoto: false,
    renderDetailProductPhoto: false,
    //state-detail-product
    detailProductId: "", //untuk update
    nameDetail: "",
    price: 0,
    hpp: 0, //hargapokok
    qty: 0, //stock
    weight: 0, //in gram
    sellerLocationId: "",
    groupProductId: "",
    photoDetailProductId: "",
    data: [],
    dataDetail: [],
    dataDetailTemp: [],
    // for show update
    listCategoryActiveTemp: [],
    listCategoryActive: [],
    // for show detail update
    listSellerLocationsArr: [],
    // for reset product
    nameReset: "",
    categoryIdReset: "",
    descriptionReset: "",
    //
    isFromRegister: false,
    modalState: false,
    isLoading: false,
    isError: false,

    // isLoadedData
    isDataLoaded: false,
    isDetailLoaded: false,
  };

  setDataDetail = () => {
    this.clearDetailForm();
    // console.log(this.state.dataDetail);
    const dtl = this.state.dataDetail;
    // this.state.dataDetail.map((dtl) => {
    //   if (parseInt(dtl.id) === parseInt(detailProductId)) {
    this.setState(
      {
        detailProductId: dtl.id,
        nameDetail: dtl.name,
        price: dtl.price,
        hpp: dtl.hpp, //hargapokok
        qty: dtl.stock, //stock
        weight: dtl.weight, //in gram
        sellerLocationId: dtl.seller_location_id,
        groupProductId: dtl.group_product_id,
        photoDetailProductId: dtl.photo_product ? dtl.photo_product.id : "",
        previewDetailProductPhoto: dtl.photo_product
          ? dtl.photo_product.link
          : "",
      },
      () => {
        if (this.state.previewDetailProductPhoto) {
          this.setState({ renderDetailProductPhoto: true });
        }
        this.setState({ isDetailLoaded: true });
        this.setSellerLocationActive(dtl.seller_location_id);
      }
    );

    //     );
    //   }
    // });
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  selectProductPhoto = (event, photoType) => {
    if (event) {
      // if (photoType === 0) {
      let photoProductState = [...this.state.photoProductState];
      let photo = { ...photoProductState[photoType] };
      photo = event;
      photoProductState[photoType] = photo;
      this.setState({ photoProductState });
      // }
    }
  };

  selectDetailProductPhoto = (event) => {
    if (event) {
      this.setState({ photoDetailProductState: event });
    }
  };

  selectProductCategory = (event) => {
    // let tempSelected;
    // tempSelected = this.state.template.find((item) => item.value === event[0]);
    if (event[0]) {
      this.setState({
        categoryId: event[0],
      });
    }
    // }
  };

  selectSellerLocation = (event) => {
    if (event[0]) {
      this.setState({
        sellerLocationId: event[0],
      });
    }
  };

  listSellerProducts = (sellerId) => {
    this.clearForm();
    this.clearDetailForm();
    this.setState({ isLoading: true });
    let token = Cookies.get("access_token");
    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
    API.get(`/group_product/${sellerId}`, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.data) {
          let listDataTemp = res.data.data.product.map((list) => ({
            id: list.id,
            seller_id: list.seller_id,
            name: list.name,
            category_id: list.category_id,
            description: list.description,
            photo_product_main: list.photo_product[0],
            is_active: list.is_active,
          }));
          this.setState({
            data: listDataTemp,
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  storeProductPhoto = (groupProductId, sellerId) => {
    this.setState({ isLoading: true });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    const data = new FormData();
    // console.log("State[0]");
    // console.log(this.state.photoProductState[0]);
    data.append("upload_img", this.state.photoProductState[0]);
    data.append("group_product_id", groupProductId);

    API.post(`/photo_product/${sellerId}`, data, config)
      .then((res) => {
        if (parseInt(res.data.code) !== 200) {
          console.log(res);
        } else {
          if (this.state.photoProductState[1] instanceof File) {
            // console.log(res);
            const data = new FormData();
            console.log("State[1]");
            console.log(this.state.photoProductState[1]);
            data.append("upload_img", this.state.photoProductState[1]);
            data.append("group_product_id", groupProductId);
            API.post(`/photo_product/${sellerId}`, data, config);
          }
          if (this.state.photoProductState[2] instanceof File) {
            // console.log(res);
            const data = new FormData();
            console.log("State[2]");
            console.log(this.state.photoProductState[2]);
            data.append("upload_img", this.state.photoProductState[2]);
            data.append("group_product_id", groupProductId);
            API.post(`/photo_product/${sellerId}`, data, config);
          }
          if (this.state.photoProductState[3] instanceof File) {
            // console.log(res);
            const data = new FormData();
            console.log("State[3]");
            console.log(this.state.photoProductState[3]);
            data.append("upload_img", this.state.photoProductState[3]);
            data.append("group_product_id", groupProductId);
            API.post(`/photo_product/${sellerId}`, data, config);
          }
          if (this.state.photoProductState[4] instanceof File) {
            // console.log(res);
            const data = new FormData();
            console.log("State[4]");
            console.log(this.state.photoProductState[4]);
            data.append("upload_img", this.state.photoProductState[4]);
            data.append("group_product_id", groupProductId);
            API.post(`/photo_product/${sellerId}`, data, config);
          }
          this.storeSellerDetailProduct(groupProductId, sellerId);
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  updateProductPhoto = (groupProductId, sellerId) => {
    this.setState({ isLoading: true });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    if (this.state.photoProductState[0] instanceof File) {
      const data = new FormData();
      // console.log("State[0]");
      // console.log(this.state.photoProductState[0]);
      data.append("id", this.state.previewProductPhoto[0].id);
      data.append("upload_img", this.state.photoProductState[0]);
      data.append("group_product_id", groupProductId);

      API.post(`/photo_product/${sellerId}`, data, config)
        .then((res) => {
          if (parseInt(res.data.code) !== 200) {
            console.log(res);
          }
        })
        .catch((error) => {
          Swal.disableLoading();
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            if (error.response) {
              let errmessage = "";
              let keys = Object.keys(error.response.data.errors);
              for (let i = 0; i < keys.length; i++) {
                errmessage +=
                  keys[i] +
                  " " +
                  error.response.data.errors[keys[i]][0] +
                  "<br/>";
              }
              Swal.fire({
                icon: "error",
                html: errmessage,
              });
            }
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    }

    if (this.state.photoProductState[1] instanceof File) {
      const data = new FormData();
      // console.log("State[1]");
      // console.log(this.state.photoProductState[1]);
      data.append("upload_img", this.state.photoProductState[1]);
      if (this.state.previewProductPhoto[1].hasOwnProperty("id")) {
        data.append("id", this.state.previewProductPhoto[1].id);
      }
      data.append("group_product_id", groupProductId);
      API.post(`/photo_product/${sellerId}`, data, config);
    }
    if (this.state.photoProductState[2] instanceof File) {
      const data = new FormData();
      // console.log("State[2]");
      // console.log(this.state.photoProductState[2]);
      data.append("upload_img", this.state.photoProductState[2]);
      if (this.state.previewProductPhoto[2].hasOwnProperty("id")) {
        data.append("id", this.state.previewProductPhoto[2].id);
      }
      data.append("group_product_id", groupProductId);
      API.post(`/photo_product/${sellerId}`, data, config);
    }
    if (this.state.photoProductState[3] instanceof File) {
      const data = new FormData();
      // console.log("State[3]");
      // console.log(this.state.photoProductState[3]);
      data.append("upload_img", this.state.photoProductState[3]);
      if (this.state.previewProductPhoto[3].hasOwnProperty("id")) {
        data.append("id", this.state.previewProductPhoto[3].id);
      }
      data.append("group_product_id", groupProductId);
      API.post(`/photo_product/${sellerId}`, data, config);
    }
    if (this.state.photoProductState[4] instanceof File) {
      const data = new FormData();
      // console.log("State[4]");
      // console.log(this.state.photoProductState[4]);
      data.append("upload_img", this.state.photoProductState[4]);
      if (this.state.previewProductPhoto[4].hasOwnProperty("id")) {
        data.append("id", this.state.previewProductPhoto[4].id);
      }
      data.append("group_product_id", groupProductId);
      API.post(`/photo_product/${sellerId}`, data, config);
    }
  };

  storeDetailProductPhoto = (
    groupProductId,
    productId,
    photoDetailProductId,
    sellerId
  ) => {
    this.setState({ isLoading: true });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    console.log(this.state.photoDetailProductState);

    const data = new FormData();
    data.append("upload_img", this.state.photoDetailProductState);
    data.append("product_id", productId);
    data.append("group_product_id", groupProductId);
    if (photoDetailProductId) {
      data.append("id", this.state.photoDetailProductId);
    }

    console.table({
      upload_img: this.state.photoDetailProductState,
      product_id: productId,
      groupProductId: groupProductId,
      photoDetailProductId: this.state.photoDetailProductId,
    });

    API.post(`/photo_product/${sellerId}`, data, config)
      .then((res) => {
        if (parseInt(res.data.code) !== 200) {
          console.log(res);
        } else {
          // console.log(res);
          this.showSellerProducts(groupProductId, sellerId);
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  storeSellerProduct = (sellerId) => {
    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
    const {
      name,
      categoryId,
      description,
      nameDetail,
      price,
      qty,
      weight,
      sellerLocationId,
    } = this.state;
    console.table({
      name,
      categoryId,
      description,
      nameDetail,
      price,
      qty,
      weight,
      sellerLocationId,
    });
    if (
      this.state.name !== "" &&
      this.state.categoryId !== "" &&
      this.state.description !== "" &&
      this.state.photoProductState[0] instanceof File &&
      this.state.nameDetail !== "" &&
      this.state.price !== 0 &&
      // this.state.hpp !== 0 && //hargapokok
      this.state.qty !== 0 && //stock
      this.state.weight !== 0 && //in gram
      this.state.sellerLocationId !== ""
    ) {
      if (this.state.nameDetail.length >= 3) {
        // console.log("true");
        const data = new FormData();
        data.append("name", this.state.name);
        data.append("category_id", this.state.categoryId);
        data.append("description", this.state.description);

        API.post(`/group_product/${sellerId}`, data, config)
          .then((res) => {
            this.setState({ isLoading: false });
            if (parseInt(res.data.code) !== 200) {
              console.log(res);
            } else {
              this.storeProductPhoto(res.data.data.group_product.id, sellerId);
              Swal.fire({
                icon: "success",
                text: "Berhasil menambahkan produk",
                onBeforeOpen: () => {
                  Swal.hideLoading();
                },
              });
            }
          })
          .catch((error) => {
            Swal.disableLoading();
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              if (error.response) {
                let errmessage = "";
                let keys = Object.keys(error.response.data.errors);
                for (let i = 0; i < keys.length; i++) {
                  errmessage +=
                    keys[i] +
                    " " +
                    error.response.data.errors[keys[i]][0] +
                    "<br/>";
                }
                Swal.fire({
                  icon: "error",
                  html: errmessage,
                });
              }
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      } else {
        Swal.fire({
          icon: "error",
          text: "Nama tidak boleh kurang dari 3 huruf!",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        text: "Isian dan foto (utama/detail) tidak boleh ada yang kosong!",
      });
    }
  };

  updateSellerProduct = (groupProductId, sellerId) => {
    this.setState({ isLoading: true });

    //Swal loading
    Swal.fire({
      title: "Mengubah...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    const data = new FormData();
    data.append("id", groupProductId);
    data.append("name", this.state.name);
    data.append("category_id", this.state.categoryId);
    data.append("description", this.state.description);

    // console.table({
    //   groupProductId: groupProductId,
    //   name: this.state.name,
    //   cat_id: this.state.categoryId,
    //   desc: this.state.description,
    // });

    if (this.state.name && this.state.categoryId && this.state.description) {
      API.post(`/group_product/${sellerId}`, data, config)
        .then((res) => {
          this.setState({ isLoading: false });
          if (parseInt(res.data.code) !== 200) {
            console.log(res);
          } else {
            this.updateProductPhoto(res.data.data.group_product.id, sellerId);
            Swal.fire({
              icon: "success",
              text: "Berhasil mengubah produk",
              onBeforeOpen: () => {
                Swal.hideLoading();
              },
            }).then((res) => {
              if (res.isConfirmed || res.isDismissed) {
                this.props.history.push(
                  `/seller_dashboard/manage_products/${sellerId}`
                );
              }
            });
          }
        })
        .catch((error) => {
          Swal.disableLoading();
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            if (error.response) {
              let errmessage = "";
              let keys = Object.keys(error.response.data.errors);
              for (let i = 0; i < keys.length; i++) {
                errmessage +=
                  keys[i] +
                  " " +
                  error.response.data.errors[keys[i]][0] +
                  "<br/>";
              }
              Swal.fire({
                icon: "error",
                html: errmessage,
              });
            }
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    } else {
      Swal.fire({
        icon: "error",
        text: "Nama, Kategori, dan Deskripsi tidak boleh kosong",
      });
    }
  };

  // deleteSellerCategory = (sellerId, categoryId) => {
  //   let token = Cookies.get("access_token");

  //   const config = {
  //     headers: {
  //       "X-Requested-With": "XMLHttpRequest",
  //       Authorization: "Bearer " + token,
  //     },
  //   };

  //   Swal.fire({
  //     title: "Anda yakin akan menghapus kategori ini?",
  //     icon: "warning",
  //     cancelButtonText: "Tidak",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Ya",
  //   }).then((result) => {
  //     if (result.value) {
  //       return API.delete(`/seller_location/${sellerId}/${categoryId}`, config)
  //         .then((res) => {
  //           if (parseInt(res.data.code) !== 200) {
  //             console.log(res.data);
  //           } else {
  //             Swal.fire({
  //               title: "Berhasil",
  //               text: "Alamat telah berhasil dihapus",
  //               type: "success",
  //               showConfirmButton: true,
  //               confirmButtonText: "Tutup",
  //             }).then(() => this.listSellerCategory());
  //           }
  //         })
  //         .catch((error) => {
  //           Swal.disableLoading();
  //           if (error.response) {
  //             /*
  //              * The request was made and the server responded with a
  //              * status code that falls out of the range of 2xx
  //              */
  //             console.log(error.response.data);
  //             console.log(error.response.status);
  //             console.log(error.response.headers);
  //             if (error.response) {
  //               let errmessage = "";
  //               let keys = Object.keys(error.response.data.errors);
  //               for (let i = 0; i < keys.length; i++) {
  //                 errmessage +=
  //                   keys[i] +
  //                   " " +
  //                   error.response.data.errors[keys[i]][0] +
  //                   "<br/>";
  //               }
  //               Swal.fire({
  //                 icon: "error",
  //                 html: errmessage,
  //               });
  //             }
  //           } else if (error.request) {
  //             /*
  //              * The request was made but no response was received, `error.request`
  //              * is an instance of XMLHttpRequest in the browser and an instance
  //              * of http.ClientRequest in Node.js
  //              */
  //             console.log(error.request);
  //           } else {
  //             // Something happened in setting up the request and triggered an Error
  //             console.log("Error", error.message);
  //           }
  //           console.log(error.config);
  //         });
  //     } else {
  //       Swal.close();
  //     }
  //   });
  // };

  beActiveProduct = (productId, statusProduct, sellerId) => {
    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    // const sellerId = Cookies.get("qqq");

    const data = {
      is_active: parseInt(statusProduct) === 1 ? "0" : "1",
    };

    Swal.fire({
      title: parseInt(statusProduct) === 1 ? "Nonaktifkan?" : "Aktifkan?",
      icon: "warning",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.value) {
        return API.put(
          `/group_product/is_active/${sellerId}/${productId}`,
          data,
          config
        )
          .then((res) => {
            Swal.fire({
              title: "Berhasil",
              text:
                parseInt(statusProduct) === 1
                  ? "Produk anda telah dinonaktifkan"
                  : "Produk anda telah diaktifkan",
              icon: "success",
              showConfirmButton: true,
              confirmButtonText: "Tutup",
            }).then(() => this.listSellerProducts(sellerId));
          })
          .catch((error) => {
            Swal.disableLoading();
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              if (error.response) {
                let errmessage = "";
                let keys = Object.keys(error.response.data.errors);
                for (let i = 0; i < keys.length; i++) {
                  errmessage +=
                    keys[i] +
                    " " +
                    error.response.data.errors[keys[i]][0] +
                    "<br/>";
                }
                Swal.fire({
                  icon: "error",
                  html: errmessage,
                });
              }
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      } else {
        Swal.close();
      }
    });
  };

  //setState untuk kategori show
  setProductIdCategoryActive = (categoryId) => {
    this.setState(
      (prevState) => ({
        listCategoryActiveTemp: prevState.listCategoryActiveTemp.map((e) =>
          e.value === categoryId ? { ...e, checked: true } : e
        ),
      }),
      () =>
        this.setState({ listCategoryActive: this.state.listCategoryActiveTemp })
    );
  };

  // setState untuk alamat show
  setSellerLocationActive = (sellerLocationId) => {
    this.setState(
      (prevState) => ({
        listSellerLocationsArr: prevState.listSellerLocationsArr.map((e) =>
          e.value === sellerLocationId ? { ...e, checked: true } : e
        ),
      })
      /* () => {
        console.log(this.state.listSellerLocationsArr);
      } */
    );
  };

  // SHOW SELLER PRODUCTS & LIST SELLER DETAILS PRODUCT
  showSellerProducts = (groupProductId, sellerId) => {
    // console.log(sellerId);
    this.setState({ isLoading: true });
    this.setState({ renderProductPhoto: false });
    // this.clearForm();

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    API.get(`/group_product/${sellerId}/${groupProductId}`, config)
      .then((res) => {
        if (res.data.data) {
          // SHOW SELLER PRODUCTS
          this.setState(
            {
              name: res.data.data.group_product.name,
              categoryId: res.data.data.group_product.category_id,
              description: res.data.data.group_product.description,
              previewProductPhoto: res.data.data.group_product.photo_product,
              nameReset: res.data.data.group_product.name,
              categoryIdReset: res.data.data.group_product.category_id,
              descriptionReset: res.data.data.group_product.description,
            },
            () => {
              let tempArr;
              this.setState(
                (state) => {
                  if (state.previewProductPhoto.length < 6) {
                    for (let index = 0; index < 5; index++) {
                      if (state.previewProductPhoto.hasOwnProperty(index)) {
                        if (index === 0) {
                          tempArr = [state.previewProductPhoto[0]];
                        } else {
                          tempArr = [
                            ...tempArr,
                            state.previewProductPhoto[index],
                          ];
                        }
                      } else {
                        tempArr = [...tempArr, []];
                      }
                    }
                  }
                  return { previewProductPhoto: tempArr };
                },
                () => {
                  // console.log(this.state.previewProductPhoto);
                  this.setState({ renderProductPhoto: true });
                }
              );
              this.setProductIdCategoryActive(this.state.categoryId);
            }
          );
          // LIST SELLER DETAILS PRODUCT
          let listDataTemp = res.data.data.group_product.dtl_product.map(
            (list) => ({
              id: list.id,
              reference_product_id: list.reference_product_id,
              name: list.name,
              price: list.price,
              stock: list.stock,
              hpp: list.hpp,
              weight: list.weight,
              seller_location_id: list.seller_location_id,
              group_product_id: list.group_product_id,
              is_active: list.is_active,
              photo_product: list.photo_product[0],
            })
          );
          this.setState(
            {
              dataDetailList: listDataTemp,
              isLoading: false,
            },
            () => this.setState({ isDataLoaded: true })
          );
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        console.log(error);
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  showSellerDetailProducts = (detailProductId, sellerId) => {
    this.clearForm();
    this.clearDetailForm();
    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    API.get(`/detail_product/${sellerId}/${detailProductId}`, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.data) {
          // SELLER DETAILS PRODUCT
          const detail_product = res.data.data.detail_product;
          let dataTemp = {
            id: detailProductId,
            reference_product_id: detail_product.reference_product_id,
            name: detail_product.name,
            price: detail_product.price,
            stock: detail_product.stock,
            hpp: detail_product.hpp,
            weight: detail_product.weight,
            seller_location_id: detail_product.seller_location_id,
            group_product_id: detail_product.group_product_id,
            is_active: detail_product.is_active,
            photo_product: detail_product.photo_product[0],
          };
          this.setState(
            {
              dataDetail: dataTemp,
              isLoading: false,
            },
            // () => this.setState({ isDetailLoaded: true })
            () => this.setDataDetail(detailProductId)
          );
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        console.log(error);
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  storeSellerDetailProduct = (groupProductId, sellerId, pageState) => {
    // console.log(sellerId);
    this.setState({ isLoading: true });
    let token = Cookies.get("access_token");
    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
    // const { nameDetail, price, qty, weight, sellerLocationId } = this.state;
    // console.table({ nameDetail, price, qty, weight, sellerLocationId });
    // console.log(groupProductId + " " + sellerId);
    const data = new FormData();
    data.append("name", this.state.nameDetail);
    data.append("price", this.state.price);
    data.append("stock", this.state.qty);
    data.append("hpp", 0);
    data.append("weight", this.state.weight);
    data.append("seller_location_id", this.state.sellerLocationId);
    data.append("group_product_id", groupProductId);

    if (
      this.state.nameDetail &&
      this.state.price &&
      this.state.qty &&
      // this.state.hpp &&
      this.state.weight &&
      this.state.sellerLocationId
    ) {
      API.post(`/detail_product/${sellerId}`, data, config)
        .then((res) => {
          this.setState({ isLoading: false });
          if (parseInt(res.data.code) !== 200) {
            console.log(res);
          } else {
            this.setState(
              {
                nameDetail: "",
                price: "",
                qty: "",
                hpp: "",
                weight: "",
                sellerLocationId: "",
                groupProductId: "",
              },
              () => {
                if (pageState === "ADD") {
                  this.props.history.push(
                    `/seller_dashboard/manage_products/${sellerId}`
                  );
                } else if (pageState === "EDIT") {
                  this.props.history.push(
                    `/seller_dashboard/manage_products/${sellerId}/edit/${groupProductId}`
                  );
                }
                if (this.state.photoDetailProductState) {
                  this.storeDetailProductPhoto(
                    groupProductId,
                    res.data.data.detail_product.id,
                    undefined,
                    sellerId
                  );
                }
              }
            );
          }
        })
        .catch((error) => {
          Swal.disableLoading();
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            if (error.response) {
              let errmessage = "";
              let keys = Object.keys(error.response.data.errors);
              for (let i = 0; i < keys.length; i++) {
                errmessage +=
                  keys[i] +
                  " " +
                  error.response.data.errors[keys[i]][0] +
                  "<br/>";
              }
              Swal.fire({
                icon: "error",
                html: errmessage,
              });
            }
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    } else {
      Swal.fire({
        icon: "error",
        text: "Data tidak boleh ada yang kosong",
      });
    }
  };

  beActiveDetailProduct = (
    detailProductId,
    groupProductId,
    statusProduct,
    sellerId
  ) => {
    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    const data = {
      is_active: parseInt(statusProduct) === 1 ? "0" : "1",
    };

    Swal.fire({
      title: parseInt(statusProduct) === 1 ? "Nonaktifkan?" : "Aktifkan?",
      icon: "warning",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.value) {
        return API.put(
          `/detail_product/is_active/${sellerId}/${detailProductId}`,
          data,
          config
        )
          .then((res) => {
            Swal.fire({
              title: "Berhasil",
              text:
                parseInt(statusProduct) === 1
                  ? "Detail produk telah dinonaktifkan"
                  : "Detail produk telah diaktifkan",
              icon: "success",
              showConfirmButton: true,
              confirmButtonText: "Tutup",
            }).then((res) => {
              if (res.isConfirmed || res.isDismissed) {
                // this.props.history.push(
                //   `/seller_dashboard/manage_products/${sellerId}/edit/${groupProductId}`
                // );
                this.showSellerProducts(groupProductId, sellerId);
              }
            });
          })
          .catch((error) => {
            Swal.disableLoading();
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              if (error.response) {
                let errmessage = "";
                let keys = Object.keys(error.response.data.errors);
                for (let i = 0; i < keys.length; i++) {
                  errmessage +=
                    keys[i] +
                    " " +
                    error.response.data.errors[keys[i]][0] +
                    "<br/>";
                }
                Swal.fire({
                  icon: "error",
                  html: errmessage,
                });
              }
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      } else {
        Swal.close();
      }
    });
  };

  updateSellerDetailProduct = (
    groupProductId,
    detailProductId,
    sellerId,
    pageState
  ) => {
    this.setState({ isLoading: true });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
    // const { nameDetail, price, qty, weight, sellerLocationId } = this.state;
    // console.table({ nameDetail, price, qty, weight, sellerLocationId });
    // console.log(groupProductId + " " + sellerId);
    // console.log("photoDetailProductId:" + this.state.photoDetailProductId);
    // console.log("dtlprodid:" + detailProductId)
    const data = new FormData();
    data.append("id", detailProductId);
    data.append("name", this.state.nameDetail);
    data.append("price", this.state.price);
    data.append("stock", this.state.qty);
    data.append("hpp", 0);
    data.append("weight", this.state.weight);
    data.append("seller_location_id", this.state.sellerLocationId);
    data.append("group_product_id", groupProductId);

    if (
      this.state.nameDetail &&
      this.state.price &&
      this.state.qty &&
      this.state.weight &&
      this.state.sellerLocationId
    ) {
      API.post(`/detail_product/${sellerId}`, data, config)
        .then((res) => {
          if (parseInt(res.data.code) !== 200) {
            console.log(res);
          } else {
            if (
              this.state.photoDetailProductId &&
              this.state.photoDetailProductState
            ) {
              // gambar sdh ada, diisi foto baru
              console.log("gambar sdh ada, diisi foto baru");
              this.storeDetailProductPhoto(
                this.state.groupProductId,
                this.state.detailProductId,
                this.state.photoDetailProductId,
                sellerId
              );
            } else if (
              !this.state.photoDetailProductId &&
              this.state.photoDetailProductState
            ) {
              console.log(
                "photoDetailProductState:" + this.state.photoDetailProductState
              );
              console.log("gambar belum ada, diisi foto baru");
              // gambar belum ada, diisi foto baru
              this.storeDetailProductPhoto(
                this.state.groupProductId,
                this.state.detailProductId,
                undefined,
                sellerId
              );
            } else {
              this.setState({ isLoading: false });
              console.log("tidak diisi gambar (ada atau tidak ada)");
              // tidak diisi gambar (ada atau tidak ada)
              // this.showSellerProducts(this.state.groupProductId, sellerId);
            }
            this.props.history.push(
              `/seller_dashboard/manage_products/${sellerId}/edit/${groupProductId}`
            );
          }
        })
        .catch((error) => {
          Swal.disableLoading();
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            if (error.response) {
              let errmessage = "";
              let keys = Object.keys(error.response.data.errors);
              for (let i = 0; i < keys.length; i++) {
                errmessage +=
                  keys[i] +
                  " " +
                  error.response.data.errors[keys[i]][0] +
                  "<br/>";
              }
              Swal.fire({
                icon: "error",
                html: errmessage,
              });
            }
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    } else {
      Swal.fire({
        icon: "error",
        text: "Data tidak boleh ada yang kosong",
      });
    }
  };

  //untuk update show
  listSellerCategoryActive = (groupProductId, pageState, sellerId) => {
    // console.log(sellerId);
    this.setState({ isLoading: true });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    // const sellerId = Cookies.get("qqq");
    // ${this.state.sellerId}

    API.get(`/seller_category/${sellerId}?active=1`, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.data) {
          let listData = res.data.data.category.map((list, idx) => ({
            //select
            text: list.name,
            value: list.id.toString(),
            checked: false,
          }));
          pageState === "ADD"
            ? this.setState({
                listCategoryActive: listData,
              })
            : this.setState(
                {
                  listCategoryActiveTemp: listData,
                },
                () => this.showSellerProducts(groupProductId, sellerId)
              );
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  //untuk update detail
  listSellerLocation = (sellerId, detailProductId) => {
    // let x = 0;
    // x++;
    // alert(x);
    this.setState({ isLoading: true });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    // const sellerId = Cookies.get("qqq");
    // ${this.state.sellerId}

    API.get(`/seller_location/${sellerId}`, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.data) {
          let listDataTemp = res.data.data.seller_location.map((list) => ({
            text: list.alias,
            value: list.id,
            checked: false,
          }));
          this.setState(
            {
              listSellerLocationsArr: listDataTemp,
              isLoading: false,
            },
            () => {
              if (detailProductId) {
                this.showSellerDetailProducts(detailProductId, sellerId);
              }
            }
          );
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        console.log(error);
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  clearForm = () => {
    this.setState(
      {
        name: "",
        categoryId: "",
        description: "",
        previewProductPhoto: "",
        renderProductPhoto: false,
      },
      () =>
        this.setState(
          (prevState) => ({
            listCategoryActiveTemp: prevState.listCategoryActiveTemp.map((e) =>
              e.checked == true ? { ...e, checked: false } : e
            ),
          }),
          () =>
            this.setState({
              listCategoryActive: this.state.listCategoryActiveTemp,
            })
        )
    );
  };

  // mengembalikan form group product di edit page
  resetForm = () => {
    this.setState(
      {
        name: this.state.nameReset,
        categoryId: this.state.categoryIdReset,
        description: this.state.descriptionReset,
      },
      () =>
        this.setState(
          (prevState) => ({
            listCategoryActive: prevState.listCategoryActive.map((e) =>
              e.checked == true ? { ...e, checked: false } : e
            ),
          }),
          () =>
            this.setState(
              (prevState) => ({
                listCategoryActive: prevState.listCategoryActive.map((e) =>
                  e.value == this.state.categoryIdReset
                    ? { ...e, checked: true }
                    : e
                ),
              }),
              () => {
                console.log(this.state.categoryIdReset);
                console.log(this.state.listCategoryActive);
              }
            )
        )
    );
  };

  clearDetailForm = () => {
    this.setState(
      {
        detailProductId: "",
        nameDetail: "",
        price: 0,
        hpp: 0, //hargapokok
        qty: 0, //stock
        weight: 0, //in gram
        sellerLocationId: "",
        groupProductId: "",
        photoDetailProductState: "",
        previewDetailProductPhoto: "",
        photoDetailProductId: "",
        isDetailLoaded: false,
      },
      () =>
        this.setState((prevState) => ({
          listSellerLocationsArr: prevState.listSellerLocationsArr.map((e) =>
            e.checked == true ? { ...e, checked: false } : e
          ),
        }))
    );
  };

  render() {
    return (
      <SellerProductsContext.Provider
        value={{
          ...this.state,
          changeHandler: this.changeHandler,
          selectProductPhoto: this.selectProductPhoto,
          selectDetailProductPhoto: this.selectDetailProductPhoto,
          selectProductCategory: this.selectProductCategory,
          selectSellerLocation: this.selectSellerLocation,
          listSellerProducts: this.listSellerProducts,
          storeSellerProduct: this.storeSellerProduct,
          updateSellerProduct: this.updateSellerProduct,
          updateSellerDetailProduct: this.updateSellerDetailProduct,
          beActiveProduct: this.beActiveProduct,
          showSellerProducts: this.showSellerProducts,
          storeSellerDetailProduct: this.storeSellerDetailProduct,
          listSellerCategoryActive: this.listSellerCategoryActive,
          listSellerLocation: this.listSellerLocation,
          clearForm: this.clearForm,
          resetForm: this.resetForm,
          setDataDetail: this.setDataDetail,
          showSellerDetailProducts: this.showSellerDetailProducts,
          beActiveDetailProduct: this.beActiveDetailProduct,
        }}
      >
        {this.props.children}
      </SellerProductsContext.Provider>
    );
  }
}

export default withRouter(SellerProductsProvider);
