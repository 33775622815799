import React from "react";
import { Route, Switch } from "react-router-dom";

import Register from "./pages/Register";
import Login from "./pages/Login";
import Landing from "./pages/Landing";
import ChangePasswordToken from "./pages/ChangePasswordToken";
import Activation from "./pages/Activation";
import Search from "./pages/Search";
import SellerDashboard from "./pages/SellerDashboard";
import { PrivateRoute } from "./utils/PrivateRoute";
import PrintTemplateMain from "./components/PrintTemplate/PrintTemplateMain";

class MainRoutes extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/activation/:token" component={Activation} />
        <Route
          exact
          path="/change_password_token/:token?"
          component={ChangePasswordToken}
        />
        <Route exact path="/search/:text?" component={Search} />
        {/* seller dashboard */}
        <PrivateRoute
          exact
          path="/seller_dashboard/:page?/:s_id?/:s_name?/:process?"
          component={SellerDashboard}
        />
        <PrivateRoute
          exact
          path="/print/:type?/:id?"
          component={PrintTemplateMain}
        />
      </Switch>
    );
  }
}

export default MainRoutes;
