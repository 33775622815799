import React from "react";
import styles from "./ProductTabCategory.module.css";
import ProductCategory from "./ProductCategory/ProductCategory";
import { MDBContainer, MDBRow } from "mdbreact";

const ProductTabCategory = (props) => {
  return (
    <>
      <MDBContainer className={styles.containerTab}>
        <MDBRow className={styles.rowProduct}>
          <ProductCategory
            activeTab={props.activeTab === 0 ? true : false}
            item={{ value: 0 }}
          />
          {props.sellerCategory.map((item) => {
            return (
              <ProductCategory
                key={item.id}
                activeTab={props.activeTab === item.id ? true : false}
                item={item}
              />
            );
          })}
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default ProductTabCategory;
