import { Link, NavLink } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";
import MainBgMobile from "../../../../assets/landing_bg_mobile.png";

export const SlideContainer = styled.div`
  max-width: 1000px;
  position: relative;
  margin: auto;
`;

export const SlideButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
`;

export const NextSlide = styled(SlideButton)`
  right: 0;
  border-radius: 3px 0 0 3px;
  /* background-color: rgba(0, 0, 0, 0.8); */
`;

export const PrevSlide = styled(SlideButton)`
  /* background-color: rgba(0, 0, 0, 0.8); */
`;

export const BackgroundSlide = styled.div`
  height: 300px;
  width: 100%;
  border-radius: 5%;
  box-shadow: 0 4px 20px 7px #401850;
  ${(props) =>
    props.useMainBg
      ? css`
          background-image: url(${MainBgMobile}),
            linear-gradient(to bottom, #a45ebf 25%, #804199 50%);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 50% 50%;
        `
      : css`
          background: linear-gradient(to bottom, #a45ebf 25%, #804199 50%);
        `}
`;

export const ContentSlide = styled.div`
  color: #f2f2f2;
  padding: 8px 12px;
  position: absolute;
  width: auto;
`;

export const TextSlide = styled(ContentSlide)`
  top: 10px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 3px;

  ${(props) =>
    props.welcome
      ? css`
          right: 5%;
          font-size: 17px;
          @media (min-width: 300px) {
            font-size: 20px;
          }
          @media (min-width: 500px) {
            font-size: 25px;
          }
        `
      : css`
          left: 10%;
          font-size: 20px;
          @media (min-width: 300px) {
            font-size: 23px;
          }
          @media (min-width: 500px) {
            font-size: 28px;
          }
        `}
`;

export const StyledButtonSignGroup = styled(ContentSlide)`
  bottom: 10px;
  text-align: center;
  font-size: 14px;
  width: 100%;
  font-weight: bold;
  letter-spacing: 0.5px;
  @media (min-width: 300px) {
    font-size: 16px;
  }
  @media (min-width: 500px) {
    font-size: 20px;
  }
  padding: 5% 3%;
`;

export const StyledButtonSign = styled.div`
  background-color: white !important;
  color: black;
  border-radius: 10px;
  padding: 5px 10px;
  margin: 20px;
  text-align: center;
  display: inline;
`;
