import React, { Component } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBInput,
  MDBRow,
  MDBCol,
  MDBAlert,
} from "mdbreact";
import AsyncSelect from "react-select/async";

class UserLocationFormModal extends Component {
  render() {
    const { openState, popUpModal, isEdit, fromCart } = this.props;
    return (
      <MDBContainer>
        <MDBModal isOpen={openState} toggle={() => popUpModal("CLOSE", 1)}>
          <MDBModalHeader toggle={() => popUpModal("CLOSE", 1)}>
            {isEdit ? "Ubah Alamat" : "Tambah Alamat"}
          </MDBModalHeader>
          <MDBModalBody>
            {fromCart? <MDBAlert color="warning" dismiss>
              Anda belum mempunyai alamat, silahkan mengisi alamat baru sebelum
              melakukan pembelian
            </MDBAlert> : ""}
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    value={this.props.alias}
                    onChange={this.props.changeHandler}
                    type="text"
                    className="form-control"
                    name="alias"
                    label="Nama Tempat"
                    required
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    value={this.props.hp}
                    onChange={this.props.changeHandler}
                    type="phone"
                    className="form-control"
                    name="hp"
                    label="Handphone"
                    required
                  />
                  <small>*Minimal 6 karakter</small>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    value={this.props.name}
                    onChange={this.props.changeHandler}
                    type="text"
                    className="form-control"
                    name="name"
                    label="Atas Nama"
                    required
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    value={this.props.address}
                    onChange={this.props.changeHandler}
                    type="text"
                    className="form-control"
                    name="address"
                    label="Alamat"
                    required
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>Kecamatan/Kabupaten/Kota</MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <AsyncSelect
                    loadOptions={this.props.loadOptions}
                    onChange={this.props.changeSelectHandler}
                    value={this.props.selectedAddress}
                    defaultValue={this.props.selectedAddressDefault}
                    cacheOptions
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBModalBody>
          <MDBModalFooter center>
            {isEdit ? (
              <MDBBtn
                color="success"
                onClick={() =>
                  this.props.updateUserLocation(this.props.modifiedItemId)
                }
              >
                PERBAHARUI
              </MDBBtn>
            ) : (
              <MDBBtn
                color="success"
                onClick={() => this.props.storeUserLocation()}
              >
                TAMBAH
              </MDBBtn>
            )}

            <MDBBtn
              outline
              color="success"
              onClick={() => this.props.popUpModal("CLOSE", 1)}
            >
              BATAL
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  }
}

export default UserLocationFormModal;
