import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React, { Component } from "react";
import parse from "html-react-parser";
import SectionContainer from "../sectionContainer";
import { AboutDiv } from "../StyledComponent";
import "../../../assets/css/fonts.css";

export default class AboutView extends Component {
  state = {
    readMore: false,
  };

  handleReadMore = () => {
    this.setState({ readMore: !this.state.readMore });
  };

  render() {
    const props = this.props;
    console.log(this.props);
    return (
      // <MDBContainer style={{ backgroundColor: props.background_color }}>
      <MDBContainer style={{marginTop:'1.5rem', fontFamily: "Lato Semi Bold",}}>
        <SectionContainer header="ABOUT US">
          <MDBContainer>
            <MDBRow hidden={props.header_img === ""}>
              <img
                className=" img-fluid img-thumbnail z-depth-1 mx-auto d-block"
                src={props.header_img}
              />
            </MDBRow>
            <hr
              hidden={
                props.header_img === "" ||
                (props.company_img === "" && props.company_txt === "")
              }
            />
            {/* company */}
            <MDBContainer className="mb-3">
              <MDBRow
                center
                style={{ paddingBottom: "1.5rem" }}
                hidden={props.company_title === ""}
              >
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontFamily: "Lato Bold",
                    fontSize: "40px",
                  }}
                >
                  {props.company_title}
                </p>
              </MDBRow>
              <MDBRow>
                <MDBCol
                  lg="6"
                  md="6"
                  sm="12"
                  style={{ paddingBottom: "2rem" }}
                  hidden={props.company_img === ""}
                >
                  <MDBRow center>
                    <img
                      className=" img-fluid img-thumbnail z-depth-1"
                      style={{ maxHeight: "400px" }}
                      src={props.company_img}
                    />
                  </MDBRow>
                </MDBCol>
                <MDBCol
                  lg={props.company_img === "" ? "12" : "6"}
                  md={props.company_img === "" ? "12" : "6"}
                  sm="12"
                  hidden={props.company_txt === ""}
                >
                  {props.company_txt !== "" ? (
                    <AboutDiv
                      className="overflow-auto"
                      hidden={this.state.show}
                      style={{
                        width: "100%",
                        maxHeight: "400px",
                        margin:"0",
                        fontFamily: "Lato Regular",
                      }}
                    >
                      {parse(props.company_txt)}
                    </AboutDiv>
                  ) : (
                    ""
                  )}
                </MDBCol>
              </MDBRow>
            </MDBContainer>
            {/* product */}
            {/* <hr hidden={props.product_img === "" && props.product_txt === ""} />
            <MDBRow className="mb-3" hidden={props.product_img === ""}>
              <MDBCol md="8" className="mx-auto d-block">
                <img
                  className=" img-fluid img-thumbnail z-depth-1"
                  src={props.product_img}
                />
              </MDBCol>
            </MDBRow>
            <br />
            <MDBRow
              hidden={props.product_img === "" && props.product_txt === ""}
            >
              <p style={{ width: "100%", textAlign: "center" }}>
                TENTANG PRODUK
              </p>
            </MDBRow>
            <br />
            <MDBRow hidden={props.product_txt === ""}>
              {parse(props.product_txt)}
            </MDBRow> */}
            {/* other */}
            {/* <hr hidden={props.other_img === "" && props.other_txt === ""} />
            <MDBRow className="mb-3" hidden={props.other_img === ""}>
              <MDBCol md="8" className="mx-auto d-block">
                <img
                  className=" img-fluid img-thumbnail z-depth-1"
                  src={props.other_img}
                />
              </MDBCol>
            </MDBRow>
            <br />
            <MDBRow hidden={props.other_img === "" && props.other_txt === ""}>
              <p style={{ width: "100%", textAlign: "center" }}>{props.company_title}</p>
            </MDBRow>
            <br />
            <MDBRow hidden={props.other_txt === ""}>
              {parse(props.other_txt)}
            </MDBRow> */}
          </MDBContainer>
        </SectionContainer>
      </MDBContainer>
    );
  }
}
