import React, { Component, createContext } from "react";

//import for provider
import API from "../../utils/API";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { withRouter } from "react-router-dom";

//Context
export const UserTransactionContext = createContext();

class UserTransactionProvider extends Component {
  state = {
    trans_pending_list: undefined,
    trans_invoice_list: undefined,
    tracking: undefined,
    modal1: false,
  };

  createConfig = (token) => {
    let config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
    return config;
  };

  getPendingTransaction = () => {
    API.get(
      `/payment/check_pending`,
      this.createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        this.setState({ trans_pending_list: res.data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getUserInvoice = (status) => {
    const data = new FormData();
    data.append("status", JSON.stringify(status));
    data.append("courier", "");
    API.post(
      `payment/user_invoice`,
      data,
      this.createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        this.setState({ trans_invoice_list: res.data.invoice_list });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateInvoiceStatus = (invoice_id, status) => {
    // console.log(typeof status)
    const data = new FormData();
    data.append("invoice_id", invoice_id);
    data.append("status", status);
    API.put(
      `payment/up_invoice_status/${invoice_id}/${status}`,
      null,
      this.createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        Swal.fire({
          title: "Berhasil",
          icon: "success",
          showConfirmButton: true,
        }).then(() => {
          this.props.history.push("/transaction/[5]");
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getTrackingStatus = (courier, tracking_code) => {
    API.get(
      `/courier/tracking_status/${courier}/${tracking_code}`,
      this.createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        if (courier === "NCS") {
          if (!res.data.track[0].hasOwnProperty("message")) {
            this.setState({ tracking: res.data });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  popupModal = (act, no) => {
    let modal = "modal" + no;
    if (act === "CLOSE") {
      this.setState({ [modal]: !this.state[modal] });
    } else if (act === "SHOW") {
      this.setState({ [modal]: !this.state[modal] });
    }
  };

  render() {
    return (
      <UserTransactionContext.Provider
        value={{
          ...this.state,
          popupModal: this.popupModal,
          getUserInvoice: this.getUserInvoice,
          getTrackingStatus: this.getTrackingStatus,
          getPendingTransaction: this.getPendingTransaction,
          updateInvoiceStatus: this.updateInvoiceStatus,
        }}
      >
        {this.props.children}
      </UserTransactionContext.Provider>
    );
  }
}

export default withRouter(UserTransactionProvider);
