import React, { Component } from "react";
import {
  // MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBCollapse,
  MDBContainer,
  MDBNavbarToggler,
  MDBIcon,
  MDBCol,
  MDBRow,
} from "mdbreact";
import Cookies from "js-cookie";

import { withRouter } from "react-router-dom";
import "./css/Header.css";
import { Text } from "../context/Language";
// import HeaderProducts from "../components/HeaderAfterLogin/HeaderProducts";
import { HeaderMenuSeller } from "../components/HeaderAfterLogin/HeaderMenuSeller";
import { HeaderMenuUser } from "../components/HeaderAfterLogin/HeaderMenuUser";
// import { ReactComponent as Wave } from "../../assets/svg/wave2.svg";
// import styled from "styled-components";
import SellerPrmContext from "../../SellerPrmContext";
// import { CopyToClipboard } from "react-copy-to-clipboard";
// import Swal from "sweetalert2";
import { HeaderNavbar } from "../components/StyledComponent";
import '../../assets/css/fonts.css';

class Header extends Component {
  static contextType = SellerPrmContext;
  constructor() {
    super();
    this.state = {
      collapseID: "",
      share_link: "",
    };
    this.headerHeight = React.createRef();
  }

  componentDidMount() {
    const path = this.props.location.pathname.toString().substring(0, 6);
    this.setState({ share_link: `https://share.1toko.com/?${this.context.link}` });
    if (path !== "/print") {
      let headerHeight = this.headerHeight.current.offsetHeight;
      this.props.checkHeaderHeight(headerHeight);
    }
  }

  toggleCollapse = (collapseID) => () =>
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));

  render() {
    const logoContainerStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
    // console.log(this.props)
    const nav_color = "#ffffff";
    const icon_color = this.props.seller_style.color_primary;
    const text_color = "black";

    // const WaveWrapper = styled.div`
    //   position: fixed;
    //   width: 100%;
    //   height: 90px;
    //   svg {
    //     overflow: hidden;
    //     width: 100%;
    //     height: 100%;
    //     fill: ${this.props.seller_style.color_primary};
    //     display: block;
    //   }
    // `;

    const ClipboardToastOption = {
      icon: "success",
      position: "center",
      title: "Tersalin",
      toast: true,
      showConfirmButton: false,
      timer: 2000,
    };

    const path = this.props.location.pathname.toString().substring(0, 6);
    if (path !== "/print") {
      return (
        <HeaderNavbar
          light
          expand="lg"
          fixed="top"
          scrolling
          style={{ padding: "0", backgroundColor: nav_color }}
        >
          <span
            style={{
              width: "100%",
              backgroundColor: nav_color,
            }}
            ref={this.headerHeight}
          >
            <MDBContainer>
              <MDBNavLink className={`${text_color}-text`} to="/">
                <MDBRow style={{ width: "450px" }}>
                  <MDBCol style={logoContainerStyle} size="4">
                    <img
                      src={this.props.subDomainData.logo}
                      className="img-fluid"
                      style={{ maxHeight: "4rem" }}
                      alt="aligment"
                    />
                  </MDBCol>
                  <MDBCol
                    size="8"
                    style={{
                      padding:'0',
                      fontFamily: "Lato Bold",
                      fontWeight: 'bold',
                      fontSize: "25px",
                      color:
                        parseInt(this.context.subDomainData.id) === 26
                          ? "black"
                          : icon_color,
                    }}
                    middle
                  >
                    {this.props.subDomainData.name_web}
                  </MDBCol>
                </MDBRow>
              </MDBNavLink>
              <MDBNavbarToggler
                onClick={this.toggleCollapse("navbarCollapse")}
                style={{ color: icon_color }}
              />
              <MDBCollapse
                id="navbarCollapse"
                isOpen={this.state.collapseID}
                navbar
              >
                <MDBNavbarNav right style={{fontFamily: "Lato Semi Bold",}}> 
                  <MDBNavItem>
                    {/* <HeaderProducts /> */}
                    {/* <MDBNavLink className="white-text" to="/" hash="#product">
                    Products
                  </MDBNavLink> */}
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink className={`${text_color}-text`} to="/about">
                      <Text pid="t1_header" tid="aboutBtn" />
                    </MDBNavLink>
                  </MDBNavItem>
                  {this.props.isSeller ? (
                    Cookies.get("access_token") ? (
                      <>
                        <HeaderMenuSeller
                          text_color={text_color}
                          seller_style={this.props.seller_style}
                        />
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    <div className="icon-cart">
                      <MDBNavItem>
                        <MDBNavLink
                          className={`${text_color}-text align-self-center col-example text-left`}
                          to="/cart"
                        >
                          <MDBIcon
                            icon="shopping-cart"
                            style={{ color: icon_color }}
                          />
                          <Text pid="t1_header" tid="cartBtn" />
                        </MDBNavLink>
                      </MDBNavItem>
                    </div>
                  )}
                  {Cookies.get("access_token") ? (
                    <HeaderMenuUser
                      logged={true}
                      IsSeller={this.props.isSeller}
                      logout={this.props.logout}
                      text_color={text_color}
                      seller_style={this.props.seller_style}
                    />
                  ) : (
                    <HeaderMenuUser
                      logged={false}
                      IsSeller={this.props.isSeller}
                      text_color={text_color}
                      seller_style={this.props.seller_style}
                    />
                  )}
                  {/* <MDBNavItem style={{display:'grid', placeItems:'center start', margin:'5px'}}>
                    <CopyToClipboard
                      onCopy={() => Swal.fire(ClipboardToastOption)}
                      text={this.state.share_link}
                    >
                      <a>Share Link</a>
                    </CopyToClipboard>
                  </MDBNavItem> */}
                </MDBNavbarNav>
              </MDBCollapse>
            </MDBContainer>
            {/* <WaveWrapper>
              <Wave />
            </WaveWrapper> */}
          </span>
        </HeaderNavbar>
      );
    } else {
      return "";
    }
  }
}

export default withRouter(Header);
