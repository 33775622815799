import React, { useContext, useEffect, useState } from "react";
import parse from "html-react-parser";
import SellerPrmContext from "../../../../SellerPrmContext";
import API from "../../../../utils/API";
import {
  ProductAboutContainer,
  TitleProductAbout,
  ProductAboutImage,
  ProductAboutText,
} from "./ProductAbout.styles";

const ProductAbout = (props) => {
  const sellerPrmContext = useContext(SellerPrmContext);
  const [titleAbout, setTitleAbout] = useState("");
  const [productAbout, setProductAbout] = useState("");
  const [productImageAbout, setProductImageAbout] = useState("");

  useEffect(() => {
    getProductAbout(sellerPrmContext.link);
  }, [sellerPrmContext.subDomainData]);

  const getProductAbout = (subdomain) => {
    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };
    API.get(`/about/${subdomain}`, config)
      .then((res) => {
        if (parseInt(res.data.code) !== 200) {
          console.log(res);
        } else {
          const about_product = res.data.data.about.about_product;
          const product_image = res.data.data.about.product_image;
          const title_product_about = res.data.data.about.product_title;
          setProductAbout(about_product);
          setProductImageAbout(product_image);
          setTitleAbout(title_product_about);
          // setTitleAbout!!
        }
      })
      .catch((error) => {
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  if (props.toggleAbout) {
    return (
      <ProductAboutContainer>
        <TitleProductAbout>{titleAbout}</TitleProductAbout>
        <div>
          <ProductAboutImage src={productImageAbout} />
        </div>
        {productAbout ? (
          <ProductAboutText productAbout={productAbout}>
            {parse(productAbout)}
          </ProductAboutText>
        ) : (
          ""
        )}
      </ProductAboutContainer>
    );
  } else {
    return <></>;
  }
};

export default ProductAbout;
