import React, { Component } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBInput,
  MDBRow,
  MDBCol,
  MDBCard,
} from "mdbreact";
import { Text } from "../../context/Language";

export default class CPFormModal extends Component {
  render() {
    return (
      <MDBContainer>
        <MDBModal
          isOpen={this.props.openState}
          toggle={() => this.props.popupModal("1")}
        >
          <MDBModalHeader toggle={() => this.props.popupModal("1")}>
            <Text pid="changePass" tid="title2" />
          </MDBModalHeader>
          <MDBModalBody>
            <MDBCard>
              <MDBContainer>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      value={this.props.data.old_password}
                      onChange={this.props.changeHandler}
                      type="password"
                      className="form-control"
                      id="data"
                      name="old_password"
                      label={<Text pid="changePass" tid="old_passInput" />}
                      required
                    />
                    <MDBInput
                      value={this.props.data.new_password}
                      onChange={this.props.changeHandler}
                      type="password"
                      className="form-control"
                      id="data"
                      name="new_password"
                      label={<Text pid="changePass" tid="new_passInput" />}
                      required
                    />
                    <MDBInput
                      value={this.props.data.conf_password}
                      onChange={this.props.changeHandler}
                      type="password"
                      className="form-control"
                      id="data"
                      name="conf_password"
                      label={<Text pid="changePass" tid="conf_passInput" />}
                      required
                    />
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBCard>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={this.props.changePassword}>
              <Text pid="changePass" tid="submitBtn" />
            </MDBBtn>
            <MDBBtn color="primary" onClick={() => this.props.popupModal("1")}>
              <Text pid="changePass" tid="closeBtn" />
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  }
}
