import React, { Component } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBInput,
} from "mdbreact";
import { Link } from "react-router-dom";
import ImageComponent from "../../_UtilityComponent/Image/ImageComponent";
import InputNumber from "../InputNumber/InputNumber";
import { Text } from "../../../context/Language";

import style from "./CartItem.module.css";
import { StyledButtonTheming } from "../../_UtilityComponent/Button/StyledButton";

class CartItem extends Component {
  render() {
    if (this.props.cart.length <= 0) {
      return (
        <div className="cart--empty cart--center text-center">
          <div className="mb-3">Keranjang kamu masih kosong nih.</div>
          <Link className="link" to="/">
            <StyledButtonTheming color="black">Yuk, belanja!</StyledButtonTheming>
          </Link>
        </div>
      );
    } else if (this.props.cart.length >= 1) {
      return (
        <div>
          {this.props.cart.map((cartItem, cartIndex) => {
            return (
              <div key={cartItem.id}>
                <MDBContainer className="mt-3 mb-3">
                  <hr style={{ borderTop: "1px solid black" }} />
                  <MDBInput
                    label={
                      "Ship From : " + cartItem.sellerLocation.zip.sub_district
                    }
                    onChange={() =>
                      this.props.changeHandlerCheckGroup(
                        this.props.sellerId,
                        cartIndex,
                        cartItem.sellerLocation.id
                      )
                    }
                    containerClass={style.checkbox}
                    type="checkbox"
                    id={cartItem.id}
                    checked={cartItem.isCheck}
                    filled
                  />
                  {cartItem.cartDetail.map(
                    (cartDetailItem, cartDetailIndex) => {
                      return (
                        <div key={cartDetailItem.id}>
                          <hr style={{ borderTop: "1px solid black" }} />
                          <MDBInput
                            label=" "
                            onChange={() =>
                              this.props.changeHandlerCheck(
                                this.props.sellerId,
                                cartIndex,
                                cartDetailIndex,
                                cartDetailItem.id
                              )
                            }
                            containerClass={style.checkbox}
                            type="checkbox"
                            id={cartDetailItem.id}
                            checked={cartDetailItem.isCheck}
                            filled
                          />
                          <MDBRow className="mb-3">
                            <MDBCol size="4">
                              <ImageComponent
                                isSquare={true}
                                img={cartDetailItem.linkPicture}
                              />
                            </MDBCol>
                            <MDBCol className="cart--detail--main" size="6">
                              <MDBRow className="prod-title font-weight-bolder">
                                {cartDetailItem.productName}
                              </MDBRow>
                              <MDBRow className="prod-detail">
                                {cartDetailItem.detailName}
                              </MDBRow>
                              <MDBRow>
                                <MDBCol className="cart--detail--main pl-0 pr-2">
                                  <div className="prod-price">
                                    {new Intl.NumberFormat("id-ID", {
                                      style: "currency",
                                      currency: "IDR",
                                    }).format(cartDetailItem.price)}
                                  </div>
                                </MDBCol>
                              </MDBRow>
                            </MDBCol>
                            <MDBCol size="2">
                              <MDBIcon
                                onClick={() =>
                                  this.props.deleteCart(
                                    cartDetailItem.id,
                                    this.props.sellerId
                                  )
                                }
                                icon="trash-alt"
                              />
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            <MDBCol className="mb-2">
                              <MDBCol
                                size="12"
                                className="mb-2"
                                style={{
                                  display: "grid",
                                  placeItems: "center",
                                }}
                              >
                                <InputNumber
                                  value={cartDetailItem.qty}
                                  increase={(e) =>
                                    this.props.actionUpdate(
                                      this.props.sellerId,
                                      "INC",
                                      cartIndex,
                                      cartDetailIndex,
                                      cartDetailItem.id       
                                    )
                                  }
                                  decrease={(e) =>
                                    this.props.actionUpdate(
                                      this.props.sellerId,
                                      "DEC",
                                      cartIndex,
                                      cartDetailIndex,
                                      cartDetailItem.id
                                    )
                                  }
                                />
                              </MDBCol>
                            </MDBCol>
                            <MDBCol size="12">
                              <MDBInput
                                value={cartDetailItem.note}
                                onChange={(e) =>
                                  this.props.changeHandlerNote(
                                    e,
                                    this.props.sellerId,
                                    cartIndex,
                                    cartDetailIndex,
                                    cartDetailItem.id
                                  )
                                }
                                type="text"
                                className="form-control"
                                name="note"
                                label={<Text pid="cartPage" tid="note" />}
                              />
                            </MDBCol>
                            <MDBCol size="12">
                              <Text pid="cartPage" tid="subTotal" /> :{" "}
                              {new Intl.NumberFormat("id-ID", {
                                style: "currency",
                                currency: "IDR",
                              }).format(cartDetailItem.totalPrice)}
                            </MDBCol>
                          </MDBRow>
                        </div>
                      );
                    }
                  )}
                </MDBContainer>
              </div>
            );
          })}
        </div>
      );
    }
  }
}

export default CartItem;
