import { MDBBtn } from "mdbreact";
import styled, { css } from "styled-components";

export const StyledButtonTheming = styled(MDBBtn)`
  background-color: ${(props) => props.theme.color.button} !important;
  color: ${(props) =>
    props.theme.color.type === "dark"
      ? (props) => props.theme.font.light
      : (props) => props.theme.font.dark};
  border-radius: 10px;
  ${(props) =>
    //transient-props
    props.$width100
      ? css`
          width: 100%;
        `
      : ""}
  font-size: 16px;

  ${(props) =>
    props.theme.template.id === "5" &&
    css`
      color: #ffffff;
    `}
`;

export const StyledButtonThemingOutline = styled(MDBBtn).attrs({
  outline: true,
})`
  border: ${(props) => props.theme.color.button} 2px solid !important;
  color: ${(props) =>
    props.theme.color.type === "dark"
      ? (props) => props.theme.font.light
      : (props) => props.theme.font.dark};
  border-radius: 10px;

  ${(props) =>
    //transient-props
    props.$width100
      ? css`
          width: 100%;
        `
      : ""}
`;

export const StyledAboutButton = styled.div`
  background-color: ${(props) => props.theme.color.button} !important;
  color: ${(props) =>
    props.theme.color.type === "dark"
      ? (props) => props.theme.font.light
      : (props) => props.theme.font.dark};
  border-radius: 10px;
  ${(props) =>
    //transient-props
    props.$width100
      ? css`
          width: 100%;
        `
      : ""}
  flex: 1 0 45%;    
  font-size: 16px;
  font-weight: bold;
  padding: 5px;
  margin: 5px;
  text-align: center;
  ${(props) =>
    props.theme.template.id === "5" &&
    css`
      color: #ffffff;
    `}
`;
