import React, { Component } from "react";
import SellerPrmContext from "../../SellerPrmContext";
import SellerDashboardMain from "../components/SellerDashboard/SellerDashboardMain";

export default class SellerDashboard extends Component {
  render() {
    return (
      <SellerPrmContext.Consumer>
        {(sellerPrm) => <SellerDashboardMain sellerPrm={sellerPrm} />}
      </SellerPrmContext.Consumer>
    );
  }
}
