import React, { Component } from "react";
import ProductTabCategory from "./ProductTabCategory/ProductTabCategory";
import ProductAllRows from "./ProductAllRows/ProductAllRows";
import { withRouter } from "react-router-dom";
import { MDBContainer, MDBCol, MDBRow, MDBCard, MDBCardBody } from "mdbreact";

// import style from "../ProductHome.module.css";
import {
  ProductSectionContainer,
  ProductSectionTitle,
  TabSectionContainer,
} from "../ProductHome.styles";

class ProductContainer extends Component {
  constructor() {
    super();
    this.state = {
      statePage: "",
      activeTab: 0,
      page: 1,
    };
    this.topCategory = React.createRef();
  }

  componentDidMount() {
    if (this.props.sellerPrm.subDomainData) {
      const statePage = this.checkPageState(this.props.location.pathname);
      const { id } = this.props.match.params;
      this.setState({ statePage }, () => {
        this.setActiveTab();
        if (statePage === "CATEGORY") {
          this.props.homeProduct.homeProductCategory(
            this.props.sellerPrm.subDomainData.id,
            id
          );
          this.props.homeProduct.homeProduct(
            this.props.sellerPrm.subDomainData.id
          );
        } else {
          this.props.homeProduct.homeProduct(
            this.props.sellerPrm.subDomainData.id
          );
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo({
        top: this.topCategory.current.offsetTop - 50,
        behavior: "smooth",
      });
      this.setState({ page: 1 });
      if (this.props.sellerPrm.subDomainData) {
        const statePage = this.checkPageState(this.props.location.pathname);
        const { id } = this.props.match.params;
        this.setState({ statePage }, () => {
          this.setActiveTab();
          if (statePage === "CATEGORY") {
            this.props.homeProduct.homeProductCategory(
              this.props.sellerPrm.subDomainData.id,
              id
            );
            this.props.homeProduct.homeProduct(
              this.props.sellerPrm.subDomainData.id
            );
          } else {
            this.props.homeProduct.homeProduct(
              this.props.sellerPrm.subDomainData.id
            );
          }
        });
      }
    }
  }

  checkPageState = (pathname) => {
    if (pathname.match(/(\/category)/g)) {
      return "CATEGORY";
    } else {
      return "ALL";
    }
  };

  setActiveTab = () => {
    const { statePage } = this.state;
    const { id } = this.props.match.params;
    if (statePage === "ALL") {
      this.setState({ activeTab: 0 });
    } else if (statePage === "CATEGORY") {
      this.setState({ activeTab: id });
    }
  };

  loadNextProduct = (sellerId, categoryId) => {
    this.setState(
      (prevState) => ({
        page: prevState.page + 1,
      }),
      () => {
        this.props.homeProduct.homeProductCategory(
          sellerId,
          categoryId,
          this.state.page
        );
      }
    );
  };

  render() {
    // console.log(this.props.homeProduct.listProdFromCat.length);
    // console.log(this.props.homeProduct.totalItem);
    return (
      <>
        <div ref={this.topCategory} />
        <TabSectionContainer>
          <ProductTabCategory
            sellerCategory={this.props.homeProduct.listCategory}
            activeTab={this.state.activeTab}
          />
        </TabSectionContainer>
        <ProductSectionContainer>
          {this.state.statePage === "ALL"
            ? this.props.homeProduct.listCategory.map((item, index) => (
                <ProductAllRows
                  key={index}
                  item={item}
                  statePage={this.state.statePage}
                />
              ))
            : ""}
          {this.state.statePage === "CATEGORY" ? (
            <>
              <MDBContainer>
                <MDBRow>
                  {this.props.homeProduct.listProdFromCat.map((item, index) => (
                    <MDBCol key={index} size="6" style={{ padding: 0 }}>
                      <ProductAllRows
                        item={item}
                        statePage={this.state.statePage}
                      />
                    </MDBCol>
                  ))}
                  {this.props.homeProduct.listProdFromCat.length !==
                  this.props.homeProduct.totalItem ? (
                    <MDBCol
                      size="12"
                      onClick={() => {
                        this.loadNextProduct(
                          this.props.sellerPrm.subDomainData.id,
                          this.props.match.params.id
                        );
                      }}
                    >
                      <MDBCard cascade narrow ecommerce>
                        <MDBCardBody cascade>
                          <div style={{ textAlign: "center" }}>
                            Muat Lebih Banyak
                          </div>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBCol>
                  ) : (
                    ""
                  )}
                </MDBRow>
              </MDBContainer>
            </>
          ) : (
            ""
          )}
        </ProductSectionContainer>
      </>
    );
  }
}

export default withRouter(ProductContainer);
