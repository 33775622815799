import React, { Component } from "react";
import SellerPrmContext from "../../SellerPrmContext";
import BankAccountMain from "../components/BankAccount/BankAccountMain";

export default class BankAccount extends Component {
  render() {
    return (
      <SellerPrmContext.Consumer>
        {(sellerPrm) => <BankAccountMain sellerPrm={sellerPrm} />}
      </SellerPrmContext.Consumer>
    );
  }
}
