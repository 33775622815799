import React, { Component } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBRow,
  MDBCol,
} from "mdbreact";
import {
  AddressSelectorItem,
  AddressSelectorItemContainer,
} from "./AddressSelector.styles";

class AddressSelector extends Component {
  render() {
    return (
      <MDBContainer>
        <MDBModal
          isOpen={this.props.showModal}
          toggle={this.props.toggleHandler}
          className="modal-add-cart"
        >
          <MDBModalHeader toggle={this.props.toggleHandler}>
            Pilih Kecamatan/Kota
          </MDBModalHeader>
          <MDBModalBody className="modalOn">
            <MDBRow>
              <MDBCol size="12">
                <input
                  className="form-control w-100"
                  type="text"
                  value={this.props.textLocation}
                  onChange={this.props.searchLocation}
                  placeholder="Tulis minimal 3 karakter"
                />
              </MDBCol>
            </MDBRow>
            <AddressSelectorItemContainer>
              {this.props.locationList
                ? this.props.locationList.map((data) => {
                    return (
                      <AddressSelectorItem
                        key={data.id}
                        onClick={(e) => this.props.selectedLocation(data)}
                      >
                        {data.name}
                      </AddressSelectorItem>
                    );
                  })
                : ""}
            </AddressSelectorItemContainer>
          </MDBModalBody>
          <MDBModalFooter></MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  }
}

export default AddressSelector;
