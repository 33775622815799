import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";
import { CenterLoader } from "../_UtilityComponent/Loader/CenterLoader";
import ShipmentView from "./ShipmentView/ShipmentView";
import ShipmentAddressSelector from "./ShipmentView/ShipmentAddress/ShipmentAddressSelector";
import ShipmentCourierSelector from "./ShipmentView/ShipmentCourier/ShipmentCourierSelector/ShipmentCourierSelector";

class ShipmentMain extends Component {
  componentDidMount() {
    if (this.props.sellerPrm.subDomainData) {
      this.props.cart.checkUserLocationData(
        true,
        this.props.sellerPrm.subDomainData.id
      );
      // this.props.cart.listData(this.props.sellerPrm.subDomainData.id, true);
      // this.props.cart.getCourierList();
    }
  }

  render() {
    const { cart, sellerPrm } = this.props;
    return (
      <>
        {!cart.isLoading ? (
          <>
            <ShipmentAddressSelector
              showModal={cart.addressSelectorModal}
              toggleHandler={cart.popAddressSelector}
              addressList={cart.addressList}
              selectedAddressId={cart.selectedShipmentAddress.id}
              setSelectedAddress={cart.setSelectedAddress}
            />
            <ShipmentCourierSelector
              showModal={cart.courierSelectorModal}
              toggleHandler={cart.popCourierSelector}
              setSelectedService={cart.setSelectedService}
              actionModalCourier={cart.actionModalCourier}
              courierList={cart.courierList}
            />
            <MDBContainer>
              <ShipmentView
                address={cart.selectedShipmentAddress}
                cartActive={cart.cartActive}
                popAddressSelector={cart.popAddressSelector}
                popCourierSelector={cart.popCourierSelector}
                isCheckingCourier={cart.isCheckingCourier}
                isCheckingCourierFailed={cart.isCheckingCourierFailed}
                sumAllTotal={cart.sumAllTotal}
                cartsCourierCalculated={cart.cartsCourierCalculated}
                sendDataToSNAP={cart.sendDataToSNAP}
                sellerName={sellerPrm.subDomainData.name_web}
                sellerId={sellerPrm.subDomainData.id}
                sellerData={sellerPrm.subDomainData.sellerData}
                user={sellerPrm.dataUser}
                link={sellerPrm.link}
              />
            </MDBContainer>
          </>
        ) : (
          <CenterLoader />
        )}
      </>
    );
  }
}

export default withRouter(ShipmentMain);
