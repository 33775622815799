import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBDataTable,
  MDBBtn,
  MDBCol,
  MDBRow,
} from "mdbreact";
import { Link } from "react-router-dom";

const SellerProductsView = (props) => {
  return (
    <MDBContainer>
      <MDBCard>
        <MDBRow>
          <MDBCol>
            <Link to={"/admin/sellerproducts/add-product"}>
              <MDBBtn>Tambah Produk</MDBBtn>
            </Link>
          </MDBCol>
          <MDBCol />
        </MDBRow>
        <MDBCardBody>
          <MDBDataTable
            hover
            noBottomColumns
            responsive
            data={props.data}
          />
        </MDBCardBody>
      </MDBCard>
    </MDBContainer>
  );
};

export default SellerProductsView;
