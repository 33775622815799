import React from "react";
import { MDBCol, MDBCard } from "mdbreact";
import { Link } from "react-router-dom";

import styles from "./ProductCategory.module.css";
import {
  StyledProductCategory_Container,
  StyledProductCategory_ContainerCard,
} from "./ProductCategory.styles";

const ProductCategory = (props) => {
  return (
    <>
      {props.item.value === 0 ? (
        <StyledProductCategory_Container>
          <Link to={"/"}>
            <StyledProductCategory_ContainerCard $activeTab={props.activeTab}>
              Semua
            </StyledProductCategory_ContainerCard>
          </Link>
        </StyledProductCategory_Container>
      ) : (
        <StyledProductCategory_Container>
          <Link to={"/category/" + props.item.id}>
            <StyledProductCategory_ContainerCard $activeTab={props.activeTab}>
              {props.item.name}
            </StyledProductCategory_ContainerCard>
          </Link>
        </StyledProductCategory_Container>
      )}
    </>
  );
};

ProductCategory.propTypes = {};

export default ProductCategory;
