import React, { Component } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBLink,
} from "mdbreact";
import style from "./ProductDetail.module.css";
import { AboutDiv, CMDBBtn } from "../StyledComponent";
import '../../../assets/css/fonts.css'

export default class ProductDetailForm extends Component {
  state = {
    varian: 0,
    quantity: 1,
    note_flag: false,
    note: "",
  };

  handelChangeVarian = (varian) => {
    this.setState({ varian: varian });
  };

  handelChange = (event) => {
    let { name, value } = event.target;
    let stock = this.props.product_detail.dtl_product_active[this.state.varian]
      .stock;
    if ((name === "quantity") & (value === "")) value = "1";
    if ((name === "quantity") & (parseInt(value) > parseInt(stock)))
      value = stock;
    this.setState({ [name]: value });
  };

  handleQuantity = (proses, stock) => {
    let quant =
      proses === "+"
        ? this.state.quantity < stock
          ? this.state.quantity + 1
          : stock
        : this.state.quantity <= 1
        ? 1
        : this.state.quantity - 1;
    this.setState({ quantity: quant });
  };

  hadelNote = () => {
    this.setState({ note_flag: !this.state.note_flag, note: "" });
  };

  render() {
    const product = this.props.product_detail.dtl_product_active;
    const desc = this.props.product_detail.description;
    const { isSeller, sellerId, addToChartFromPage, seller_style } = this.props;
    console.log(this.props);
    const isStockReady = parseInt(product[this.state.varian].stock) <= 0;
    return (
      <MDBCard style={{ minHeight: "630px" }}>
        <MDBCardBody>
          <MDBCardTitle>
            <div style={{fontFamily:'Lato Bold', marginBottom:'5px'}}>
              {this.props.name + " - " + product[this.state.varian].name}
            </div>
            <div style={{ fontSize: "15px", color: "gray", fontFamily:'Lato Semi Bold', marginBottom:'5px'}}>
              {`${product[this.state.varian].weight} gram`}
            </div>
            <div style={{fontFamily:'Lato Semi Bold', marginBottom:'5px'}}>
              {new Intl.NumberFormat("ID", {
                style: "currency",
                currency: "IDR",
              }).format(product[this.state.varian].price)}
            </div>
          </MDBCardTitle>
          <hr />
          <MDBRow>
            <MDBCol>
              <span style={{ fontWeight: "bold" }}>{`Description : `}</span>
              <AboutDiv
                className="overflow-auto"
                hidden={this.state.show}
                style={{
                  width: "100%",
                  height: "110px",
                }}
              >
                {desc}
              </AboutDiv>
            </MDBCol>
          </MDBRow>
          <hr />
          <MDBRow>
            <MDBCol size="3" middle>
              VARIAN
            </MDBCol>
            <MDBCol middle>
              <MDBRow>
                {product.map((data, index) => {
                  return (
                    <CMDBBtn
                      size="sm"
                      bg_color={seller_style.color_btn}
                      key={index}
                      disabled={parseInt(data.stock) <= 0}
                      onClick={() => this.handelChangeVarian(index)}
                      active={this.state.varian === index}
                      style={{ borderRadius: "15px" }}
                    >
                      {data.name}
                    </CMDBBtn>
                  );
                })}
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <hr />
          <MDBRow>
            <MDBCol size="3" middle>
              JUMLAH
            </MDBCol>
            <MDBCol middle>
              <MDBRow>
                <MDBBtn
                  disabled={isStockReady}
                  size="sm"
                  color={"dark"}
                  outline
                  rounded
                  onClick={() =>
                    this.handleQuantity("-", product[this.state.varian].stock)
                  }
                >
                  <MDBIcon icon="minus" />
                </MDBBtn>
                <input
                  disabled={isStockReady}
                  name="quantity"
                  type="number"
                  onChange={this.handelChange}
                  value={this.state.quantity}
                  className={style.input_number}
                />
                <MDBBtn
                  disabled={isStockReady}
                  size="sm"
                  color={"dark"}
                  outline
                  rounded
                  onClick={() =>
                    this.handleQuantity("+", product[this.state.varian].stock)
                  }
                >
                  <MDBIcon icon="plus" />
                </MDBBtn>
                <MDBCol middle>
                  <div style={{ color: "grey" }}>{`Stock : ${
                    product[this.state.varian].stock
                  }`}</div>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="3" middle></MDBCol>
            <MDBCol middle>
              <MDBLink
                to="#"
                onClick={() => this.hadelNote()}
                hidden={this.state.note_flag}
              >
                <span style={{ color: seller_style.color_btn}}>
                  Tulis catatan untuk penjual
                </span>
              </MDBLink>
              <div
                className="form-group w-100"
                style={{ display: "flex", marginTop: 10 }}
                hidden={!this.state.note_flag}
              >
                <input
                  type="text"
                  onChange={this.handelChange}
                  className="form-control"
                  name="note"
                  value={this.state.note}
                />
                <MDBBtn
                  style={{ marginTop: "3px" }}
                  size="sm"
                  color="danger"
                  onClick={() => this.hadelNote()}
                >
                  <MDBIcon icon="times" />
                </MDBBtn>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="3" middle>
              TOTAL
            </MDBCol>
            <MDBCol style={{ fontSize: 20 }} middle>
              {new Intl.NumberFormat("ID", {
                style: "currency",
                currency: "IDR",
              }).format(product[this.state.varian].price * this.state.quantity)}
            </MDBCol>
          </MDBRow>
          {isSeller ? (
            ""
          ) : (
            <>
              <hr />
              <MDBRow end>
                {/* <MDBBtn>Beli</MDBBtn> */}
                <CMDBBtn
                  active
                  bg_color={seller_style.color_btn}
                  onClick={() =>
                    addToChartFromPage(
                      sellerId,
                      product[this.state.varian].id,
                      this.state.quantity,
                      this.state.note,
                      product[this.state.varian].seller_location_id,
                      product[this.state.varian].stock
                    )
                  }
                  style={{ borderRadius: "15px" }}
                >
                  Tambah ke Keranjang
                </CMDBBtn>
              </MDBRow>
            </>
          )}
        </MDBCardBody>
      </MDBCard>
    );
  }
}
