import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
} from "mdbreact";
import React, { Component } from "react";

export default class DashboardSellerOrderProcessTrackingModal extends Component {
  render() {
    console.log(this.props);
    return (
      <MDBContainer>
        <MDBModal
          isOpen={this.props.trackingStatusModal}
          toggle={() => this.props.trackingModalHandler("CLOSE")}
          size="md"
          centered
        >
          <MDBModalHeader
            toggle={() => this.props.trackingModalHandler("CLOSE")}
          >
            Status Pengiriman
          </MDBModalHeader>
          <MDBModalBody>
            <div className="overflow-auto" style={{ maxHeight: "400px" }}>
              {this.props.trackingStatus ? (
                <TrackingDetailComponent
                  courier={this.props.trackingStatus.courier}
                  track={this.props.trackingStatus.track}
                />
              ) : (
                <MDBCol size="12" middle style={{ marginBottom: "1rem" }}>
                  <MDBCard>
                    <MDBCardBody className="z-depth-2">
                      <div style={{ display: "flex" }}>
                        <MDBIcon icon="comment-dots" />
                        <div
                          style={{ marginLeft: "5px", fontSize: "15px" }}
                        ></div>
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              )}
            </div>
          </MDBModalBody>
        </MDBModal>
      </MDBContainer>
    );
  }
}

class TrackingDetailComponent extends Component {
  formatDate = (string) => {
    var options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return new Date(string).toLocaleDateString("ID", options);
  };

  render() {
    console.log(this.props);
    const { courier, track } = this.props;
    if (courier === "NCS") {
      return track.map((data, index) => {
        if (
          data.Status !== "DE" &&
          data.Status !== "SD" &&
          data.Status !== "ST"
        ) {
          return (
            <div key={index} style={{ marginBottom: "1rem" }}>
              <MDBCol middle size="2">
                <MDBIcon
                  size="sm"
                  icon={data.Status === "OK" ? "thumbs-up" : "check-circle"}
                  style={{ color: "green" }}
                />
              </MDBCol>
              <MDBCol middle>
                <table
                  style={{
                    width: "100%",
                  }}
                >
                  <tbody>
                    <tr>
                      <td colSpan="3">{this.formatDate(data.TimeStamp)}</td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>{data.Comment}</td>
                    </tr>
                    <tr>
                      <td>Lokasi</td>
                      <td>{data.Station}</td>
                    </tr>
                  </tbody>
                </table>
              </MDBCol>
            </div>
          );
        }
      });
    }
  }
}
