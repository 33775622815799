import React, { Component } from "react";
import AboutSettingMain from "../components/About/AboutSettingMain";
import SellerPrmContext from "../../SellerPrmContext";

export default class AboutSetting extends Component {
  render() {
    return (
      <SellerPrmContext.Consumer>
        {(sellerPrm) => <AboutSettingMain sellerPrm={sellerPrm} />}
      </SellerPrmContext.Consumer>
    );
  }
}
