import { MDBIcon } from "mdbreact";
import React from "react";
import * as S from "../_Styled/CardPayment.styles.js";
import ImageComponent from "../_UtilityComponent/Image/ImageComponent.js";

const formatDate = (string) => {
  let options = { day: "numeric", month: "long", year: "numeric" };
  return new Date(Date.parse(string)).toLocaleDateString("ID", options);
};

const formatCurrency = (country, currency, text) => {
  return new Intl.NumberFormat(country, {
    style: "currency",
    currency: currency,
  }).format(text);
};

const status = {
  1: "Menunggu Konfirmasi",
  2: "Menunggu Pesanan Diambil",
  3: "Pesanan dalam Pengiriman",
  4: "Meminta Konfirmasi Pesanan Selesai",
  5: "Pesanan Selesai",
  98: "Meminta Pembatalan Pesanan",
  99: "Pesanan Dibatalkan",
};

export const UserTransactionStatusItem = (props) => {
  console.log(props);
  switch (props.selectedProcess) {
    case 1:
      return (
        <div>
          <S.CardContainerPayment className="mt-3 mb-3">
            <S.CardHeaderPayment>
              <S.CardInfoName>Tanggal Pembayaran</S.CardInfoName>
              <S.CardInfoContent>
                {formatDate(props.item.transaction_time)}
              </S.CardInfoContent>
            </S.CardHeaderPayment>
            <S.CardBodyPayment>
              <S.CardInfoName>No.Order</S.CardInfoName>
              <S.CardInfoContent>{props.item.order_id}</S.CardInfoContent>
              <S.CardInfoName>Status</S.CardInfoName>
              <S.CardInfoContent>{props.item.status}</S.CardInfoContent>
              <S.CardInfoName>Nama Toko</S.CardInfoName>
              <S.CardInfoContent>
                {props.item.seller_details.seller_name}
              </S.CardInfoContent>
              <S.CardInfoName>Metode Bayar</S.CardInfoName>
              <S.CardInfoContent>{props.item.payment_type}</S.CardInfoContent>
            </S.CardBodyPayment>

            <UserTrStatusDtlItems cartDetail={props.item.cart_detail} />

            <S.CardFooterPayment>
              <S.CardInfoName>Total Pembayaran</S.CardInfoName>
              <S.CardInfoContent>
                {formatCurrency("ID", "IDR", props.item.gross_amount)}
              </S.CardInfoContent>
            </S.CardFooterPayment>
          </S.CardContainerPayment>
        </div>
      );
    case 2:
    case 3:
    case 4:
      return (
        <div>
          <S.CardContainerPayment className="mt-3 mb-3">
            <S.CardHeaderPayment>
              <S.CardInfoName>Tanggal Pembelian</S.CardInfoName>
              <S.CardInfoContent>
                {formatDate(props.item.created_at)}
              </S.CardInfoContent>
            </S.CardHeaderPayment>
            <S.CardBodyPayment>
              <S.CardInfoName>No.Invoice</S.CardInfoName>
              <S.CardInfoContent>{props.item.id}</S.CardInfoContent>
              <S.CardInfoName>Status</S.CardInfoName>
              <S.CardInfoContent>{status[props.item.status]}</S.CardInfoContent>
              <S.CardInfoName>Pengiriman</S.CardInfoName>
              <S.CardInfoContent>{`${props.item.courier_name} (${props.item.courier_service})`}</S.CardInfoContent>
              {props.item.status === "1" ? (
                <S.CardItemReqCancelBtn
                  onClick={() => props.updateInvoiceStatus(props.item.id, 98)}
                >
                  <MDBIcon icon="trash-alt" />
                  {` Request Cancel`}
                </S.CardItemReqCancelBtn>
              ) : (
                ""
              )}
              {props.item.status === "3" ? (
                <S.CardItemTrackBtn
                  onClick={() =>
                    props.getTrackingStatus(
                      props.item.courier_name,
                      props.item.tracking_code
                    )
                  }
                >
                  <MDBIcon icon="map" />
                  {` Lacak`}
                </S.CardItemTrackBtn>
              ) : (
                ""
              )}
              {props.item.status === "4" ? (
                <S.CardItemDoneBtn
                  onClick={() => props.updateInvoiceStatus(props.item.id, 5)}
                >
                  <MDBIcon icon="thumbs-up" />
                  {` Selesai`}
                </S.CardItemDoneBtn>
              ) : (
                ""
              )}
            </S.CardBodyPayment>

            <UserTrStatusDtlItems dtlInvoice={props.item.dtl_invoice} />

            <S.CardFooterPayment>
              <S.CardInfoName>Total Pembayaran</S.CardInfoName>
              <S.CardInfoContent>
                {formatCurrency("ID", "IDR", props.item.payment.payment_total)}
              </S.CardInfoContent>
            </S.CardFooterPayment>
          </S.CardContainerPayment>
        </div>
      );
  }
};

const UserTrStatusDtlItems = (props) => {
  if (props.cartDetail) {
    return (
      <>
        <S.CardItemDtlContainer>
          <S.CardItemDtlRow>
            {props.cartDetail.map((item) => {
              return item.item_details.map((item, idx) => (
                <UserTrStatusDtlItemPending key={idx} item={item} />
              ));
            })}
          </S.CardItemDtlRow>
        </S.CardItemDtlContainer>
      </>
    );
  } else if (props.dtlInvoice) {
    return (
      <>
        <S.CardItemDtlContainer>
          <S.CardItemDtlRow>
            {props.dtlInvoice.map((item, idx) => (
              <UserTrStatusDtlItemInvoice key={idx} item={item} />
            ))}
          </S.CardItemDtlRow>
        </S.CardItemDtlContainer>
      </>
    );
  }
};

const UserTrStatusDtlItemPending = (props) => {
  let isCourier = props.item.item_id.toString().split("-")[0] === "COURIER";
  let price = parseInt(props.item.price) * parseInt(props.item.quantity);
  if (!isCourier) {
    return (
      <S.CardItemDtlWrapper>
        <S.CardImageContainer>
          <ImageComponent isSquare={true} img={props.item.img} />
        </S.CardImageContainer>
        <S.CardItemInfo>
          <div style={{ fontWeight: 600 }}>
            {props.item.name} ({props.item.detail_name})
          </div>
          <div> {props.item.weight} gr</div>
          <div>{`${new Intl.NumberFormat("ID", {
            style: "currency",
            currency: "IDR",
          }).format(price)} (${props.item.quantity} item)`}</div>
        </S.CardItemInfo>
      </S.CardItemDtlWrapper>
    );
  } else {
    return <div></div>;
  }
};

const UserTrStatusDtlItemInvoice = (props) => {
  let isCourier = props.item.product_id.toString().split("-")[0] === "COURIER";
  let price =
    parseInt(props.item.product_price) * parseInt(props.item.product_quantity);
  if (!isCourier) {
    return (
      <S.CardItemDtlWrapper>
        <S.CardImageContainer>
          <ImageComponent isSquare={true} img={props.item.product_image} />
        </S.CardImageContainer>
        <S.CardItemInfo>
          <div style={{ fontWeight: 600 }}>{props.item.product_name}</div>
          <div> {props.item.product_weight} gr</div>
          <div>{`${new Intl.NumberFormat("ID", {
            style: "currency",
            currency: "IDR",
          }).format(price)} (${props.item.product_quantity} item)`}</div>
        </S.CardItemInfo>
      </S.CardItemDtlWrapper>
    );
  } else {
    return <div></div>;
  }
};
