import React, { Component } from "react";
import API from "../../utils/API";
import Swal from "sweetalert2";
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBLink,
  MDBRow,
} from "mdbreact";
import { CMDBBtn } from "../components/StyledComponent";

export default class Activation extends Component {
  state = {
    codeFeedback: "",
    isLoading: false,
    isError: false,
  };

  componentDidMount() {
    const { token } = this.props.match.params;
    this.checkToken(token);
  }

  checkToken = (token) => {
    this.setState({ isLoading: true });
    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };
    API.get(`/auth/activation/${token}`, config)
      .then((res) => {
        console.log(res);
        this.setState({
          codeFeedback: res.data.code,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { codeFeedback, isError, isLoading } = this.state;
    return (
      <MDBContainer style={{ paddingTop: "5rem" }}>
        {!isLoading && codeFeedback !== "" ? (
          parseInt(codeFeedback) === 200 ? (
            <MDBCard>
              <MDBCardBody>
                <MDBCol>
                  <MDBRow center>Akun berhasil diaktivasi</MDBRow>
                  <MDBRow center>
                    <MDBLink to="/login">
                      <CMDBBtn bg_color="#804199">LOGIN</CMDBBtn>
                    </MDBLink>
                  </MDBRow>
                </MDBCol>
              </MDBCardBody>
            </MDBCard>
          ) : (
            <MDBCard>
              <MDBCardBody>
                <MDBCol>
                  <MDBRow center>Akun telah diaktivasi</MDBRow>
                  <MDBRow center>
                    <MDBLink to="/login">
                      <CMDBBtn bg_color="#804199">LOGIN</CMDBBtn>
                    </MDBLink>
                  </MDBRow>
                </MDBCol>
              </MDBCardBody>
            </MDBCard>
          )
        ) : (
          ""
        )}
      </MDBContainer>
    );
  }
}
