import styled, { css, keyframes } from "styled-components";

export const StoreListLandingContainer = styled.div`
  margin-top: 50px;
  /* display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px; */
  max-width: 1200px;
  margin: 40px auto;
  display: flex;
  flex-wrap: wrap;
  height: 200px;
  overflow-y: scroll;
`;

export const StoreCardContainer = styled.div`  
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid #ddd;
  min-width: 25%;
  height: fit-content;
  padding: 5px;
  background: white;
`;

export const StoreCardImg = styled.div`
  background-image: ${(props) => `url(${props.src})`};
  background-color: white;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100px;
  width: auto;
`;
