import { MDBCol, MDBContainer, MDBIcon, MDBRow } from "mdbreact";
import React from "react";
import "./css/Home.css";
import google_play_img from "../../assets/google-play-badge.png";

const Footer = (props) => {
  let {
    seller_data,
    seller_style,
    seller_location,
    facebook,
    instagram,
    twitter,
  } = props.subDomainData;

  return (
    <div
      style={{
        backgroundColor: seller_style.color_primary,
        color: "white",
        fontFamily: "Lato Regular",
      }}
    >
      {/* <MDBFooter className="pt-0"> */}
      <MDBContainer>
        <MDBRow style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
          <MDBCol lg="6" sm="8">
            <div style={{ fontWeight: "bold" }}>{`Alamat :`}</div>
            <div>{seller_location.address}</div>
            <div>{`Email : ${seller_data.email}`}</div>
            <div className="footer_icon">
              <a href="#!">
                <MDBIcon fab icon="facebook" style={{ color: "#3b5998" }} />
              </a>
              <a href="#!">
                <MDBIcon fab icon="twitter" style={{ color: "#00acee" }} />
              </a>
              <a href="#!">
                <MDBIcon fab icon="instagram" />
              </a>
            </div>
          </MDBCol>
          <MDBCol
            lg="3"
            sm="4"
            style={{
              padding: 0,
            }}
          >
            <div style={{ fontWeight: "bold" }}>{`1Toko`}</div>
            <div className="term">
              <a href="#!">{`Terms & Conditions`}</a>
              <br />
              <a href="#!">{`Shipping Policy`}</a>
              <br />
              <a href="#!">{`Return Policy`}</a>
              <br />
              <a href="#!">{`How to Order`}</a>
              <br />
            </div>
          </MDBCol>
          <MDBCol lg="3" sm="12">
            <div style={{ fontWeight: "bold" }}>{`Download App`}</div>
            <img src={google_play_img} style={{ maxWidth: "200px" }} />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
};
export default Footer;
