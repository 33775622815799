import {
  MDBBadge,
  MDBBtn,
  MDBDropdownItem,
  MDBNavbar,
  MDBTimePicker,
} from "mdbreact";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const CMDBBtn = styled(MDBBtn)`
  color: ${(props) => props.bg_color} !important;
  background-color: #ffffff !important;
  border: 2px solid ${(props) => props.bg_color};
  font-weight: bold;
  .btn-default:not([disabled]):not(.disabled)&:active,
  .btn-default:not([disabled]):not(.disabled)&.active,
  .show > .btn-default.dropdown-toggle {
    color: #ffffff !important;
    background-color: ${(props) => props.bg_color} !important;
  }
`;

export const CMDBBadge = styled(MDBBadge)`
  background-color: ${(props) => props.bg_color} !important;
`;

export const CMDBDropdownItem = styled(MDBDropdownItem)`
  &:hover {
    background-color: ${(props) => props.color} !important;
    border-radius: 0.125rem;
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
      0 4px 15px 0 rgba(0, 0, 0, 0.15);
    transition: all 0.1s linear;
  }
`;

export const Sidebar = styled.div`
  height: 100%;
  width: 200px;
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  background-color: ${(props) => props.bg_color};
  overflow-x: hidden;
`;

const activeClassName = "nav-item-active";
export const StyledLink = styled(NavLink).attrs({ activeClassName })`
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 16px;
  color: #818181;
  display: block;

  &:hover {
    color: #f1f1f1;
  }

  &.${activeClassName} {
    background-color: #ffffff;
  }
`;

export const NSCPickupForm = styled.div`
  .md-form {
    margin: 0;
  }
`;

export const AboutDiv = styled.div`
  p {
    margin: 0;
  }
`;

export const HeaderNavbar = styled(MDBNavbar)`
  box-shadow: none;
`;
