import React, { Component } from "react";
import SellerPrmContext from "../../SellerPrmContext";
import DiscussionMain from "../components/ProductPage/Discussion/DiscussionMain";
import HomeProductContext from "../context/HomeProductContext";

export default class Discussion extends Component {
  render() {
    return (
      <div>
        <SellerPrmContext.Consumer>
          {(sellerPrm) => (
            <HomeProductContext.Consumer>
              {(homeProduct) => (
                <DiscussionMain
                  discussionPage={"DISCUSSION"}
                  sellerPrm={sellerPrm}
                  homeProduct={homeProduct}
                />
              )}
            </HomeProductContext.Consumer>
          )}
        </SellerPrmContext.Consumer>
      </div>
    );
  }
}
