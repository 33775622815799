import React, { Component } from "react";
import style from "./ImageComponent.module.css";

let imgStyle = null;

export default class ImageComponent extends Component {
  constructor(props) {
    super(props);
    this.containerImg = React.createRef();
  }

  componentDidMount() {
    imgStyle = {
      height: this.props.isSquare
        ? this.containerImg.current.offsetWidth
        : "auto",
    };
  }

  render() {
    return (
      <div ref={this.containerImg} className={style.pictureContainer}>
        <div className={style.pictureTable}>
          <div
            className={
              (`${style.pictureCell}
              ${this.props.roundedFrame ? style.pictureCellRounded : ""}
              ${this.props.transparent ? style.pictureCellTransparent : ""}
              ${this.props.noPadding ? style.pictureCellNoPadding : ""}`)
            }
          >
            <img
              style={this.imgStyle}
              className={style.img}
              src={this.props.img}
            />
          </div>
        </div>
      </div>
    );
  }
}
