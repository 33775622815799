import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBRow,
  MDBSelect,
} from "mdbreact";
import {
  ManageSellerAddProductsSubTitle,
  ManageSellerProductsPhotoContainer,
  ManageSellerProductsPhotos,
} from "./ManageSellerProducts.styles";
import MDBFileUpload from "mdb-react-fileupload";
import React from "react";

export const ManageSellerProductsAdd = (props) => {
  return (
    <MDBContainer>
      <ManageSellerAddProductsSubTitle>
        Foto Produk
      </ManageSellerAddProductsSubTitle>
      <ManageSellerProductsPhotoContainer>
        <ManageSellerProductsPhotos>
          <MDBFileUpload
            showRemove={false}
            containerHeight={150}
            messageDefault={"Gambar Utama (Wajib)"}
            messageReplace={"Utama"}
            getValue={(e) => props.selectProductPhoto(e, 0)}
          />
        </ManageSellerProductsPhotos>
        <ManageSellerProductsPhotos>
          <MDBFileUpload
            showRemove={false}
            containerHeight={150}
            messageDefault={"Gambar Tambahan"}
            // messageReplace={"Depan"}
            getValue={(e) => props.selectProductPhoto(e, 1)}
          />
        </ManageSellerProductsPhotos>
        <ManageSellerProductsPhotos>
          <MDBFileUpload
            showRemove={false}
            containerHeight={150}
            messageDefault={"Gambar Tambahan"}
            // messageReplace={"Samping"}
            getValue={(e) => props.selectProductPhoto(e, 2)}
          />
        </ManageSellerProductsPhotos>
        <ManageSellerProductsPhotos>
          <MDBFileUpload
            showRemove={false}
            containerHeight={150}
            messageDefault={"Gambar Tambahan"}
            // messageReplace={"Atas"}
            getValue={(e) => props.selectProductPhoto(e, 3)}
          />
        </ManageSellerProductsPhotos>
        <ManageSellerProductsPhotos>
          <MDBFileUpload
            showRemove={false}
            containerHeight={150}
            messageDefault={"Gambar Tambahan"}
            // messageReplace={"Detail"}
            getValue={(e) => props.selectProductPhoto(e, 4)}
          />
        </ManageSellerProductsPhotos>
      </ManageSellerProductsPhotoContainer>
      <ManageSellerAddProductsSubTitle>
        Keterangan Produk
      </ManageSellerAddProductsSubTitle>
      <MDBInput
        value={props.name}
        name="name"
        onChange={props.changeHandler}
        type="text"
        className="form-control"
        label="Nama Produk"
        required
      >
        <div className="invalid-feedback">Nama Produk</div>
      </MDBInput>
      <MDBSelect
        search
        color="primary"
        options={props.categoryActiveList}
        selected="Pilih kategori produk"
        color="primary"
        label="Kategori"
        getValue={props.selectProductCategory}
        required
      />
      <MDBInput
        value={props.description}
        onChange={props.changeHandler}
        type="textarea"
        className="form-control"
        name="description"
        label="Deskripsi"
        required
      />
      <ManageSellerAddProductsSubTitle>
        Variasi Detail Produk
      </ManageSellerAddProductsSubTitle>
      <MDBInput
        value={props.nameDetail}
        onChange={props.changeHandler}
        type="text"
        className="form-control"
        name="nameDetail"
        label="Nama Variasi Utama"
        required
      />
      <small>
        *Tips:Masukkan variasi dari barang, seperti warna Merah atau ukuran XL
      </small>
      <MDBInput
        value={props.qty}
        onChange={props.changeHandler}
        type="number"
        className="form-control"
        name="qty"
        label="Stok Barang"
        required
      />
      <MDBInput
        value={props.price}
        onChange={props.changeHandler}
        type="number"
        className="form-control"
        name="price"
        label="Harga"
        required
      />
      <MDBInput
        value={props.weight}
        onChange={props.changeHandler}
        type="number"
        className="form-control"
        name="weight"
        label="Berat (Gram)"
        required
      />
      <MDBSelect
        search
        color="primary"
        options={props.sellerLocationList}
        selected="Pilih lokasi barang"
        label="Lokasi barang"
        getValue={props.selectSellerLocation}
        required
      />
      <ManageSellerProductsPhotos>
        <MDBFileUpload
          showRemove={false}
          containerHeight={150}
          messageDefault={"Gambar Detail Produk"}
          messageReplace={"Detail Produk"}
          getValue={(e) => props.selectDetailProductPhoto(e)}
        />
      </ManageSellerProductsPhotos>
      <MDBContainer style={{ paddingTop: "5%", paddingBottom: "10%" }}>
        <MDBBtn
          className="w-100 mr-0 ml-0 mt-2"
          color="deep-orange"
          type="submit"
          onClick={() => props.storeSellerProduct(props.sellerId)}
        >
          Simpan
        </MDBBtn>
      </MDBContainer>
    </MDBContainer>
  );
};
