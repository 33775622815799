import React, { Component } from "react";
import SellerProductsFormView from "./SellerProductsFormView";
import SellerProductDetailFormModal from "./SellerProductDetailFormModal";
import { withRouter } from "react-router-dom";

class SellerProductsFormMain extends Component {
  state = {
    statePage: "",
  };

  componentDidMount() {
    // this.props.sellerLocation.listSellerLocation();
    this.props.sellerProducts.listSellerLocation();
    const statePage = this.checkPageState(this.props.location.pathname);
    const { id } = this.props.match.params;
    this.setState({ statePage });
    if (statePage === "EDIT") {
      this.props.sellerProducts.listSellerCategoryActive(id, "EDIT");
    } else {
      this.props.sellerProducts.listSellerCategoryActive(id, "ADD");
      this.props.sellerProducts.clearForm();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // if page is edit-product
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const statePage = this.checkPageState(this.props.location.pathname);
      const { id } = this.props.match.params;
      this.setState({ statePage });
      if (statePage === "EDIT") {
        this.props.sellerProducts.listSellerCategoryActive(id, "EDIT");
      } else {
        this.props.sellerProducts.listSellerCategoryActive(id, "ADD");
        this.props.sellerProducts.clearForm();
      }
    }
  }

  checkPageState = (pathname) => {
    if (pathname.match(/(\/admin\/sellerproducts\/edit-product)/g)) {
      return "EDIT";
    } else if (pathname.match(/(\/admin\/sellerproducts\/add-product)/g)) {
      return "ADD";
    }
  };

  render() {
    return (
      <div>
        <SellerProductDetailFormModal
          //// STATE
          nameDetail={this.props.sellerProducts.nameDetail}
          price={this.props.sellerProducts.price}
          hpp={this.props.sellerProducts.hpp}
          qty={this.props.sellerProducts.qty}
          weight={this.props.sellerProducts.weight}
          sellerLocationList={this.props.sellerProducts.listSellerLocationsArr}
          groupProductId={this.props.match.params.id}
          // sellerLocationId={this.props.sellerProducts.changeHandler}
          previewDetailProductPhoto={this.props.sellerProducts.previewDetailProductPhoto}
          //// METHOD
          storeSellerProduct={this.props.sellerProducts.storeSellerProduct}
          updateSellerDetailProduct={this.props.sellerProducts.updateSellerDetailProduct}
          storeSellerDetailProduct={
            this.props.sellerProducts.storeSellerDetailProduct
          }
          selectDetailProductPhoto={
            this.props.sellerProducts.selectDetailProductPhoto
          }
          selectSellerLocation={this.props.sellerProducts.selectSellerLocation}
          changeHandler={this.props.sellerProducts.changeHandler}
          modalState={this.props.sellerProducts.modalState}
          toggleHandler={this.props.sellerProducts.modalHandler}
          actionType={this.props.sellerProducts.actionType}
        />
        <SellerProductsFormView
          //// STATE
          name={this.props.sellerProducts.name}
          description={this.props.sellerProducts.description}
          previewProductPhoto={this.props.sellerProducts.previewProductPhoto}
          categoryActiveList={this.props.sellerProducts.listCategoryActive}
          statePage={this.state.statePage}
          data={this.props.sellerProducts.dataTableDetail}
          groupProductId={this.props.match.params.id}
          //// METHOD
          resetForm={this.props.sellerProducts.resetForm}
          addModal={this.props.sellerProducts.modalHandler}
          storeSellerProduct={this.props.sellerProducts.storeSellerProduct}
          updateSellerProduct={this.props.sellerProducts.updateSellerProduct}
          selectProductPhoto={this.props.sellerProducts.selectProductPhoto}
          selectProductCategory={
            this.props.sellerProducts.selectProductCategory
          }
          changeHandler={this.props.sellerProducts.changeHandler}
        />
      </div>
    );
  }
}

export default withRouter(SellerProductsFormMain);
