import React, { Component } from "react";
import { MDBContainer, MDBModal, MDBModalHeader, MDBModalBody } from "mdbreact";

import style from "./ShipmentAddressSelector.module.css";
import { UserLocationList } from "../../../UserLocation/UserLocationList/UserLocationList";

export default class ShipmentAddressSelector extends Component {
  render() {
    const { showModal, toggleHandler } = this.props;
    return (
      <MDBContainer>
        <MDBModal
          className={`${style.modalShipmentAddress}`}
          isOpen={showModal}
          toggle={() => toggleHandler("CLOSE")}
        >
          <MDBModalHeader toggle={() => toggleHandler("CLOSE")}>
            Pilih Alamat Lain
          </MDBModalHeader>
          <br />
          <UserLocationList
            style={style}
            isFromShipment={true}
            addressList={this.props.addressList}
            selectedAddressId={this.props.selectedAddressId}
            setSelectedAddress={this.props.setSelectedAddress}
          />
        </MDBModal>
      </MDBContainer>
    );
  }
}
