import React from "react";
import { SearchBox } from "../../SearchBox/SearchBox";
import { RoundedBlackButton } from "../../_UtilityComponent/Button/RoundedButton";

export const WebSearchForm = (props) => {
  return (
    <form onSubmit={(event) => props.submitHandler(event)} autoComplete="off">
      <SearchBox
        placeholder="Search Website"
        inputName={"query"}
        onChange={props.changeHandler}
      />
    </form>
  );
};
