import React, { Component } from "react";
import API from "../../utils/API";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import SellerLocationContext from "./SellerLocationContext";
import { withRouter } from "react-router-dom";

var debounce = require("lodash.debounce");

class SellerLocationProvider extends Component {
  constructor() {
    super();
    this.state = {
      // state
      name: "",
      hp: "",
      alias: "",
      address: "",
      selectedAddress: "",
      selectedAddressDefault: "",
      //
      zipCode: "",
      sellerId: "",
      data: [],
      locationList: [],
      textLocation: "",
      modalOption: false,
      modalAddress: false,
      updateSellerLocationId: "",
      actionType: "",
      primaryAddressId: "",
      selectedItemId: "",
      isLoading: false,
      isError: false,
    };
    this.searchAPI = debounce(this.searchAPI.bind(this), 1000);
  }

  // untuk react-select async
  loadOptions = (inputValuePrm, callback) => {
    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };

    let inputValue = inputValuePrm ? `keyword=${inputValuePrm}` : "";
    let limit = "&limit=50";
    // let limit = limitPrm ? `&limit=${limitPrm}` : "";

    if (inputValuePrm.length >= 3) {
      API.get(`/prm/zipcode?${inputValue}${limit}`, config).then((res) => {
        // console.log(res);
        if (parseInt(res.data.code) !== 200) {
          alert(res.data.errors);
        } else {
          let zipCodeTemp = res.data.data.zipcode.map((zipCode) => ({
            value: zipCode.value, //id
            label: zipCode.label.toString(), //address
          }));
          callback(zipCodeTemp);
        }
      });
    }
  };

  addressSelectorHandler = () => {
    this.setState({
      modalAddress: !this.state.modalAddress,
      locationList: [],
      textLocation: "",
    });
  };

  searchLocation = (event) => {
    this.setState({ textLocation: event.target.value }, async () => {
      if (this.state.textLocation.length >= 3) {
        this.searchAPI(this.state.textLocation);
      }
    });
  };

  searchAPI = (inputValuePrm) => {
    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };
    let inputValue = inputValuePrm ? `keyword=${inputValuePrm}` : "";
    let limit = "&limit=50";
    API.get(`/prm/zipcode?${inputValue}${limit}`, config).then((res) => {
      let locationListTemp = res.data.data.zipcode.map((data) => {
        return {
          id: data.value,
          name: data.label,
        };
      });
      this.setState({ locationList: locationListTemp }, () =>
        console.log(this.state.locationList)
      );
    });
  };

  selectedLocation = (selectedAddress) => {
    this.setState({ selectedAddress }, () => this.addressSelectorHandler());
  };

  handleChangeSelect = (selectedAddress) => {
    this.setState({ selectedAddress }, () =>
      /* {} */
      console.log(`Option selected:`, this.state.selectedAddress)
    );
  };

  getZipLocation = (sellerId) => {
    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };

    API.get(`/prm/zipcode`, config)
      .then((res) => {
        if (parseInt(res.data.code) !== 200) {
          alert(res.data.errors);
        } else {
          let zipCodeTemp = res.data.data.zipcode.map((zipCode) => ({
            value: zipCode.value, //id
            label: zipCode.label.toString(), //address
          }));
          this.setState({ zipCode: zipCodeTemp, isLoading: false });
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  modalHandler = (actionType, itemId) => {
    if (actionType === "CLOSE") {
      this.setState({
        actionType,
        selectedItemId: "",
        modalOption: this.state.modalOption ? false : true,
      });
    } else if (actionType === "MODALOPTION") {
      this.setState({
        actionType,
        selectedItemId: itemId,
        modalOption: this.state.modalOption ? false : true,
      });
    }
  };

  checkGudangHandler = () => {
    this.setState({
      isCheckGudang: !this.state.isCheckGudang,
    });
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  selectZipCodeHandler = (event) => {
    // let tempSelected;
    // tempSelected = this.state.zipCode.find((item) => item.value === event[0]);
    if (event[0]) {
      this.setState({
        selectedZipCode: event[0],
      });
    }
  };

  listSellerLocation = (sellerId) => {
    this.setState({ isLoading: true });
    let token = Cookies.get("access_token");
    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
    API.get(`/seller_location/${sellerId}`, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.data) {
          const primaryAddressId = res.data.data.seller_location.find(
            (address) => parseInt(address.is_primary) === 1
          );
          let listDataTemp = res.data.data.seller_location.map((list) => ({
            id: list.id,
            seller_id: list.seller_id,
            alias: list.alias,
            name: list.name,
            hp: list.hp,
            address: list.address,
            zip_id: {
              label:
                list.zip.province +
                ", " +
                list.zip.sub_province +
                ", " +
                list.zip.district +
                ", " +
                list.zip.sub_district +
                ", " +
                list.zip.zip,
              value: list.zip.id,
            },
            is_primary: list.is_primary,
            is_check_gudang: list.is_check_gudang,
            created_at: list.created_at,
            updated_at: list.updated_at,
            // listForSellerDetailProduct
            text: list.alias,
            value: list.id,
          }));
          this.setState({
            data: listDataTemp,
            isLoading: false,
            primaryAddressId: primaryAddressId.id,
          });
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  storeSellerLocation = (e, sellerId) => {
    e.preventDefault();
    console.log(this.props.history);
    const { name, hp, alias, address } = this.state;
    const addressId = this.state.selectedAddress.id;
    this.setState({ isLoading: true });

    Swal.fire({
      text: "Menambahkan...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    const data = new FormData();
    data.append("name", name);
    data.append("hp", hp);
    data.append("alias", alias);
    data.append("zip_id", addressId);
    data.append("address", address);

    //
    // data.append("is_check_gudang", "");

    if (
      name !== "" &&
      hp !== "" &&
      alias !== "" &&
      addressId !== "" &&
      address !== ""
    ) {
      console.table({ name, hp, alias, addressId, address });
      API.post(`/seller_location/${sellerId}`, data, config)
        .then((res) => {
          this.setState({ isLoading: false });
          if (parseInt(res.data.code) !== 200) {
            console.log(res);
          } else {
            this.setState(
              {
                name: "",
                hp: "",
                alias: "",
                selectedAddress: "",
                address: "",
              },
              () => {
                this.listSellerLocation(sellerId);
                this.props.history.push(
                  `/seller_dashboard/manage_location/${sellerId}`
                );
              }
            );
            Swal.fire({
              icon: "success",
              text: "Berhasil menambahkan alamat",
              onBeforeOpen: () => {
                Swal.hideLoading();
              },
            });
          }
        })
        .catch((error) => {
          Swal.disableLoading();
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            if (error.response) {
              let errmessage = "";
              let keys = Object.keys(error.response.data.errors);
              for (let i = 0; i < keys.length; i++) {
                errmessage +=
                  keys[i] +
                  " " +
                  error.response.data.errors[keys[i]][0] +
                  "<br/>";
              }
              Swal.fire({
                icon: "error",
                html: errmessage,
              });
            }
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Sorry...",
        text: "Tidak boleh ada yang kosong!",
      });
    }
  };

  updateSellerLocation = (sellerLocationId, sellerId) => {
    this.setState({
      isLoading: true,
    });

    //Swal loading
    Swal.fire({
      title: "Mengubah...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    const data = {
      name: this.state.name,
      hp: this.state.hp,
      alias: this.state.alias,
      zip_id: this.state.selectedAddress.value.toString(),
      address: this.state.address,
      is_check_gudang: this.state.isCheckGudang,
    };

    // const sellerId = Cookies.get("qqq");
    // console.log(this.state.sellerId);

    // const data = new FormData();
    // data.append("name", this.state.name);
    // data.append("hp", this.state.hp);
    // data.append("alias", this.state.alias);
    // data.append("zip_id", this.state.zipId);
    // data.append("address", this.state.address);
    // data.append("is_check_gudang", this.state.isCheckGudang ? 1 : 0);

    API.put(`/seller_location/${sellerId}/${sellerLocationId}`, data, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (parseInt(res.data.code) !== 200) {
        } else {
          this.setState(
            {
              name: "",
              hp: "",
              alias: "",
              zipId: "",
              address: "",
              isCheckGudang: false,
              modalState: false,
            },
            () => this.listSellerLocation(sellerId)
          );
          Swal.fire({
            icon: "success",
            text: "Alamat telah berhasil diupdate",
            onBeforeOpen: () => {
              Swal.hideLoading();
            },
          });
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  deleteSellerLocation = (sellerId, addressId) => {
    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    Swal.fire({
      title: "Anda yakin akan menghapus alamat ini?",
      icon: "warning",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.value) {
        return API.delete(`/seller_location/${sellerId}/${addressId}`, config)
          .then((res) => {
            if (parseInt(res.data.code) !== 200) {
              console.log(res.data);
            } else {
              Swal.fire({
                title: "Berhasil",
                text: "Alamat telah berhasil dihapus",
                type: "success",
                showConfirmButton: true,
                confirmButtonText: "Tutup",
              }).then(() => this.listSellerLocation(sellerId));
            }
          })
          .catch((error) => {
            Swal.disableLoading();
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              if (error.response) {
                let errmessage = "";
                let keys = Object.keys(error.response.data.errors);
                for (let i = 0; i < keys.length; i++) {
                  errmessage +=
                    keys[i] +
                    " " +
                    error.response.data.errors[keys[i]][0] +
                    "<br/>";
                }
                Swal.fire({
                  icon: "error",
                  html: errmessage,
                });
              }
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      } else {
        Swal.close();
      }
    });
  };

  bePrimarySellerLocation = (sellerId, addressId) => {
    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    const data = {};

    Swal.fire({
      title: "Anda yakin jadikan alamat ini sebagai alamat utama?",
      icon: "warning",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.value) {
        return API.put(
          `/seller_location/set_primary/${sellerId}/${addressId}`,
          data,
          config
        )
          .then((res) => {
            Swal.fire({
              title: "Berhasil",
              text: "Alamat utama anda telah dirubah",
              type: "success",
              showConfirmButton: true,
              confirmButtonText: "Tutup",
            }).then(() => this.listSellerLocation(sellerId));
          })
          .catch((error) => {
            Swal.disableLoading();
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              if (error.response) {
                let errmessage = "";
                let keys = Object.keys(error.response.data.errors);
                for (let i = 0; i < keys.length; i++) {
                  errmessage +=
                    keys[i] +
                    " " +
                    error.response.data.errors[keys[i]][0] +
                    "<br/>";
                }
                Swal.fire({
                  icon: "error",
                  html: errmessage,
                });
              }
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      } else {
        Swal.close();
      }
    });
  };

  render() {
    return (
      <SellerLocationContext.Provider
        value={{
          ...this.state,
          loadOptions: this.loadOptions,
          handleChangeSelect: this.handleChangeSelect,
          changeHandler: this.changeHandler,
          checkGudangHandler: this.checkGudangHandler,
          modalHandler: this.modalHandler,
          addressSelectorHandler: this.addressSelectorHandler,
          searchLocation: this.searchLocation,
          selectedLocation: this.selectedLocation,
          getZipLocation: this.getZipLocation,
          listSellerLocation: this.listSellerLocation,
          storeSellerLocation: this.storeSellerLocation,
          updateSellerLocation: this.updateSellerLocation,
          bePrimarySellerLocation: this.bePrimarySellerLocation,
        }}
      >
        {this.props.children}
      </SellerLocationContext.Provider>
    );
  }
}

export default withRouter(SellerLocationProvider);
