import React, { useState } from "react";
import { SlideContainer } from "./SliderCarousel.styles";
import { Slides } from "./Slides";

const SlideCarousel = () => {
  const [slidesState, setSlidesState] = useState([
    {
      id: 1,
      text: "Welcome",
      displayState: "block",
    },
    {
      id: 2,
      text: "Let's build your own online business",
      displayState: "none",
    },
  ]);
  const [currSlideId, setCurrSlideId] = useState(1);

  const changeSlide = (n) => {
    let tempSlideId = currSlideId;
    tempSlideId = tempSlideId + n;
    setCurrSlideId(tempSlideId);
    showSlides(tempSlideId);
  };

  const showSlides = (n) => {
    let copySlideState = [...slidesState];
    // set all slide to none
    for (let i = 0; i < slidesState.length; i++) {
      copySlideState[i].displayState = "none";
    }
    // set current index
    if (n > slidesState.length) {
      setCurrSlideId(1);
      copySlideState[0].displayState = "block";
    } else if (n < 1) {
      setCurrSlideId(slidesState.length);
      copySlideState[slidesState.length - 1].displayState = "block";
    } else {
      copySlideState[n - 1].displayState = "block";
    }
    setSlidesState(copySlideState);
  };

  return (
    <SlideContainer>
      <Slides slidesState={slidesState} changeSlide={changeSlide} />
    </SlideContainer>
  );
};

export default SlideCarousel;
