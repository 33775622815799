import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  BottomNavigationContainer,
  BottomNavigationDiv,
  BottomNavigationIconDiv,
  BottomNavigationText,
  BottomNavigationIcon,
} from "./BottomNavigation.styles";

const BottomNavigation = (props) => {
  let location = useLocation();
  const bottomNavHeightRef = useRef(null);
  const [displayNav, setDisplayNav] = useState(true);
  const [itemNav, setItemNav] = useState([
    {
      index: 1,
      icon: <BottomNavigationIcon icon="home" />,
      text: "Home",
      link: "/",
    },
    {
      index: 2,
      icon: <BottomNavigationIcon icon="shopping-cart" />,
      text: "Cart",
      link: "/cart",
    },
    {
      index: 3,
      icon: <BottomNavigationIcon icon="user" />,
      text: "Profile",
      link: "/profile",
    },
  ]);

  useEffect(() => {
    if (displayNav === true) {
      let bottomNavHeightRefVar = bottomNavHeightRef.current.offsetHeight;
      props.checkBottomNavHeight(bottomNavHeightRefVar);
    } else {
      props.checkBottomNavHeight(0);
    }
  }, [bottomNavHeightRef]);

  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname === "/cart" ||
      location.pathname === "/profile" ||
      location.pathname.match(/\/category\//g)
    ) {
      setDisplayNav(true);
    } else {
      setDisplayNav(false);
    }
  }, [location.pathname]);

  if (displayNav) {
    return (
      <BottomNavigationContainer ref={bottomNavHeightRef}>
        <BottomNavigationItems itemNav={itemNav} />
      </BottomNavigationContainer>
    );
  } else {
    return <div></div>;
  }
};

const BottomNavigationItems = (props) => {
  return props.itemNav.map((item, index) => {
    return (
      <BottomNavigationDiv exact key={index} to={item.link}>
        <BottomNavigationIconDiv>{item.icon}</BottomNavigationIconDiv>
        <BottomNavigationText>{item.text}</BottomNavigationText>
      </BottomNavigationDiv>
    );
  });
};

export default BottomNavigation;
