import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React from "react";
import { Link } from "react-router-dom";
import ProductTabCategory from "../ProductHomeConsumer/ProductContainer/ProductTabCategory/ProductTabCategory.js";
import * as S from "../_Styled/CardPayment.styles.js";
import { CenterLoader } from "../_UtilityComponent/Loader/CenterLoader.js";
import { UserTransactionStatusEmpty } from "./UserTransactionStatusEmpty";
import { UserTransactionStatusItem } from "./UserTransactionStatusItem";
import {
  StyledContainerTabs,
  StyledRowTabs,
  StyledTab,
  StyledTabCard,
} from "./UserTransactionView.styles.js";

const UserTransactionStatusView = (props) => {
  return (
    <S.ContainerPayment>
      <UserTransactionStatusTitle />
      <UserTransactionTabs
        page={props.page}
        pageProcessList={props.pageProcessList}
      />
      <UserTransactionStatusItems
        selectedProcess={props.selectedProcess}
        trPendingList={props.trPendingList}
        trInvoiceList={props.trInvoiceList}
        updateInvoiceStatus={props.updateInvoiceStatus}
        getTrackingStatus={props.getTrackingStatus}
      />
    </S.ContainerPayment>
  );
};

export default UserTransactionStatusView;

const UserTransactionStatusTitle = () => {
  return (
    <>
      <MDBRow>
        <MDBCol className="text-center mt-3">
          <h4>Transaction Status</h4>
        </MDBCol>
      </MDBRow>
    </>
  );
};

const UserTransactionTabs = (props) => {
  return (
    <div>
      <StyledContainerTabs>
        <StyledRowTabs>
          {props.pageProcessList.map((item) => {
            if (!props.page && item.id === 1) {
              return (
                <UserTransactionTab
                  key={item.id}
                  activeTab={true}
                  item={item}
                />
              );
            } else {
              return (
                <UserTransactionTab
                  key={item.id}
                  activeTab={props.page === item.page ? true : false}
                  item={item}
                  page={props.page}
                />
              );
            }
          })}
        </StyledRowTabs>
      </StyledContainerTabs>
    </div>
  );
};

const UserTransactionTab = (props) => {
  if (props.page) {
    return (
      <StyledTab>
        <Link to={props.activeTab ? `#` : `${props.item.page}`}>
          <StyledTabCard $activeTab={props.activeTab}>
            {props.item.name}
          </StyledTabCard>
        </Link>
      </StyledTab>
    );
  } else {
    return (
      <StyledTab>
        <Link
          to={props.activeTab ? `#` : `transaction-status/${props.item.page}`}
        >
          <StyledTabCard $activeTab={props.activeTab}>
            {props.item.name}
          </StyledTabCard>
        </Link>
      </StyledTab>
    );
  }
};

const UserTransactionStatusItems = (props) => {
  switch (props.selectedProcess) {
    case 1:
      return (
        <>
          {props.trPendingList.length > 0 &&
            props.trPendingList.map((item, idx) => {
              return (
                <UserTransactionStatusItem
                  key={idx}
                  item={item}
                  selectedProcess={props.selectedProcess}
                />
              );
            })}
        </>
      );
    case 2:
    case 3:
    case 4:
      return (
        <>
          {props.trInvoiceList &&
            props.trInvoiceList.map((item, idx) => {
              return (
                <UserTransactionStatusItem
                  key={idx}
                  item={item}
                  selectedProcess={props.selectedProcess}
                  updateInvoiceStatus={props.updateInvoiceStatus}
                  getTrackingStatus={props.getTrackingStatus}
                />
              );
            })}
        </>
      );
  }
};
