import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBRow,
  MDBSelect,
} from "mdbreact";
import {
  ManageSellerAddProductsSubTitle,
  ManageSellerProductsPhotoContainer,
  ManageSellerProductsPhotos,
  ManageSellerAddProductsSubSection,
} from "./ManageSellerProducts.styles";
import MDBFileUpload from "mdb-react-fileupload";
import React from "react";
import { ManageSellerProductsVariantLists } from "./ManageSellerProductsVariantLists";

export const ManageSellerProductsEdit = (props) => {
  // console.log(props.previewProductPhoto)
  return (
    <MDBContainer>
      <ManageSellerAddProductsSubTitle>
        Foto Produk
      </ManageSellerAddProductsSubTitle>
      {props.previewProductPhoto && props.renderProductPhoto ? (
        <ManageSellerProductsPhotoContainer>
          <ManageSellerProductsPhotos>
            <MDBFileUpload
              showRemove={false}
              containerHeight={150}
              messageDefault={"Gambar Utama"}
              getValue={(e) => props.selectProductPhoto(e, 0)}
              defaultFileSrc={
                props.previewProductPhoto.hasOwnProperty(0)
                  ? props.previewProductPhoto[0].link
                  : ""
              }
            />
          </ManageSellerProductsPhotos>
          <ManageSellerProductsPhotos>
            {props.previewProductPhoto.hasOwnProperty(1) ? (
              <MDBFileUpload
                showRemove={false}
                containerHeight={150}
                messageDefault={"Gambar Tambahan"}
                getValue={(e) => props.selectProductPhoto(e, 1)}
                defaultFileSrc={props.previewProductPhoto[1].link}
              />
            ) : (
              <MDBFileUpload
                showRemove={false}
                containerHeight={150}
                messageDefault={"Gambar Tambahan"}
                getValue={(e) => props.selectProductPhoto(e, 1)}
              />
            )}
          </ManageSellerProductsPhotos>
          <ManageSellerProductsPhotos>
            {props.previewProductPhoto.hasOwnProperty(2) ? (
              <MDBFileUpload
                showRemove={false}
                containerHeight={150}
                messageDefault={"Gambar Tambahan"}
                getValue={(e) => props.selectProductPhoto(e, 2)}
                defaultFileSrc={props.previewProductPhoto[2].link}
              />
            ) : (
              <MDBFileUpload
                showRemove={false}
                containerHeight={150}
                messageDefault={"Gambar Tambahan"}
                getValue={(e) => props.selectProductPhoto(e, 2)}
              />
            )}
          </ManageSellerProductsPhotos>
          <ManageSellerProductsPhotos>
            {props.previewProductPhoto.hasOwnProperty(3) ? (
              <MDBFileUpload
                showRemove={false}
                containerHeight={150}
                messageDefault={"Gambar Tambahan"}
                getValue={(e) => props.selectProductPhoto(e, 3)}
                defaultFileSrc={props.previewProductPhoto[3].link}
              />
            ) : (
              <MDBFileUpload
                showRemove={false}
                containerHeight={150}
                messageDefault={"Gambar Tambahan"}
                getValue={(e) => props.selectProductPhoto(e, 3)}
              />
            )}
          </ManageSellerProductsPhotos>
          <ManageSellerProductsPhotos>
            {props.previewProductPhoto.hasOwnProperty(4) ? (
              <MDBFileUpload
                showRemove={false}
                containerHeight={150}
                messageDefault={"Gambar Tambahan"}
                getValue={(e) => props.selectProductPhoto(e, 4)}
                defaultFileSrc={props.previewProductPhoto[4].link}
              />
            ) : (
              <MDBFileUpload
                showRemove={false}
                containerHeight={150}
                messageDefault={"Gambar Tambahan"}
                getValue={(e) => props.selectProductPhoto(e, 4)}
              />
            )}
          </ManageSellerProductsPhotos>
        </ManageSellerProductsPhotoContainer>
      ) : (
        ""
      )}
      <ManageSellerAddProductsSubTitle>
        Keterangan Produk
      </ManageSellerAddProductsSubTitle>
      <MDBInput
        value={props.name}
        name="name"
        onChange={props.changeHandler}
        type="text"
        className="form-control"
        label="Nama Produk"
        required
      >
        <div className="invalid-feedback">Nama Produk</div>
      </MDBInput>
      <MDBSelect
        search
        options={props.categoryActiveList}
        selected="Pilih kategori produk"
        color="primary"
        label="Kategori"
        getValue={props.selectProductCategory}
      />
      <MDBInput
        value={props.description}
        onChange={props.changeHandler}
        type="textarea"
        className="form-control"
        name="description"
        label="Deskripsi"
        required
      />
      <ManageSellerAddProductsSubSection>
        <div style={{ fontWeight: "600" }}>Varian</div>
        <div
          // icon="plus"
          style={{
            color: "purple" /* , fontSize: "1.3rem"  */,
            fontWeight: "500",
          }}
          onClick={() => {
            props.history.push(`${props.history.location.pathname}/add`);
          }}
        >
          {" "}
          Tambah{" "}
        </div>
      </ManageSellerAddProductsSubSection>
      <ManageSellerProductsVariantLists
        //STATE
        dataDetail={props.dataDetail}
        history={props.history}
        groupProductId={props.groupProductId}
        //METHOD
        beActiveDetailProduct={props.beActiveDetailProduct}
        sellerId={props.sellerId}
      />
      <MDBContainer style={{ paddingTop: "5%", paddingBottom: "10%" }}>
        <MDBBtn
          className="w-100 mr-0 ml-0 mt-2"
          color="deep-orange"
          type="submit"
          onClick={() =>
            props.updateSellerProduct(props.groupProductId, props.sellerId)
          }
        >
          Simpan
        </MDBBtn>
      </MDBContainer>
    </MDBContainer>
  );
};
