import { MDBCol, MDBRow } from "mdbreact";
import React from "react";
import * as S from "../_Styled/CardPayment.styles.js";
import { CenterLoader } from "../_UtilityComponent/Loader/CenterLoader.js";
import { UserPaymentStatusEmpty } from "./UserPaymentStatusEmpty.js";
import { UserPaymentStatusItem } from "./UserPaymentStatusItem.js";

const UserPaymentStatusView = (props) => {
  return (
    <S.ContainerPayment>
      <UserPaymentStatusTitle />
      <UserPaymentStatusItems
        paymentStatus={props.paymentStatus}
        isLoading={props.isLoading}
      />
    </S.ContainerPayment>
  );
};

export default UserPaymentStatusView;

const UserPaymentStatusTitle = () => {
  return (
    <>
      <MDBRow>
        <MDBCol className="text-center mt-3">
          <h4>Payment Status</h4>
        </MDBCol>
      </MDBRow>
    </>
  );
};

const UserPaymentStatusItems = (props) => {
  if (!props.isLoading && props.paymentStatus.length > 0) {
    return (
      <div>
        {props.paymentStatus.map((item, idx) => {
          return <UserPaymentStatusItem key={idx} item={item} />;
        })}
      </div>
    );
  } else if (!props.isLoading && props.paymentStatus.length <= 0) {
    return <UserPaymentStatusEmpty />;
  } else {
    return <CenterLoader />;
  }
};
