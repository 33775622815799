import React, { Component } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBCard,
  MDBFileInput,
  MDBSelect,
  MDBBtn,
  MDBSpinner,
  MDBSelectOption,
  MDBSelectOptions,
  MDBSelectInput,
  MDBIcon,
  MDBInputGroup,
} from "mdbreact";

import styles from "./RegisterView.module.css";
import { Text } from "../../context/Language";
import SectionContainer from "../sectionContainer";
import { CMDBBtn } from "../StyledComponent";

export class RegisterView extends Component {
  state = {
    hidepass: true,
    hidepass_conf: true,
  };

  handelHidePass = (name, state) => {
    this.setState({ [state]: !this.state[state] }, () => {
      var pass = document.getElementById(name);
      pass.setAttribute("type", this.state[state] ? "password" : "text");
    });
  };

  render() {
    return (
      // <div style={{ backgroundColor: "#804199" }}>
      <form
        className="needs-validation"
        onSubmit={this.props.submitHandler}
        noValidate
      >
        <MDBContainer style={{ paddingTop: "6rem" }}>
          <SectionContainer header={"REGISTER"}>
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                    {<Text pid="registerPage" tid="setupUserTitle" />}
                  </span>
                  <hr />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol lg="4" sm="12">
                  <MDBInput
                    value={this.props.email}
                    onChange={this.props.changeHandler}
                    type="email"
                    className="form-control"
                    name="email"
                    label={<Text pid="registerPage" tid="emailInput" />}
                    required
                  />
                </MDBCol>
                <MDBCol lg="4" sm="6">
                  <MDBInput
                    value={this.props.name}
                    onChange={this.props.changeHandler}
                    type="text"
                    className="form-control"
                    name="name"
                    label={<Text pid="registerPage" tid="nameInput" />}
                    required
                  />
                </MDBCol>
                <MDBCol lg="4" sm="6">
                  <MDBInput
                    value={this.props.no_tlp}
                    onChange={this.props.changeHandler}
                    type="text"
                    className="form-control"
                    name="no_tlp"
                    label={<Text pid="registerPage" tid="noTlp" />}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol lg="6" sm="12">
                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        value={this.props.password}
                        onChange={this.props.changeHandler}
                        type="password"
                        className="form-control"
                        name="password"
                        id="password"
                        label={<Text pid="registerPage" tid="passInput" />}
                        required
                      />
                    </MDBCol>
                    <MDBCol middle size="1">
                      <MDBRow center>
                        <MDBIcon
                          icon={this.state.hidepass ? "eye" : "eye-slash"}
                          onClick={() =>
                            this.handelHidePass("password", "hidepass")
                          }
                          style={{ height: "24px", width: "24px" }}
                        />
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  <small>
                    {<Text pid="registerPage" tid="passInputHint" />}
                  </small>
                </MDBCol>
                <MDBCol lg="6" sm="12">
                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        value={this.props.passwordConfirmation}
                        onChange={this.props.changeHandler}
                        type="password"
                        className="form-control"
                        name="passwordConfirmation"
                        id="passwordConfirmation"
                        label={
                          <Text pid="registerPage" tid="passConfirmInput" />
                        }
                        required
                      />
                    </MDBCol>
                    <MDBCol middle size="1">
                      <MDBRow center>
                        <MDBIcon
                          icon={this.state.hidepass_conf ? "eye" : "eye-slash"}
                          onClick={() =>
                            this.handelHidePass(
                              "passwordConfirmation",
                              "hidepass_conf"
                            )
                          }
                          style={{ height: "24px", width: "24px" }}
                        />
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              {this.props.isStore ? (
                ""
              ) : (
                <MDBRow>
                  <MDBCol className={styles.checkCustomLogo}>
                    <MDBInput
                      label={<Text pid="registerPage" tid="isSellerRegister" />}
                      onChange={() => this.props.checkIsSeller()}
                      type="checkbox"
                      id="isSellerCheck"
                      checked={this.props.isSeller}
                      filled
                    />
                  </MDBCol>
                </MDBRow>
              )}
            </MDBContainer>
            {this.props.isSeller ? (
              <MDBContainer style={{ paddingTop: "1rem" }}>
                <MDBRow>
                  <MDBCol>
                    <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                      {<Text pid="registerPage" tid="setupStoreTitle" />}
                    </span>
                    <hr />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      value={this.props.nameWeb}
                      onChange={this.props.changeHandler}
                      type="text"
                      className="form-control"
                      name="nameWeb"
                      label={<Text pid="registerPage" tid="storeNameInput" />}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol size="6">
                    <MDBInput
                      value={this.props.subDomain}
                      onChange={this.props.changeHandler}
                      onBlur={() =>
                        this.props.checkDomainAvailability(
                          this.props.subDomain,
                          "sub"
                        )
                      }
                      type="text"
                      className="form-control"
                      name="subDomain"
                      label={<Text pid="registerPage" tid="subDomainInput" />}
                      required
                    />
                  </MDBCol>
                  <MDBCol className="md-form form-control border-0" size="2">
                    .1toko.com
                  </MDBCol>
                  <MDBCol size="4" middle>
                    {this.props.renderLoaderSub ? (
                      <>
                        <MDBSpinner />
                        <Text pid="registerPage" tid="checkingDomain" />
                      </>
                    ) : (
                      ""
                    )}
                    {!this.props.renderLoaderSub &&
                    this.props.isAvailableSubDomain &&
                    this.props.isSubDomainChecked ? (
                      <div style={{ color: "green" }}>
                        <Text pid="registerPage" tid="availableDomain" />
                      </div>
                    ) : (
                      ""
                    )}
                    {!this.props.renderLoaderSub &&
                    !this.props.isAvailableSubDomain &&
                    this.props.isSubDomainChecked ? (
                      <div style={{ color: "red" }}>
                        <Text pid="registerPage" tid="unavailableDomain" />
                      </div>
                    ) : (
                      ""
                    )}
                  </MDBCol>
                </MDBRow>
                {/* <MDBRow>
                  <MDBCol lg="6" sm="8">
                    <MDBInput
                      value={this.props.domain}
                      onChange={this.props.changeHandler}
                      onBlur={() =>
                        this.props.checkDomainAvailability(
                          this.props.domain,
                          "domain"
                        )
                      }
                      type="text"
                      className="form-control"
                      name="domain"
                      label={<Text pid="registerPage" tid="domainInput" />}
                    />
                  </MDBCol>
                  <MDBCol size="4" middle>
                    {this.props.renderLoaderDomain ? (
                      <>
                        <MDBSpinner />
                        <Text pid="registerPage" tid="checkingDomain" />
                      </>
                    ) : (
                      ""
                    )}
                    {!this.props.renderLoaderDomain &&
                    this.props.isAvailableDomain &&
                    this.props.isDomainChecked ? (
                      <div style={{ color: "green" }}>
                        <Text pid="registerPage" tid="availableDomain" />
                      </div>
                    ) : (
                      ""
                    )}
                    {!this.props.renderLoaderDomain &&
                    !this.props.isAvailableDomain &&
                    this.props.isDomainChecked ? (
                      <div style={{ color: "red" }}>
                        <Text pid="registerPage" tid="unavailableDomain" />
                      </div>
                    ) : (
                      ""
                    )}
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <small>
                      {<Text pid="registerPage" tid="domainInputHint" />}
                    </small>
                  </MDBCol>
                </MDBRow> */}
                {this.props.color_list !== undefined ? (
                  // console.log(this.props.color_list[0])
                  <>
                    <MDBContainer
                      style={{ marginTop: "1rem", marginBottom: "1rem" }}
                    >
                      <MDBRow>
                        <span>{`Template Color List :`}</span>
                      </MDBRow>
                      <MDBRow>
                        {this.props.color_list.map((data, index) => {
                          return (
                            <MDBIcon
                              key={index}
                              icon="square"
                              size="2x"
                              style={{ color: data.id }}
                              onClick={() =>
                                this.props.handelChangeColor(
                                  data.id,
                                  data.value
                                )
                              }
                            />
                          );
                        })}
                      </MDBRow>
                      {this.props.color_primary_name !== "" ? (
                        <>
                          <MDBRow>
                            <span>{`Selected Color : `}</span>
                          </MDBRow>
                          <MDBRow>
                            <span
                              style={{
                                color: "white",
                                backgroundColor: this.props.color_primary,
                                padding: "10px",
                                width: "200px",
                                textAlign: "center",
                              }}
                            >
                              {this.props.color_primary_name}
                            </span>
                          </MDBRow>
                        </>
                      ) : (
                        ""
                      )}
                    </MDBContainer>
                    {/* logo and banner */}
                    <MDBRow>
                      <MDBCol lg="6" sm="12">
                        <div>
                          <Text pid="registerPage" tid="isCustomLogoCheck" />
                        </div>
                        <div
                          hidden={this.props.previewCustomLogoToko === ""}
                          style={{ position: "relative" }}
                        >
                          <MDBIcon
                            icon="times"
                            size="2x"
                            onClick={() =>
                              this.props.deleteImageHandler("Logo")
                            }
                            style={{
                              position: "absolute",
                              zIndex: "30",
                              right: "0",
                              color: "red",
                              cursor: "pointer",
                              backgroundColor: "white",
                            }}
                          />
                          <img
                            className=" img-fluid img-thumbnail z-depth-1 mx-auto d-block"
                            src={this.props.previewCustomLogoToko}
                            style={{ maxHeight: "200px" }}
                          />
                        </div>
                        <MDBFileInput
                          textFieldTitle="Logo"
                          getValue={this.props.selectLogoHandler}
                        />
                        <p>{`*max logo file size is 100Kb`}</p>
                      </MDBCol>
                      <MDBCol lg="6" sm="12">
                        <div>
                          <Text pid="registerPage" tid="isCustomBannerCheck" />
                        </div>
                        <div
                          hidden={this.props.previewCustomBannerToko === ""}
                          style={{ position: "relative" }}
                        >
                          <MDBIcon
                            icon="times"
                            size="2x"
                            onClick={() =>
                              this.props.deleteImageHandler("Banner")
                            }
                            style={{
                              position: "absolute",
                              zIndex: "30",
                              right: "0",
                              color: "red",
                              cursor: "pointer",
                              backgroundColor: "white",
                            }}
                          />
                          <img
                            className=" img-fluid img-thumbnail z-depth-1 mx-auto d-block"
                            src={this.props.previewCustomBannerToko}
                            style={{ maxHeight: "200px" }}
                          />
                        </div>
                        <MDBFileInput
                          textFieldTitle="Banner"
                          getValue={this.props.selectBannerHandler}
                        />
                        <p>{`*max banner file size is 100Kb`}</p>
                      </MDBCol>
                    </MDBRow>
                  </>
                ) : (
                  ""
                )}
                <MDBRow>
                  <MDBCol lg="4" sm="12">
                    <MDBRow>
                      <MDBCol
                        middle
                        size="1"
                        className="md-form form-control border-0"
                        style={{ marginRight: "5px" }}
                      >
                        <MDBIcon
                          fab
                          icon="facebook-square"
                          style={{ color: "#3b5998" }}
                          size="2x"
                        />
                      </MDBCol>
                      <MDBCol middle>
                        <MDBInput
                          value={this.props.facebook}
                          onChange={this.props.changeHandler}
                          type="text"
                          className="form-control"
                          name="facebook"
                          label="Facebook"
                        />
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                  <MDBCol lg="4" sm="12" middle>
                    <MDBRow>
                      <MDBCol
                        middle
                        size="1"
                        className="md-form form-control border-0"
                        style={{ marginRight: "5px" }}
                      >
                        <MDBIcon
                          fab
                          icon="twitter-square"
                          style={{ color: "#00acee" }}
                          size="2x"
                        />
                      </MDBCol>
                      <MDBCol middle>
                        <MDBInput
                          value={this.props.twitter}
                          onChange={this.props.changeHandler}
                          type="text"
                          className="form-control"
                          name="twitter"
                          label="Twitter"
                        />
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                  <MDBCol lg="4" sm="12">
                    <MDBRow>
                      <MDBCol
                        middle
                        size="1"
                        className="md-form form-control border-0"
                        style={{ marginRight: "5px" }}
                      >
                        <MDBIcon fab icon="instagram" size="2x" />
                      </MDBCol>
                      <MDBCol middle>
                        <MDBInput
                          value={this.props.instagram}
                          onChange={this.props.changeHandler}
                          type="text"
                          className="form-control"
                          name="instagram"
                          label="Instagram"
                        />
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            ) : (
              ""
            )}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CMDBBtn
                active
                type="submit"
                bg_color="#804199"
                style={{ borderRadius: "0.5rem", width: "300px" }}
              >
                <Text pid="registerPage" tid="submitRegister" />
              </CMDBBtn>
            </div>
          </SectionContainer>
        </MDBContainer>
      </form>
      // </div>
    );
  }
}

export default RegisterView;
