import { MDBContainer } from "mdbreact";
import { Link } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";
import { RoundedWhiteButton } from "../_UtilityComponent/Button/RoundedButton";
import ImageComponent from "../_UtilityComponent/Image/ImageComponent";

const DashboardItemContainer = styled.div`
  margin: 10% 0;
  padding-top: 10%;
  text-align: center;
`;

export const DashboardItemContainerGrid = styled(DashboardItemContainer)`
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  grid-gap: 10%;
  place-items: center;
`;

export const DashboardItemContainerList = styled(DashboardItemContainer)``;

export const DashboardItemCardStyled = styled(Link)`
  display: grid;
  background-color: white;
  border-radius: 10%;
  padding: 5%;
`;

export const DashboardItemCardThumbnail = styled(DashboardItemCardStyled)`
  grid-template-rows: 80% 1fr;
  place-items: center;
  height: 120px;
  width: 110px;
`;

export const DashboardItemCardList = styled(DashboardItemCardStyled)`
  grid-template-rows: repeat(auto-fill, 100%);
`;

export const DashboardItemTextStyled = styled.div`
  font-weight: 600;
  color: purple;
  font-size: 0.6rem;
`;

export const DashboardItemStoreImg = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

export const AddSellerStyleButton = styled.button`
  position: fixed;
  background-color: #a831b5;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 60px;
  height: 60px;
  bottom: 30px;
  right: 30px;

  @media only screen and (max-width: 300px) {
    width: 50px;
    height: 50px;
    bottom: 30px;
    right: 30px;
  }
`;
