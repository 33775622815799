import React, { Component } from "react";
import {
  MDBCard,
  MDBRow,
  MDBCardImage,
  MDBCardBody,
  MDBView,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBContainer,
} from "mdbreact";
import style from "./ProductDetail.module.css";

export default class ProductVarianPreview extends Component {
  state = {
    photo_number: 0,
  };

  container = null;

  handelChangePhoto = (number) => {
    // console.log("masuk");
    this.setState({ photo_number: number });
  };

  scrollLeft = () => {
    var container = document.getElementById("container");
    this.sideScroll(container, "left", 25, 100, 10);
  };

  scrollRight = () => {
    var container = document.getElementById("container");
    this.sideScroll(container, "right", 25, 100, 10);
  };

  sideScroll = (element, direction, speed, distance, step) => {
    let scrollAmount = 0;
    var slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  render() {
    const { photo, seller_style } = this.props;
    return (
      <>
        {photo !== undefined ? (
          <MDBCard style={{ height: "630px" }}>
            <MDBCardBody>
              <MDBCardImage
                className="img-fluid mx-auto d-block"
                src={photo[this.state.photo_number].link}
                style={{ maxHeight: "430px" }}
                hover
              />
              <hr />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  maxHeight: "100px",
                  position: "relative",
                }}
              >
                <div className={style.rowProduct} id="container">
                  {photo.map((data, index) => {
                    return (
                      <img
                        key={index}
                        className="img-fluid img-thumbnail"
                        src={data.link}
                        onClick={() => this.handelChangePhoto(index)}
                        style={{ maxWidth: "100px" }}
                      />
                    );
                  })}
                </div>
                <button
                  size="sm"
                  style={{
                    border:'1px solid black',
                    borderRadius: "50%",
                    backgroundColor: "white",
                    position: "absolute",
                    left: "0",
                  }}
                  onClick={() => this.scrollLeft()}
                >
                  <MDBIcon
                    icon="angle-double-left"
                    style={{ paddingTop: "5px" }}
                  />
                </button>
                <button
                  size="sm"
                  style={{
                    border:'1px solid black',
                    borderRadius: "50%",
                    backgroundColor: "white",
                    position: "absolute",
                    right: "0",
                  }}
                  onClick={() => this.scrollRight()}
                >
                  <MDBIcon
                    icon="angle-double-right"
                    style={{ paddingTop: "5px" }}
                  />
                </button>
              </div>
            </MDBCardBody>
          </MDBCard>
        ) : (
          ""
        )}
      </>
    );
  }
}
