import React, { Component } from "react";
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdown,
  MDBRow,
} from "mdbreact";
import { Text } from "../../context/Language";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import { Link, withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import logo from "../../../assets/lg_1toko.png";

class MainHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseID: "",
    };
  }

  toggleCollapse = (collapseID) => () =>
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));

  render() {
    const { dataSeller } = this.props.dataUser;
    const path = this.props.location.pathname.toString().substring(0,6);
    if (path !== "/print") {
      return (
        <>
          <MDBNavbar
            light
            color="grey lighten-5"
            expand="md"
            fixed="top"
            scrolling
          >
            <MDBContainer>
              <Link to="/">
                <MDBNavbarBrand>
                  <img
                    src={logo}
                    style={{ maxHeight: "3rem" }}
                    alt="satu-toko"
                  />
                  {/* 1toko */}
                </MDBNavbarBrand>
              </Link>
              <MDBNavbarToggler
                onClick={this.toggleCollapse("navbarCollapse")}
                style={{ color: 'black' }}
              />
              <MDBCollapse
                id="navbarCollapse"
                isOpen={this.state.collapseID}
                navbar
              >
                <MDBNavbarNav right>
                  {!Cookies.get("access_token") ? (
                    <>
                      <MDBNavItem>
                        <MDBNavLink to="/login">
                          <Text pid="landing" tid="loginBtn" />
                        </MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem>
                        <MDBNavLink to="/register">
                          <Text pid="landing" tid="registerBtn" />
                        </MDBNavLink>
                      </MDBNavItem>
                    </>
                  ) : (
                    <>
                      {dataSeller ? (
                        <MDBNavItem>
                          <MDBNavLink to="/seller_dashboard">
                            My Store
                          </MDBNavLink>
                        </MDBNavItem>
                      ) : (
                        ""
                      )}
                      <MDBNavItem>
                        <MDBDropdown>
                          <MDBDropdownToggle nav caret color="secondary">
                            <Text pid="userMenu_header" tid="helloText" />
                            {this.props.dataUser.dataUser
                              ? this.props.dataUser.dataUser.name
                              : ""}
                          </MDBDropdownToggle>
                          <MDBDropdownMenu color="secondary">
                            <MDBDropdownItem>
                              <MDBNavLink
                                className="black-text"
                                to="#"
                                onClick={this.props.logout}
                              >
                                <Text pid="userMenu_header" tid="logoutBtn" />
                              </MDBNavLink>
                            </MDBDropdownItem>
                          </MDBDropdownMenu>
                        </MDBDropdown>
                      </MDBNavItem>
                    </>
                  )}
                  <MDBNavItem>
                    <LanguageSelector />
                  </MDBNavItem>
                </MDBNavbarNav>
              </MDBCollapse>
            </MDBContainer>
          </MDBNavbar>
        </>
      );
    } else {
      return "";
    }
  }
}

export default withRouter(MainHeader);
