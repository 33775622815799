import React, { Component } from "react";
import API from "../../utils/API";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import Axios from "axios";
import { withRouter } from "react-router-dom";
import CartContext from "./CartContext";

var debounce = require("lodash.debounce");

class CartProvider extends Component {
  constructor() {
    super();
    this.state = {
      /////////////////////// CART
      cart: [],
      cartTotalItem: "",
      // cartDetail: [],
      checkAll: true,
      //state for address
      name: "",
      hp: "",
      alias: "",
      address: "",
      //state-address-selector
      selectedAddress: "",
      //data alamat
      addressList: [],
      zipCode: [],
      modal1: false,
      /////////////////////// SHIPMENT
      cartActive: [],
      cartActiveTotalItem: "",
      addressSelectorModal: false,
      selectedShipmentAddress: "",
      //
      courierList: [],
      courierSelectorModal: false,
      selectedCartForCourier: "",
      actionModalCourier: "CLOSE",
      //
      cartsCourierCalculated: false,
      sumAllTotal: "",
      //
      isEdit: false,
      isLoading: false,
      //
    };
    this.updateCart = debounce(this.updateCart.bind(this), 300);
    this.updateCheckGroup = debounce(this.updateCheckGroup.bind(this), 300);
    this.updateCheckAll = debounce(this.updateCheckAll.bind(this), 300);
  }

  listData = (sellerId, isFromShipment) => {
    this.setState({ isLoading: true });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    API.get(`/cart/${sellerId}`, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.data) {
          let cartLength = 0;
          let cartTotalLength = 0;
          const cart = res.data.data.cart.map((cartItem, cartIndex) => {
            cartLength = 0;
            const cartDetail = cartItem.dtl_cart.map((dtlItem, dtlIndex) => {
              cartLength = cartLength + parseInt(dtlItem.qty);
              cartTotalLength = cartTotalLength + parseInt(dtlItem.qty);
              return {
                id: dtlItem.id,
                cartId: dtlItem.cart_id,
                productId: dtlItem.product_id,
                groupProductId: dtlItem.product_group_id,
                qty: parseInt(dtlItem.qty),
                stock: parseInt(dtlItem.dtl_product.stock),
                note: dtlItem.note,
                detailName: dtlItem.dtl_product.name,
                productName: dtlItem.group_product.name,
                price: parseInt(dtlItem.dtl_product.price),
                totalPrice:
                  parseInt(dtlItem.qty) * parseInt(dtlItem.dtl_product.price),
                weight: parseInt(dtlItem.dtl_product.weight),
                linkPicture: dtlItem.group_product.thumbnail.link,
                isCheck: parseInt(dtlItem.checked) === 1 ? true : false,
                // productNcsCode: dtlItem.dtl_product.seller_location.zip.ncs_code,}
              };
            });
            return {
              id: cartItem.id,
              isCheck: true,
              sellerLocation: cartItem.seller_location,
              cartDetail: cartDetail,
              cartItem: cartLength,
            };
          });
          if (!isFromShipment) {
            this.setState(
              {
                cart: cart,
                cartTotalItem: cartTotalLength,
                isLoading: false,
              },
              () => {
                this.checkAllGroupState();
                this.checkAllState();
                this.accumulateCart();
              }
            );
          } else {
            const cartActiveTemp = cart
              .map((a) => {
                const cartDetailActive = a.cartDetail.filter(
                  (b) => b.isCheck === true
                );
                return { ...a, cartDetail: cartDetailActive };
              })
              .filter((c) => c.cartDetail.length > 0);

            let cartLength = 0;
            let cartTotalLength = 0;
            let totalWeight = 0;
            let totalPrice = 0;
            const cartActive = cartActiveTemp.map((a) => {
              cartLength = 0;
              totalWeight = 0;
              totalPrice = 0;
              a.cartDetail.map((b) => {
                cartLength += parseInt(b.qty);
                cartTotalLength += parseInt(b.qty);
                totalWeight += parseInt(b.weight) * parseInt(b.qty);
                totalPrice += parseInt(b.totalPrice);
                return { ...b };
              });
              //Adding variable for checking, if the cart has been accumulate with shipping courier
              return {
                ...a,
                listCourier: "",
                isRouteAvailable: false,
                isServiceSelected: false,
                //
                cartItem: cartLength,
                cartWeight: totalWeight,
                cartPrice: totalPrice,
                selectedService: "",
              };
            });
            this.setState(
              { cartActive, cartActiveTotalItem: cartTotalLength },
              () => {
                this.checkPriceCourier();
                this.accumulateCart(true);
              }
            );
          }
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  accumulateCart = (isFromShipment) => {
    let totalPriceCart;
    if (!isFromShipment) {
      if (this.state.cartTotalItem > 0) {
        totalPriceCart = this.state.cart.reduce((a, b) => {
          const price = b.cartDetail.reduce((c, d) => {
            if (d.isCheck === true) return (c += parseInt(d.totalPrice));
            else return c;
          }, 0);
          return (a += price);
        }, 0);
      }
      this.setState({ totalPriceCart });
    } else {
      if (this.state.cartActiveTotalItem > 0) {
        totalPriceCart = this.state.cartActive.reduce((a, b) => {
          const price = b.cartDetail.reduce((c, d) => {
            if (d.isCheck === true) return (c += parseInt(d.totalPrice));
            else return c;
          }, 0);
          return (a += price);
        }, 0);
      }
      this.setState({ totalPriceCart }, () => this.checkSumTotal());
    }
  };

  showCart = (cartId) => {
    this.setState({ isLoading: true });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    API.get(`/cart/show_cart/${cartId}`, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.data) {
          this.setState({
            showCartData: res.data.data.detail_cart,
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  // update API based on action
  actionUpdate = (
    sellerId,
    actionType,
    cartIndex,
    cartDetailIndex,
    cartDetailId
  ) => {
    if (actionType === "INC") {
      let cartUpdate = [...this.state.cart];
      if (
        cartUpdate[cartIndex].cartDetail[cartDetailIndex].qty <
        cartUpdate[cartIndex].cartDetail[cartDetailIndex].stock
      ) {
        cartUpdate[cartIndex].cartDetail[cartDetailIndex].qty++;
      }
      cartUpdate[cartIndex].cartDetail[cartDetailIndex].totalPrice =
        parseInt(cartUpdate[cartIndex].cartDetail[cartDetailIndex].qty) *
        parseInt(cartUpdate[cartIndex].cartDetail[cartDetailIndex].price);
      const quantityUpdate =
        cartUpdate[cartIndex].cartDetail[cartDetailIndex].qty;
      const noteUpdate = cartUpdate[cartIndex].cartDetail[cartDetailIndex].note;
      const productIdUpdate =
        cartUpdate[cartIndex].cartDetail[cartDetailIndex].productId;
      const checkUpdate =
        cartUpdate[cartIndex].cartDetail[cartDetailIndex].isCheck;
      this.setState({ cart: cartUpdate }, () => {
        this.accumulateCart();
        this.updateCart(
          sellerId,
          productIdUpdate,
          cartDetailId,
          quantityUpdate,
          checkUpdate,
          noteUpdate
        );
      });
    } else if (actionType === "DEC") {
      let cartUpdate = [...this.state.cart];
      if (cartUpdate[cartIndex].cartDetail[cartDetailIndex].qty > 1) {
        cartUpdate[cartIndex].cartDetail[cartDetailIndex].qty--;
      }
      cartUpdate[cartIndex].cartDetail[cartDetailIndex].totalPrice =
        parseInt(cartUpdate[cartIndex].cartDetail[cartDetailIndex].qty) *
        parseInt(cartUpdate[cartIndex].cartDetail[cartDetailIndex].price);
      const quantityUpdate =
        cartUpdate[cartIndex].cartDetail[cartDetailIndex].qty;
      const noteUpdate = cartUpdate[cartIndex].cartDetail[cartDetailIndex].note;
      const productIdUpdate =
        cartUpdate[cartIndex].cartDetail[cartDetailIndex].productId;
      const checkUpdate =
        cartUpdate[cartIndex].cartDetail[cartDetailIndex].isCheck;
      this.setState({ cart: cartUpdate }, () => {
        this.accumulateCart();
        this.updateCart(
          sellerId,
          productIdUpdate,
          cartDetailId,
          quantityUpdate,
          checkUpdate,
          noteUpdate
        );
      });
    }
  };

  changeHandlerNote = (
    event,
    sellerId,
    cartIndex,
    cartDetailIndex,
    cartDetailId
  ) => {
    let cartUpdate = [...this.state.cart];
    cartUpdate[cartIndex].cartDetail[cartDetailIndex].note = event.target.value;
    const quantityUpdate =
      cartUpdate[cartIndex].cartDetail[cartDetailIndex].qty;
    const noteUpdate = cartUpdate[cartIndex].cartDetail[cartDetailIndex].note;
    const productIdUpdate =
      cartUpdate[cartIndex].cartDetail[cartDetailIndex].productId;
    const checkUpdate =
      cartUpdate[cartIndex].cartDetail[cartDetailIndex].isCheck;
    this.setState({ cart: cartUpdate }, () => {
      this.updateCart(
        sellerId,
        productIdUpdate,
        cartDetailId,
        quantityUpdate,
        checkUpdate,
        noteUpdate
      );
    });
  };

  checkAllGroupState = (cartIndex) => {
    if (this.state.cartTotalItem > 0) {
      if (cartIndex) {
        let tempData = this.state.cart[cartIndex].cartDetail.find(
          (a) => a.isCheck === false
        );
        if (!tempData) {
          let cartUpdate = [...this.state.cart];
          cartUpdate[cartIndex].isCheck = true;
          this.setState({ cart: cartUpdate });
        } else {
          let cartUpdate = [...this.state.cart];
          cartUpdate[cartIndex].isCheck = false;
          this.setState({ cart: cartUpdate });
        }
      } else {
        const cart = this.state.cart.map((a) => {
          const tempData = a.cartDetail.find((b) => b.isCheck === false);
          if (!tempData) {
            return { ...a, isCheck: true };
          } else {
            return { ...a, isCheck: false };
          }
        });
        this.setState({ cart });
      }
    }
  };

  checkAllState = () => {
    if (this.state.cartTotalItem > 0) {
      let tempData = this.state.cart.find((a) => {
        return a.cartDetail.find((b) => b.isCheck === false);
      });
      if (!tempData) {
        this.setState({ checkAll: true });
      } else {
        this.setState({ checkAll: false });
      }
    }
  };

  changeHandlerCheck = (sellerId, cartIndex, cartDetailIndex, cartDetailId) => {
    let cartUpdate = [...this.state.cart];
    cartUpdate[cartIndex].cartDetail[cartDetailIndex].isCheck = !cartUpdate[
      cartIndex
    ].cartDetail[cartDetailIndex].isCheck;
    const quantityUpdate =
      cartUpdate[cartIndex].cartDetail[cartDetailIndex].qty;
    const noteUpdate = cartUpdate[cartIndex].cartDetail[cartDetailIndex].note;
    const productIdUpdate =
      cartUpdate[cartIndex].cartDetail[cartDetailIndex].productId;
    const checkUpdate =
      cartUpdate[cartIndex].cartDetail[cartDetailIndex].isCheck;
    this.setState({ cart: cartUpdate }, () => {
      this.accumulateCart();
      this.checkAllState();
      this.checkAllGroupState(cartIndex);
      this.updateCart(
        sellerId,
        productIdUpdate,
        cartDetailId,
        quantityUpdate,
        checkUpdate,
        noteUpdate
      );
    });
  };

  changeHandlerCheckGroup = (sellerId, cartIndex, sellerLocationId) => {
    let cartUpdate = [...this.state.cart];
    cartUpdate[cartIndex].isCheck = !cartUpdate[cartIndex].isCheck;
    cartUpdate[cartIndex].cartDetail.map((cartDetail) => {
      cartDetail.isCheck = cartUpdate[cartIndex].isCheck;
    });
    this.setState({ cart: cartUpdate }, () => {
      this.accumulateCart();
      this.checkAllState();
      this.updateCheckGroup(sellerId, cartIndex, sellerLocationId);
    });
  };

  changeHandlerCheckAll = (sellerId) => {
    this.setState(
      (prevState) => ({
        checkAll: !prevState.checkAll,
        cart: prevState.cart.map((e) => {
          const cartDetail = e.cartDetail.map((e) => ({
            ...e,
            isCheck: !prevState.checkAll,
          }));
          return { ...e, isCheck: !prevState.checkAll, cartDetail: cartDetail };
        }),
      }),
      () => {
        this.accumulateCart();
        this.updateCheckAll(sellerId);
      }
    );
  };

  updateCheckGroup = (sellerId, cartIndex, sellerLocationId) => {
    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    const checkValue = this.state.cart[cartIndex].isCheck ? 1 : 0;

    const data = new FormData();
    data.append("check_value", checkValue);
    data.append("seller_location_id", sellerLocationId);

    API.post(`/cart/${sellerId}/check_cart_group`, data, config)
      .then((res) => {
        if (parseInt(res.data.code) !== 200) {
          console.log(res);
        } else {
          // this.listData(sellerId);
          // this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  updateCheckAll = (sellerId) => {
    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    const checkValue = this.state.checkAll ? 1 : 0;

    const data = new FormData();
    data.append("check_value", checkValue);

    API.post(`/cart/${sellerId}/check_cart`, data, config)
      .then((res) => {
        if (parseInt(res.data.code) !== 200) {
          console.log(res);
        } else {
          // this.listData(sellerId);
          // this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  updateCart = (sellerId, productId, cartDetailId, qty, isCheckPrm, note) => {
    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    let isCheck = isCheckPrm === true ? 1 : 0;

    const data = new FormData();
    data.append("product_id", productId);
    data.append("dtl_carts_id", cartDetailId);
    data.append("qty", qty);
    data.append("note", note);
    data.append("checked", isCheck);
    // data.append("");

    API.post(`/cart/${sellerId}`, data, config)
      .then((res) => {
        if (parseInt(res.data.code) !== 200) {
          console.log(res);
        } else {
          // this.listData(sellerId);
          // this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  deleteCart = (dtlCartId, sellerId) => {
    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    Swal.fire({
      title: "Anda yakin akan menghapus barang ini?",
      icon: "warning",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.value) {
        return API.delete(`/cart/${dtlCartId}`, config)
          .then((res) => {
            if (parseInt(res.data.code) !== 200) {
              console.log(res.data);
            } else {
              Swal.fire({
                title: "Berhasil",
                text: "Barang telah dihapus dari keranjang",
                type: "success",
                showConfirmButton: true,
                confirmButtonText: "Tutup",
              }).then(() => {
                this.listData(sellerId);
              });
            }
          })
          .catch((error) => {
            Swal.disableLoading();
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              if (error.response) {
                let errmessage = "";
                let keys = Object.keys(error.response.data.errors);
                for (let i = 0; i < keys.length; i++) {
                  errmessage +=
                    keys[i] +
                    " " +
                    error.response.data.errors[keys[i]][0] +
                    "<br/>";
                }
                Swal.fire({
                  icon: "error",
                  html: errmessage,
                });
              }
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      } else {
        Swal.close();
      }
    });
  };

  // add address function
  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  changeSelectHandler = (selectedAddress) => {
    this.setState({ selectedAddress }, () =>
      /* {} */
      console.log(`Option selected:`, this.state.selectedAddress)
    );
  };

  loadOptions = (inputValuePrm, callback) => {
    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };

    let inputValue = inputValuePrm ? `keyword=${inputValuePrm}` : "";
    let limit = "&limit=50";
    // let limit = limitPrm ? `&limit=${limitPrm}` : "";

    if (inputValuePrm.length >= 3) {
      API.get(`/prm/zipcode?${inputValue}${limit}`, config).then((res) => {
        // console.log(res);
        if (parseInt(res.data.code) !== 200) {
          alert(res.data.errors);
        } else {
          let zipCodeTemp = res.data.data.zipcode.map((zipCode) => ({
            value: zipCode.value, //id
            label: zipCode.label.toString(), //address
          }));
          callback(zipCodeTemp);
        }
      });
    }
  };

  // getUserListAddress
  checkUserLocationData = (isFromShipment, sellerId) => {
    this.setState({ isLoading: true });

    let addressTemp;
    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + Cookies.get("access_token"),
      },
    };

    if (Cookies.get("access_token")) {
      API.get("/user_location", config)
        .then((res) => {
          if (res.data.data) {
            addressTemp = res.data.data.user_location;
            this.setState(
              {
                addressList: addressTemp,
                isLoading: false,
              },
              () => {
                if (!isFromShipment) {
                  if (this.state.addressList.length > 0) {
                    this.props.history.push("/cart/shipment");
                  } else {
                    this.popAddressModal("ADD", 1);
                  }
                } else {
                  this.listData(sellerId, true);
                  let address = this.state.addressList.find(
                    (address) => parseInt(address.is_primary) === 1
                  );
                  this.setState({
                    selectedShipmentAddress: address,
                  });
                }
              }
            );
          }
        })
        .catch((error) => {
          Swal.disableLoading();
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            if (error.response) {
              let errmessage = "";
              let keys = Object.keys(error.response.data.errors);
              for (let i = 0; i < keys.length; i++) {
                errmessage +=
                  keys[i] +
                  " " +
                  error.response.data.errors[keys[i]][0] +
                  "<br/>";
              }
              Swal.fire({
                icon: "error",
                html: errmessage,
              });
            }
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    }
  };

  popAddressModal = (actionType, no) => {
    this.setState({ isLoading: false });

    const modal = "modal" + no;
    if (actionType === "CLOSE") {
      this.setState({
        [modal]: !this.state[modal],
        actionType,
      });
    } else if (actionType === "ADD") {
      this.setState({ [modal]: !this.state[modal], actionType });
    }
  };

  storeUserLocation = () => {
    this.setState({ isLoading: true });

    //Swal loading
    Swal.fire({
      title: "Menambahkan...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    if (
      this.state.name !== "" &&
      this.state.hp !== "" &&
      this.state.alias !== "" &&
      this.state.address !== "" &&
      this.state.selectedAddress !== ""
    ) {
      const data = new FormData();
      data.append("name", this.state.name);
      data.append("hp", this.state.hp);
      data.append("alias", this.state.alias);
      data.append("zip_id", this.state.selectedAddress.value);
      data.append("address", this.state.address);
      // data.append("latitude", this.state.latitude);
      // data.append("longitude", this.state.longitude);

      API.post(`/user_location`, data, config)
        .then((res) => {
          this.setState({ isLoading: false });
          if (parseInt(res.data.code) !== 200) {
            console.log(res);
          } else {
            Swal.fire({
              icon: "success",
              text: "Berhasil menambahkan alamat, silahkan melanjutkan.",
            }).then((res) => {
              this.setState(
                {
                  name: "",
                  hp: "",
                  alias: "",
                  address: "",
                  selectedAddress: "",
                },
                () => {
                  this.popAddressModal("CLOSE", 1);
                  this.props.history.push("/cart/shipment");
                }
              );
            });
          }
        })
        .catch((error) => {
          Swal.disableLoading();
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            if (error.response) {
              let errmessage = "";
              let keys = Object.keys(error.response.data.errors);
              for (let i = 0; i < keys.length; i++) {
                errmessage +=
                  keys[i] +
                  " " +
                  error.response.data.errors[keys[i]][0] +
                  "<br/>";
              }
              Swal.fire({
                icon: "error",
                html: errmessage,
              });
            }
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Sorry...",
        text: "Tidak boleh ada yang kosong!",
      });
      this.setState({ isLoading: false });
      // this.popAddressModal("CLOSE", 1);
    }
  };

  //function for shipment
  popAddressSelector = (actionType) => {
    this.setState({ isLoading: false });

    if (actionType === "CLOSE") {
      this.setState({
        addressSelectorModal: !this.state.addressSelectorModal,
        actionType,
      });
    } else if (actionType === "OPEN") {
      this.setState({
        addressSelectorModal: !this.state.addressSelectorModal,
        actionType,
      });
    }
  };

  setSelectedAddress = (id) => {
    let address = this.state.addressList.find(
      (address) => parseInt(address.id) === parseInt(id)
    );
    this.setState(
      {
        selectedShipmentAddress: address,
      },
      () => {
        this.resetShipment();
        this.popAddressSelector("CLOSE");
      }
    );
  };

  popCourierSelector = (actionType, cart) => {
    // Currently changed
    if (cart) {
      this.setState({ selectedCartForCourier: cart });
    } else {
      this.setState({ selectedCartForCourier: "" });
    }

    // Current state of courier modal
    if (actionType === "CLOSE") {
      this.setState({
        courierSelectorModal: !this.state.courierSelectorModal,
        courierList: [],
        actionModalCourier: actionType,
      });
    } else if (actionType === "COURIER") {
      this.setState({
        courierSelectorModal: !this.state.courierSelectorModal,
        courierList: cart.listCourier,
        actionModalCourier: actionType,
      });
    }
  };

  setSelectedService = (courier) => {
    const { selectedCartForCourier } = this.state;
    this.setState(
      (prevState) => {
        let cartActive = prevState.cartActive.map((a) =>
          parseInt(a.id) === parseInt(selectedCartForCourier.id)
            ? {
                ...a,
                selectedService: courier,
                isServiceSelected: true,
              }
            : { ...a }
        );
        return { cartActive };
      },
      () => {
        this.checkSumTotal();
        this.popCourierSelector("CLOSE");
        console.log(this.state.cartActive);
      }
    );
  };

  checkSumTotal = () => {
    // check if all cart courier already calculated
    const check = this.state.cartActive.find(
      (cart) => cart.isServiceSelected === false
    );
    if (!check) {
      const sumAll = this.state.cartActive.reduce((a, b) => {
        return (a += parseInt(b.selectedService.cart_price));
      }, 0);
      this.setState({ cartsCourierCalculated: true, sumAllTotal: sumAll });
    } else {
      this.setState({
        cartsCourierCalculated: false,
        sumAllTotal: this.state.totalPriceCart,
      });
    }
  };

  resetShipment = () => {
    // reset if address get changed
    this.setState(
      (prevState) => {
        const cartActive = prevState.cartActive.map((cart) => ({
          ...cart,
          isCurrentlyCheckingCourier: false,
          isCourierAlreadyChecked: false,
          isCheckingCourierFailed: false,
          isServiceSelected: false,
          listCourier: "",
          selectedService: "",
        }));

        return { cartActive };
      },
      () => {
        this.checkPriceCourier();
        this.accumulateCart(true);
      }
    );
  };

  checkPriceCourier = async () => {
    this.setState({ isLoading: true });
    let token = Cookies.get("access_token");
    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
    let axiosArray = [];
    this.state.cartActive.map((cart) => {
      const data = new FormData();
      data.append("cart_id", cart.id);
      data.append("origin", cart.sellerLocation.zip_id);
      data.append("destination", this.state.selectedShipmentAddress.zip_id);
      data.append("cart_weight", cart.cartWeight);
      data.append("cart_price", cart.cartPrice);
      axiosArray.push(API.post(`/prm/courier/check-price`, data, config));
    });
    const response = await Axios.all(axiosArray);
    response.map((res) => {
      if (res.data.data) {
        const data = res.data.data;
        this.setState((prevState) => {
          let cartActive = prevState.cartActive;
          //Check at least 1 courier doesn't have error
          const checkCourier = data.courier.find((a) =>
            !a.courier_data.hasOwnProperty("error") ? true : false
          );
          if (checkCourier) {
            cartActive = prevState.cartActive.map((a) => {
              if (data.cart_id === a.id) {
                return {
                  ...a,
                  listCourier: data.courier,
                  isRouteAvailable: true,
                };
              } else {
                return { ...a };
              }
            });
          }
          return { cartActive, isLoading: false };
        });
      } else {
        console.log(res);
        this.setState({ isLoading: false });
      }
    });
  };

  sendDataToSNAP = async (user, sellerId, sellerName, sellerData) => {
    const cart_details = this.state.cartActive.map((cart) => {
      console.log(cart);
      let sellerLocation = cart.sellerLocation;
      let item_details = cart.cartDetail.map((cartItem) => ({
        detail_id: cartItem.id,
        item_id: cartItem.productId,
        price: cartItem.price,
        quantity: cartItem.qty,
        name: cartItem.productName,
        group_product_id: cartItem.groupProductId,
        detail_name: cartItem.detailName,
        note: cartItem.note,
        img: cartItem.linkPicture,
        weight: cartItem.weight,
      }));
      item_details.push({
        detail_id: "-", //punya courier
        item_id: "COURIER-" + cart.id,
        price: cart.selectedService.courier_price,
        quantity: 1,
        name: cart.selectedService.courier_name,
        group_product_id: "",
        detail_name: cart.selectedService.service,
        note: "",
        img: "",
        weight: "",
      });
      return {
        id: cart.id,
        data_json: {
          cus_profile: user,
          cus_location: this.state.selectedShipmentAddress,
          seller_profile: sellerData,
          seller_location: sellerLocation,
        },
        total_weight: cart.cartWeight,
        courier_detail: {
          courier_name: cart.selectedService.courier_name,
          courier_service: cart.selectedService.service,
          courier_price: cart.selectedService.courier_price,
        },
        item_details: item_details,
      };
    });

    const primaryAddress = this.state.addressList.find(
      (address) => parseInt(address.is_primary) === 1
    );

    const shipmentAddress = this.state.selectedShipmentAddress;

    const customer_details = {
      id: user.id,
      first_name: user.name,
      last_name: "",
      email: user.email,
      phone: user.no_tlp,
      billing_address: {
        first_name: user.name,
        last_name: "",
        email: user.email,
        phone: user.no_tlp,
        address: primaryAddress.address,
        city: primaryAddress.zip.sub_province,
        postal_code: primaryAddress.zip.zip,
        country_code: "IDN",
      },
      shipping_address: {
        first_name: shipmentAddress.name,
        last_name: "",
        email: user.email,
        phone: shipmentAddress.hp,
        address: shipmentAddress.address,
        city: shipmentAddress.zip.sub_province,
        postal_code: shipmentAddress.zip.zip,
        country_code: "IDN",
      },
    };

    const params = {
      transaction_details: {
        gross_amount: this.state.sumAllTotal,
      },
      seller_details: { id: sellerId, seller_name: sellerName },
      cart_details: cart_details,
      customer_details: customer_details,
      callbacks: {
        finish: `http://${window.location.hostname}/transaction-status`,
      },
    };

    console.log(params);

    const enabledPayments = [
      "credit_card",
      "bca_klikpay",
      "bri_epay",
      "qris",
      "permata_va",
      "bca_va",
      "bni_va",
      "other_va",
      "echannel", //mandiri
      "indomaret",
      "alfamart",
    ];

    let token = Cookies.get("access_token");
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    API.post("payment/send-to-snap", params, config)
      .then(function (response) {
        console.log(response);
        window.snap.pay(response.data.midtrans_response.data.token, {
          enabledPayments,
          onSuccess: function (result) {
            let url = new URL(window.location.href);
            url.pathname = `/transaction-status/`;
            url.search = `?order_id=${result.order_id}&status_code=${result.status_code}&transaction_status=${result.transaction_status}&payment_type=${result.payment_type}`;
            window.location.href = url.toString();
          },
          onPending: function (result) {
            let url = new URL(window.location.href);
            url.pathname = `/payment-status/`;
            url.search = `?order_id=${result.order_id}&status_code=${result.status_code}&transaction_status=${result.transaction_status}&payment_type=${result.payment_type}`;
            window.location.href = url.toString();
          },
          onError: function (result) {
            // console.log("error");
            // console.log(result);
          },
          onClose: function () {
            Swal.fire({
              icon: "error",
              text:
                "Layar pembayaran telah ditutup, silahkan ulangi pembayaran.",
            });
          },
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    return (
      <CartContext.Provider
        value={{
          ...this.state,
          listData: this.listData,
          changeEditState: this.changeEditState,
          actionUpdate: this.actionUpdate,
          changeHandlerNote: this.changeHandlerNote,
          updateCart: this.updateCart,
          deleteCart: this.deleteCart,
          changeHandler: this.changeHandler,
          changeSelectHandler: this.changeSelectHandler,
          loadOptions: this.loadOptions,
          checkUserLocationData: this.checkUserLocationData,
          popAddressModal: this.popAddressModal,
          storeUserLocation: this.storeUserLocation,
          changeHandlerCheck: this.changeHandlerCheck,
          changeHandlerCheckGroup: this.changeHandlerCheckGroup,
          changeHandlerCheckAll: this.changeHandlerCheckAll,
          popAddressSelector: this.popAddressSelector,
          setSelectedAddress: this.setSelectedAddress,
          setSelectedService: this.setSelectedService,
          popCourierSelector: this.popCourierSelector,
          sendDataToSNAP: this.sendDataToSNAP,
        }}
      >
        {this.props.children}
      </CartContext.Provider>
    );
  }
}

export default withRouter(CartProvider);
