import { MDBRow } from "mdbreact";
import React from "react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import {
  BackgroundSlide,
  StyledButtonSign,
  NextSlide,
  PrevSlide,
  TextSlide,
  StyledButtonSignGroup,
} from "./SliderCarousel.styles";

export const Slides = (props) => {
  return (
    <div>
      {props.slidesState.map((item, idx) => {
        return <Slide item={item} key={idx} />;
      })}
      <PrevSlide onClick={() => props.changeSlide(-1)}> &#10094; </PrevSlide>
      <NextSlide onClick={() => props.changeSlide(1)}> &#10095; </NextSlide>
    </div>
  );
};

const Slide = (props) => {
  return (
    <div style={{ display: props.item.displayState }}>
      <BackgroundSlide useMainBg={props.item.id === 1 ? true : false} />
      <TextSlide welcome={props.item.id === 1 ? true : false}>
        {props.item.text}
      </TextSlide>
      {props.item.id === 1 ? <ButtonSign /> : ""}
    </div>
  );
};

const ButtonSign = () => {
  let history = useHistory();
  const handleClick = (to) => {
    history.push(to);
  };

  return (
    <StyledButtonSignGroup>
      {!Cookies.get("access_token") ? (
        <>
          <StyledButtonSign onClick={() => handleClick("/register")}>
            SIGN UP
          </StyledButtonSign>

          <StyledButtonSign
            onClick={() => {
              handleClick("/login");
            }}
          >
            SIGN IN
          </StyledButtonSign>
        </>
      ) : (
        ""
      )}
    </StyledButtonSignGroup>
  );
};
