import React, { Component } from "react";
import style from "./InputNumber.module.css";

class InputNumber extends Component {
  // state = {
  //   value: 0
  // }

  // decrease = () => {
  //   this.setState({ value: this.state.value - 1 });
  // }

  // increase = () => {
  //   this.setState({ value: this.state.value + 1 });
  // }

  render() {
    return (
      <div className={`${style.number_input}`}>
        <button
          onClick={this.props.decrease}
          className={`${style.minus}`}
        ></button>
        <input
          readOnly
          className={`${style.quantity}`}
          name="quantity"
          value={this.props.value} /* onChange={()=> console.log('change')} */
          type="number"
        />
        <button
          onClick={this.props.increase}
          className={`${style.plus}`}
        ></button>
      </div>
    );
  }
}

export default InputNumber;
