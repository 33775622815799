import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
} from "mdbreact";
import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class InvoiceView extends Component {
  state = {
    show: true,
  };

  handleShowDetail = () => {
    this.setState({ show: !this.state.show });
  };

  formatDate = (string) => {
    var options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(string).toLocaleDateString("ID", options);
  };

  formatCurrency = (country, currency, text) => {
    return new Intl.NumberFormat(country, {
      style: "currency",
      currency: currency,
    }).format(text);
  };

  handelTracking = (courier, tracking_code) => {
    const { popupModal, getTrackingStatus } = this.props;
    getTrackingStatus(courier, tracking_code);
  };

  render() {
    const {
      idx,
      invoice,
      handelPickup,
      updateInvoiceStatus,
    } = this.props;

    const status = {
      1: "Meminta Konfirmasi Pesanan",
      2: "Meminta Pesanan Diambil",
      3: "Pesanan dalam Pengiriman",
      4: "Menunggu Konfirmasi Selesai",
      5: "Pesanan Selesai",
      98: "Meminta Pembatalan Pesanan",
      99: "Pesanan Dibatalkan",
    };

    return (
      <MDBCard className="w-100" style={{ marginBottom: "1rem" }}>
        <MDBCardBody>
          <MDBRow>
            <MDBCol>
              <table className="w-100">
                <tbody>
                  <tr>
                    <td>No. Invoice</td>
                    <td>:</td>
                    <td>{invoice.id}</td>
                  </tr>
                  <tr>
                    <td>Status Pesanan</td>
                    <td>:</td>
                    <td>{status[parseInt(invoice.status)]}</td>
                  </tr>
                  <tr>
                    <td>Pengiriman</td>
                    <td>:</td>
                    <td>{`${invoice.courier_name} (${invoice.courier_service})`}</td>
                  </tr>
                  <tr>
                    <td>Total Bayar</td>
                    <td>:</td>
                    <td>
                      {this.formatCurrency(
                        "ID",
                        "IDR",
                        invoice.payment.payment_total
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <hr style={{ padding: "0", margin: "0" }} />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">Customer Data</td>
                  </tr>
                  <tr>
                    <td>Name</td>
                    <td>:</td>
                    <td>{invoice.customer_name}</td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td>:</td>
                    <td>{invoice.customer_tlp}</td>
                  </tr>
                  <tr>
                    <td>Location</td>
                    <td>:</td>
                    <td>{invoice.user_location}</td>
                  </tr>
                </tbody>
              </table>
            </MDBCol>
          </MDBRow>
          <MDBRow style={{ margin: "8px"}}>
            <MDBRow>
              <button
                style={{
                  fontSize: "15px",
                  backgroundColor: "#804199",
                  color: "white",
                  border: `1px solid #804199`,
                  borderRadius: "4px",
                  marginLeft: "5px",
                }}
                onClick={() => this.handleShowDetail()}
              >
                <MDBIcon far icon="eye" />
                {` Lihat Detail`}
              </button>
              <Link to={`${window.location.hostname}/print/invoice/${invoice.id}`} target="_blank">
                <button
                  style={{
                    fontSize: "15px",
                    backgroundColor: "#804199",
                    color: "white",
                    border: `1px solid #804199`,
                    borderRadius: "4px",
                    marginLeft: "5px",
                  }}
                >
                  <MDBIcon icon="print" />
                  {` Cetak`}
                </button>
              </Link>
              {invoice.status === "1" ? (
                <button
                  style={{
                    fontSize: "15px",
                    backgroundColor: "green",
                    color: "white",
                    border: `1px solid green`,
                    borderRadius: "4px",
                    marginLeft: "5px",
                  }}
                  onClick={() => updateInvoiceStatus(invoice.id, 2)}
                >
                  <MDBIcon icon="thumbs-up" />
                  {` Konfirmasi`}
                </button>
              ) : (
                ""
              )}
              {invoice.status === "3" ? (
                <button
                  style={{
                    fontSize: "15px",
                    backgroundColor: "green",
                    color: "white",
                    border: `1px solid green`,
                    borderRadius: "4px",
                    marginLeft: "5px",
                  }}
                  onClick={() =>
                    this.handelTracking(invoice.courier_name, invoice.tracking_code)
                  }
                >
                  <MDBIcon icon="map" />
                  {` Lacak`}
                </button>
              ) : (
                ""
              )}
              {invoice.status === "98" ? (
                <button
                  style={{
                    fontSize: "15px",
                    backgroundColor: "red",
                    color: "white",
                    border: `1px solid red`,
                    borderRadius: "4px",
                    marginLeft: "5px",
                  }}
                  onClick={() => updateInvoiceStatus(invoice.id, 99)}
                >
                  <MDBIcon icon="trash-alt" />
                  {` Konfirmasi Pembatalan`}
                </button>
              ) : (
                ""
              )}
              {invoice.status === "1" || invoice.status === "2" ? (
                <button
                  style={{
                    fontSize: "15px",
                    backgroundColor: "red",
                    color: "white",
                    border: `1px solid red`,
                    borderRadius: "4px",
                    marginLeft: "5px",
                  }}
                  onClick={() => updateInvoiceStatus(invoice.id, 99)}
                >
                  <MDBIcon icon="trash-alt" />
                  {` Tolak Pesanan`}
                </button>
              ) : (
                ""
              )}
              {invoice.status === "2" ? (
                <button
                  style={{
                    fontSize: "15px",
                    backgroundColor: invoice.flag ? "red" : "green",
                    color: "white",
                    border: `1px solid ${invoice.flag ? "red" : "green"}`,
                    borderRadius: "4px",
                    marginLeft: "5px",
                    float:'right'
                  }}
                  onClick={() => handelPickup(idx)}
                >
                  {invoice.flag ? (
                    <MDBIcon icon="window-close" />
                  ) : (
                    <MDBIcon icon="check-square" />
                  )}
                  {` ${invoice.flag ? "Batal" : "Pilih"}`}
                </button>
              ) : (
                ""
              )}
            </MDBRow>
            <div
              className="overflow-auto"
              hidden={this.state.show}
              style={{
                width: "100%",
                height: "180px",
                marginTop: "1rem",
              }}
            >
              {invoice.dtl_invoice.map((data, index) => {
                let weight =
                  parseInt(data.product_quantity) *
                  parseInt(data.product_weight);
                let price =
                  parseInt(data.product_quantity) *
                  parseInt(data.product_price);
                let isCourier =
                  data.product_id.toString().split("-")[0] === "COURIER";
                return (
                  <MDBRow
                    key={index}
                    style={{
                      marginBottom: "1rem",
                      fontSize: "15px",
                      marginLeft: "0",
                      marginRight: "0",
                    }}
                  >
                    <MDBCol size="3" style={{ textAlign: "center" }}>
                      {isCourier ? (
                        ""
                      ) : (
                        <img
                          className="img-fluid img-thumbnail z-depth-2"
                          src={data.product_image}
                          style={{ maxHeight: "4rem" }}
                        />
                      )}
                    </MDBCol>
                    <MDBCol>
                      <MDBRow>{`${data.product_name}`}</MDBRow>
                      {isCourier ? (
                        <>
                          <MDBRow>
                            {`Subtotal : ${new Intl.NumberFormat("ID", {
                              style: "currency",
                              currency: "IDR",
                            }).format(price)}`}
                          </MDBRow>
                        </>
                      ) : (
                        <>
                          <MDBRow>
                            {`Subtotal : ${new Intl.NumberFormat("ID", {
                              style: "currency",
                              currency: "IDR",
                            }).format(price)} (${
                              data.product_quantity
                            } item / ${weight} gr)`}
                          </MDBRow>
                          <MDBRow>{`Note : ${data.product_note}`}</MDBRow>
                        </>
                      )}
                    </MDBCol>
                  </MDBRow>
                );
              })}
            </div>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    );
  }
}
