import React, { Component } from "react";
import queryString from "query-string";
import WebSearchView from "../components/WebSearchView/WebSearchView";
import API from "../../utils/API";
import Swal from "sweetalert2";

export default class WebSearch extends Component {
  state = {
    query: "",
    title: "",
    data: [],
    isLoading: false,
    isError: false,
  };

  componentDidMount() {
    this.getQueryValue();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
      this.getQueryValue();
    }
  }

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  submitHandler = (event) => {
    if (event) {
      event.preventDefault();
    }

    const { history } = this.props;
    const { query } = this.state;

    if (history && query) {
      history.push("/search?q=" + this.state.query);
    }
  };

  getQueryValue = () => {
    const values = queryString.parse(this.props.location.search);
    this.setState({ query: values.q }, () => {
      this.getSearchResult();
      if (this.state.query)
        this.setState({
          title: `Hasil untuk pencarian "${this.state.query}"`,
        });
    });
  };

  getSearchResult = () => {
    this.setState({ isLoading: true });

    const data = new FormData();
    data.append("query", this.state.query);

    API.post(`/web-search`, data)
      .then((res) => {
        this.setState({ isLoading: false });
        if (parseInt(res.data.code) !== 200) {
          console.log(res);
        } else {
          this.setState({ data: res.data.data.search });
        }
      })
      .catch((error) => {
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  render() {
    return (
      <WebSearchView
        {...this.state}
        changeHandler={this.changeHandler}
        submitHandler={this.submitHandler}
      />
    );
  }
}
