import React, { Component } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCardText,
  MDBCardTitle,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
} from "mdbreact";
import SectionContainer from "../sectionContainer";
import ShipmentDetailProductView from "./ShipmentDetailProductView";
import ShipmentUserLocationInfo from "./ShipmentUserLocationInfo";
import UserLocationListModal from "./UserLocationListModal";
import { CMDBBadge, CMDBBtn } from "../StyledComponent";

export default class ShipmentView extends Component {
  componentDidMount() {
    const sellerId = this.props.sellerprm.subDomainData.id;
    this.props.shipmentprm.setInit(sellerId);
  }

  render() {
    const {
      //state
      is_ready,
      cart_list,
      selected_location,
      total_item,
      total_price,
      total_shipment_service,
      //function
      popupModal,
      paymentHandler,
      changeServiceHandler,
      changeCourierHandler,
    } = this.props.shipmentprm;
    const sellerId = this.props.sellerprm.subDomainData.id;
    const sellerName = this.props.sellerprm.subDomainData.name_web;
    const { dataUser } = this.props.sellerprm;
    const { seller_style, seller_data } = this.props.sellerprm.subDomainData;
    // console.log(this.props)
    return (
      <>
        <UserLocationListModal
          {...this.props.shipmentprm}
          seller_style={seller_style}
        />
        <MDBContainer style={{ paddingTop: "50px", minWidth: "1100px" }}>
          {cart_list && selected_location ? (
            <SectionContainer
              header={"CHECKOUT"}
              style={{ minWidth: "1100px" }}
            >
              <MDBRow>
                <MDBCol size="8">
                  <ShipmentUserLocationInfo
                    selected_location={selected_location}
                    popupModal={popupModal}
                    seller_style={seller_style}
                  />
                  <hr />
                  <div className="overflow-auto" style={{ maxHeight: "380px" }}>
                    {cart_list.map((cart, cart_index) => {
                      // console.log(cart);
                      if (cart.total_product > 0) {
                        return (
                          <div key={cart.cart_id}>
                            <MDBCard>
                              <MDBCardBody>
                                <MDBCardTitle>
                                  {cart.seller_location.zip.sub_district}
                                </MDBCardTitle>
                                <ShipmentDetailProductView
                                  cart_detail={cart.cart_detail}
                                />
                                <MDBContainer>
                                  <MDBRow>
                                    <MDBCol size="7">
                                      {cart.cart_detail.map((data) => {
                                        return (
                                          <ShipmentDetailProductView
                                            data={data}
                                            key={data.detail_id}
                                          />
                                        );
                                      })}
                                    </MDBCol>
                                    <MDBCol size="5">
                                      <h4>
                                        <CMDBBadge
                                          bg_color={seller_style.color_btn}
                                        >
                                          Pilih Layanan Pengiriman :
                                        </CMDBBadge>
                                      </h4>
                                      {cart.courier_list.length > 0 ? (
                                        <MDBSelect
                                          className="w-100"
                                          options={cart.courier_list}
                                          selected="Pilih Kurir"
                                          label="Pilih Kurir"
                                          getValue={(e) =>
                                            changeCourierHandler(e, {
                                              cart_index: cart_index,
                                            })
                                          }
                                        />
                                      ) : (
                                        <div style={{ textAlign: "left" }}>
                                          <span>
                                            Alamat Anda di luar jangkauan jasa
                                            kurir yang ada
                                          </span>
                                          <br />
                                          <CMDBBtn
                                            bg_color={seller_style.color_btn}
                                            size="sm"
                                            onClick={() =>
                                              (window.location.href = "/cart")
                                            }
                                          >
                                            Back to Cart
                                          </CMDBBtn>
                                        </div>
                                      )}
                                      {cart.courier_is_selected ? (
                                        <MDBSelect
                                          label={"Pilih Service"}
                                          getValue={(e) =>
                                            changeServiceHandler(e, {
                                              cart_index: cart_index,
                                            })
                                          }
                                        >
                                          <MDBSelectInput
                                            selected={"Pilih Service"}
                                          />
                                          <MDBSelectOptions>
                                            <MDBSelectOption disabled>
                                              {"Pilih Service"}
                                            </MDBSelectOption>
                                            {cart.service_list[
                                              parseInt(
                                                cart.selected_courier.value
                                              )
                                            ].service.map((data, index) => {
                                              return (
                                                <MDBSelectOption
                                                  value={data}
                                                  key={cart_index + "_" + index}
                                                >
                                                  {`${data.service} (${data.estimate} hari) `}
                                                </MDBSelectOption>
                                              );
                                            })}
                                          </MDBSelectOptions>
                                        </MDBSelect>
                                      ) : (
                                        ""
                                      )}
                                      {cart.service_is_selected ? (
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <span
                                            style={{ textAlign: "left" }}
                                          >{`Ongkos Kirim`}</span>
                                          <span style={{ textAlign: "center" }}>
                                            {new Intl.NumberFormat("ID", {
                                              style: "currency",
                                              currency: "IDR",
                                            }).format(cart.service_price)}
                                          </span>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </MDBCol>
                                  </MDBRow>
                                </MDBContainer>
                                <hr />
                                <MDBContainer>
                                  <MDBRow
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <span
                                      style={{ textAlign: "left" }}
                                    >{`Subtotal`}</span>
                                    <span style={{ textAlign: "right" }}>
                                      {new Intl.NumberFormat("ID", {
                                        style: "currency",
                                        currency: "IDR",
                                      }).format(
                                        cart.total_price + cart.service_price
                                      )}
                                    </span>
                                  </MDBRow>
                                </MDBContainer>
                              </MDBCardBody>
                            </MDBCard>
                            <br />
                          </div>
                        );
                      }
                    })}
                  </div>
                </MDBCol>
                <MDBCol size="3">
                  <MDBCard style={{ minWidth: "338px" }}>
                    <MDBCardBody>
                      <MDBCardTitle>Ringkasan Belanja</MDBCardTitle>
                      <MDBCardText
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{ textAlign: "left" }}
                        >{`Total Harga (${total_item} Product)`}</span>
                        <span style={{ textAlign: "right" }}>
                          {new Intl.NumberFormat("ID", {
                            style: "currency",
                            currency: "IDR",
                          }).format(total_price)}
                        </span>
                      </MDBCardText>
                      <MDBCardText
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{ textAlign: "left" }}
                        >{`Total Ongkos Kirim`}</span>
                        <span style={{ textAlign: "right" }}>
                          {new Intl.NumberFormat("ID", {
                            style: "currency",
                            currency: "IDR",
                          }).format(total_shipment_service)}
                        </span>
                      </MDBCardText>
                      <hr />
                      <MDBCardText
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{ textAlign: "left" }}
                        >{`Total Tagihan`}</span>
                        <span style={{ textAlign: "right" }}>
                          {new Intl.NumberFormat("ID", {
                            style: "currency",
                            currency: "IDR",
                          }).format(
                            (
                              parseInt(total_shipment_service) +
                              parseInt(total_price)
                            ).toString()
                          )}
                        </span>
                      </MDBCardText>
                      <MDBRow>
                        <MDBCol>
                          <CMDBBtn
                            active
                            bg_color={seller_style.color_btn}
                            className="w-100"
                            disabled={!is_ready}
                            onClick={() =>
                              paymentHandler(
                                dataUser,
                                sellerId,
                                sellerName,
                                seller_data
                              )
                            }
                          >{`Pilih Pembayaran`}</CMDBBtn>
                        </MDBCol>
                      </MDBRow>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </SectionContainer>
          ) : (
            ""
          )}
        </MDBContainer>
      </>
    );
  }
}
